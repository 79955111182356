import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CpGuard implements CanActivate {

  constructor( private objAuthService : AuthService,
                private objRoute : Router ) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if( ! this.objAuthService.isAuthenticaded() ) {
        this.objRoute.navigate(['/auth/login']);
      }

    return this.objAuthService.isAuthenticaded();
  }

}
