<div class="mat-dialog-title">
  <div class="title">Verificacion de configuracion de correo</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="btnClose_clickEvent()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content">
    <div class="bs-row">
      <div class="bs-col">
        <span class="material-icons icon-check" *ngIf="boolPort=='true'" [style.color]="'#229954'" >check_circle</span>
        <span class="material-icons icon-check" *ngIf="boolPort=='false'" [style.color]="'#D50000'" >cancel</span>
        <span class="material-icons icon-check" *ngIf="boolPort==''"      [style.color]="'#797D7F'" >circle</span>
        <span>Puerto del servidor abierto</span>
      </div>
    </div>

    <div class="bs-row">
      <div class="bs-col">
        <span class="material-icons icon-check" *ngIf="boolServer=='true'" [style.color]="'#229954'" >check_circle</span>
        <span class="material-icons icon-check" *ngIf="boolServer=='false'" [style.color]="'#D50000'" >cancel</span>
        <span class="material-icons icon-check" *ngIf="boolServer==''"      [style.color]="'#797D7F'" >circle</span>
        <span>Conexión con el servidor</span>
      </div>
    </div>

    <div class="bs-row">
      <div class="bs-col">
        <span class="material-icons icon-check" [style.color]="(boolEmail=='true')?'#229954':(boolEmail=='false')?'#D50000':'#797D7F'" >
          {{(boolEmail == 'true') ? 'check_circle' : (boolEmail == 'false') ? 'cancel' : 'circle'}}
        </span>
        <span>Inicio de sesión en el servidor de correo de entrada</span>
      </div>
    </div>

    <div class="bs-row">
      <div class="bs-col">
        <span class="material-icons icon-check" [style.color]="(boolMessage=='true')?'#229954':(boolMessage=='false')?'#D50000':'#797D7F'" >
          {{(boolMessage == 'true') ? 'check_circle' : (boolMessage == 'false') ? 'cancel' : 'circle'}}
        </span>
        <span>Enviar mensaje de correo electrónico</span>
      </div>
    </div>

  </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="btnAceptar_clickEvent()">{{acceptLabel}}</button>
  <button *ngIf="boolMessage != 'true'" class="gray" type="button" [disabled]="btnCancelar.disabled" (click)="btnCancelar_clickEvent()">{{cancelLabel}}</button>
</div>
