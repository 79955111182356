import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DatePipe} from "@angular/common";
import {CotizacionesService} from "../../../../services/cotizaciones.service";
import {AuthService} from "../../../../services/auth.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FormOptimizer} from "../../../../libraries/formOptimizer";
import {ApplicationConfig} from "../../../../libraries/application-config";
import {AlertComponent} from "../../../../components/alert/alert.component";
import {Globals} from "../../../../libraries/globals";
import {SuccessComponent} from "../../../../components/success/success.component";

@Component({
  selector: 'app-cotizaciones-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class CotizacionesCancelComponent implements OnInit {

  @Output('onClose') onClose : EventEmitter<CotizacionesCancelComponent> = new EventEmitter<CotizacionesCancelComponent>();
  public objApplicationConfig = ApplicationConfig;
  public objCotizacion        : any;
  public objUsuario           : any;
  public lstUsuarios          : Array<any>;

  public fgCotizacionesCancel : FormGroup = new FormGroup({
    cancelacionComentarios     : new FormControl('', Validators.required)
  });

  public objLoader = {
    type            : 'loader',
    visible         : false
  };

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  };

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  };

  public formComponents: Array<any> = [
    this.fgCotizacionesCancel,
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objDialogRef: MatDialogRef<CotizacionesCancelComponent>,
    private objMatDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private objCotizacionesService : CotizacionesService,
    private objAuthService : AuthService
  ) { }

  ngOnInit() {
  }

  public formCotizacionCancelacion_submitEvent() : void {
    (<any>Object).values(this.fgCotizacionesCancel.controls).forEach( (itemControl : FormControl) => {
      itemControl.markAsTouched();
    });


    if(this.fgCotizacionesCancel.valid) {
      FormOptimizer.formDisable(this.formComponents);

      const idUsuario : number = this.objAuthService.usuario.idUsuario;

      let cotizacionCancel : any = {
        idCotizacion           : this.data.idCotizacion,
        cancelacionComentarios : this.fgCotizacionesCancel.controls['cancelacionComentarios'].value
      }

      this.objCotizacionesService.updateEstatusCancelar(cotizacionCancel)
        .subscribe( objResponse => {
          if( objResponse.action) {
            this.objCotizacion = objResponse.result.cotizacion;
            this.close();
            this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
          } else
            this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
            FormOptimizer.formEnable(this.formComponents);
        }, error => {
          FormOptimizer.formEnable(this.formComponents);
        });
    } else {
      this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Formulario incompleto", mensaje : "Complete los campos solicitados.", autoCloseDelay : 3000}));
      FormOptimizer.formEnable(this.formComponents);
    }

  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }
}
