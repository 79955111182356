import {Globals} from "./globals";

export class CotizacionPartidaCalc {

  public static calcular(lstPartidas : Array<any>, objMoneda: any, equivalencia : number) : Array<any> {
    let lstCotizacionesPartidas : Array<any> = [];

    if (lstPartidas.length > 0) {
      lstPartidas.forEach(itemCotizacionPartida => {

        let objReturn : any = {};

        let precioXCantidad: number = this.calcPrecioXCantidad(objMoneda, equivalencia, itemCotizacionPartida.moneda, Number(itemCotizacionPartida.precioUnitario), Number(itemCotizacionPartida.cantidad));
        let descuentoCantidad : number = this.calcDescuentoCantidad(itemCotizacionPartida.idCotizacionPartidaTipoDescuento, precioXCantidad, Number(itemCotizacionPartida.descuentoCantidad), Number(itemCotizacionPartida.descuentoPorcentaje));
        let descuentoPorcentaje : number = this.calcDescuentoPorcentaje(itemCotizacionPartida.idCotizacionPartidaTipoDescuento, precioXCantidad, Number(itemCotizacionPartida.descuentoCantidad), Number(itemCotizacionPartida.descuentoPorcentaje));
        let importe : number = this.calcImporte(precioXCantidad, descuentoCantidad);

        objReturn = {
          checked                          : itemCotizacionPartida.checked,
          servicio                         : itemCotizacionPartida.servicio,
          idServicio                       : Number(itemCotizacionPartida.idServicio),
          concepto                         : itemCotizacionPartida.concepto,
          unidadMedida                     : itemCotizacionPartida.unidadMedida,
          idUnidadMedida                   : Number(itemCotizacionPartida.idUnidadMedida),
          precioUnitario                   : Number(itemCotizacionPartida.precioUnitario).toFixed(2),
          precioXCantidad                  : precioXCantidad.toFixed(2),
          moneda                           : itemCotizacionPartida.moneda,
          idMoneda                         : Number(itemCotizacionPartida.idMoneda),
          cantidad                         : Number(itemCotizacionPartida.cantidad).toFixed(2),
          descuentoCantidad                : descuentoCantidad.toFixed(2),
          descuentoPorcentaje              : descuentoPorcentaje.toFixed(2),
          idCotizacionPartidaTipoDescuento : itemCotizacionPartida.idCotizacionPartidaTipoDescuento,
          impuestos                        : this.lstInnerImpuestos(itemCotizacionPartida.impuestos, precioXCantidad),
          importe                          : importe.toFixed(2)
        }

        lstCotizacionesPartidas.push(objReturn);
      });
    }

    return lstCotizacionesPartidas;
  }

  public static calcularEdit(lstPartidas : Array<any>, objMoneda: any, equivalencia : number) : Array<any> {
    let lstCotizacionesPartidas : Array<any> = [];

    if (lstPartidas.length > 0) {
      lstPartidas.forEach(itemCotizacionPartida => {

        let objReturn : any = {};

        let precioXCantidad: number = this.calcPrecioXCantidad(objMoneda, equivalencia, itemCotizacionPartida.moneda, Number(itemCotizacionPartida.precioUnitario), Number(itemCotizacionPartida.cantidad));
        let descuentoCantidad : number = this.calcDescuentoCantidad(itemCotizacionPartida.idCotizacionPartidaTipoDescuento, precioXCantidad, Number(itemCotizacionPartida.descuentoCantidad), Number(itemCotizacionPartida.descuentoPorcentaje));
        let descuentoPorcentaje : number = this.calcDescuentoPorcentaje(itemCotizacionPartida.idCotizacionPartidaTipoDescuento, precioXCantidad, Number(itemCotizacionPartida.descuentoCantidad), Number(itemCotizacionPartida.descuentoPorcentaje));
        let importe : number = this.calcImporte(precioXCantidad, descuentoCantidad);

        objReturn = {
          idCotizacionPartida              : itemCotizacionPartida.idCotizacionPartida,
          checked                          : itemCotizacionPartida.checked,
          servicio                         : itemCotizacionPartida.servicio,
          idServicio                       : Number(itemCotizacionPartida.idServicio),
          concepto                         : itemCotizacionPartida.concepto,
          unidadMedida                     : itemCotizacionPartida.unidadMedida,
          idUnidadMedida                   : Number(itemCotizacionPartida.idUnidadMedida),
          precioUnitario                   : Number(itemCotizacionPartida.precioUnitario).toFixed(2),
          precioXCantidad                  : precioXCantidad.toFixed(2),
          moneda                           : itemCotizacionPartida.moneda,
          idMoneda                         : Number(itemCotizacionPartida.idMoneda),
          cantidad                         : Number(itemCotizacionPartida.cantidad).toFixed(2),
          descuentoCantidad                : descuentoCantidad.toFixed(2),
          descuentoPorcentaje              : descuentoPorcentaje.toFixed(2),
          idCotizacionPartidaTipoDescuento : itemCotizacionPartida.idCotizacionPartidaTipoDescuento,
          impuestos                        : this.lstInnerImpuestosEdit(itemCotizacionPartida.impuestos, precioXCantidad),
          importe                          : importe.toFixed(2)
        }

        lstCotizacionesPartidas.push(objReturn);
      });
    }

    return lstCotizacionesPartidas;
  }

  public static calcPrecioXCantidad(objMoneda: any, equivalencia : number, monedaPartida : any, precioUnitario : number, cantidad : number): number {
    let precioXCantidad : number = 0;

    if (Globals.exist(objMoneda) && objMoneda != null && precioUnitario != 0 && equivalencia != 0) {
      if (objMoneda.codigo != monedaPartida) {
        let precioUnitarioXEquivalencia : number = 0;
        if (objMoneda?.idMoneda != 1) {
          precioUnitarioXEquivalencia = precioUnitario / equivalencia;
        } else if (objMoneda?.codigo != monedaPartida) {
          precioUnitarioXEquivalencia = precioUnitario * equivalencia;
        }
        precioXCantidad = cantidad * precioUnitarioXEquivalencia;
      } else {
        precioXCantidad = cantidad * precioUnitario;
      }
    }

    return precioXCantidad;
  }

  public static calcDescuentoCantidad(idCotizacionPartidaTipoDescuento : number, precioXCantidad : number, descuentoCantidad : number, descuentoPorcentaje : number): number {
    let descuento : number = 0;

    if (idCotizacionPartidaTipoDescuento != 0) {
      if ( idCotizacionPartidaTipoDescuento == 2 ) {
        descuento = (precioXCantidad * descuentoPorcentaje) / 100;
      } else {
        descuento = descuentoCantidad;
      }
    }

    return descuento;
  }

  public static calcDescuentoPorcentaje(idCotizacionPartidaTipoDescuento : number, precioXCantidad : number, descuentoCantidad : number, descuentoPorcentaje : number): number {
    let descuento : number = 0;

    if (idCotizacionPartidaTipoDescuento != 0) {
      if ( idCotizacionPartidaTipoDescuento == 1 ) {
        descuento = (descuentoCantidad / precioXCantidad) * 100;
      } else {
        descuento = descuentoPorcentaje;
      }
    }

    return descuento;
  }

  private static lstInnerImpuestos(lstImpuestos : Array<any>, precioXCantidad : number) : Array<any> {
    let lstImpuestosReturn : Array<any> = []

    lstImpuestos.forEach(itemImpuesto => {

      let objImpuesto: any = {
        porcentajeNatural : itemImpuesto.porcentajeNatural,
        impuesto          : itemImpuesto.impuesto,
        impuestoTipo : {
          idImpuestoTipo : itemImpuesto.impuestoTipo.idImpuestoTipo,
          impuestoTipo   : itemImpuesto.impuestoTipo.impuestoTipo,
        },
        idImpuesto        : itemImpuesto.idImpuesto,
        porcentaje        : itemImpuesto.porcentaje,
        enabled           : itemImpuesto.enabled,

        checked           : itemImpuesto.checked,
        total             : Number(precioXCantidad * (itemImpuesto.porcentaje / 100)).toFixed(2)
      }

      lstImpuestosReturn.push(objImpuesto);
    });

    return lstImpuestosReturn;
  }

  private static lstInnerImpuestosEdit(lstImpuestos : Array<any>, precioXCantidad : number) : Array<any> {
    let lstImpuestosReturn : Array<any> = []

    lstImpuestos.forEach(itemImpuesto => {

      let objImpuesto: any = {
        porcentajeNatural : itemImpuesto.porcentajeNatural,
        impuesto          : itemImpuesto.impuesto,
        impuestoTipo : {
          idImpuestoTipo : itemImpuesto.impuestoTipo.idImpuestoTipo,
          impuestoTipo   : itemImpuesto.impuestoTipo.impuestoTipo,
        },
        idImpuesto        : itemImpuesto.idImpuesto,
        porcentaje        : itemImpuesto.porcentaje,
        enabled           : itemImpuesto.enabled,

        idCotizacionPartidaImpuesto : itemImpuesto.idCotizacionPartidaImpuesto,
        checked                     : itemImpuesto.checked,
        total                       : Number(precioXCantidad * (itemImpuesto.porcentaje / 100)).toFixed(2)
      }

      lstImpuestosReturn.push(objImpuesto);
    });

    return lstImpuestosReturn;
  }

  public static calcImporte(precioXCantidad : number, descuentoCantidad : number) : number {
    let importe : number = 0;

    if(descuentoCantidad != 0) {
      importe = precioXCantidad - descuentoCantidad;
    } else {
      importe = precioXCantidad;
    }

    return importe;
  }

  public static roundedUpDecimal(num: number): number {
    return Math.ceil(num / 10) * 10;
  }

  public static roundedUpHundreds(num: number): number {
    return Math.ceil(num / 100) * 100;
  }

  public static roundedUpThousands(num: number): number {
    return Math.ceil(num / 1000) * 1000;
  }

  public static roundedDownDecimal(num: number): number {
    return Math.floor(num / 10) * 10;
  }

  public static roundedDownHundreds(num: number): number {
    return Math.floor(num / 100) * 100;
  }

  public static roundedDownThousands(num: number): number {
    return Math.floor(num / 1000) * 1000;
  }
}
