<!-- ============================================================== -->
<!-- Notification - style you can find in header.scss -->
<!-- ============================================================== -->
<button [matMenuTriggerFor]="notification" class="btn-notification">
    <mat-icon class="icon-notify" [class.icon-notify-shake]="boolNewNotify && (objPrivilegio.COTIZACIONES_LIST || objPrivilegio.ORDENES_PEDIDOS_LIST || objPrivilegio.ORDENES_SERVICIOS_LIST || objPrivilegio.MANTENIMIENTO_LIST)" >notifications</mat-icon>
    <div class="notify" [hidden]="boolNotify || (!objPrivilegio.COTIZACIONES_LIST && !objPrivilegio.ORDENES_PEDIDOS_LIST && !objPrivilegio.ORDENES_SERVICIOS_LIST && !objPrivilegio.MANTENIMIENTO_LIST)">
        <span class="heartbit"></span>
        <span class="point"></span>
    </div>
</button>
<mat-menu #notification="matMenu" class="mymegamenu">
  <div class="mailbox" [style.width.px]="(objPrivilegio.COTIZACIONES_LIST && objPrivilegio.ORDENES_PEDIDOS_LIST && objPrivilegio.ORDENES_SERVICIOS_LIST && objPrivilegio.MANTENIMIENTO_LIST)?468:350">
    <div class="header">
      <div class="drop-title" (click)="$event.stopPropagation()">Notificaciones</div>
    </div>
    <div class="body">
      <div class="toolbar-menu">
        <button class="toolbar-button" [hidden]="!objPrivilegio.COTIZACIONES_LIST" [class.active]="objScene == 'cotizacion'" (click)="toolbarViewUpdate( $event, 'cotizacion')">
          Cotizaciones
          <div class="toolbar-notify" [hidden]="boolNewNotifyCotizacion">
            <span class="point"></span>
          </div>
        </button>
        <button class="toolbar-button" [hidden]="!objPrivilegio.ORDENES_PEDIDOS_LIST" [class.active]="objScene == 'pedido'"     (click)="toolbarViewUpdate( $event, 'pedido')">
          Ordenes pedido
          <div class="toolbar-notify" [hidden]="boolNewNotifyOrdPedido">
            <span class="point"></span>
          </div>
        </button>
        <button class="toolbar-button" [hidden]="!objPrivilegio.ORDENES_SERVICIOS_LIST" [class.active]="objScene == 'servicio'"   (click)="toolbarViewUpdate( $event, 'servicio')">
          Ordenes servicio
          <div class="toolbar-notify" [hidden]="boolNewNotifyOrdServicio">
            <span class="point"></span>
          </div>
        </button>
        <button class="toolbar-button" [hidden]="!objPrivilegio.MANTENIMIENTO_LIST" [class.active]="objScene == 'mantenimiento'"   (click)="toolbarViewUpdate( $event, 'mantenimiento')">
          Mantenimiento
          <div class="toolbar-notify" [hidden]="boolNewNotifyOrdMantenimiento">
            <span class="point"></span>
          </div>
        </button>
      </div>

      <div class="message-center" [hidden]="objScene != 'cotizacion'">
        <div class="item-message" *ngFor="let itemCotizacion of lstCotizaciones">
          <div class="notify-content">
            <div class="icon-content">
              <div class="round" matTooltip="{{itemCotizacion.createUsuarioNombreCompleto}}" [style.backgroundColor]="itemCotizacion.background">
                <span [style.color]="itemCotizacion.color">{{itemCotizacion.createUsuarioIniciales}}</span>
              </div>
            </div>
            <div class="mail-content">
              <span class="title">{{itemCotizacion.cotizacion.folio}}</span>
              <span class="subtitle">{{itemCotizacion.cotizacion.clienteNombreComercial}}</span>
              <span class="event">{{itemCotizacion.registroModuloAccionMensaje}}</span>
              <div class="button-link-content">
                <div [hidden]="itemCotizacion.cotizacion.idCotizacionEstatus != 2" class="button-link" matTooltip="Aprobar" (click)="btnCotizacionAprobar_clickEvent(itemCotizacion.cotizacion)">
                  <span class="material-icons">task</span>
                </div>

                <div [hidden]="itemCotizacion.cotizacion.idCotizacionEstatus != 1" class="button-link" matTooltip="Modificar o generar" (click)="btnCotizacionEdit_clickEvent(itemCotizacion.cotizacion)">
                  <span class="material-icons">edit</span>
                </div>

                <div [hidden]="itemCotizacion.cotizacion.idCotizacionEstatus == 3 || itemCotizacion.cotizacion.idCotizacionEstatus == 5" class="button-link" matTooltip="Cancelar" (click)="btnCotizacionCancel_clickEvent(itemCotizacion.cotizacion)">
                  <span class="material-icons">cancel</span>
                </div>
              </div>
            </div>
            <div class="badge-content">
              <span class="badge" [class]="['cotizacion-estatus-' + itemCotizacion.cotizacion.idCotizacionEstatus]">{{itemCotizacion.cotizacion.cotizacionEstatus}}</span>
              <span class="date">{{itemCotizacion.createdFechaNatural}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="message-center" [hidden]="objScene != 'pedido'">
        <div class="item-message" *ngFor="let itemOrdenePedido of lstOrdenesPedidos">
          <div class="notify-content">
            <div class="icon-content">
              <div class="round" matTooltip="{{itemOrdenePedido.createUsuarioNombreCompleto}}" [style.backgroundColor]="itemOrdenePedido.background">
                <span [style.color]="itemOrdenePedido.color">{{itemOrdenePedido.createUsuarioIniciales}}</span>
              </div>
            </div>
            <div class="mail-content">
              <span class="title">{{itemOrdenePedido.ordenPedido.folio}}</span>
              <span class="subtitle">{{itemOrdenePedido.ordenPedido.clienteNombreComercial}}</span>
              <span class="event">{{itemOrdenePedido.registroModuloAccionMensaje}}</span>
              <div class="button-link-content">
                <div class="button-link" [hidden]="(itemOrdenePedido.ordenPedido.idOrdenPedidoEstatus > 2)" matTooltip="Ordenes de servicios" (click)="btnListOrdenServicio_clickEvent(itemOrdenePedido.ordenPedido)">
                  <span class="material-icons">description</span>
                </div>
              </div>
            </div>
            <div class="badge-content">
              <span class="badge" [class]="['estatus-' + itemOrdenePedido.ordenPedido.idOrdenPedidoEstatus]">{{itemOrdenePedido.ordenPedido.ordenPedidoEstatus}}</span>
              <span class="date">{{itemOrdenePedido.createdFechaNatural}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="message-center" [hidden]="objScene != 'servicio'">
        <div class="item-message" *ngFor="let itemOrdeneServicio of lstOrdenesServicios">
          <div class="notify-content">
            <div class="icon-content">
              <div class="round" matTooltip="{{itemOrdeneServicio.createUsuarioNombreCompleto}}" [style.backgroundColor]="itemOrdeneServicio.background">
                <span [style.color]="itemOrdeneServicio.color">{{itemOrdeneServicio.createUsuarioIniciales}}</span>
              </div>
            </div>
            <div class="mail-content">
              <span class="title">{{itemOrdeneServicio.ordenServicio.folio}}</span>
              <span class="subtitle">{{itemOrdeneServicio.ordenServicio.clienteNombreComercial}}</span>
              <span class="event">{{itemOrdeneServicio.registroModuloAccionMensaje}}</span>
              <div class="button-link-content">
                <div class="button-link" [hidden]="(itemOrdeneServicio.ordenServicio.idOrdenServicioEstatus > 2)" matTooltip="Finalizar" (click)="btnFinalizarOrdenServicio_clickEvent(itemOrdeneServicio.ordenServicio)">
                  <span class="material-icons">task_alt</span>
                </div>
              </div>
            </div>
            <div class="badge-content">
              <span class="badge" [class]="['estatus-' + itemOrdeneServicio.ordenServicio.idOrdenServicioEstatus]">{{itemOrdeneServicio.ordenServicio.ordenServicioEstatus}}</span>
              <span class="date">{{itemOrdeneServicio.createdFechaNatural}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="message-center" [hidden]="objScene != 'mantenimiento'">
        <div class="item-message" *ngFor="let itemUnidadMantenimientoTaller of lstOrdenesMantenimiento">
          <div class="notify-content" *ngIf="itemUnidadMantenimientoTaller.idRegistroModulo == 5">
            <div class="icon-content">
              <div class="round" matTooltip="{{itemUnidadMantenimientoTaller.createUsuarioNombreCompleto}}" [style.backgroundColor]="itemUnidadMantenimientoTaller.background">
                <span [style.color]="itemUnidadMantenimientoTaller.color">{{itemUnidadMantenimientoTaller.createUsuarioIniciales}}</span>
              </div>
            </div>
            <div class="mail-content">
              <span class="title">{{itemUnidadMantenimientoTaller.mantenimiento.unidadMantenimientoFolio}}</span>
              <span class="subtitle"><strong>{{itemUnidadMantenimientoTaller.mantenimiento.unidadMantenimientoTaller}}:</strong> {{itemUnidadMantenimientoTaller.mantenimiento.folio}}</span>
              <span class="event">{{itemUnidadMantenimientoTaller.registroModuloAccionMensaje}}</span>
              <div class="button-link-content">
                <div [hidden]="!(itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoTallerEstatus == 1)" class="button-link" matTooltip="Iniciar servicio" (click)="btnInit_clickEvent(itemUnidadMantenimientoTaller.mantenimiento)">
                  <span class="material-icons">arrow_forward</span>
                </div>

                <div [hidden]="!(itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoTallerEstatus == 2)" class="button-link" matTooltip="Finalizar servicio" (click)="btnFinalize_clickEvent(itemUnidadMantenimientoTaller.mantenimiento)">
                  <span class="material-icons">check_circle_outline</span>
                </div>

                <div [hidden]="!(itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoTallerEstatus < 3)" class="button-link" matTooltip="Cancelar servicio" (click)="btnCancel_clickEvent(itemUnidadMantenimientoTaller.mantenimiento)">
                  <span class="material-icons">cancel</span>
                </div>
              </div>
            </div>
            <div class="badge-content">
              <span class="badge"
                    matTooltip="Finalización forzada"
                    [matTooltipDisabled]="itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoTallerEstatus != 4"
                    [class]="['mantenimiento-estatus-' + itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoTallerEstatus]">
                {{(itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoTallerEstatus != 4)?itemUnidadMantenimientoTaller.mantenimiento.unidadMantenimientoTallerEstatus:'Finalizado'}}
              </span>
              <div class="progress-radial-bar-content">
                <div class="progress-radial-bar">
                  <div class="progress"
                       matTooltip="Atrasado"
                       [matTooltipDisabled]="itemUnidadMantenimientoTaller.mantenimiento.isEnTiempo"
                       [style.background]="'conic-gradient(' + gradientPercentageMantenimientoTaller(itemUnidadMantenimientoTaller.mantenimiento) + ')'">
                    <div class="progress-value">{{itemUnidadMantenimientoTaller.mantenimiento.diasLaboralesPorcentaje}}%</div>
                  </div>
                  <div class="radial-bar-title">Taller</div>
                </div>
                <div class="progress-radial-bar">
                  <div class="progress"
                       matTooltip="Atrasado"
                       [matTooltipDisabled]="itemUnidadMantenimientoTaller.mantenimiento.unidadMantenimientoIsEnTiempo"
                       [style.background]="'conic-gradient(' + gradientPercentageMantenimiento(itemUnidadMantenimientoTaller.mantenimiento) + ')'">
                    <div class="progress-value">{{itemUnidadMantenimientoTaller.mantenimiento.unidadMantenimientoPorcentaje}}%</div>
                  </div>
                  <div class="radial-bar-title">Manto</div>
                </div>
              </div>
            </div>
          </div>

          <div class="notify-content" *ngIf="itemUnidadMantenimientoTaller.idRegistroModulo == 4">
            <div class="icon-content">
              <div class="round" matTooltip="{{itemUnidadMantenimientoTaller.createUsuarioNombreCompleto}}" [style.backgroundColor]="itemUnidadMantenimientoTaller.background">
                <span [style.color]="itemUnidadMantenimientoTaller.color">{{itemUnidadMantenimientoTaller.createUsuarioIniciales}}</span>
              </div>
            </div>
            <div class="mail-content">
              <span class="title">{{itemUnidadMantenimientoTaller.mantenimiento.folio}}</span>
              <span class="subtitle"><strong>Unidad:</strong> {{itemUnidadMantenimientoTaller.mantenimiento.unidad}}</span>
              <span class="event">{{itemUnidadMantenimientoTaller.registroModuloAccionMensaje}}</span>
              <div class="button-link-content">
                <div [hidden]="!(itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoEstatus == 4)" class="button-link" matTooltip="Finalizar servicio" (click)="btnOrdenMantenimientoFinalize_clickEvent(itemUnidadMantenimientoTaller.mantenimiento)">
                  <span class="material-icons">check_circle_outline</span>
                </div>
                <div [hidden]="!(itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoEstatus == 2)" class="button-link" matTooltip="Forzar finalización" (click)="btnOrdenMantenimientoForceFinalize_clickEvent(itemUnidadMantenimientoTaller.mantenimiento)">
                  <span class="material-icons">playlist_add_check_circle</span>
                </div>
              </div>
            </div>
            <div class="badge-content">
              <span class="badge" [class]="['mantenimiento-estatus-' + itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoEstatus]">{{itemUnidadMantenimientoTaller.mantenimiento.unidadMantenimientoEstatus}}</span>
              <div class="progress-radial-bar-content">
                <div class="progress-radial-bar">
                  <div class="progress"
                       matTooltip="Atrasado"
                       [matTooltipDisabled]="itemUnidadMantenimientoTaller.mantenimiento.isEnTiempo"
                       [style.background]="'conic-gradient(' + gradientPercentageOrdenMantenimiento(itemUnidadMantenimientoTaller.mantenimiento) + ')'">
                    <div class="progress-value">{{itemUnidadMantenimientoTaller.mantenimiento.diasLaboralesPorcentaje}}%</div>
                  </div>
                  <div class="radial-bar-title">Manto</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="nav-content">
        <div class="nav-link" (click)="viewAllNotifications_clickEvent()">ver todos</div>
        <div class="nav-link" [hidden]="objScene != 'cotizacion'"    [routerLink]="['/cp/cotizaciones']"      >Ir a cotización</div>
        <div class="nav-link" [hidden]="objScene != 'pedido'"        [routerLink]="['/cp/ordenes-pedidos']"   >Ir a ordenes de pedido</div>
        <div class="nav-link" [hidden]="objScene != 'servicio'"      [routerLink]="['/cp/ordenes-servicios']" >Ir a ordenes de servicios</div>
        <div class="nav-link" [hidden]="objScene != 'mantenimiento'" [routerLink]="['/cp/mantenimientos']"    >Ir a mantenimientos</div>
      </div>
    </div>
  </div>
</mat-menu>
