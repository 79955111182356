import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Globals } from 'src/app/libraries/globals';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {

  @Input('titulo') titulo : string = '';
  @Input('mensaje') mensaje : string = '';
  @Input('mensajeHTML') mensajeHTML : string = '';
  @Input('successLabel') successLabel : string = 'Aceptar';
  @Input('autoCloseDelay') autoCloseDelay : number = 0;
  @Input('fnClose') fnClose : any;
  @Input('fnSuccess') fnSuccess : any;
  @Input('fnCancel') fnCancel : any;

  @Output('onClose') onClose : EventEmitter<SuccessComponent> = new EventEmitter<SuccessComponent>();

  constructor(  public objDialogRef: MatDialogRef<SuccessComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
    if( Globals.exist(data.titulo) ) this.titulo = data.titulo;
    if( Globals.exist(data.mensaje) ) this.mensaje = data.mensaje;
    if( Globals.exist(data.mensajeHTML) ) this.mensajeHTML = data.mensajeHTML;
    if( Globals.exist(data.successLabel) ) this.successLabel = data.successLabel;
    if( Globals.exist(data.autoCloseDelay) ) this.autoCloseDelay = data.autoCloseDelay;
    if( Globals.exist(data.fnClose) ) this.fnClose = data.fnClose;
    if( Globals.exist(data.fnSuccess) ) this.fnSuccess = data.fnSuccess;
    if( Globals.exist(data.fnCancel) ) this.fnCancel = data.fnCancel;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() :void {
    setTimeout(() => {
      if( this.autoCloseDelay > 0 ) {
        setTimeout(() => {
          this.close();
        }, this.autoCloseDelay);
      }
    }, 0);
  }

  public close() : void {
    this.objDialogRef.close();
    if( Globals.exist(this.fnClose) ) this.fnClose();
    this.onClose.emit(this);
  }

  public btnAceptar_clickEvent() : void {
    if( Globals.exist(this.fnSuccess) ) this.fnSuccess();
    this.close();
  }

  public btnCancelar_clickEvent() : void {
    if( Globals.exist(this.fnCancel) ) this.fnCancel();
    this.close();
  }

  public btnClose_clickEvent() : void {
    this.close();
  }

}
