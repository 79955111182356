import { AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, QueryList, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { SuccessComponent } from 'src/app/components/success/success.component';
import { ZoneUploaderComponent } from 'src/app/components/zone-uploader/zone-uploader.component';
import { ApplicationConfig } from 'src/app/libraries/application-config';
import { Globals } from 'src/app/libraries/globals';
import { UsuariosFicherosService } from 'src/app/services/usuarios-ficheros.service';
import { UsuariosFicherosFileItemComponent } from './file-item/file-item.component';
import { saveAs } from 'file-saver';
import { ConfirmComponent } from 'src/app/components/confirm/confirm.component';
import {FormOptimizer} from "../../../../../libraries/formOptimizer";
import {SendEmailIndexComponent} from "../../send_email/index.component";
import {FileViewerComponent} from "../../../../../components/file-viewer/file-viewer.component";
import {AuthService} from "../../../../../services/auth.service";

@Component({
  selector: 'app-usuarios-ficheros-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class UsuariosFicherosIndexComponent implements OnInit, AfterViewInit {

  @ViewChild('dataTable') dataTable: ElementRef<HTMLTableElement>;

  public ApplicationConfig = ApplicationConfig;
  public Globals           = Globals;
  public nombreCompleto                   : string = ' usuario';
  public lstUsuarioFicheros : Array<any> = [];
  public lstUsuarioFicherosTipos : Array<any> = [];
  public lstUsuarioFicherosPendientes : Array<any> = [];
  public lstUsuarioFicherosVencidos : Array<any> = [];
  public lstUsuarioFicherosVigentes : Array<any> = [];
  @ViewChild('compZoneUploader') compZoneUploader : ZoneUploaderComponent;
  @ViewChildren('compFileItem') compFileItem : QueryList<UsuariosFicherosFileItemComponent>;
  public indexFicheroUpload : number = 0;
  public countQueueUpload : number = 0;
  public countQueueTotal : number = 0;
  public lstQueueUploadFiles : Array<any> = [];
  public boolUpload : boolean = false;
  public fileSizeUploader : number = 0;
  public numberItemsVigentes : number = 0;
  public fileSizeVigente : number = 0;
  public numberItemsVencidos : number = 0;
  public fileSizeVencidos : number = 0;
  public valueConfiguracion           : boolean = false;
  public pdfValidate : Array<String> = ['gif','webp','jpeg','jpg','png','pdf'];
  public objPrivilegios               : any;
  public maxWidth                     : number = 0;

  public objLoader = {
    type            : 'loader',
    visible         : true
  }

  public objScene : any = ["ACTIVOS"];
  public boolChkVigentes : boolean = false;
  public boolChkSomeVigentes : boolean = false;
  public boolChkVencidos : boolean = false;
  public boolChkSomeVencidos : boolean = false;

  public formComponent : Array<any> = [
    this.objLoader
  ];
  @Output('onClose') onClose : EventEmitter<UsuariosFicherosIndexComponent> = new EventEmitter<UsuariosFicherosIndexComponent>();

  constructor(
                private objDialogRef: MatDialogRef<UsuariosFicherosIndexComponent>,
                private objMatDialog : MatDialog,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private objUsuariosFicherosService : UsuariosFicherosService,
                private objAuthService : AuthService,
  ) { this.objPrivilegios = objAuthService.privilegios; }

  ngOnInit(): void {

  }

  ngAfterViewInit() : void {
    this.maxWidth = this.dataTable?.nativeElement.offsetWidth - 645;
    setTimeout(()=> this.load(), 200);
  }

  public load() : void {
    FormOptimizer.formDisable(this.formComponent);
    if(Globals.exist(this.data.nombreCompleto)) this.nombreCompleto = ' ' + this.data.nombreCompleto;
    this.objUsuariosFicherosService.index(this.data.idUsuario).subscribe( objResponse => {
      this.lstUsuarioFicherosTipos        = objResponse.result.usuariosFicherosTipos;
      this.initUsuariosFicherosVigentes(objResponse.result.usuariosFicherosVigentes);
      this.initUsuariosFicherosVencidos(objResponse.result.usuariosFicherosVencidos);
      this.initUsuariosFicherosPendientes(objResponse.result.usuariosFicherosPendientes);

      if (Globals.exist(objResponse.result?.configuracion) && Globals.exist(objResponse.result.configuracion?.value)) {
        this.valueConfiguracion = (objResponse.result.configuracion.value === "true");
      }

      FormOptimizer.formEnable(this.formComponent);
    }, (error)=> {
      FormOptimizer.formEnable(this.formComponent);
    });
  }

  public initUsuariosFicherosVigentes(lstUsuariosFicherosVigentes : Array<any>) : void {
    this.lstUsuarioFicherosVigentes = [];
    lstUsuariosFicherosVigentes.forEach( itemUsuariosFicherosVigentes => {
      itemUsuariosFicherosVigentes['checked'] = false;
      this.lstUsuarioFicherosVigentes.push(itemUsuariosFicherosVigentes);
    });
  }

  public initUsuariosFicherosVencidos(lstUsuariosFicherosVencidos : Array<any>) : void {
    this.lstUsuarioFicherosVencidos = [];
    lstUsuariosFicherosVencidos.forEach( itemUsuariosFicherosVencidos => {
      itemUsuariosFicherosVencidos['checked'] = false;
      this.lstUsuarioFicherosVencidos.push(itemUsuariosFicherosVencidos);
    });
  }

  public initUsuariosFicherosPendientes(lstUsuarioFicherosPendientes : Array<any>) : void {
    this.lstUsuarioFicherosPendientes    = [];
    lstUsuarioFicherosPendientes.forEach( itemUsuariosFicherosPendientes => {
      itemUsuariosFicherosPendientes['ready'] = false;
      this.lstUsuarioFicherosPendientes.push(itemUsuariosFicherosPendientes);
    });
  }


  public close() : void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

  public objScene_change(evento : any) : void {

  }

  public btnSubirFicheros_clickEvent() : void {
    this.ficherosStore(true);
  }

  public ficherosStore( boolBegin : boolean = false) : void {
    let validate : boolean = false;
    if(boolBegin) {
      this.indexFicheroUpload = 0;
      let arrFileItems = this.compFileItem.toArray();
      this.lstQueueUploadFiles = [];

      let boolValid : boolean = true;
      for( let index : number = 0; index < arrFileItems.length; index++ ) {
        if( !arrFileItems[index].validate() ) boolValid = false;
      }

      if(boolValid) {
        validate = true;
        for( let index : number = 0; index < arrFileItems.length; index++ ) {
          let objFileItem = arrFileItems[index];
          this.lstQueueUploadFiles.push(objFileItem.getUsuarioFichero());
          this.countQueueTotal++;

          objFileItem.formDisable();
        }
        this.objLoader.visible = true;
      }
    } else {
      validate = true;
    }

    if(validate) {
      if(this.countQueueUpload < this.countQueueTotal) {
        let objUsuarioFichero = this.lstQueueUploadFiles[this.indexFicheroUpload];

        let formUnidadFicheroStore = new FormData();

        formUnidadFicheroStore.append("idUsuario",            this.data.idUsuario);
        formUnidadFicheroStore.append("nombre",               objUsuarioFichero.nombre);
        formUnidadFicheroStore.append("tamano",               objUsuarioFichero.tamano);
        if( Globals.exist(objUsuarioFichero.idUsuarioFicheroTipo) ) {
          formUnidadFicheroStore.append("idUsuarioFicheroTipo", objUsuarioFichero.idUsuarioFicheroTipo);
        }

        formUnidadFicheroStore.append("vigenciaFechaInicio",  objUsuarioFichero.vigenciaFechaInicio);
        formUnidadFicheroStore.append("vigenciaFechaFin",     objUsuarioFichero.vigenciaFechaFin);
        formUnidadFicheroStore.append("vigencia",             objUsuarioFichero.vigencia);
        formUnidadFicheroStore.append("file",                 objUsuarioFichero.file.object);

        this.objUsuariosFicherosService.store(formUnidadFicheroStore)
        .subscribe( objResponse => {

          if(Globals.exist(objResponse)) {
            if(objResponse.status == 'completed'){
              if(objResponse.body.action) {

                let arrFileItems = this.compFileItem.toArray();
                arrFileItems[this.indexFicheroUpload].uploadResponse.boolFinalize  = true;
                arrFileItems[this.indexFicheroUpload].uploadResponse.message       = 'Archivo cargado satisfactoriamente';
                arrFileItems[this.indexFicheroUpload].uploadResponse.boolSuccess   = true;

                this.lstQueueUploadFiles.splice(this.indexFicheroUpload, 1);
                this.compZoneUploader.files.splice(this.indexFicheroUpload, 1);

                let indexFicherosRequeridos = this.lstUsuarioFicherosPendientes.findIndex((item) => { return (item.idClienteFicheroTipo == arrFileItems[this.indexFicheroUpload].usuarioFicheroTipo.idClienteFicheroTipo); });
                this.lstUsuarioFicherosPendientes.splice(indexFicherosRequeridos, 1);

                this.countQueueUpload++;

                setTimeout(() => {
                  this.ficherosStore();
                }, 1000);


              } else {
                let arrFileItems = this.compFileItem.toArray();

                arrFileItems[this.indexFicheroUpload].uploadResponse.boolFinalize  = true;
                arrFileItems[this.indexFicheroUpload].uploadResponse.boolSuccess   = false;
                arrFileItems[this.indexFicheroUpload].uploadResponse.message       = 'No fue posible subir el documento. Error: ' + objResponse.body.message;
                // arrFileItems[this.indexFicheroUpload].enableForm(true);

                this.countQueueUpload++;
                this.indexFicheroUpload++;
                setTimeout(() => {
                  this.ficherosStore();
                }, 1000);
              }
            } else if(objResponse.status == 'progress') {
              this.compFileItem.toArray()[this.indexFicheroUpload].uploadResponse = objResponse;
            }
          }
        }, error => {

        });

      } else {
        let UploadFilesComplete = this.lstQueueUploadFiles.length;

        if(UploadFilesComplete == 0) {

          this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo : "Documentos actualizados", mensaje : "Se han subido los documentos satisfactoriamente.", fnClose : ()=> {
            this.objLoader.visible = false;
            this.load(); }}
          ));

        } else {
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
          this.objLoader.visible = false;
          this.load();
        }

      }
    } else {
      this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Formulario incompleto", mensaje : "Complete los campos solicitados.", autoCloseDelay : 3000}));
    }
  }

  public compUsuarioFicheroFileItem_deleteEvent( target : UsuariosFicherosFileItemComponent ) : void {
    this.fileSizeUploader -= target.fcTamano.value;
    this.compZoneUploader.files.splice(target.index, 1);
  }

  public compUsuarioFicheroFileItem_changeEvent() {
    let arrFileItems = this.compFileItem.toArray();

    for(let i : number = 0; i < this.lstUsuarioFicherosPendientes.length; i++) {
      this.lstUsuarioFicherosPendientes[i]['ready'] = false;
      for(let j : number = 0; j < arrFileItems.length; j++) {
        let itemFile : any = arrFileItems[j];
        if( Globals.exist(itemFile.usuarioFicheroTipo) && itemFile.usuarioFicheroTipo.idUsuarioFicheroTipo == this.lstUsuarioFicherosPendientes[i].idUsuarioFicheroTipo) {
          this.lstUsuarioFicherosPendientes[i]['ready'] = true;
          break;
        }
      }
    }
  }

  public compUsuarioFicheroFileItem_initEvent(event : UsuariosFicherosFileItemComponent) {
    setTimeout(() => this.fileSizeUploader += event.fcTamano.value);
  }

  public btnUsuarioFicheroDownload_clickEvent( item : any) : void {

    this.objUsuariosFicherosService.download(item.idUsuarioFichero)
    .subscribe( objResponse => {

      saveAs(objResponse, item.nombre + "." + item.extension);
      // Wait.hide(this.mainContainer);

    }, error => {
      // console.log("error");
    });
  }

  public btnUsuarioFicheroDelete_clickEvent( item : any) : void {
    this.objMatDialog.open(ConfirmComponent, Globals.confirmConfig({titulo : "Eliminar documento", mensaje : "¿Está seguro que desea eliminar el documento?, una vez eliminado no podrá recuperarlo.", fnAccept : ()=> {
      FormOptimizer.formDisable(this.formComponent);
      this.objUsuariosFicherosService.delete(item.idUsuarioFichero)
      .subscribe( objResponse => {
        if( objResponse.action) {
          this.load();
          this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
        } else
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.title, autoCloseDelay: 3000}));
        FormOptimizer.formEnable(this.formComponent);
      }, error => {
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
        FormOptimizer.formEnable(this.formComponent);
      });
    }}));
  }

  public chkUsuariosFicherosVigentes_changeEvent( event : any ) : void {
    this.boolChkVigentes = event;
    this.fileSizeVigente = 0;
    this.numberItemsVigentes = 0;
    this.lstUsuarioFicherosVigentes.forEach(itemUsuarioFichero => {
      itemUsuarioFichero.checked = this.boolChkVigentes;
      if(this.boolChkVigentes) {
        this.fileSizeVigente += itemUsuarioFichero.tamano;
        this.numberItemsVigentes ++;
      }
    });
    this.boolChkSomeVigentes = this.lstUsuarioFicherosVigentes.some(itemUsuariFichero => itemUsuariFichero.checked);
  }

  public chkItemUsuarioFicheroVigente_changeEvent( index : number ) : void {
    this.boolChkVigentes = this.lstUsuarioFicherosVigentes.every(itemUsuariFichero => itemUsuariFichero.checked);
    this.boolChkSomeVigentes = this.lstUsuarioFicherosVigentes.some(itemUsuariFichero => itemUsuariFichero.checked);
    if(this.lstUsuarioFicherosVigentes[index].checked) {
      this.fileSizeVigente += this.lstUsuarioFicherosVigentes[index].tamano;
      this.numberItemsVigentes ++;
    } else {
      this.fileSizeVigente -= this.lstUsuarioFicherosVigentes[index].tamano;
      this.numberItemsVigentes --;
    }
  }

  public chkUsuariosFicherosVencidos_changeEvent( event : any ) : void {
    this.boolChkVencidos = event;
    this.fileSizeVencidos = 0;
    this.numberItemsVencidos = 0;
    this.lstUsuarioFicherosVencidos.forEach(itemUsuarioFichero => {
        itemUsuarioFichero.checked = this.boolChkVencidos;
        if(this.boolChkVigentes) {
            this.fileSizeVencidos += itemUsuarioFichero.tamano;
            this.numberItemsVencidos ++;
        }
    });
    this.boolChkSomeVencidos = this.lstUsuarioFicherosVencidos.some(itemUsuariFichero => itemUsuariFichero.checked);
  }

  public chkItemUsuarioFicheroVencido_changeEvent( index : number ) : void {
    this.boolChkVencidos = this.lstUsuarioFicherosVencidos.every(itemUsuariFichero => itemUsuariFichero.checked);
    this.boolChkSomeVencidos = this.lstUsuarioFicherosVencidos.some(itemUsuariFichero => itemUsuariFichero.checked);
      if(this.lstUsuarioFicherosVencidos[index].checked) {
          this.fileSizeVencidos += this.lstUsuarioFicherosVencidos[index].tamano;
          this.numberItemsVencidos ++;
      } else {
          this.fileSizeVencidos -= this.lstUsuarioFicherosVencidos[index].tamano;
          this.numberItemsVencidos --;
      }
  }

  public btnVigentesEliminarSeleccionados_clickEvent() : void {
    this.objMatDialog.open(ConfirmComponent, Globals.confirmConfig({titulo : "Eliminar documentos seleccionados", mensaje : "¿Está seguro que desea eliminar los documentos seleccionados?, una vez eliminados no podrá recuperarlos.", fnAccept : ()=> {
      FormOptimizer.formDisable(this.formComponent);
      let lstUsuariosFicherosSeleccionados = this.lstUsuarioFicherosVigentes.filter( itemUsuarioFichero => itemUsuarioFichero.checked).map(itemUsuarioFichero => {return { idUsuarioFichero : itemUsuarioFichero.idUsuarioFichero}});
      let objDeleteFiles = {
        ficheros  : lstUsuariosFicherosSeleccionados
      };

      this.objUsuariosFicherosService.deleteList(objDeleteFiles)
      .subscribe( objResponse => {
        if( objResponse.action) {
          this.load();
          this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
        } else
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.title, autoCloseDelay: 3000}));
        FormOptimizer.formEnable(this.formComponent);
      }, error => {
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
        FormOptimizer.formEnable(this.formComponent);
      });
    }}));

  }

  public btnVencidosEliminarSeleccionados_clickEvent() : void {

    this.objMatDialog.open(ConfirmComponent, Globals.confirmConfig({titulo : "Eliminar documentos seleccionados", mensaje : "¿Está seguro que desea eliminar los documentos seleccionados?, una vez eliminados no podrá recuperarlos.", fnAccept : ()=> {
      FormOptimizer.formDisable(this.formComponent);
      let lstUsuariosFicherosSeleccionados = this.lstUsuarioFicherosVencidos.filter( itemUsuarioFichero => itemUsuarioFichero.checked).map(itemUsuarioFichero => {return { idUsuarioFichero : itemUsuarioFichero.idUsuarioFichero}});
      let objDeleteFiles = {
        ficheros  : lstUsuariosFicherosSeleccionados
      };

      this.objUsuariosFicherosService.deleteList(objDeleteFiles)
      .subscribe( objResponse => {
        if( objResponse.action) {
          this.load();
          this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
        } else
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.title, autoCloseDelay: 3000}));
        FormOptimizer.formEnable(this.formComponent);
      }, error => {
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
        FormOptimizer.formEnable(this.formComponent);
      });
    }}));

  }

  public btnVigentesDescargarSeleccionados_clickEvent() : void {
    this.objLoader.visible = true;
    let lstUsuariosFicherosSeleccionados = this.lstUsuarioFicherosVigentes.filter( itemUsuarioFichero => itemUsuarioFichero.checked).map(itemUsuarioFichero => {return { idUsuarioFichero : itemUsuarioFichero.idUsuarioFichero}});
    let objDescargarZip = {
      ficheros  : lstUsuariosFicherosSeleccionados
    };

    this.objUsuariosFicherosService.zip(objDescargarZip)
    .subscribe( objResponse => {
      saveAs(objResponse, this.data.nombreCompleto + " docs vigentes" + ".zip");
      this.objLoader.visible = false;

    }, error => {
      this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
      this.objLoader.visible = false;
      this.load();
    });
  }

  public btnVencidosDescargarSeleccionados_clickEvent() : void {
    this.objLoader.visible = true;
    let lstUsuariosFicherosSeleccionados = this.lstUsuarioFicherosVencidos.filter( itemUsuarioFichero => itemUsuarioFichero.checked).map(itemUsuarioFichero => {return { idUsuarioFichero : itemUsuarioFichero.idUsuarioFichero}});
    let objDescargarZip = {
      ficheros  : lstUsuariosFicherosSeleccionados
    };

    this.objUsuariosFicherosService.zip(objDescargarZip)
    .subscribe( objResponse => {
      saveAs(objResponse, this.data.nombreCompleto + " docs vencidos" + ".zip");
      this.objLoader.visible = false;

    }, error => {
      this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
      this.objLoader.visible = false;
      this.load();
    });
  }

  public btnEnviarCorreo_clickEvent() : void {
    let lstFicherosVigentes : Array<any> = this.lstUsuarioFicherosVigentes.filter(itemUsuarioFichero => itemUsuarioFichero.checked).map(itemUsuarioFichero => itemUsuarioFichero = {idUsuarioFichero : itemUsuarioFichero.idUsuarioFichero});

    let objDialog = this.objMatDialog.open(SendEmailIndexComponent, {
      width         : '96%',
      height        : '96%',
      data          : {
        idUsuario : this.data.idUsuario,
        ficheros : lstFicherosVigentes
      },
      disableClose  : true
    });
  }

  public viewFile_clickEvente(itemUsuarioFichero : any) : void {
    if(this.pdfValidate.includes(itemUsuarioFichero.extension)) {
      let objDialog = this.objMatDialog.open(FileViewerComponent, {
        width     : '100%',
        maxWidth  : '100%',
        height    : '100%',
        maxHeight : '100%',
        data      : {
          extension : itemUsuarioFichero.extension,
          fileName  : itemUsuarioFichero.nombre + "." + itemUsuarioFichero.extension,
          pdfSrc    : this.ApplicationConfig.ENDPOINT + 'api/usuarios/ficheros/viewer/' + itemUsuarioFichero.idUsuarioFichero
        },
        panelClass    : 'visualizarPanelClass',
        backdropClass : 'backdropBackground',
        disableClose  : true
      });

      objDialog.componentInstance.onDownload.subscribe(() => {
        this.objUsuariosFicherosService.download(itemUsuarioFichero.idUsuarioFichero)
          .subscribe(objResponse => {
            saveAs(objResponse, itemUsuarioFichero.nombre + "." + itemUsuarioFichero.extension);
          }, error => {

          });
      });

      objDialog.componentInstance.onPrint.subscribe(() => {
        this.objUsuariosFicherosService.download(itemUsuarioFichero.idUsuarioFichero)
          .subscribe(objResponse => {
            let blob = new Blob([objResponse], {type: 'application/pdf'});
            let blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            iframe.src = blobUrl;
            document.body.appendChild(iframe);
            if (iframe.contentWindow != null) {
              iframe.contentWindow.focus();
              iframe.contentWindow.print();
            }
          }, error => {

          });
      });
    } else {
      this.btnUsuarioFicheroDownload_clickEvent(itemUsuarioFichero);
    }
  }
}
