<div class="picture-upload-content" [style.width]="width">
  <div class="overlay" *ngIf="!disabled" (click)="btnUpload_clickEvent()">
    <span class="material-icons unselectable">file_upload</span>
  </div>

  <div *ngIf="!isValid()" class="preview" [class.disabled]="disabled" [style.backgroundImage]="'url(' + (( Globals.exist(preview) && preview.trim() != '') ? preview : defaultImage) + ')'"
  [style.width]="width"
  [style.height]="height"
  [style.borderRadius]="borderRadius"
  [style.backgroundSize]="backgroundSize"
  ></div>

  <div *ngIf="isValid()" class="preview" [style.backgroundImage]="'url(' + preview + ')'"
  [style.width]="width"
  [style.height]="height"
  [style.borderRadius]="borderRadius"
  [style.backgroundSize]="backgroundSize"
  ></div>

  <input type="file" #inputFile (change)="file_changeEvent($event)" accept="{{accept}}" />

</div>
