import {Globals} from "./globals";
import * as moment from "moment/moment";

export class Utils {
  public static replaceSpecialCharacters(str: string): string {
    if (Globals.isStringNotEmpty(str)) {
      return decodeURIComponent(escape(str));
    } else return '';
  }

  public static formatDateTime() : string {
    return moment().format("YYYY[_]MM[_]DD[_]HH[_]mm[_]ss");
  }
}
