import {Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {FormOptimizer} from "../../../../../../libraries/formOptimizer";
import {InputAutocompleteComponent} from "../../../../../../components/input-autocomplete/input-autocomplete.component";
import {OrdenesServiciosService} from "../../../../../../services/ordenes-servicios.service";
import {Globals} from "../../../../../../libraries/globals";

export interface Miembros {
  idOperador       : number,
  operador         : string,
  operadorValidate : boolean,
  boolOperador     : boolean,
  idPuesto         : number,
  puesto           : string,
  puestoValidate   : boolean,
  disabled         : boolean
}

@Component({
  selector: 'tr[app-ordenes-servicio-unidad-item]',
  templateUrl: './unidad-item.component.html',
  styleUrls: ['./unidad-item.component.scss']
})
export class OrdenesServiciosUnidadItemComponent implements OnInit {

  @ViewChild('txtUnidad')                 txtUnidad              : InputAutocompleteComponent;
  @ViewChildren('lstTxtUsuarioOperacion') lstTxtUsuarioOperacion : QueryList<InputAutocompleteComponent>;
  @ViewChildren('lstTxtPuesto')           lstTxtPuesto           : QueryList<InputAutocompleteComponent>;

  @Output('onDelete')           onDelete           : EventEmitter<OrdenesServiciosUnidadItemComponent> = new EventEmitter<OrdenesServiciosUnidadItemComponent>();
  @Output('onDeleteOperadores') onDeleteOperadores : EventEmitter<OrdenesServiciosUnidadItemComponent> = new EventEmitter<OrdenesServiciosUnidadItemComponent>();
  @Output('onAddOperadores')    onAddOperadores    : EventEmitter<OrdenesServiciosUnidadItemComponent> = new EventEmitter<OrdenesServiciosUnidadItemComponent>();
  @Output('onChecked')          onChecked          : EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('onPrincipal')        onPrincipal        : EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input('index') index : number;
  private _hasPrincipal : boolean = false;
  @Input('hasPrincipal')
  get hasPrincipal() : boolean { return this._hasPrincipal; }
  set hasPrincipal( value : boolean ) {
    this._hasPrincipal = value;
    if (value) {
      this.boolValidUnidadPrincipal = false;
    }
  }

  public fcOdometro   : FormControl = new FormControl('');
  public fcHoromatro  : FormControl = new FormControl('');

  public Globals                  = Globals;
  public fcUnidad                 : FormControl = new FormControl('');
  public objUnidad                : any    = {};
  public idUnidad                 : number = 0;
  public checked                  : boolean = false;
  public lstUnidad_Usuarios       : Array<Miembros> = [{ idOperador: 0, operador: '', operadorValidate: true, boolOperador: false, idPuesto: 0, puesto:'', puestoValidate: true, disabled: false }];
  public lstUnidades              : Array<any> = [];
  public lstUsuarios              : Array<any> = [];
  public lstPuestos               : Array<any> = [];
  public toggleUnidad             : boolean    = true;
  public boolunidadInfo           : boolean    = true;
  public boolUnidadRequered       : boolean    = false;
  public boolAutoCompleteUnidad   : boolean    = false;
  public boolUnidadPrincipal      : boolean    = false;
  public boolValidUnidadPrincipal : boolean    = false;
  public boolHorometro            : boolean = false;
  public boolOdometro             : boolean = false;
  public lstInnerOrdenesServiciosMiembros_UsuariosPuestos    : Array<any> = [];
  public filteredlstOrdenesServiciosMiembros_UsuariosPuestos : Array<any> = [];
  private _lstOrdenesServiciosMiembros_UsuariosPuestos       : Array<any> = [];
  @Input('lstOrdenesServiciosMiembros_UsuariosPuestos')
  get lstOrdenesServiciosMiembros_UsuariosPuestos() : Array<any> { return this._lstOrdenesServiciosMiembros_UsuariosPuestos; }
  set lstOrdenesServiciosMiembros_UsuariosPuestos(value : Array<any>) {
    this._lstOrdenesServiciosMiembros_UsuariosPuestos = value;

    this.lstOrdenesServiciosMiembros_UsuariosPuestos.forEach( itemOrdenServicioMiembro_UsuarioPuesto => {
      if(Globals.exist(itemOrdenServicioMiembro_UsuarioPuesto.idOrdenServicioMiembroUsuarioPuesto) && Globals.exist(itemOrdenServicioMiembro_UsuarioPuesto.ordenServicioMiembroUsuarioPuesto)) {
        let objOrdenServicioMiembro_UsuarioPuesto : any = {
          idOrdenServicioMiembroUsuarioPuesto : itemOrdenServicioMiembro_UsuarioPuesto.idOrdenServicioMiembroUsuarioPuesto,
          ordenServicioMiembroUsuarioPuesto   : itemOrdenServicioMiembro_UsuarioPuesto.ordenServicioMiembroUsuarioPuesto
        };

        this.lstInnerOrdenesServiciosMiembros_UsuariosPuestos.push(objOrdenServicioMiembro_UsuarioPuesto);
      }
    });

    this.filteredlstOrdenesServiciosMiembros_UsuariosPuestos = this.lstInnerOrdenesServiciosMiembros_UsuariosPuestos;
  }

  public objChkBox = {
    type     : 'checkbox',
    disabled : false
  };

  public btnAccion = {
    type            : 'button',
    disabled        : false
  };

  public formComponents: Array<any> = [
    this.objChkBox,
    this.btnAccion,
    this.fcUnidad,
  ];

  constructor(
    private objOrdenesServiciosService : OrdenesServiciosService
  ) { }

  ngOnInit(): void {
  }

  public getOrdenServicioUnidad() : Array<any> {
    let objReturn : any;

    let lstMiembros : Array<any> = [];

    this.lstUnidad_Usuarios.forEach( itemUnidad_Usuario => {
      let objMiembro : any = {
        idUsuario : Number(itemUnidad_Usuario.idOperador),
        idPuesto  : Number(itemUnidad_Usuario.idPuesto)
      }

      lstMiembros.push(objMiembro);
    });

    objReturn = {
      idUnidad : this.idUnidad,
      odometro : FormOptimizer.formDataNumber(this.fcOdometro),
      horometro : FormOptimizer.formDataNumber(this.fcHoromatro),
      isPrincipal : FormOptimizer.formDataBoolean({value : this.boolUnidadPrincipal}),
      miembro  : lstMiembros
    }

    return  objReturn;
  }

  public btnUnidadToggle_clickEvent() : void {
    this.toggleUnidad = !this.toggleUnidad;
    this.boolunidadInfo = true;
    this.boolUnidadRequered = false;
    this.objUnidad = {};
    if(!this.toggleUnidad) {
      this.fcUnidad.disable();
      this.fcUnidad.setValue('Sin unidad');
      this.boolValidUnidadPrincipal = false;
    } else {
      this.fcUnidad.enable();
      this.fcUnidad.setValue('');
      this.txtUnidad.objValue = null;
      this.idUnidad = 0;
      this.lstUnidades = [];
    }
  }

  public btnUnidadPrincipal_clickEvent() : void {
    this.boolUnidadPrincipal = !this.boolUnidadPrincipal;
    this.principal(this.boolUnidadPrincipal);
  }

  public chkItemPartidaSelected_changeEvent( event : any ) : void {
    this.onChecked.emit(event);
  }

  public disableFormPartida() : void {
    FormOptimizer.formDisable(this.formComponents);
    this.lstUnidad_Usuarios.forEach( itemUnidad_Usuario => itemUnidad_Usuario.disabled = true);
  }

  public enableFormPartida() : void {
    FormOptimizer.formEnable(this.formComponents);
    this.lstUnidad_Usuarios.forEach( itemUnidad_Usuario => itemUnidad_Usuario.disabled = false);
  }

  public btnPartidaEliminar_clickEvent() : void {
    if (this.boolUnidadPrincipal && this.hasPrincipal) {
      this.principal(false);
      this.onDelete.emit(this);
    } else {
      this.onDelete.emit(this);
    }
  }

  public eliminarUsuario_clickEvent(index : number) : void {
    this.lstUnidad_Usuarios.splice(index, 1);
  }

  public agregarUsuario_clickEvent() : void {
    this.lstUnidad_Usuarios.push({
      idOperador       : 0,
      operador         : '',
      operadorValidate : true,
      boolOperador     : false,
      idPuesto         : 0,
      puesto           : '',
      puestoValidate   : true,
      disabled         : false
    });
  }

  public txtUnidad_keyUpEvent(event : any) : void {
    let strSearch : string = this.fcUnidad.value;

    if (event.keyCode == 13 && !this.boolAutoCompleteUnidad) {
      this.boolAutoCompleteUnidad = true;
      this.txtUnidad.loading = true;
      this.boolUnidadRequered = false;
      this.objOrdenesServiciosService.findUnidad(strSearch)
        .subscribe( objResponse => {
          this.lstUnidades = objResponse.result.unidades;
          this.txtUnidad.loading = false;
        }, error => {
          this.txtUnidad.loading = false;
        });
    } else if (!(event.keyCode > 36 && event.keyCode < 41) && event.keyCode != 13) {
      this.txtUnidad_clearEvent();
    } else if (strSearch == '') {
      this.txtUnidad_clearEvent();
      this.boolUnidadRequered = true;
    }
  }

  public txtUnidad_OnFocusOutEvent(){
    if (!this.boolAutoCompleteUnidad) {
      this.boolUnidadRequered = false;
      this.txtUnidad_clearEvent();
      this.fcUnidad.setValue("");
    } else if (this.txtUnidad.objValue == null) {
      this.boolUnidadRequered = true;
      this.fcUnidad.setValue("");
    }
  }

  public txtUnidad_clearEvent() : void {
    this.boolAutoCompleteUnidad = false;
    this.boolunidadInfo = true;
    this.txtUnidad.objValue = null;
    this.lstUnidades = [];
    this.objUnidad = {};
    this.fcHoromatro.clearValidators();
    this.fcHoromatro.updateValueAndValidity();
    this.fcOdometro.clearValidators();
    this.fcOdometro.updateValueAndValidity();
  }

  public unidadSelected_selectedEvent(idUnidad: number) : void {
    this.objUnidad = this.lstUnidades.find(itemUnidad => itemUnidad.idUnidad == idUnidad);
    this.txtUnidad.objValue = this.objUnidad;
    this.fcUnidad.setValue(this.objUnidad?.numeroEconomico);
    this.idUnidad = idUnidad;
    this.boolunidadInfo = false;
    this.boolUnidadRequered = false;
    this.unidadMedicion();
  }

  public unidadMedicion() : void {
    if (Globals.exist(this.objUnidad) && Globals.exist(this.objUnidad?.hasHorometro) && this.objUnidad.hasHorometro) {
      this.fcHoromatro.setValidators([Validators.required]);
      this.fcHoromatro.updateValueAndValidity();
    } else {
      this.fcHoromatro.clearValidators();
      this.fcHoromatro.updateValueAndValidity();
    }

    if (Globals.exist(this.objUnidad) && Globals.exist(this.objUnidad?.hasOdometro) && this.objUnidad.hasOdometro) {
      this.fcOdometro.setValidators([Validators.required]);
      this.fcOdometro.updateValueAndValidity();
    } else {
      this.fcOdometro.clearValidators();
      this.fcOdometro.updateValueAndValidity();
    }
  }

  public inputHoroometro_changeEvent() : void {
    if (Globals.exist(this.objUnidad?.horometro) && Globals.isNumeric(this.objUnidad.horometro)) {
      this.boolHorometro = false;
      if (Globals.isNumeric(this.fcHoromatro.value)) {
        if (this.fcHoromatro.value < this.objUnidad.horometro) this.boolHorometro = true;
      }
    } else {
      this.boolHorometro = false;
    }
  }

  public inputOdometro_changeEvent() : void {
    if (Globals.exist(this.objUnidad?.odometro) && Globals.isNumeric(this.objUnidad.odometro)) {
      this.boolOdometro = false;
      if (Globals.isNumeric(this.fcOdometro.value)) {
        if (this.fcOdometro.value < this.objUnidad.odometro) this.boolOdometro = true;
      }
    } else {
      this.boolOdometro = false;
    }
  }

  public txtUsuario_keyUpEvent( event : any, index : number) : void {
    let user : string = this.lstUnidad_Usuarios[index].operador;

    if (event.keyCode == 13 && !this.lstUnidad_Usuarios[index].boolOperador) {
      this.lstUnidad_Usuarios[index].boolOperador = true;
      this.lstTxtUsuarioOperacion.toArray()[index].loading = true;
      this.objOrdenesServiciosService.findUsuarios(user)
        .subscribe( objResponse => {
          this.lstUsuarios = objResponse.result.usuarios;
          this.lstTxtUsuarioOperacion.toArray()[index].loading = false;
        }, error => {
          this.lstTxtUsuarioOperacion.toArray()[index].loading = false;
        });
    } else if (!(event.keyCode > 36 && event.keyCode < 41) && event.keyCode != 13) {
      this.txtOperador_clearEvent(index);
    } else if (user == '') {
      this.txtOperador_clearEvent(index);
    }
  }

  public txtOperador_OnFocusOutEvent(index : number) : void {
    if (!this.lstUnidad_Usuarios[index].boolOperador) {
      this.txtOperador_clearEvent(index);
      this.lstUnidad_Usuarios[index].operador = '';
    } else if (this.lstTxtUsuarioOperacion.toArray()[index].objValue == null) {
      this.lstUnidad_Usuarios[index].operador = '';
    }
  }

  public txtOperador_clearEvent(index : number) : void {
    this.lstUnidad_Usuarios[index].boolOperador = false;
    this.lstTxtUsuarioOperacion.toArray()[index].objValue = null;
    this.lstUsuarios = [];
  }

  public usuarioSelected_selectedEvent(idUsuario: number, index : number) : void {
    let objUsuario = this.lstUsuarios.find(itemUsuario => itemUsuario.idUsuario == idUsuario);
    this.lstTxtUsuarioOperacion.toArray()[index].objValue = objUsuario;
    this.lstUnidad_Usuarios[index].operador   = objUsuario.nombreCompleto;
    this.lstUnidad_Usuarios[index].idOperador = objUsuario.idUsuario;
  }

  public txtPuesto_keyUpEvent( event : any, index : number) : void {
    this.lstTxtPuesto.toArray()[index].objValue = null;

    let puesto : string = this.lstUnidad_Usuarios[index].puesto;

    this.lstTxtPuesto.toArray()[index].loading = true;
    this.filteredlstOrdenesServiciosMiembros_UsuariosPuestos = this.lstInnerOrdenesServiciosMiembros_UsuariosPuestos.filter( option => {
      this.lstTxtPuesto.toArray()[index].loading = false;
      return option.ordenServicioMiembroUsuarioPuesto.toLowerCase().includes(puesto.toLowerCase());
    });
  }

  public puestoSelected_selectedEvent(idPuesto: number, index : number) : void {
    let objPuesto = this.filteredlstOrdenesServiciosMiembros_UsuariosPuestos.find( itemPuesto => itemPuesto.idOrdenServicioMiembroUsuarioPuesto == idPuesto);
    this.lstTxtPuesto.toArray()[index].objValue = objPuesto;
    this.lstUnidad_Usuarios[index].idPuesto = objPuesto.idOrdenServicioMiembroUsuarioPuesto;
    this.lstUnidad_Usuarios[index].puesto   = objPuesto.ordenServicioMiembroUsuarioPuesto;
    if (objPuesto.idOrdenServicioMiembroUsuarioPuesto === 1 && !this.hasPrincipal) {
      this.btnUnidadPrincipal_clickEvent();
    }
  }

  public operadorValidate_changeEvent(index : number) : void {
    this.lstUnidad_Usuarios[index].operadorValidate = this.lstUnidad_Usuarios[index].operador != '' && this.lstUnidad_Usuarios[index].operador != null;
  }

  public puestoValidate_changeEvent(index : number) : void {
    this.lstUnidad_Usuarios[index].puestoValidate = this.lstUnidad_Usuarios[index].puesto != '' && this.lstUnidad_Usuarios[index].puesto != null;
  }

  public validateOperadores() : boolean {
    let objReturn : boolean = true;

    if (this.toggleUnidad && !Globals.exist(this.txtUnidad.objValue)) {
      this.boolUnidadRequered = true;
    }

    if(this.lstUnidad_Usuarios.length > 0) {
      this.lstUnidad_Usuarios.forEach((itemUnidad_Usuario, index) => {
        itemUnidad_Usuario.operadorValidate = (Globals.isStringNotEmpty(itemUnidad_Usuario.operador));
        itemUnidad_Usuario.puestoValidate = itemUnidad_Usuario.idPuesto > 0;
      });

      objReturn = this.lstUnidad_Usuarios.every(itemUnidad_Usuario => itemUnidad_Usuario.operadorValidate && itemUnidad_Usuario.puestoValidate) && !this.boolUnidadRequered;
    }

    return objReturn;
  }

  public validateUnidadPrincipal() : boolean {
    let objReturn : boolean = true;

    if (this.toggleUnidad && !this.hasPrincipal) {
      objReturn = false;
      this.boolValidUnidadPrincipal = true;
    }

    return objReturn;
  }

  public principal(event : boolean): void {
    this.onPrincipal.emit(event);
  }
}
