

export class Usuario {

    idUsuario: string;
    nombreUsuario: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    email: string;
    password: string;
    createdIdUsuario: string;
    createdFecha:any;
    updatedIdUsuario: string;
    updatedFecha: string;
    debeCambiarClv: boolean;
    deleted: boolean;
    tokenGenerado:string;
    estado:string;
    arregloRoles:string;
    urlFoto:string;

    constructor() {
        this.idUsuario = "";
        this.nombreUsuario = "";
        this.apellidoPaterno = "";
        this.apellidoMaterno = "";
        this.email = "";
        this.password = "";
        this.createdIdUsuario = "";
        this.updatedIdUsuario = "";
        this.updatedFecha = "";

        this.debeCambiarClv = false;
        this.deleted = false;
        this.tokenGenerado="";
        this.estado="";
        this.arregloRoles="";
        this.urlFoto="";
    }

}