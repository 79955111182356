<div class="login-register" [style.backgroundImage]="'url(assets/images/backgrounds/' + imgBackground + ')'">

    <div class="login-middle">

        <div class="container">
            <div class="ilustration" [style.backgroundImage]="'url(assets/images/login/' + imgIlustracion + ')'">
                <div class="logo" [style.backgroundImage]="'url(assets/images/logos/' + imgLogo + ')'"></div>
            </div>
            <div class="form">
                <form [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="text-center">
                        <span class="server">Al servicio de</span>
                    </div>

                    <div fxLayout="row wrap">
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field>
                                <input matInput placeholder="Usuario" [formControl]="form.controls['uname']" autocomplete="off">
                                <span matPrefix ><span class="material-icons">vpn_key</span> &nbsp;</span>

                            </mat-form-field>
                            <small *ngIf="form.controls['uname'].hasError('required') && form.controls['uname'].touched" class="text-danger support-text">Correo es requerido.</small>
                        </div>
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <mat-form-field>
                                <input matInput type="password" placeholder="Password" [formControl]="form.controls['password']" minlength="8" maxlength="16">
                                <span matPrefix ><span class="material-icons">account_circle</span> &nbsp;</span>
                            </mat-form-field>
                            <small *ngIf="form.controls['password'].hasError('required') && form.controls['password'].touched" class="text-danger support-text">Password es requerido.</small>
                            <small *ngIf="!(form.controls['password'].hasError('required')) && form.controls['password'].hasError('minlength')" class="text-danger support-text">Tu password debe tener 8 o más caracteres.</small>
                        </div>
                        <!-- <div fxFlex.gt-sm="50" fxFlex.gt-xs="50">
                            <mat-checkbox color="warn">Remember me</mat-checkbox>
                        </div> -->
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" class="text-center">
                            <a [routerLink]="['/authentication/forgot']" class="link olvidadte-password">¿Olvidaste tu password?</a>
                        </div>
                        <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
                            <button mat-raised-button color="primary" class="btn-block btn-lg m-t-20 m-b-20" type="submit" [disabled]="!form.valid">Iniciar Sesión</button>
                            <small class="text-danger support-text text-center">{{ msgUsuario }}</small>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div class="shadow"></div>
    </div>
</div>
