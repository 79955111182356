import {Component, EventEmitter, Input, Output, Inject, LOCALE_ID} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {Globals} from "../../../../../libraries/globals";
import {FormOptimizer} from "../../../../../libraries/formOptimizer";

@Component({
  selector: 'tr[app-cotizaciones-edit-partida-item]',
  templateUrl: './partida-item.component.html',
  styleUrls: ['./partida-item.component.scss']
})
export class CotizacionesEditPartidaItemComponent {

  @Output('onDelete') onDelete: EventEmitter<CotizacionesEditPartidaItemComponent> = new EventEmitter<CotizacionesEditPartidaItemComponent>();
  @Output('onChecked') onChecked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output('onChange') onChange: EventEmitter<void> = new EventEmitter<void>();

  @Input('index') index: number;
  private _itemCotizacionPartida: any;
  @Input('itemCotizacionPartida')
  get itemCotizacionPartida(): any {
    return this._itemCotizacionPartida;
  }

  set itemCotizacionPartida(value: any) {
    this._itemCotizacionPartida = value;
    this.iniLoading();
  }

  private _lstServicios: Array<any> = [];
  @Input('lstServicios')
  get lstServicios(): Array<any> {
    return this._lstServicios;
  }

  set lstServicios(value: Array<any>) {
    this._lstServicios = value;
    if (Globals.exist(this.lstServicios) && this.lstServicios.length > 0) this.fcServicio.setValue(this.lstServicios[0].idServicio);
  }

  private _lstMonedas: Array<any> = [];
  @Input('lstMonedas')
  get lstMonedas(): Array<any> {
    return this._lstMonedas;
  }

  set lstMonedas(value: Array<any>) {
    this._lstMonedas = value;
    if (Globals.exist(this.lstMonedas) && this.lstMonedas.length > 0) {
      this.fcMoneda.setValue(this.lstMonedas[0].idMoneda);
      this.codigoMonedaPartida = this.lstMonedas[0].codigo;
      this.codigoMoneda = this.lstMonedas[0].codigo;
    }
  }

  private _lstUnidadesMedidas: Array<any> = [];
  @Input('lstUnidadesMedidas')
  get lstUnidadesMedidas(): Array<any> {
    return this._lstUnidadesMedidas;
  }

  set lstUnidadesMedidas(value: Array<any>) {
    this._lstUnidadesMedidas = value;
    if (Globals.exist(this.lstUnidadesMedidas) && this.lstUnidadesMedidas.length > 0) this.fcUnidadMedida.setValue(this.lstUnidadesMedidas[0].idUnidadMedida);
  }

  private _lstImpuestos: Array<any> = [];
  public lstInnerImpuestos: Array<any> = [];

  @Input('lstImpuestos')
  get lstImpuestos(): Array<any> {
    return this._lstImpuestos;
  }

  set lstImpuestos(value: Array<any>) {
    this._lstImpuestos = value;
    this.lstInnerImpuestos = [];
    if (Globals.exist(this.lstImpuestos) && this.lstImpuestos.length > 0) {
      this.lstImpuestos.forEach(itemImpuesto => {
        let objImpuesto: any = {
          porcentajeNatural: itemImpuesto.porcentajeNatural,
          impuesto: itemImpuesto.impuesto,
          impuestoTipo: {
            idImpuestoTipo: itemImpuesto.impuestoTipo.idImpuestoTipo,
            impuestoTipo: itemImpuesto.impuestoTipo.impuestoTipo,
          },
          idImpuesto: itemImpuesto.idImpuesto,
          porcentaje: itemImpuesto.porcentaje,
          enabled: itemImpuesto.enabled,

          idCotizacionPartidaImpuesto: 0,
          checked: false,
          total: 0
        }
        this.lstInnerImpuestos.push(objImpuesto);
      });

      this.iniLoading();
    }
  }

  private _equivalencia: number = 0;
  @Input('equivalencia')
  get equivalencia(): number {
    return this._equivalencia;
  }

  set equivalencia(value: number) {
    this._equivalencia = value;
    this.iniLoading();
    this.calcPrecioXCantidad();
    this.calcDescuento();
    this.calcImporte();
  }

  private _objMoneda: any;
  @Input('objMoneda')
  get objMoneda(): any {
    return this._objMoneda;
  }

  set objMoneda(value: any) {
    this._objMoneda = value;
    this.codigoMoneda = this.objMoneda?.codigo;
    this.iniLoading();
    this.calcPrecioXCantidad();
    this.calcDescuento();
    this.calcImporte();
  }

  public objChkBox = {
    type: 'checkbox',
    disabled: false
  };

  public btnAccion = {
    type: 'button',
    disabled: false
  };

  public fcServicio: FormControl = new FormControl('', [Validators.required]);
  public fcDescripcion: FormControl = new FormControl('');
  public fcUnidadMedida: FormControl = new FormControl('', [Validators.required]);
  public fcPrecioUnitario: FormControl = new FormControl('', [Validators.required]);
  public fcMoneda: FormControl = new FormControl('', [Validators.required]);
  public fcCantidad: FormControl = new FormControl('', [Validators.required]);
  public fcDescuentoCantidad: FormControl = new FormControl('');
  public fcDescuentoPorcentaje: FormControl = new FormControl('');

  public codigoMonedaPartida: string = '';
  public codigoMoneda: string = '';
  public checked: boolean = false;
  public cobrarImpuestos: boolean = false;

  public idCotizacionPartida: number = 0;

  public cantidad: number = 0;
  public precioUnitario: number = 0;
  public precioUnitarioXEquivalencia: number = 0;
  public precioDescuento: number = 0;
  public precioXCantidad: number = 0;
  public descuentoCantidad: number = 0;
  public descuentoPorcentaje: number = 0;
  public descuentoActive: number = 0; // CANT || PORC
  public importe: number = 0;
  public importeConImpuestos: number = 0;

  public formComponents: Array<any> = [
    this.objChkBox,
    this.btnAccion,
    this.fcServicio,
    this.fcDescripcion,
    this.fcUnidadMedida,
    this.fcPrecioUnitario,
    this.fcMoneda,
    this.fcCantidad,
    this.fcDescuentoCantidad,
    this.fcDescuentoPorcentaje
  ];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
  ) {
    this.fcCantidad.valueChanges.subscribe(value => {
      this.cantidad = 0;
      if (Globals.exist(value) && value.toString().trim() != "" && Number(value.toString().trim()) != NaN) {
        this.cantidad = Number(this.fcCantidad.value);
        this.calcPrecioXCantidad();
        this.calcDescuento();
        this.calcImporte();
      }
    });

    this.fcPrecioUnitario.valueChanges.subscribe(value => {
      this.precioUnitario = 0;
      if (Globals.exist(value) && value.toString().trim() != "" && Number(value.toString().trim()) != NaN) {
        this.precioUnitario = Number(this.fcPrecioUnitario.value);
        this.calcPrecioXCantidad();
        this.calcDescuento();
        this.calcImporte();
      }
    });
  }

  private boolLoading: boolean = false;

  public iniLoading(): void {
    if (Globals.exist(this.itemCotizacionPartida.moneda)
      && Globals.exist(this.itemCotizacionPartida.impuestosPartida)
      && Globals.exist(this.itemCotizacionPartida.unidadMedida)
      && Globals.exist(this.itemCotizacionPartida.servicio)
      && Globals.exist(this.objMoneda)
      && this.equivalencia > 0
      // && Globals.exist(this.lstInnerImpuestos)
      && this.lstInnerImpuestos.length > 0
      && !this.boolLoading
    ) {
      this.boolLoading = true;

      this.idCotizacionPartida = this.itemCotizacionPartida.idCotizacionPartida;
      let objMoneda: any = this.itemCotizacionPartida?.moneda;
      this.codigoMonedaPartida = objMoneda.codigo;
      this.fcServicio.setValue(this.itemCotizacionPartida.servicio.idServicio);
      this.fcDescripcion.setValue(this.itemCotizacionPartida.concepto);
      this.fcUnidadMedida.setValue(this.itemCotizacionPartida.unidadMedida.idUnidadMedida);
      this.fcPrecioUnitario.setValue(this.itemCotizacionPartida.precioUnitario);
      this.fcMoneda.setValue(objMoneda.idMoneda);
      this.fcCantidad.setValue(this.itemCotizacionPartida.cantidad);

      const lstCotizacionPartidaImpuestos: Array<any> = this.itemCotizacionPartida.impuestosPartida as Array<any>;

      for (let index: number = 0; index < lstCotizacionPartidaImpuestos.length; index++) {
        this.lstInnerImpuestos[index].idCotizacionPartidaImpuesto = lstCotizacionPartidaImpuestos[index].idCotizacionPartidaImpuesto;

        if (lstCotizacionPartidaImpuestos[index].total > 0)
          this.lstInnerImpuestos[index].checked = true;
      }

      this.calcPrecioXCantidad();
      if (Globals.exist(this.itemCotizacionPartida.cotizacionPartidaTipoDescuento)) {
        let descuento: number = this.precioXCantidad - this.itemCotizacionPartida.importe;
        this.descuentoActive = this.itemCotizacionPartida.cotizacionPartidaTipoDescuento.idCotizacionPartidaTipoDescuento;
        switch (this.descuentoActive) {
          case 1:
            this.fcDescuentoCantidad.setValue(descuento.toFixed(2));
            break;
          case 2:
            let porcentaje: number = (descuento * 100) / this.precioXCantidad;
            this.fcDescuentoPorcentaje.setValue(porcentaje.toFixed(2));
            break;
        }
        this.calcDescuento();
      }
      this.calcImporte();
    }
  }

  public getCotizacionPartida(): Array<any> {
    let objReturn: any;

    this.lstInnerImpuestos.forEach((itemImpuesto: any) => {
      if (!itemImpuesto['checked']) {
        let total: number = 0;
        itemImpuesto.total = total.toFixed(2);
      }
    });

    objReturn = {
      idCotizacionPartida              : this.idCotizacionPartida,
      idServicio                       : Number(this.fcServicio.value),
      concepto                         : this.fcDescripcion.value,
      idUnidadMedida                   : Number(this.fcUnidadMedida.value),
      precioUnitario                   : this.precioUnitario.toFixed(2),
      idMoneda                         : Number(this.fcMoneda.value),
      cantidad                         : this.cantidad.toFixed(2),
      descuentoCantidad                : this.descuentoCantidad.toFixed(2),
      descuentoPorcentaje              : this.descuentoPorcentaje.toFixed(2),
      idCotizacionPartidaTipoDescuento : this.descuentoActive,
      impuestos                        : this.lstInnerImpuestos,
      importe                          : this.importe.toFixed(2)
    }

    return objReturn;
  }

  public chkItemPartidaSelected_changeEvent(event: any): void {
    this.onChecked.emit(event);
  }

  private calcPrecioXCantidad(): void {
    if (Globals.exist(this.objMoneda) && this.objMoneda != null && this.precioUnitario != 0 && this.equivalencia != 0) {
      if (this.codigoMoneda != this.codigoMonedaPartida) {
        if (this.objMoneda?.idMoneda != 1) {
          this.precioUnitarioXEquivalencia = this.precioUnitario / this.equivalencia;
        } else if (this.objMoneda?.codigo != this.codigoMonedaPartida) {
          this.precioUnitarioXEquivalencia = this.precioUnitario * this.equivalencia;
        }
        this.precioXCantidad = this.cantidad * this.precioUnitarioXEquivalencia;
      } else {
        this.precioXCantidad = this.cantidad * this.precioUnitario;
      }
    }
  }

  public setDescuentoActive($event: any, idDesc: number) {
    if ($event.keyCode != 9 && !$event.shiftKey) {
      this.descuentoActive = idDesc;
    }
  }

  public calcDescuento(): void {
    switch (this.descuentoActive) {
      case 1:
        this.calcDescuentoCantidad();
        break;
      case 2:
        this.calcDescuentoPorcentaje();
        break;
    }

    this.calcImporte();
  }

  public calcDescuentoCantidad(): void {
    if (this.descuentoActive == 1) {
      this.descuentoCantidad = 0.00;
      this.descuentoPorcentaje = 0;

      let regex = '[0-9]+';
      if (Globals.exist(this.fcDescuentoCantidad.value) && this.fcDescuentoCantidad.value != '' && this.fcDescuentoCantidad.value.toString().match(regex)) {
        if (Number(this.fcDescuentoCantidad.value) != 0 && this.fnValidate(this.fcPrecioUnitario.value) && this.fnValidate(this.fcCantidad.value)) {
          this.descuentoCantidad = Number(this.fcDescuentoCantidad.value);
          this.descuentoPorcentaje = (this.descuentoCantidad / this.precioXCantidad) * 100;
          this.fcDescuentoPorcentaje.setValue(this.descuentoPorcentaje.toFixed(2));
        } else {
          this.fcDescuentoPorcentaje.setValue(0);
          this.descuentoActive = 0;
        }
      } else {
        this.fcDescuentoPorcentaje.setValue('');
        this.fcDescuentoCantidad.setValue('');
        this.descuentoActive = 0;
      }
    }
  }

  public calcDescuentoPorcentaje(): void {
    if (this.descuentoActive == 2) {
      this.descuentoPorcentaje = 0;
      this.descuentoCantidad = 0;
      let regex = '[0-9]+';
      if (Globals.exist(this.fcDescuentoPorcentaje.value) && this.fcDescuentoPorcentaje.value != '' && this.fcDescuentoPorcentaje.value.toString().match(regex)) {
        if (Number(this.fcDescuentoPorcentaje.value) != 0 && this.fnValidate(this.fcPrecioUnitario.value) && this.fnValidate(this.fcCantidad.value)) {
          this.descuentoPorcentaje = Number(this.fcDescuentoPorcentaje.value);
          this.descuentoCantidad = (this.precioXCantidad * this.descuentoPorcentaje) / 100;
          this.fcDescuentoCantidad.setValue(this.descuentoCantidad.toFixed(2));
        } else {
          this.fcDescuentoCantidad.setValue(0);
          this.descuentoActive = 0;
        }
      } else {
        this.fcDescuentoPorcentaje.setValue('');
        this.fcDescuentoCantidad.setValue('');
        this.descuentoActive = 0;
      }
    }
  }

  public calcImporte(): void {
    this.importe = this.precioXCantidad - this.descuentoCantidad;
    this.lstInnerImpuestos.forEach(itemImpuesto => {
      let total: number = this.precioXCantidad * (itemImpuesto.porcentaje / 100);
      itemImpuesto.total = total.toFixed(2);
    });
    this.onChange_EventEmit();
  }

  public onChange_EventEmit(): void {
    this.onChange.emit();
  }

  public selectMoneda_selectedEvent(event: any): void {
    let idMoneda: number = event.target.value;
    let objMoneda = this.lstMonedas.find(itemMoneda => itemMoneda.idMoneda == idMoneda);
    this.codigoMonedaPartida = objMoneda.codigo;
    this.calcPrecioXCantidad();
    this.calcDescuento();
  }

  public fnValidate(value: string): boolean {
    let validate: boolean = false;

    if (Globals.exist(value) && value.toString().trim() != "" && Number(value.toString().trim()) != NaN && Number(value) != 0) validate = true;

    return validate;
  }

  public validate(): boolean {
    let objReturn: boolean = true;

    this.fcServicio.markAsTouched();
    this.fcDescripcion.markAsTouched();
    this.fcUnidadMedida.markAsTouched();
    this.fcPrecioUnitario.markAsTouched();
    this.fcMoneda.markAsTouched();
    this.fcCantidad.markAsTouched();
    this.fcDescuentoCantidad.markAsTouched();
    this.fcDescuentoPorcentaje.markAsTouched();

    if (this.fcServicio.hasError('required')) objReturn = false;
    if (this.fcPrecioUnitario.hasError('required')) objReturn = false;
    if (this.fcCantidad.hasError('required')) objReturn = false;
    if (this.fcMoneda.hasError('required')) objReturn = false;
    if (this.fcUnidadMedida.hasError('required')) objReturn = false;

    return objReturn;
  }

  public disableFormPartida(): void {
    FormOptimizer.formDisable(this.formComponents);
  }

  public enableFormPartida(): void {
    FormOptimizer.formEnable(this.formComponents);
  }

  public chkImporteSelected_changeEvent(): void {
    this.onChange_EventEmit();
  }

  public btnPartidaEliminar_clickEvent(): void {
    this.onDelete.emit(this);
  }
}
