<div class="flex-container" (contextmenu)="$event.preventDefault();">
  <div class="mat-dialog-title" [style.width.%]="100">
    <div class="title"> Notificaciones</div>
    <div class="spacer"></div>
    <div class="buttons">
      <button type="button" class="title-button" (click)="close()">
        <mat-icon class="title-button-icon">clear</mat-icon>
      </button>
    </div>
  </div>

  <div class="mat-dialog-body mat-dialog-no-actions">
    <div class="content-notify">
      <div class="toolbar-menu">
        <button class="toolbar-button" [hidden]="!objPrivilegio.COTIZACIONES_LIST" [class.active]="objScene == 'cotizacion'" (click)="changeScene_clickEvent('cotizacion')">
          Cotizaciones
          <div class="toolbar-notify" [hidden]="boolNewNotifyCotizacion">
            <span class="point"></span>
          </div>
        </button>
        <button class="toolbar-button" [hidden]="!objPrivilegio.ORDENES_PEDIDOS_LIST" [class.active]="objScene == 'pedido'"     (click)="changeScene_clickEvent('pedido')">
          Ordenes pedido
          <div class="toolbar-notify" [hidden]="boolNewNotifyOrdPedido">
            <span class="point"></span>
          </div>
        </button>
        <button class="toolbar-button" [hidden]="!objPrivilegio.ORDENES_SERVICIOS_LIST" [class.active]="objScene == 'servicio'"   (click)="changeScene_clickEvent('servicio')">
          Ordenes servicio
          <div class="toolbar-notify" [hidden]="boolNewNotifyOrdServicio">
            <span class="point"></span>
          </div>
        </button>
        <button class="toolbar-button" [hidden]="!objPrivilegio.MANTENIMIENTO_LIST" [class.active]="objScene == 'mantenimiento'"   (click)="changeScene_clickEvent('mantenimiento')">
          Ordenes mantenimiento
          <div class="toolbar-notify" [hidden]="boolNewNotifyOrdMantenimiento">
            <span class="point"></span>
          </div>
        </button>
      </div>

      <div class="notify-center-content" [hidden]="objScene != 'cotizacion'">
        <div class="item-message" *ngFor="let itemCotizacion of lstCotizaciones | paginate: {id:'cotizacion', itemsPerPage: 50, currentPage: pageCotizacion }">
          <div class="notify-content">
            <div class="icon-content">
              <div class="round" matTooltip="{{itemCotizacion.createUsuarioNombreCompleto}}" [style.backgroundColor]="itemCotizacion.background">
                <span [style.color]="itemCotizacion.color">{{itemCotizacion.createUsuarioIniciales}}</span>
              </div>
            </div>
            <div class="mail-content">
              <span class="title">{{itemCotizacion.cotizacion.folio}}</span>
              <span class="subtitle">{{itemCotizacion.cotizacion.clienteNombreComercial}}</span>
              <span class="event">{{itemCotizacion.registroModuloAccionMensaje}}</span>
              <div class="button-link-content">
                <div [hidden]="itemCotizacion.cotizacion.idCotizacionEstatus != 2" class="button-link" matTooltip="Aprobar" (click)="btnCotizacionAprobar_clickEvent(itemCotizacion.cotizacion)">
                  <span class="material-icons">task</span>
                </div>

                <div [hidden]="itemCotizacion.cotizacion.idCotizacionEstatus != 1" class="button-link" matTooltip="Modificar" (click)="btnCotizacionEdit_clickEvent(itemCotizacion.cotizacion)">
                  <span class="material-icons">edit</span>
                </div>

                <div [hidden]="itemCotizacion.cotizacion.idCotizacionEstatus == 3 || itemCotizacion.cotizacion.idCotizacionEstatus == 5" class="button-link" matTooltip="Cancelar" (click)="btnCotizacionCancel_clickEvent(itemCotizacion.cotizacion)">
                  <span class="material-icons">cancel</span>
                </div>
              </div>
            </div>
            <div class="badge-content">
              <span class="badge" [class]="['cotizacion-estatus-' + itemCotizacion.cotizacion.idCotizacionEstatus]">{{itemCotizacion.cotizacion.cotizacionEstatus}}</span>
              <span class="date">{{itemCotizacion.createdFechaNatural}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="notify-center-content" [hidden]="objScene != 'pedido'">
        <div class="item-message" *ngFor="let itemOrdenePedido of lstOrdenesPedidos | paginate: {id:'pedido', itemsPerPage: 50, currentPage: pagePedido }">
          <div class="notify-content">
            <div class="icon-content">
              <div class="round" matTooltip="{{itemOrdenePedido.createUsuarioNombreCompleto}}" [style.backgroundColor]="itemOrdenePedido.background">
                <span [style.color]="itemOrdenePedido.color">{{itemOrdenePedido.createUsuarioIniciales}}</span>
              </div>
            </div>
            <div class="mail-content">
              <span class="title">{{itemOrdenePedido.ordenPedido.folio}}</span>
              <span class="subtitle">{{itemOrdenePedido.ordenPedido.clienteNombreComercial}}</span>
              <span class="event">{{itemOrdenePedido.registroModuloAccionMensaje}}</span>
              <div class="button-link-content">
                <div class="button-link" [hidden]="(itemOrdenePedido.ordenPedido.idOrdenPedidoEstatus > 2)" matTooltip="Ordenes de servicios" (click)="btnListOrdenServicio_clickEvent(itemOrdenePedido.ordenPedido)">
                  <span class="material-icons">description</span>
                </div>
              </div>
            </div>
            <div class="badge-content">
              <span class="badge" [class]="['estatus-' + itemOrdenePedido.ordenPedido.idOrdenPedidoEstatus]">{{itemOrdenePedido.ordenPedido.ordenPedidoEstatus}}</span>
              <span class="date">{{itemOrdenePedido.createdFechaNatural}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="notify-center-content" [hidden]="objScene != 'servicio'">
        <div class="item-message" *ngFor="let itemOrdeneServicio of lstOrdenesServicios | paginate: {id:'servicio', itemsPerPage: 50, currentPage: pageServicio }">
          <div class="notify-content">
            <div class="icon-content">
              <div class="round" matTooltip="{{itemOrdeneServicio.createUsuarioNombreCompleto}}" [style.backgroundColor]="itemOrdeneServicio.background">
                <span [style.color]="itemOrdeneServicio.color">{{itemOrdeneServicio.createUsuarioIniciales}}</span>
              </div>
            </div>
            <div class="mail-content">
              <span class="title">{{itemOrdeneServicio.ordenServicio.folio}}</span>
              <span class="subtitle">{{itemOrdeneServicio.ordenServicio.clienteNombreComercial}}</span>
              <span class="event">{{itemOrdeneServicio.registroModuloAccionMensaje}}</span>
              <div class="button-link-content">
                <div class="button-link" [hidden]="!(itemOrdeneServicio.ordenServicio.idOrdenServicioEstatus == 3)" matTooltip="Finalizar" (click)="btnFinalizarOrdenServicio_clickEvent(itemOrdeneServicio.ordenServicio)">
                  <span class="material-icons">task_alt</span>
                </div>
              </div>
            </div>
            <div class="badge-content">
              <span class="badge" [class]="['estatus-' + itemOrdeneServicio.ordenServicio.idOrdenServicioEstatus]">{{itemOrdeneServicio.ordenServicio.ordenServicioEstatus}}</span>
              <span class="date">{{itemOrdeneServicio.createdFechaNatural}}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="notify-center-content" [hidden]="objScene != 'mantenimiento'">
        <div class="item-message" *ngFor="let itemUnidadMantenimientoTaller of lstOrdenesMantenimiento | paginate: {id:'mantenimiento', itemsPerPage: 50, currentPage: pageServicio }">
          <div class="notify-content" *ngIf="itemUnidadMantenimientoTaller.idRegistroModulo == 5">
            <div class="icon-content">
              <div class="round" matTooltip="{{itemUnidadMantenimientoTaller.createUsuarioNombreCompleto}}" [style.backgroundColor]="itemUnidadMantenimientoTaller.background">
                <span [style.color]="itemUnidadMantenimientoTaller.color">{{itemUnidadMantenimientoTaller.createUsuarioIniciales}}</span>
              </div>
            </div>
            <div class="mail-content">
              <span class="title">{{itemUnidadMantenimientoTaller.mantenimiento.unidadMantenimientoFolio}}</span>
              <span class="subtitle"><strong>{{itemUnidadMantenimientoTaller.mantenimiento.unidadMantenimientoTaller}}:</strong> {{itemUnidadMantenimientoTaller.mantenimiento.folio}}</span>
              <span class="event">{{itemUnidadMantenimientoTaller.registroModuloAccionMensaje}}</span>
              <div class="button-link-content">
                <div [hidden]="!(itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoTallerEstatus == 1)" class="button-link" matTooltip="Iniciar servicio" (click)="btnInit_clickEvent(itemUnidadMantenimientoTaller.mantenimiento)">
                  <span class="material-icons">arrow_forward</span>
                </div>

                <div [hidden]="!(itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoTallerEstatus == 2)" class="button-link" matTooltip="Finalizar servicio" (click)="btnFinalize_clickEvent(itemUnidadMantenimientoTaller.mantenimiento)">
                  <span class="material-icons">check_circle_outline</span>
                </div>

                <div [hidden]="!(itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoTallerEstatus < 3)" class="button-link" matTooltip="Cancelar servicio" (click)="btnCancel_clickEvent(itemUnidadMantenimientoTaller.mantenimiento)">
                  <span class="material-icons">cancel</span>
                </div>
              </div>
            </div>
            <div class="badge-content">
              <span class="badge"
                    matTooltip="Finalización forzada"
                    [matTooltipDisabled]="itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoTallerEstatus != 4"
                    [class]="['mantenimiento-estatus-' + itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoTallerEstatus]">
                {{(itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoTallerEstatus != 4)?itemUnidadMantenimientoTaller.mantenimiento.unidadMantenimientoTallerEstatus:'Finalizado'}}
              </span>
              <div class="progress-radial-bar-content">
                <div class="progress-radial-bar">
                  <div class="progress"
                       matTooltip="Atrasado"
                       [matTooltipDisabled]="itemUnidadMantenimientoTaller.mantenimiento.isEnTiempo"
                       [style.background]="'conic-gradient(' + gradientPercentageMantenimientoTaller(itemUnidadMantenimientoTaller.mantenimiento) + ')'">
                    <div class="progress-value">{{itemUnidadMantenimientoTaller.mantenimiento.diasLaboralesPorcentaje}}%</div>
                  </div>
                  <div class="radial-bar-title">Taller</div>
                </div>
                <div class="progress-radial-bar">
                  <div class="progress"
                       matTooltip="Atrasado"
                       [matTooltipDisabled]="itemUnidadMantenimientoTaller.mantenimiento.unidadMantenimientoIsEnTiempo"
                       [style.background]="'conic-gradient(' + gradientPercentageMantenimiento(itemUnidadMantenimientoTaller.mantenimiento) + ')'">
                    <div class="progress-value">{{itemUnidadMantenimientoTaller.mantenimiento.unidadMantenimientoPorcentaje}}%</div>
                  </div>
                  <div class="radial-bar-title">Manto</div>
                </div>
              </div>
            </div>
          </div>

          <div class="notify-content" *ngIf="itemUnidadMantenimientoTaller.idRegistroModulo == 4">
            <div class="icon-content">
              <div class="round" matTooltip="{{itemUnidadMantenimientoTaller.createUsuarioNombreCompleto}}" [style.backgroundColor]="itemUnidadMantenimientoTaller.background">
                <span [style.color]="itemUnidadMantenimientoTaller.color">{{itemUnidadMantenimientoTaller.createUsuarioIniciales}}</span>
              </div>
            </div>
            <div class="mail-content">
              <span class="title">{{itemUnidadMantenimientoTaller.mantenimiento.folio}}</span>
              <span class="subtitle"><strong>Unidad:</strong> {{itemUnidadMantenimientoTaller.mantenimiento.unidad}}</span>
              <span class="event">{{itemUnidadMantenimientoTaller.registroModuloAccionMensaje}}</span>
              <div class="button-link-content">
                <div [hidden]="!(itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoEstatus == 4)" class="button-link" matTooltip="Finalizar servicio" (click)="btnOrdenMantenimientoFinalize_clickEvent(itemUnidadMantenimientoTaller.mantenimiento)">
                  <span class="material-icons">check_circle_outline</span>
                </div>
                <div [hidden]="!(itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoEstatus == 2)" class="button-link" matTooltip="Forzar finalización" (click)="btnOrdenMantenimientoForceFinalize_clickEvent(itemUnidadMantenimientoTaller.mantenimiento)">
                  <span class="material-icons">playlist_add_check_circle</span>
                </div>
              </div>
            </div>
            <div class="badge-content">
              <span class="badge" [class]="['mantenimiento-estatus-' + itemUnidadMantenimientoTaller.mantenimiento.idUnidadMantenimientoEstatus]">{{itemUnidadMantenimientoTaller.mantenimiento.unidadMantenimientoEstatus}}</span>
              <div class="progress-radial-bar-content">
                <div class="progress-radial-bar">
                  <div class="progress"
                       matTooltip="Atrasado"
                       [matTooltipDisabled]="itemUnidadMantenimientoTaller.mantenimiento.isEnTiempo"
                       [style.background]="'conic-gradient(' + gradientPercentageOrdenMantenimiento(itemUnidadMantenimientoTaller.mantenimiento) + ')'">
                    <div class="progress-value">{{itemUnidadMantenimientoTaller.mantenimiento.diasLaboralesPorcentaje}}%</div>
                  </div>
                  <div class="radial-bar-title">Manto</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="status-bar">
        <div class="search" [hidden]="objScene != 'cotizacion'"    [routerLink]="['/cp/cotizaciones']"      (click)="close()"><span class="label">Ir a cotización</span></div>
        <div class="search" [hidden]="objScene != 'pedido'"        [routerLink]="['/cp/ordenes-pedidos']"   (click)="close()"><span class="label">Ir a ordenes de pedido</span></div>
        <div class="search" [hidden]="objScene != 'servicio'"      [routerLink]="['/cp/ordenes-servicios']" (click)="close()"><span class="label">Ir a ordenes de servicios</span></div>
        <div class="search" [hidden]="objScene != 'mantenimiento'" [routerLink]="['/cp/mantenimientos']"    (click)="close()"><span class="label">Ir a ordenes de mantenimiento</span></div>
        <div class="count"><span class="label">registros :</span> {{length}}</div>
        <div class="paginator" [hidden]="objScene != 'cotizacion'">
          <pagination-controls
            id="cotizacion"
            (pageChange)="pageCotizacion = $event"
            responsive="true"
            previousLabel="prev"
            nextLabel="next"></pagination-controls>
        </div>
        <div class="paginator" [hidden]="objScene != 'pedido'">
          <pagination-controls
            id="pedido"
            (pageChange)="pagePedido = $event"
            responsive="true"
            previousLabel="prev"
            nextLabel="next"></pagination-controls>
        </div>
        <div class="paginator" [hidden]="objScene != 'servicio'">
          <pagination-controls
            id="servicio"
            (pageChange)="pageServicio = $event"
            responsive="true"
            previousLabel="prev"
            nextLabel="next"></pagination-controls>
        </div>
        <div class="paginator" [hidden]="objScene != 'mantenimiento'">
          <pagination-controls
            id="mantenimiento"
            (pageChange)="pageMantenimiento = $event"
            responsive="true"
            previousLabel="prev"
            nextLabel="next"></pagination-controls>
        </div>
      </div>
    </div>
  </div>

  <div class="mat-dialog-loader mat-dialog-no-actions">
    <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
  </div>
</div>


