import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {MatTooltipModule} from "@angular/material/tooltip";
import {FileViewerComponent} from "./file-viewer.component";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatDividerModule} from "@angular/material/divider";
import {FileViewerService} from "../../services/file-viewer.service";



@NgModule({
  declarations: [
    FileViewerComponent
  ],
  imports: [
    CommonModule,

    MatButtonModule,
    MatDividerModule,
    MatTooltipModule,
    MatIconModule,
    MatProgressBarModule,

    PdfViewerModule,
  ],
  exports : [
    FileViewerComponent,
  ],
  providers: [
    FileViewerService
  ]
})
export class FileViewerModule { }
