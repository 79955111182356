import {HttpClient, HttpEventType} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../libraries/application-config';
import { Sessions } from '../libraries/sessions';
import { AuthService } from './auth.service';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  constructor(  private http: HttpClient,
                private objAuthService : AuthService ) { }

  index( modulo : string ): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/usuarios/' + modulo + '/index', Sessions.headerSession(this.objAuthService.access_token));
  }

  datatable( objParams : any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/usuarios/datatable", objParams, Sessions.headerSession(this.objAuthService.access_token));
  }

  deleted(): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/deleted", Sessions.headerSession(this.objAuthService.access_token));
  }

  create(): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/create", Sessions.headerSession(this.objAuthService.access_token));
  }

  emailDuplicate(strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/exist/" + strSearch + "/email", Sessions.headerSession(this.objAuthService.access_token));
  }

  claveDuplicate(strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/exist/" + strSearch + "/clave", Sessions.headerSession(this.objAuthService.access_token));
  }

  codigoGenerate(strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/generate/" + strSearch + "/codigo", Sessions.headerSession(this.objAuthService.access_token));
  }

  codigoDuplicate(strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/exist/" + strSearch + "/codigo", Sessions.headerSession(this.objAuthService.access_token));
  }

  findOrdenPedido( strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/" + strSearch + "/find-orden-pedido", Sessions.headerSession(this.objAuthService.access_token));
  }

  findOrdenServicio(strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/" + strSearch + "/find-orden-servicio", Sessions.headerSession(this.objAuthService.access_token));
  }

  findUsuarios(strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/" + strSearch + "/find-usuarios", Sessions.headerSession(this.objAuthService.access_token));
  }

  store( objParams : any ) : Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/usuarios", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  refresh( idUsuario : number ): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/" + idUsuario + '/refresh', Sessions.headerSession(this.objAuthService.access_token));
  }

  edit( idUsuario : number ): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/" + idUsuario + '/edit', Sessions.headerSession(this.objAuthService.access_token));
  }

  update( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/usuarios", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  passwordUpdate( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/usuarios/password", objParams, Sessions.headerSession(this.objAuthService.access_token));
  }

  settingSendEmail( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/usuarios/settingSendEmail", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  estatusDesactivar( idUsuario : number ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/usuarios/desactivar/" + idUsuario, {}, Sessions.headerFormData(this.objAuthService.access_token));
  }

  estatusDesactivarList(lstUsuarios : any): Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/usuarios/desactivarLista", lstUsuarios, Sessions.headerSession(this.objAuthService.access_token));
  }

  estatusActivar( idUsuario : number ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/usuarios/activar/" + idUsuario, {}, Sessions.headerFormData(this.objAuthService.access_token));
  }

  estatusActivarList(lstUsuarios : any): Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/usuarios/activarLista", lstUsuarios, Sessions.headerSession(this.objAuthService.access_token));
  }

  estatusEliminar( idUsuario : number ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/usuarios/eliminar/" + idUsuario, {}, Sessions.headerFormData(this.objAuthService.access_token));
  }

  estatusEliminarList(lstUsuarios : any): Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/usuarios/eliminarLista", lstUsuarios, Sessions.headerSession(this.objAuthService.access_token));
  }

  estatusRecuperar( idUsuario : number ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/usuarios/recuperar/" + idUsuario, Sessions.headerFormData(this.objAuthService.access_token));
  }

  estatusRecuperarList(lstUsuarios : any): Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/usuarios/recuperarLista", lstUsuarios, Sessions.headerSession(this.objAuthService.access_token));
  }

  delete( idUsuario : number ) : Observable<any> {
    return this.http.delete<any>(ApplicationConfig.ENDPOINT + "api/usuarios/" + idUsuario, Sessions.headerSession(this.objAuthService.access_token));
  }

  exportExcel(objParams : any ): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/usuarios/export/excel", objParams, Sessions.headerFile(this.objAuthService.access_token));
  }

  editSettingEmail( idUsuario : number ): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/" + idUsuario + '/editSettingEmail', Sessions.headerSession(this.objAuthService.access_token));
  }

  settingEmail( idUsuario : number ): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/" + idUsuario + '/setting_email', Sessions.headerSession(this.objAuthService.access_token));
  }

  infoEmail(idUsuario: number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/" + idUsuario + "/infoEmail", Sessions.headerSession(this.objAuthService.access_token));
  }

  findClientes( strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/usuarios/" + strSearch + "/find-clientes", Sessions.headerSession(this.objAuthService.access_token));
  }

  sendEmail(objParams: any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/usuarios/sendEmail", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

}
