import {Component, EventEmitter, Inject, Input, OnInit, Output, AfterViewInit} from '@angular/core';
import {Globals} from "../../libraries/globals";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {SuccessComponent} from "../success/success.component";
import {FormOptimizer} from "../../libraries/formOptimizer";
import {AlertComponent} from "../alert/alert.component";
import {UsuariosService} from "../../services/usuarios.service";
import {ConfiguracionesService} from "../../services/configuraciones.service";

@Component({
  selector: 'app-email-status',
  templateUrl: './email-status.component.html',
  styleUrls: ['./email-status.component.scss']
})
export class EmailStatusComponent implements OnInit, AfterViewInit {

  public titulo         : string = '';
  public boolEmail      : string = '';
  public boolPort       : string = '';
  public boolServer     : string = '';
  public boolMessage    : string = '';
  public acceptLabel    : string = 'Aceptar';
  public cancelLabel    : string = 'Cancelar';
  public autoCloseDelay : number = 0;
  public fnClose        : any;
  public fnAccept       : any;
  public fnChange       : Function;
  public fnCancel       : any;

  @Output('onClose') onClose : EventEmitter<EmailStatusComponent> = new EventEmitter<EmailStatusComponent>();
  @Output('onCloseAll') onCloseAll : EventEmitter<EmailStatusComponent> = new EventEmitter<EmailStatusComponent>();
  @Output('onChange') onChange : EventEmitter<EmailStatusComponent> = new EventEmitter<EmailStatusComponent>();

  public objLoader = {
    type            : 'loader',
    visible         : true
  }

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  }

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  }

  public formComponents : Array<any> = [
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objMatDialog       : MatDialog,
    private objUsuariosService : UsuariosService,
    private objConfiguraciones : ConfiguracionesService,
    public objDialogRef        : MatDialogRef<EmailStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.init());
  }

  public init() : void {
    FormOptimizer.formDisable(this.formComponents);

    if ( Globals.exist( this.data?.scene ) && this.data.scene === 'USUARIOS' ) {
      this.objUsuariosService.settingSendEmail(this.data)
        .subscribe(objResponse => {
          this.validated(objResponse.action, objResponse.code);
          this.change();
          FormOptimizer.formEnable(this.formComponents);
        }, error => {
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: "Error de comunicación", mensaje: "Ocurrió un error de comunicación con el servidor.", autoCloseDelay: 3000}));
          FormOptimizer.formEnable(this.formComponents);
        });
    } else if ( Globals.exist( this.data?.scene ) && this.data.scene === 'CONFIGURACIONES' ) {
      this.objConfiguraciones.validatedEmail(this.data)
        .subscribe(objResponse => {
          this.validated(objResponse.action, objResponse.code);
          this.change();
          FormOptimizer.formEnable(this.formComponents);
        }, error => {
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: "Error de comunicación", mensaje: "Ocurrió un error de comunicación con el servidor.", autoCloseDelay: 3000}));
          FormOptimizer.formEnable(this.formComponents);
        });
    }
  }

  public validated( action : boolean, code : string ) : void {
    if (action) {
      this.boolPort    = 'true';
      this.boolServer  = 'true';
      this.boolEmail   = 'true';
      this.boolMessage = 'true';
    } else if (code == 'OPEN_PORT_SERVER_001' || code == 'PORT_BUSY_SERVER_001') {
      this.acceptLabel = (this.data.scene === 'USUARIOS')? '¿Guardar de todos modos?' : 'Aceptar';
      this.boolPort    = 'false';
      this.boolServer  = 'false';
      this.boolEmail   = 'false';
      this.boolMessage = 'false';
    } else if (code == 'CONNECT_SERVER') {
      this.acceptLabel = (this.data.scene === 'USUARIOS')? '¿Guardar de todos modos?' : 'Aceptar';
      this.boolPort    = 'true';
      this.boolServer  = 'false';
      this.boolEmail   = 'false';
      this.boolMessage = 'false';
    } else if (code == 'LOGIN') {
      this.acceptLabel = (this.data.scene === 'USUARIOS')? '¿Guardar de todos modos?' : 'Aceptar';
      this.boolPort    = 'true';
      this.boolServer  = 'true';
      this.boolEmail   = 'false';
      this.boolMessage = 'false';
    }
  }

  public change() : void {
    this.onChange.emit(this);
  }

  public closeAll() : void {
    this.objDialogRef.close();
    this.onCloseAll.emit(this);
  }

  public close() : void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

  public btnAceptar_clickEvent() : void {
    if( this.boolMessage == 'false' ) {
      this.data.validEmail = false;
      this.init();
      this.closeAll();
    } else {
      this.closeAll();
    }
  }

  public btnCancelar_clickEvent() : void {
    this.close();
  }

  public btnClose_clickEvent() : void {
    if( this.boolMessage == 'false' ) {
      this.close();
    } else {
      this.closeAll();
    }
  }
}
