import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Globals } from '../libraries/globals';

@Injectable({
  providedIn: 'root'
})
export class CookiesService {

  public lifetime : number | Date = 365;

  constructor(  public objCookieService : CookieService ) { }

  public getCookieService() : CookieService {
    return this.objCookieService;
  }

  public start() : void {
    if( !this.check('mainMenuToogle') ) this.objCookieService.set('mainMenuToogle', 'true', 365);
  }

  public check(variable : string) : boolean {
    return this.objCookieService.check(variable);
  }

  public setJson(variable : string, value : any, expires?: number | Date) : void {
    let strValue : string = JSON.stringify(value);
    let valueExpires = expires || this.lifetime;
    this.objCookieService.set( variable, strValue, valueExpires );
  }

  public getJson(variable : string) : any {
    let objReturn : any = null;
    if( this.check(variable) ) {
      let strValue : string = this.objCookieService.get(variable);
      objReturn = JSON.parse(strValue);
    }
    return objReturn;
  }

  public setBoolean(variable : string, value : boolean, expires?: number | Date) : void {
    let strValue : string = JSON.stringify(value);
    let valueExpires = expires || this.lifetime;
    this.objCookieService.set( variable, strValue, valueExpires );
  }

  public getBoolean(variable : string) : boolean {
    let objReturn : boolean = false;
    if( this.check(variable) ) {
      let strValue : string = this.objCookieService.get(variable);
      objReturn = strValue == 'true';
    }
    return objReturn;
  }

  public set(variable : string, value : string, expires?: number | Date) : void {
    let valueExpires = expires || this.lifetime;
    this.objCookieService.set( variable, value, valueExpires );
  }

  public get(variable : string) : string {
    let objReturn : string = '';
    if( this.check(variable) ) {
      objReturn = this.objCookieService.get(variable);
    }
    return objReturn;
  }

  public delete(variable : string) : void {
    if( this.check(variable) ) {
      this.objCookieService.delete(variable);
    }
  }
}
