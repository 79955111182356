import {AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import { ApplicationConfig } from 'src/app/libraries/application-config';
import {PictureUploadComponent} from "../../../../components/picture-upload/picture-upload.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CuentaService} from "../../../../services/cuenta.service";
import {AuthService} from "../../../../services/auth.service";
import {DatePipe} from "@angular/common";
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormOptimizer} from "../../../../libraries/formOptimizer";
import {map} from "rxjs/operators";
import {Globals} from "../../../../libraries/globals";
import {UsuariosFicherosIndexComponent} from "../../usuarios/ficheros/index/index.component";

@Component({
  selector: 'app-cuenta-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class CuentaIndexComponent implements OnInit, AfterViewInit {

  @Output('onClose') onClose : EventEmitter<CuentaIndexComponent> = new EventEmitter<CuentaIndexComponent>();
  @ViewChild('compPictureUpload') compPictureUpload : PictureUploadComponent;

  public fgUsuarioEdit : FormGroup = new FormGroup({
    nombre            : new FormControl('', Validators.required),
    apellidoPaterno   : new FormControl('', Validators.required),
    apellidoMaterno   : new FormControl(''),
    isUsuario         : new FormControl(false),
    email             : new FormControl('', Validators.required),
    password          : new FormControl(''),
    codigo            : new FormControl('', Validators.required),

    empresa           : new FormControl(''),
    sucursal          : new FormControl(''),
    departamento      : new FormControl(''),
    puesto            : new FormControl('')
  });

  public ApplicationConfig = ApplicationConfig;
  public objUsuario : any;
  public lstEmpresas : Array<any> =[];
  public lstSucursales : Array<any> =[];
  public lstSucursalesFiltered: any;
  public lstDepartamentos : Array<any> =[];
  public lstDepartamentosFiltered: any;
  public lstPuestos : Array<any> =[];
  public lstPuestosFiltered: any;

  public boolLoader             : boolean    = false;

  public objCompPictureUpload = {
    type            : 'componentForm',
    disabled        : false
  }

  public objLoader = {
    type            : 'loader',
    visible         : true
  }

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  }

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  }

  public formComponents : Array<any> = [
    this.objLoader,
    this.fgUsuarioEdit,
    this.objCompPictureUpload,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objDialogRef: MatDialogRef<CuentaIndexComponent>,
    private objCuentaService : CuentaService,
    private objAuthService : AuthService,
    public objMatDialog: MatDialog,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout( () => this.init() );
  }

  public init() {
    FormOptimizer.formDisable(this.formComponents);

    this.objCuentaService.edit(this.objAuthService.usuario.idUsuario)
      .subscribe( objResponse => {
        console.log(objResponse.result);

        this.objUsuario = objResponse.result.usuario;

        this.lstEmpresas      = objResponse.result.empresas;
        this.lstSucursalesFiltered    = this.lstSucursales    = objResponse.result.sucursales;
        this.lstDepartamentosFiltered = this.lstDepartamentos = objResponse.result.departamentos;
        this.lstPuestos       = objResponse.result.puestos;

        this.fgUsuarioEdit.controls['nombre'].setValue(this.objUsuario.nombre);
        this.fgUsuarioEdit.controls['apellidoPaterno'].setValue(this.objUsuario.apellidoPaterno);
        this.fgUsuarioEdit.controls['apellidoMaterno'].setValue(this.objUsuario.apellidoMaterno);
        this.fgUsuarioEdit.controls['email'].setValue(this.objUsuario.email);
        this.fgUsuarioEdit.controls['isUsuario'].setValue(this.objUsuario.isUsuario);
        this.isUsuario_changeEvent();

        this.fgUsuarioEdit.controls['codigo'].setValue(this.objUsuario.codigo);
        this.compPictureUpload.setPreview(ApplicationConfig.ENDPOINT + 'api/usuarios/foto/' + this.objUsuario.idUsuario + '/512');

        this.lstSucursalesFiltered = this.fgUsuarioEdit.controls['empresa'].valueChanges.pipe(
          map(value => {
            this.fgUsuarioEdit.controls['sucursal'].setValue('');
            return this.lstSucursales.filter(itemSucursal => Globals.exist(itemSucursal.empresa) &&  itemSucursal.empresa.idEmpresa == value);
          })
        );

        this.lstDepartamentosFiltered = this.fgUsuarioEdit.controls['empresa'].valueChanges.pipe(
          map(value => {
            this.fgUsuarioEdit.controls['departamento'].setValue('');
            this.fgUsuarioEdit.controls['puesto'].setValue('');
            return this.lstDepartamentos.filter(itemDepartamento => Globals.exist(itemDepartamento.empresaParent) &&  itemDepartamento.empresaParent.idEmpresa == value);
          })
        );

        this.lstPuestosFiltered = this.fgUsuarioEdit.controls['departamento'].valueChanges.pipe(
          map(value => {
            this.fgUsuarioEdit.controls['puesto'].setValue('');
            return this.lstPuestos.filter(itemPuesto => Globals.exist(itemPuesto.departamentos) && itemPuesto.departamentos.includes(value));
          })
        );

        setTimeout(() => {
          if(  Globals.exist( this.objUsuario.empresa ) ) { this.fgUsuarioEdit.controls['empresa'].setValue(this.objUsuario.empresa.idEmpresa); }
          if(  Globals.exist( this.objUsuario.sucursal ) ) { this.fgUsuarioEdit.controls['sucursal'].setValue(this.objUsuario.sucursal.idSucursal); }
          if(  Globals.exist( this.objUsuario.departamento ) ) this.fgUsuarioEdit.controls['departamento'].setValue(this.objUsuario.departamento.idDepartamento);
          if(  Globals.exist( this.objUsuario.puesto ) ) this.fgUsuarioEdit.controls['puesto'].setValue(this.objUsuario.puesto.idPuesto);
        });

        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        FormOptimizer.formEnable(this.formComponents);
      });
  }

  public formUsuarioEdit_submitEvent() : void {

  }

  public btnUsuarioFicheros_clickEvent() : void {
    let objDialog = this.objMatDialog.open( UsuariosFicherosIndexComponent, {
      width         : '96%',
      maxWidth      : '1366px',
      height        : '96%',
      // maxHeight     : '00px',
      data          : this.objUsuario,
      disableClose  : true
    });

    // objDialog.componentInstance.onClose.subscribe( ( itemUsuarioEditComponent : UsuariosEditComponent) => {
    //   this.datatableRefresh();
    // });
  }

  public isUsuario_changeEvent() : void {
    if( this.fgUsuarioEdit.controls['isUsuario'].value ) {
      this.fgUsuarioEdit.controls['password'].enable();
      this.fgUsuarioEdit.controls['password'].setValidators([Validators.required]);
      this.fgUsuarioEdit.controls['password'].updateValueAndValidity();
    } else {
      this.fgUsuarioEdit.controls['password'].disable();
      this.fgUsuarioEdit.controls['password'].clearValidators();
      this.fgUsuarioEdit.controls['password'].updateValueAndValidity();
    }
  }

  public close() : void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

}
