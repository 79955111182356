import {AfterViewInit, Component, OnInit} from '@angular/core';
import {NotificacionesService} from "../../../services/notificaciones.service";
import {MatDialog} from "@angular/material/dialog";
import {NotificationsComponent} from "../../../cp/notifications/notifications.component";
import {WebSocketShareService} from "../../../libraries/websocketshare.service";
import {WebSocketAPI} from "../../../libraries/websocketapi";
import {AuthService} from "../../../services/auth.service";
import {CotizacionApproveComponent} from "../../../cp/views/cotizaciones/approve/approve.component";
import {CotizacionesEditComponent} from "../../../cp/views/cotizaciones/edit/edit.component";
import {OrdenesServiciosFinalizarComponent} from "../../../cp/views/ordenesServicios/finalize/finalize.component";
import {OrdenesServiciosIndexComponent} from "../../../cp/views/ordenesPedidos/ordenes-servicios/index/index.component";
import {UnidadMantenimientoTalleresInitServiceComponent} from "../../../cp/views/servicioMantenimiento/init-service/init-service.component";
import {UnidadMantenimientoTalleresFinalizeServiceComponent} from "../../../cp/views/servicioMantenimiento/finalize-service/finalize-service.component";
import {Globals} from "../../../libraries/globals";
import {UnidadesMantenimientosService} from "../../../services/unidades-mantenimientos.service";
import {ConfirmComponent} from "../../../components/confirm/confirm.component";
import {SuccessComponent} from "../../../components/success/success.component";
import {AlertComponent} from "../../../components/alert/alert.component";
import {CotizacionesCancelComponent} from "../../../cp/views/cotizaciones/cancel/cancel.component";
import {OrdenesPedidosService} from "../../../services/ordenes-pedidos.service";
import {
  UnidadMantenimientoTalleresCancelServiceComponent
} from "../../../cp/views/servicioMantenimiento/cancel-service/cancel-service.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: []
})
export class AppHeaderComponent implements OnInit, AfterViewInit{

  public lstCotizaciones               : Array<any> = [];
  public lstOrdenesPedidos             : Array<any> = [];
  public lstOrdenesServicios           : Array<any> = [];
  public lstOrdenesMantenimiento       : Array<any> = [];
  public boolNotify                    : boolean = true;
  public boolNewNotify                 : boolean = false;
  public boolNewNotifyCotizacion       : boolean = true;
  public boolNewNotifyOrdPedido        : boolean = true;
  public boolNewNotifyOrdServicio      : boolean = true;
  public boolNewNotifyOrdMantenimiento : boolean = true;
  public objScene                      : string  = 'cotizacion';
  public objPrivilegio                 : any     = {};

  constructor(
    public objMatDialog               : MatDialog,
    private objNotificacionesService  : NotificacionesService,
    private objOrdenesPedidosService  : OrdenesPedidosService,
    private objMantenimientosService  : UnidadesMantenimientosService,
    private objAuthService            : AuthService,
    private websocketService          : WebSocketShareService,
    private webSocketAPI              : WebSocketAPI
  ){
    this.objPrivilegio = objAuthService.privilegios;
    if(!this.objPrivilegio.COTIZACIONES_LIST)      this.objScene = 'pedido';
    if(!this.objPrivilegio.ORDENES_PEDIDOS_LIST)   this.objScene = 'servicio';
    if(!this.objPrivilegio.ORDENES_SERVICIOS_LIST) this.objScene = 'mantenimiento';
  }

  ngOnInit(): void {
    // setTimeout(() => {
    //   this.objNotificacionesService.notification().subscribe( (objResponse) => {
    //     if(this.objPrivilegio.COTIZACIONES_LIST) {
    //       this.lstCotizaciones = objResponse.result?.cotizaciones;
    //       this.lstCotizaciones.forEach( (itemCotizacion, index) => {
    //         if (Globals.exist(itemCotizacion?.jsonReference)) {
    //           itemCotizacion['cotizacion'] = JSON.parse(itemCotizacion.jsonReference);
    //         }
    //       });
    //     }
    //
    //     if(this.objPrivilegio.ORDENES_PEDIDOS_LIST) {
    //       this.lstOrdenesPedidos = objResponse.result?.ordenesPedidos;
    //       this.lstOrdenesPedidos.forEach( itemOrdenPedido => {
    //         if (Globals.exist(itemOrdenPedido?.jsonReference)) {
    //           itemOrdenPedido['ordenPedido'] = JSON.parse(itemOrdenPedido.jsonReference);
    //         }
    //       });
    //     }
    //
    //     if(this.objPrivilegio.ORDENES_SERVICIOS_LIST) {
    //       this.lstOrdenesServicios = objResponse.result?.ordenesServicios;
    //       this.lstOrdenesServicios.forEach( itemOrdenServicio => {
    //         if (Globals.exist(itemOrdenServicio?.jsonReference)) {
    //           itemOrdenServicio['ordenServicio'] = JSON.parse(itemOrdenServicio.jsonReference);
    //         }
    //       });
    //     }
    //
    //     if(this.objPrivilegio.MANTENIMIENTO_LIST) {
    //       this.lstOrdenesMantenimiento = objResponse.result?.ordenesMantenimientos;
    //       this.lstOrdenesMantenimiento.forEach( itemMantenimiento => {
    //         if (Globals.exist(itemMantenimiento?.jsonReference)) {
    //           itemMantenimiento['mantenimiento'] = JSON.parse(itemMantenimiento.jsonReference);
    //         }
    //       });
    //     }
    //
    //     if(this.lstCotizaciones.length > 0 || this.lstOrdenesPedidos.length > 0 || this.lstOrdenesServicios.length > 0 || this.lstOrdenesMantenimiento.length > 0) {
    //       this.boolNotify = false;
    //       if(this.lstCotizaciones.length > 0)         this.boolNewNotifyCotizacion       = false;
    //       if(this.lstOrdenesPedidos.length > 0)       this.boolNewNotifyOrdPedido        = false;
    //       if(this.lstOrdenesServicios.length > 0)     this.boolNewNotifyOrdServicio      = false;
    //       if(this.lstOrdenesMantenimiento.length > 0) this.boolNewNotifyOrdMantenimiento = false;
    //       this.newNotification_changeEvent();
    //     }
    //   }, error => {
    //
    //   });
    // });
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.webSocketAPI._connect();
    //   this.onCotizacionNewValueReceive();
    //   this.onOrdenPedidoNewValueReceive();
    //   this.onOrdenServicioNewValueReceive();
    //   this.onOrdenMantenimientoNewValueReceive();
    // });
  }

  public viewAllNotifications_clickEvent() : void {
    let objDialog = this.objMatDialog.open( NotificationsComponent, {
      width         : '96%',
      maxWidth      : '520px',
      height        : '96%',
      maxHeight     : '574px',
      data          : this.objScene,
      disableClose  : true
    });
  }

  ngOnDestroy() {}

  public toolbarViewUpdate(event : MouseEvent, view : string ) : void {
    event.stopPropagation();
    this.objScene = view;
    if(view == 'cotizacion')    this.boolNewNotifyCotizacion       = true;
    if(view == 'pedido')        this.boolNewNotifyOrdPedido        = true;
    if(view == 'servicio')      this.boolNewNotifyOrdServicio      = true;
    if(view == 'mantenimiento') this.boolNewNotifyOrdMantenimiento = true;
  }

  public newNotification_changeEvent() : void {
    // this.boolNewNotify = true;
    // let audio : HTMLAudioElement = new Audio();
    // audio.src = ".../../../../assets/sound/notification.mp3";
    // audio.load();
    // audio.muted = false;
    // audio.play()
    //   .then(_ => {})
    //   .catch( error => {
    //     console.log(error);
    //   });
    //
    // setTimeout( () => {
    //   this.boolNewNotify = false;
    // }, 10000);
  }

  public onCotizacionNewValueReceive() {
    // this.websocketService.getCotizacionNewValue().subscribe(objResponse => {
    //   if (this.objPrivilegio.COTIZACIONES_LIST) {
    //     let objNotificacion : any = objResponse;
    //     if (Globals.exist(objNotificacion?.jsonReference)) {
    //       objNotificacion['cotizacion'] = JSON.parse(objNotificacion.jsonReference);
    //       this.lstCotizaciones.unshift(objNotificacion);
    //     }
    //     if (this.lstCotizaciones.length > 0) {
    //       this.boolNotify = false;
    //       this.boolNewNotifyCotizacion = false;
    //       this.newNotification_changeEvent();
    //     } else {
    //       this.boolNotify = true;
    //     }
    //   }
    // });
  }

  public onOrdenPedidoNewValueReceive() {
      // this.websocketService.getOrdenPedidoNewValue().subscribe(objResponse => {
      //   if (this.objPrivilegio.ORDENES_PEDIDOS_LIST) {
      //     let objNotificacion : any = objResponse;
      //     if (Globals.exist(objNotificacion?.jsonReference)) {
      //       objNotificacion['ordenPedido'] = JSON.parse(objNotificacion.jsonReference);
      //       this.lstOrdenesPedidos.unshift(objNotificacion);
      //     }
      //     if (this.lstOrdenesPedidos.length > 0) {
      //       this.boolNotify = false;
      //       this.boolNewNotifyOrdPedido = false;
      //       this.newNotification_changeEvent();
      //     } else {
      //       this.boolNotify = true;
      //     }
      //   }
      // });
  }

  public onOrdenServicioNewValueReceive() {
    // this.websocketService.getOrdenServicioNewValue().subscribe(objResponse => {
    //   if(this.objPrivilegio.ORDENES_SERVICIOS_LIST) {
    //     let objNotificacion : any = objResponse;
    //     if (Globals.exist(objNotificacion?.jsonReference)) {
    //       objNotificacion['ordenServicio'] = JSON.parse(objNotificacion.jsonReference);
    //       this.lstOrdenesServicios.unshift(objNotificacion);
    //     }
    //     if (this.lstOrdenesServicios.length > 0) {
    //       this.boolNotify = false;
    //       this.boolNewNotifyOrdServicio = false;
    //       this.newNotification_changeEvent();
    //     } else {
    //       this.boolNotify = true;
    //     }
    //   }
    // });
  }

  public onOrdenMantenimientoNewValueReceive() {
    // this.websocketService.getOrdenMantenimientoNewValue().subscribe(objResponse => {
    //   if(this.objPrivilegio.MANTENIMIENTO_LIST) {
    //     let objNotificacion : any = objResponse;
    //     if (Globals.exist(objNotificacion?.jsonReference)) {
    //       objNotificacion['mantenimiento'] = JSON.parse(objNotificacion.jsonReference);
    //       this.lstOrdenesMantenimiento.unshift(objNotificacion);
    //     }
    //     if (this.lstOrdenesMantenimiento.length > 0) {
    //       this.boolNotify = false;
    //       this.boolNewNotifyOrdMantenimiento = false;
    //       this.newNotification_changeEvent();
    //     } else {
    //       this.boolNotify = true;
    //     }
    //   }
    // });
  }

  public btnCotizacionAprobar_clickEvent(itemCotizacion: any) : void {
    let objDialog = this.objMatDialog.open(CotizacionApproveComponent, {
      width         : '96%',
      maxWidth      : '96%',
      height        : '96%',
      data          : itemCotizacion,
      disableClose  : true
    });
  }

  public btnCotizacionEdit_clickEvent(itemCotizacion: any) {
    let objDialog = this.objMatDialog.open(CotizacionesEditComponent, {
      width         : '98%',
      maxWidth      : '98%',
      minWidth      : '800px',
      height        : '96%',
      data          : itemCotizacion,
      disableClose  : true
    });
  }

  public btnCotizacionCancel_clickEvent(itemCotizacion: any) : void {
    let objDialog = this.objMatDialog.open(CotizacionesCancelComponent, {
      width         : '76%',
      maxWidth      : '700px',
      height        : '76%',
      maxHeight     : '300px',
      data          : itemCotizacion,
      disableClose  : true
    });
  }

  public btnListOrdenServicio_clickEvent(itemOrdenPedido : any):void {
    let objDialog = this.objMatDialog.open(OrdenesServiciosIndexComponent, {
      width: '98%',
      maxWidth: '1300px',
      minWidth: '800px',
      height: '96%',
      maxHeight: '640px',
      data: {
        idOrdenPedido: itemOrdenPedido.idOrdenPedido,
        folio: itemOrdenPedido.folio,
        scene: 'SERVICIOS'
      },
      // maxHeight     : '900px',
      disableClose: true
    });
  }

  public btnFinalizarOrdenServicio_clickEvent(itemOrdenServicio : any) : void {
    let objDialog = this.objMatDialog.open(OrdenesServiciosFinalizarComponent, {
      width: '98%',
      maxWidth: '98%',
      minWidth: '800px',
      height: '96%',
      data: itemOrdenServicio,
      // maxHeight     : '900px',
      disableClose: true
    });
  }

  public btnInit_clickEvent(itemUnidadMantenimientoTaller : any) : void {
    let objDialog = this.objMatDialog.open(UnidadMantenimientoTalleresInitServiceComponent, {
      width         : '96%',
      maxWidth      : '1150px',
      height        : '96%',
      maxHeight     : '900px',
      data          : itemUnidadMantenimientoTaller,
      disableClose  : true
    });
  }

  public btnFinalize_clickEvent(itemUnidadMantenimientoTaller : any) : void {
    let objDialog = this.objMatDialog.open(UnidadMantenimientoTalleresFinalizeServiceComponent, {
      width         : '96%',
      maxWidth      : '1150px',
      height        : '96%',
      maxHeight     : '900px',
      data          : itemUnidadMantenimientoTaller,
      disableClose  : true
    });
  }

  public btnCancel_clickEvent(itemUnidadMantenimientoTaller : any) : void {
    let objDialog = this.objMatDialog.open(UnidadMantenimientoTalleresCancelServiceComponent, {
      width         : '76%',
      maxWidth      : '600px',
      height        : '76%',
      maxHeight     : '310px',
      data          : itemUnidadMantenimientoTaller,
      disableClose  : true
    });
  }

  public btnOrdenMantenimientoFinalize_clickEvent(itemUnidadMantenimientoTaller : any) : void {
    this.objMatDialog.open(ConfirmComponent, Globals.confirmConfig({titulo : "Finalizar orden de mantenimiento", mensaje : "¿Está seguro que desea finalizar la orden de mantenimiento " + itemUnidadMantenimientoTaller.folio + "?.", fnAccept : ()=> {
        let fdUnidadMantenimientoFinalize = new FormData();
        fdUnidadMantenimientoFinalize.append("idUnidadMantenimiento"       , itemUnidadMantenimientoTaller.idUnidadMantenimiento);

        this.objMantenimientosService.finalize(fdUnidadMantenimientoFinalize)
          .subscribe( objResponse => {
            if( objResponse.action) {
              this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
            } else
              this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
          }, error => {
            this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
          });
      }}));
  }

  public btnOrdenMantenimientoForceFinalize_clickEvent(itemUnidadMantenimientoTaller : any) : void {
    this.objMatDialog.open(ConfirmComponent, Globals.confirmConfig({titulo : "Finalizar orden de mantenimiento", mensaje : "¿Está seguro que desea finalizar forzadamente la orden de mantenimiento " + itemUnidadMantenimientoTaller.folio + "?. Al finalizar, no podrá modificar los estatus de los talleres", fnAccept : ()=> {
        let fdUnidadMantenimientoFinalize = new FormData();
        fdUnidadMantenimientoFinalize.append("idUnidadMantenimiento"       , itemUnidadMantenimientoTaller.idUnidadMantenimiento);

        this.objMantenimientosService.finalizeForce(fdUnidadMantenimientoFinalize)
          .subscribe( objResponse => {
            this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: "Finalizar orden de mantenimiento", mensaje: "La orden de mantenimiento " + itemUnidadMantenimientoTaller.folio + " se ha finalizado satisfactoriamente", autoCloseDelay: 3000}));
            if( objResponse.action) {
              this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
            } else
              this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
          }, error => {
            this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
          });
      }}));
  }



  public gradientPercentageMantenimientoTaller(itemUnidadMantenimientoTaller : any) : string {
    let strReturn : string = 'rgb(233, 236, 239)';
    let porcentaje : number = (itemUnidadMantenimientoTaller.idUnidadMantenimientoTallerEstatus != 5)? itemUnidadMantenimientoTaller.diasLaboralesPorcentaje : 100;
    if (itemUnidadMantenimientoTaller.isEnTiempo) {
      switch (itemUnidadMantenimientoTaller.idUnidadMantenimientoTallerEstatus) {
        case 2:
          strReturn = 'rgb(64, 154, 233)';
          break;
        case 3:
          strReturn = 'rgb(33, 193, 214)';
          break;
        case 4:
          strReturn = 'rgb(230, 81, 0)';
          break;
        case 5:
          strReturn = 'rgb(252, 75, 108)';
          break;
      }
    } else {
      strReturn = 'rgb(252, 75, 108)';
    }

    return strReturn + ' ' + porcentaje + '%, #E9ECEF ' + porcentaje + '%';
  }

  public gradientPercentageMantenimiento(itemUnidadMantenimientoTaller : any) : string {
    let strReturn : string = 'rgb(233, 236, 239)';
    let porcentaje : number = (itemUnidadMantenimientoTaller.idUnidadMantenimientoEstatus != 5)? itemUnidadMantenimientoTaller.unidadMantenimientoPorcentaje : 100;
    if (itemUnidadMantenimientoTaller.unidadMantenimientoIsEnTiempo) {
      switch (itemUnidadMantenimientoTaller.idUnidadMantenimientoEstatus) {
        case 2:
          strReturn = 'rgb(64, 154, 233)';
          break;
        case 3:
          strReturn = 'rgb(33, 193, 214)';
          break;
        case 4:
          strReturn = 'rgb(230, 81, 0)';
          break;
        case 5:
          strReturn = 'rgb(252, 75, 108)';
          break;
      }
    } else {
      strReturn = 'rgb(252, 75, 108)';
    }

    return strReturn + ' ' + porcentaje + '%, #E9ECEF ' + porcentaje + '%';
  }

  public gradientPercentageOrdenMantenimiento(itemUnidadMantenimientoTaller : any) : string {
    let strReturn : string = 'rgb(233, 236, 239)';
    let porcentaje : number = (itemUnidadMantenimientoTaller.idUnidadMantenimientoEstatus != 5)? itemUnidadMantenimientoTaller.diasLaboralesPorcentaje : 100;
    if (itemUnidadMantenimientoTaller.isEnTiempo) {
      switch (itemUnidadMantenimientoTaller.idUnidadMantenimientoEstatus) {
        case 2:
          strReturn = 'rgb(64, 154, 233)';
          break;
        case 3:
          strReturn = 'rgb(33, 193, 214)';
          break;
        case 4:
          strReturn = 'rgb(230, 81, 0)';
          break;
        case 5:
          strReturn = 'rgb(252, 75, 108)';
          break;
      }
    } else {
      strReturn = 'rgb(252, 75, 108)';
    }

    return strReturn + ' ' + porcentaje + '%, #E9ECEF ' + porcentaje + '%';
  }
}
