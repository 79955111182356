import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {ApplicationConfig} from "../../../../../libraries/application-config";
import {FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormOptimizer} from "../../../../../libraries/formOptimizer";
import {SuccessComponent} from "../../../../../components/success/success.component";
import {Globals} from "../../../../../libraries/globals";
import {AlertComponent} from "../../../../../components/alert/alert.component";
import {OrdenesServiciosService} from "../../../../../services/ordenes-servicios.service";

@Component({
  selector: 'app-cancel',
  templateUrl: './cancel.component.html',
  styleUrls: ['./cancel.component.scss']
})
export class OrdenesPedidosOrdenesServiciosCancelComponent {

  @Output('onClose')  onClose  : EventEmitter<OrdenesPedidosOrdenesServiciosCancelComponent> = new EventEmitter<OrdenesPedidosOrdenesServiciosCancelComponent>();
  @Output('onChange') onChange : EventEmitter<OrdenesPedidosOrdenesServiciosCancelComponent> = new EventEmitter<OrdenesPedidosOrdenesServiciosCancelComponent>();
  public objApplicationConfig = ApplicationConfig;
  public objOrdenServicio        : any;
  public folio                : string = '';
  public fcComentario         : FormControl = new FormControl('', Validators.required);

  public objLoader = {
    type            : 'loader',
    visible         : false
  };

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  };

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  };

  public formComponents: Array<any> = [
    this.fcComentario,
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objDialogRef: MatDialogRef<OrdenesPedidosOrdenesServiciosCancelComponent>,
    private objMatDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private objOrdenesServiciosService : OrdenesServiciosService,
  ) { this.folio = this.data.folio; }

  public formCotizacionCancelacion_submitEvent() : void {
    this.fcComentario.markAllAsTouched();


    if(this.fcComentario.valid) {
      let fdOrdenServicioCancel = new FormData();
      fdOrdenServicioCancel.append("idOrdenServicio", FormOptimizer.formDataNumber(this.data.idOrdenServicio));
      fdOrdenServicioCancel.append("comentario"     , FormOptimizer.formDataString(this.fcComentario));

      FormOptimizer.formDisable(this.formComponents);
      this.objOrdenesServiciosService.cancel(fdOrdenServicioCancel)
        .subscribe( objResponse => {
          if( objResponse.action) {
            this.objOrdenServicio = objResponse.result.ordenServicio;
            this.change();
            this.close();
            this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
          } else
            this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
          FormOptimizer.formEnable(this.formComponents);
        }, error => {
          FormOptimizer.formEnable(this.formComponents);
        });
    } else {
      this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Formulario incompleto", mensaje : "Complete los campos solicitados.", autoCloseDelay : 3000}));
      FormOptimizer.formEnable(this.formComponents);
    }

  }

  public change(): void {
    this.onChange.emit(this);
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }
}
