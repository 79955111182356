<div class="image-grid-success" [hidden]="!checked">
  <mat-icon>check</mat-icon>
</div>
<div class="image-grid-content-preview" (click)="verPdf_clickEvent()">
  <div *ngIf="!isValid()" class="image-grid-preview" [style.backgroundImage]="'url(' + (( Globals.exist(preview) && preview.trim() != '') ? preview : defaultImage) + ')'"></div>
  <div *ngIf="isValid()" class="image-grid-preview" [style.backgroundImage]="'url(' + preview + ')'"></div>
</div>
<div class="image-grid-input" [class.no-grid-button]="!removable">
  <input *ngIf="editable" matInput class="table-input" maxlength="256" [style.width.%]="100" [formControl]="fcNombre" [class.required]="fcNombre.hasError('required')  && fcNombre.touched">
  <div *ngIf="!editable" class="image-grid-title">{{fcNombre.value}}.{{extension}}</div>
</div>

<div *ngIf="removable" class="image-grid-content-button">
  <button type="button" class="image-grid-button" [class.disabled]="btnEliminar.disabled" matTooltip="Eliminar" [matTooltipDisabled]="btnEliminar.disabled" (click)="btnUnidadImagen_eliminarEvent()">
    <mat-icon class="image-grid-button-icon">delete</mat-icon>
  </button>
</div>
