import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {GerenciamientosService} from "../../../../../services/gerenciamientos/gerenciamientos.service";
import {OrdenesServiciosService} from "../../../../../services/ordenes-servicios.service";
import {ApplicationConfig} from "../../../../../libraries/application-config";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FormOptimizer} from "../../../../../libraries/formOptimizer";
import {Globals} from "../../../../../libraries/globals";
import {SuccessComponent} from "../../../../../components/success/success.component";
import {AlertComponent} from "../../../../../components/alert/alert.component";
import {InputAutocompleteComponent} from "../../../../../components/input-autocomplete/input-autocomplete.component";

@Component({
  selector: 'app-ordenes-servicios-gerenciamientos-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class OrdenesServiciosGerenciamientosCreateComponent implements OnInit, AfterViewInit {

  @Output ('onClose') onClose : EventEmitter<OrdenesServiciosGerenciamientosCreateComponent> = new EventEmitter<OrdenesServiciosGerenciamientosCreateComponent>();

  public fcDescripcionCarga                 : FormControl = new FormControl('');
  public fcOperadorPrincipalUsuarioTelefono : FormControl = new FormControl('');
  public fcOperadorUsuarioTelefono          : FormControl = new FormControl('');
  public fcHorasDormidas                    : FormControl = new FormControl('');

  public fgGerenciamientoUbicacionesEvaluacionRiesgo: FormGroup = new FormGroup({
    tablaA : new FormControl('', Validators.required),
    tablaB : new FormControl('', Validators.required),
    tablaC : new FormControl('', Validators.required),
    tablaD : new FormControl('', Validators.required),
    tablaE : new FormControl('', Validators.required),
    tablaF : new FormControl('', Validators.required)
  });


  public objApplicationConfig    = ApplicationConfig;
  public objScene                : any = ["DETALLES"];
  public objOrdenServicio        : any = {};
  public objOrdenServicioMiembro : any = {};
  public objOperadorUsuario      : any = {};
  public objGerenciamiento       : any = {};
  public numPasajeros            : number = 0;
  public lstOperadoresUsuarios   : Array<any> = [];
  public saveGerenciamiento      : boolean = false;

  public btnAccion = {
    type            : 'button',
    disabled        : false
  };

  public objLoader = {
    type      : 'loader',
    visible   : false
  };

  public btnAceptar = {
    type      : 'button',
    disabled  : false
  };

  public btnCancelar = {
    type      : 'button',
    disabled  : false
  };

  public formComponents: Array<any> = [
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(private objDialogRef: MatDialogRef<OrdenesServiciosGerenciamientosCreateComponent>,
              private objMatDialog: MatDialog,
              private objGerenciamientosService : GerenciamientosService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void { }

  ngAfterViewInit() : void {
    // console.log(this.data);
    setTimeout( ()=> this.init(), 200 );
  }

  public init() : void {
    FormOptimizer.formDisable(this.formComponents);
    this.objGerenciamientosService.create(this.data.idOrdenServicioMiembro)
      .subscribe(objResponse => {
        if ( Globals.exist(objResponse?.action) && objResponse.action ) {
          if ( Globals.exist(objResponse?.result)) {

            if ( Globals.exist(objResponse.result?.ordenServicioMiembro)) {
              this.objOrdenServicioMiembro = objResponse.result.ordenServicioMiembro;
            }

            if ( Globals.exist(objResponse.result?.operadores)) {
              this.lstOperadoresUsuarios = objResponse.result.operadores as Array<any>;
              if (this.lstOperadoresUsuarios.length != 0) {
                this.numPasajeros = this.lstOperadoresUsuarios.length;
              }
            }

            if ( Globals.exist(objResponse.result?.ordenServicio)) {
              this.objOrdenServicio = objResponse.result.ordenServicio;
              this.fcDescripcionCarga.setValue(this.objOrdenServicio?.descripcion);
            }
          }
        }
        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        FormOptimizer.formEnable(this.formComponents);

      });

  }

  public formOrdenServicioCreate_submitEvent(): void {
    if (this.fgGerenciamientoUbicacionesEvaluacionRiesgo.valid) {
      let fdGerenciamientoEvalacionRiesgo = new FormData();

      fdGerenciamientoEvalacionRiesgo.append("idCliente", FormOptimizer.formDataNumber(this.objOrdenServicio.idCliente));
      fdGerenciamientoEvalacionRiesgo.append("idOrdenServicio", FormOptimizer.formDataNumber(this.objOrdenServicio.idOrdenServicio));
      fdGerenciamientoEvalacionRiesgo.append("idGerenciamientoTipo", FormOptimizer.formDataNumber(this.objOrdenServicioMiembro.idGerenciamientoTipo));
      fdGerenciamientoEvalacionRiesgo.append("idOrdenServicioMiembro", FormOptimizer.formDataNumber(this.objOrdenServicioMiembro.idOrdenServicioMiembro));
      fdGerenciamientoEvalacionRiesgo.append("idGerenciamientoTrayecto", FormOptimizer.formDataNumber(this.data.idGerenciamientoTrayecto));
      fdGerenciamientoEvalacionRiesgo.append("idUnidad", FormOptimizer.formDataNumber(this.objOrdenServicioMiembro.idUnidad));
      fdGerenciamientoEvalacionRiesgo.append("conductorIdUsuario", FormOptimizer.formDataNumber(this.objOperadorUsuario.idUsuario));
      fdGerenciamientoEvalacionRiesgo.append("pasajeros", FormOptimizer.formDataNumber(this.numPasajeros));
      fdGerenciamientoEvalacionRiesgo.append("checkPoint", FormOptimizer.formDataString(this.objOrdenServicioMiembro.checkPoint));
      fdGerenciamientoEvalacionRiesgo.append("isViajeNecesario", FormOptimizer.formDataBoolean(this.objOrdenServicioMiembro.isViajeNecesario));
      fdGerenciamientoEvalacionRiesgo.append("hasReunionPrevia", FormOptimizer.formDataBoolean(this.objOrdenServicioMiembro.hasReunionPrevia));
      fdGerenciamientoEvalacionRiesgo.append("tablaA", FormOptimizer.formDataNumber(this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaA']));
      fdGerenciamientoEvalacionRiesgo.append("tablaB", FormOptimizer.formDataNumber(this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaB']));
      fdGerenciamientoEvalacionRiesgo.append("tablaC", FormOptimizer.formDataNumber(this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaC']));
      fdGerenciamientoEvalacionRiesgo.append("tablaD", FormOptimizer.formDataNumber(this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaD']));
      fdGerenciamientoEvalacionRiesgo.append("tablaE", FormOptimizer.formDataNumber(this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaE']));
      fdGerenciamientoEvalacionRiesgo.append("tablaF", FormOptimizer.formDataNumber(this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaF']));
      fdGerenciamientoEvalacionRiesgo.append("descripcionCarga", FormOptimizer.formDataString(this.fcDescripcionCarga));
      fdGerenciamientoEvalacionRiesgo.append("operadorPrincipalUsuarioTelefono", FormOptimizer.formDataString(this.fcOperadorPrincipalUsuarioTelefono));
      fdGerenciamientoEvalacionRiesgo.append("operadorUsuarioTelefono", FormOptimizer.formDataString(this.fcOperadorUsuarioTelefono));
      fdGerenciamientoEvalacionRiesgo.append("horasDormidas", FormOptimizer.formDataNumber(this.fcHorasDormidas));

      this.lstOperadoresUsuarios.forEach((itemOperadorUsuario, index) => {
        fdGerenciamientoEvalacionRiesgo.append("idUsuario[" + index + "]", FormOptimizer.formDataNumber(itemOperadorUsuario.idUsuario));
        fdGerenciamientoEvalacionRiesgo.append("telefono[" + index + "]", FormOptimizer.formDataString(itemOperadorUsuario.telefono));
        fdGerenciamientoEvalacionRiesgo.append("horasDormidas[" + index + "]", FormOptimizer.formDataString(itemOperadorUsuario?.horasDormidas));
      });

      FormOptimizer.formDisable(this.formComponents);
      this.objGerenciamientosService.store(fdGerenciamientoEvalacionRiesgo)
        .subscribe(objResponse => {
          if (Globals.exist(objResponse?.action) && objResponse.action) {
            if (Globals.exist(objResponse?.result) && Globals.exist(objResponse.result?.gerenciamientoEvaluacionRiesgo)) {
              this.objGerenciamiento = objResponse.result.gerenciamientoEvaluacionRiesgo;
            }
            this.close();
          } else
            this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: 'Error de comunicación', mensaje: 'Ocurrió un error de comunicación con el servidor.', autoCloseDelay: 3000}));
          //   this.objMatDialog.open(AlertComponent, Globals.alertConfig({ titulo: objResponse.title,  mensaje: objResponse.message,  autoCloseDelay: 3000 }));
          FormOptimizer.formEnable(this.formComponents);
        }, error => {
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: "Error de comunicación", mensaje: "Ocurrió un error de comunicación con el servidor.", autoCloseDelay: 3000}));
          FormOptimizer.formEnable(this.formComponents);
        });
    } else {
      this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo: 'Error de formulario', mensaje: 'El formulario de criterio de evaluación no está completo, complete la información.'}));
      this.objScene = ["REQUERIMIENTO"];
    }
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }
}
