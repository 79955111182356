import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, ViewChild} from '@angular/core';
import {InputAutocompleteComponent} from "../../../../../components/input-autocomplete/input-autocomplete.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ApplicationConfig} from "../../../../../libraries/application-config";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {GerenciamientosService} from "../../../../../services/gerenciamientos/gerenciamientos.service";
import {FormOptimizer} from "../../../../../libraries/formOptimizer";
import {Globals} from "../../../../../libraries/globals";
import {AlertComponent} from "../../../../../components/alert/alert.component";

@Component({
  selector: 'app-ordenes-servicios-gerenciamientos-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class OrdenesServiciosGerenciamientosUbicacionesEditComponent implements OnInit, AfterViewInit {

  @Output ('onClose') onClose : EventEmitter<OrdenesServiciosGerenciamientosUbicacionesEditComponent> = new EventEmitter<OrdenesServiciosGerenciamientosUbicacionesEditComponent>();

  public fcDescripcionCarga                 : FormControl = new FormControl('');
  public fcOperadorPrincipalUsuarioTelefono : FormControl = new FormControl('');
  public fcOperadorUsuarioTelefono          : FormControl = new FormControl('');
  public fcHorasDormidas                    : FormControl = new FormControl('');

  public fgGerenciamientoUbicacionesEvaluacionRiesgo: FormGroup = new FormGroup({
    tablaA : new FormControl('1', Validators.required),
    tablaB : new FormControl('8', Validators.required),
    tablaC : new FormControl('8', Validators.required),
    tablaD : new FormControl('4', Validators.required),
    tablaE : new FormControl('4', Validators.required),
    tablaF : new FormControl('8', Validators.required)
  });


  public objApplicationConfig  = ApplicationConfig;
  public objScene              : any = ["DETALLES"];
  public objOrdenServicio      : any = {};
  public objUnidad             : any = {};
  public objCliente            : any = {};
  public objOperadorUsuario    : any = {};
  public objGerenciamientoTipo : any = {};
  public objGerenciamiento     : any = {};
  public lstUbicaciones        : Array<any> = [];
  public lstOrdenesServicios   : Array<any> = [];
  public lstUnidades           : Array<any> = [];
  public lstUnidadesFiltered   : Array<any> = [];
  public lstGerenciamientoTipo : Array<any> = [];
  public lstUsuarios           : Array<any> = [];
  public lstClientes           : Array<any> = [];
  public unidadNumeroEconomico : string = '';
  public saveGerenciamiento    : boolean = false;

  public btnAccion = {
    type            : 'button',
    disabled        : false
  };

  public objLoader = {
    type      : 'loader',
    visible   : false
  };

  public btnAceptar = {
    type      : 'button',
    disabled  : false
  };

  public btnCancelar = {
    type      : 'button',
    disabled  : false
  };

  public formComponents: Array<any> = [
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(private objDialogRef: MatDialogRef<OrdenesServiciosGerenciamientosUbicacionesEditComponent>,
              private objMatDialog: MatDialog,
              private objGerenciamientosService : GerenciamientosService,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void { }

  ngAfterViewInit() : void {
    setTimeout( ()=> this.init(), 200 );
  }

  public init() : void {
    FormOptimizer.formDisable(this.formComponents);
    this.objGerenciamientosService.edit(this.data.idGerenciamientoEvaluacionRiesgo)
      .subscribe(objResponse => {
        if ( Globals.exist(objResponse?.action) && objResponse.action ) {
          if ( Globals.exist(objResponse?.result)) {
            this.objGerenciamiento = objResponse.result?.gerenciamientoEvaluacionRiesgo;
            this.fcOperadorPrincipalUsuarioTelefono.setValue(this.objGerenciamiento.operadorPrincipalUsuarioTelefono);
            this.fcOperadorUsuarioTelefono.setValue(this.objGerenciamiento.operadorUsuarioTelefono);
            this.fcDescripcionCarga.setValue(this.objGerenciamiento.descripcionCarga);
            this.fcHorasDormidas.setValue(this.objGerenciamiento.operadorUsuarioHorasDormidas);

            if (Globals.exist(this.objGerenciamiento?.tablaA)) {
              this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaA'].setValue(FormOptimizer.formDataNumber(this.objGerenciamiento.tablaA));
            }

            if (Globals.exist(this.objGerenciamiento?.tablaB)) {
              this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaB'].setValue(FormOptimizer.formDataNumber(this.objGerenciamiento.tablaB));
            }

            if (Globals.exist(this.objGerenciamiento?.tablaC)) {
              this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaC'].setValue(FormOptimizer.formDataNumber(this.objGerenciamiento.tablaC));
            }

            if (Globals.exist(this.objGerenciamiento?.tablaD)) {
              this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaD'].setValue(FormOptimizer.formDataNumber(this.objGerenciamiento.tablaD));
            }

            if (Globals.exist(this.objGerenciamiento?.tablaE)) {
              this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaE'].setValue(FormOptimizer.formDataNumber(this.objGerenciamiento.tablaE));
            }

            if (Globals.exist(this.objGerenciamiento?.tablaF)) {
              this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaF'].setValue(FormOptimizer.formDataNumber(this.objGerenciamiento.tablaF));
            }

          }
        }
        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        FormOptimizer.formEnable(this.formComponents);

      });

  }

  public formOrdenServicioCreate_submitEvent(): void {
    let fdGerenciamientoEvalacionRiesgo = new FormData();

    fdGerenciamientoEvalacionRiesgo.append("idGerenciamientoEvaluacionRiesgo" , FormOptimizer.formDataNumber( this.objGerenciamiento.idGerenciamientoEvaluacionRiesgo ));
    fdGerenciamientoEvalacionRiesgo.append("idCliente"                        , FormOptimizer.formDataNumber( this.objGerenciamiento.idCliente ));
    fdGerenciamientoEvalacionRiesgo.append("idOrdenServicio"                  , FormOptimizer.formDataNumber( this.objGerenciamiento.idOrdenServicio ));
    fdGerenciamientoEvalacionRiesgo.append("idGerenciamientoTipo"             , FormOptimizer.formDataNumber( this.objGerenciamiento.idGerenciamientoTipo ));
    fdGerenciamientoEvalacionRiesgo.append("idOrdenServicioMiembro"           , FormOptimizer.formDataNumber( this.objGerenciamiento.idOrdenServicioMiembro ));
    fdGerenciamientoEvalacionRiesgo.append("idUnidad"                         , FormOptimizer.formDataNumber( this.objGerenciamiento.idUnidad ));
    fdGerenciamientoEvalacionRiesgo.append("conductorIdUsuario"               , FormOptimizer.formDataNumber( this.objGerenciamiento.idOperadorPrincipalUsuario ));
    fdGerenciamientoEvalacionRiesgo.append("pasajeros"                        , FormOptimizer.formDataNumber( this.objGerenciamiento.pasajeros ));
    fdGerenciamientoEvalacionRiesgo.append("checkPoint"                       , this.objGerenciamiento.checkPoint );
    fdGerenciamientoEvalacionRiesgo.append("isViajeNecesario"                 , this.objGerenciamiento.isViajeNecesario );
    fdGerenciamientoEvalacionRiesgo.append("hasReunionPrevia"                 , this.objGerenciamiento.hasReunionPrevia );
    fdGerenciamientoEvalacionRiesgo.append("tablaA"                           , FormOptimizer.formDataNumber(this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaA']));
    fdGerenciamientoEvalacionRiesgo.append("tablaB"                           , FormOptimizer.formDataNumber(this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaB']));
    fdGerenciamientoEvalacionRiesgo.append("tablaC"                           , FormOptimizer.formDataNumber(this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaC']));
    fdGerenciamientoEvalacionRiesgo.append("tablaD"                           , FormOptimizer.formDataNumber(this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaD']));
    fdGerenciamientoEvalacionRiesgo.append("tablaE"                           , FormOptimizer.formDataNumber(this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaE']));
    fdGerenciamientoEvalacionRiesgo.append("tablaF"                           , FormOptimizer.formDataNumber(this.fgGerenciamientoUbicacionesEvaluacionRiesgo.controls['tablaF']));
    fdGerenciamientoEvalacionRiesgo.append("descripcionCarga"                 , FormOptimizer.formDataString(this.fcDescripcionCarga));
    fdGerenciamientoEvalacionRiesgo.append("operadorPrincipalUsuarioTelefono" , FormOptimizer.formDataString(this.fcOperadorPrincipalUsuarioTelefono));
    fdGerenciamientoEvalacionRiesgo.append("operadorUsuarioTelefono"          , FormOptimizer.formDataString(this.fcOperadorUsuarioTelefono));
    fdGerenciamientoEvalacionRiesgo.append("horasDormidas"                    , FormOptimizer.formDataNumber(this.fcHorasDormidas));

    FormOptimizer.formDisable(this.formComponents);
    this.objGerenciamientosService.update(fdGerenciamientoEvalacionRiesgo)
      .subscribe(objResponse => {
        if ( Globals.exist(objResponse?.action) && objResponse.action ) {
          this.saveGerenciamiento = true;
          this.close();
        } else
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : 'Error de comunicación', mensaje : 'Ocurrió un error de comunicación con el servidor.', autoCloseDelay : 3000}));
        //   this.objMatDialog.open(AlertComponent, Globals.alertConfig({ titulo: objResponse.title,  mensaje: objResponse.message,  autoCloseDelay: 3000 }));
        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: "Error de comunicación", mensaje: "Ocurrió un error de comunicación con el servidor.", autoCloseDelay: 3000}));
        FormOptimizer.formEnable(this.formComponents);
      });
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }
}
