<div class="mat-dialog-title">
  <!-- <div class="icon"><span class="material-icons">add</span></div> -->
  <div class="title"> Mi cuenta</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content-full-area" [style.height.%]="100">
    <div class="bs-sidebar-left" [style.width.px]="210">
      <div class="bs-content">
        <div class="bs-row">
          <div class="bs-col col-image">
            <comp-picture-upload [height]="'160px'" [width]="'160px'" #compPictureUpload [defaultImage]="ApplicationConfig.ENDPOINT + 'api/usuarios/foto/0/512'"></comp-picture-upload>
          </div>
        </div>

        <div class="bs-row">
          <div class="bs-col">
            <button type="button" class="btn-fichero" mat-button (click)="btnUsuarioFicheros_clickEvent()"> Documentos </button>
          </div>
        </div>
      </div>
    </div>

    <div class="bs-viewport">
      <div class="bs-content">
        <form [formGroup]="fgUsuarioEdit">
          <div class="bs-row">
            <div class="bs-col">
              <div class="section-title"><span>Información de usuario</span></div>
            </div>
          </div>

          <div class="bs-row">

            <div class="bs-col-4">
              <mat-form-field>
                <input type="text" matInput formControlName="nombre" maxlength="128" placeholder="Nombre(s)" autocomplete="off">
              </mat-form-field>
            </div>

            <div class="bs-col-4">
              <mat-form-field>
                <input type="text" matInput formControlName="apellidoPaterno" maxlength="128" placeholder="Apellido paterno" autocomplete="off">
              </mat-form-field>
            </div>

            <div class="bs-col-4">
              <mat-form-field>
                <input type="text" matInput formControlName="apellidoMaterno" maxlength="128" placeholder="Apellido materno" autocomplete="off">
              </mat-form-field>
            </div>
          </div>

          <div class="bs-row" [style.marginTop.px]="25">
            <div class="bs-col-9">
              <mat-form-field>
                <input type="text" matInput formControlName="email" placeholder="Email" maxlength="256" autocomplete="off">
              </mat-form-field>
            </div>

            <div class="bs-col-3">
              <mat-form-field>
                <input type="text" matInput formControlName="codigo" maxlength="8" placeholder="Código" autocomplete="off">
              </mat-form-field>
            </div>
          </div>

          <div class="bs-row" [style.marginTop.px]="25">
            <div class="bs-col">
              <mat-checkbox formControlName="isUsuario" (change)="isUsuario_changeEvent()">¿Es usuario del sistema?</mat-checkbox>
            </div>
          </div>

          <div class="bs-row">
            <div class="bs-col-6">
              <mat-form-field>
                <input type="password" matInput formControlName="password" placeholder="Password" maxlength="64" autocomplete="off">
              </mat-form-field>
            </div>
          </div>

          <div class="bs-row">
            <div class="bs-col">
              <div class="section-title"><span>Información de empleado</span></div>
            </div>
          </div>

          <div class="bs-row">
            <div class="bs-col-12">
              <mat-form-field>
                <mat-label>Empresa</mat-label>
                <mat-select formControlName="empresa">
                  <mat-option *ngFor="let itemEmpresa of lstEmpresas" [value]="itemEmpresa.idEmpresa">{{itemEmpresa.nombreComercial}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="bs-row">
            <div class="bs-col-12">
              <mat-form-field>
                <mat-label>Sucursal</mat-label>
                <mat-select formControlName="sucursal">
                  <mat-option *ngFor="let itemSucrusal of lstSucursalesFiltered | async" [value]="itemSucrusal.idSucursal">{{itemSucrusal.sucursal}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="bs-row">
            <div class="bs-col-12">
              <mat-form-field>
                <mat-label>Departamento</mat-label>
                <mat-select formControlName="departamento">
                  <mat-option *ngFor="let itemDepartamento of lstDepartamentosFiltered | async" [value]="itemDepartamento.idDepartamento">
                    <span class="path" *ngIf="itemDepartamento.path">{{itemDepartamento.path}} / </span>{{itemDepartamento.departamento}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="bs-row">
            <div class="bs-col-12">
              <mat-form-field>
                <mat-label>Puesto</mat-label>
                <mat-select formControlName="puesto">
                  <mat-option *ngFor="let itemPuesto of lstPuestos of lstPuestosFiltered | async" [value]="itemPuesto.idPuesto">{{itemPuesto.puesto}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>

</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="formUsuarioEdit_submitEvent()">Aceptar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>
