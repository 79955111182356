import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {ApplicationConfig} from "../../../../../libraries/application-config";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {OrdenesServiciosUnidadItemComponent} from "./unidad-item/unidad-item.component";
import {FormOptimizer} from "../../../../../libraries/formOptimizer";
import {AlertComponent} from "../../../../../components/alert/alert.component";
import {Globals} from "../../../../../libraries/globals";
import {DatePipe} from "@angular/common";
import {OrdenesServiciosService} from "../../../../../services/ordenes-servicios.service";
import {ConfirmComponent} from "../../../../../components/confirm/confirm.component";
import {CotizacionesService} from "../../../../../services/cotizaciones.service";

@Component({
  selector: 'app-ordenes-servicios-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class OrdenesServiciosCreateComponent implements OnInit, AfterViewInit {

  @Output      ('onClose')          onClose          : EventEmitter<OrdenesServiciosCreateComponent> = new EventEmitter<OrdenesServiciosCreateComponent>();
  @Output      ('onChange')         onChange         : EventEmitter<OrdenesServiciosCreateComponent> = new EventEmitter<OrdenesServiciosCreateComponent>();
  @ViewChildren('compUnidadesItem') compUnidadesItem : QueryList<OrdenesServiciosUnidadItemComponent>;

  public fgOrdenServicioDirecciones : FormGroup = new FormGroup({
    origen       : new FormControl('', Validators.required),
    destino      : new FormControl('', Validators.required),
    autoriza     : new FormControl(''),
    folioCliente : new FormControl(''),
    folioEmpresa : new FormControl(''),
    descripcion  : new FormControl('', Validators.required)
  });

  public fgOrdenServicioTiemposEjecucion: FormGroup = new FormGroup({
    programadaFecha       : new FormControl('', Validators.required),
    programadaHora        : new FormControl('', Validators.required)
  });

  public Globals                                     = Globals;
  public objApplicationConfig                        = ApplicationConfig;
  public objOrdenPedido                              : any = {}
  public objOrdenServicio                            : any = {}
  public lstCotizacionPartidas                       : Array<any> = [];
  public lstUnidades_Usuarios                        : Array<any> = [{}];
  public objScene                                    : any = ["CLIENTE"];
  public folio                                       : string = '';
  public boolChkOrdenServicioUnidad                  : boolean = false;
  public boolChkSomeOrdenServicioUnidad              : boolean = false;
  public hasPrincipal                                : boolean = false;
  public lstOrdenesServiciosMiembros_UsuariosPuestos : Array<any> = [{}];

  public objLoader = {
    type     : 'loader',
    visible  : false
  };

  public objChkBox = {
    type     : 'checkbox',
    disabled : false
  };

  public btnOptionBar = {
    type     : 'button',
    disabled : false
  };

  public btnAccion = {
    type     : 'button',
    disabled : false
  };

  public btnAceptar = {
    type     : 'button',
    disabled : false
  };

  public btnCancelar = {
    type     : 'button',
    disabled : false
  };

  public formComponents: Array<any> = [
    this.fgOrdenServicioTiemposEjecucion,
    this.btnOptionBar,
    this.btnAccion,
    this.objChkBox,
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objOrdenesServiciosService : OrdenesServiciosService,
    private objCotizacionesService: CotizacionesService,
    private objDialogRef: MatDialogRef<OrdenesServiciosCreateComponent>,
    private objMatDialog: MatDialog,
    private objDatePipe : DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
    FormOptimizer.formDisable(this.formComponents);
  }

  ngAfterViewInit(): void {
    setTimeout( ()=> this.init() );
  }

  public init() : void {
      if(Globals.exist(this.data.folio)) this.folio = this.data.folio + ' - ';
      this.objOrdenesServiciosService.create( this.data.idOrdenPedido )
          .subscribe( objResponse=> {
              this.objOrdenPedido = objResponse.result?.ordenPedido;
              this.lstCotizacionPartidas = objResponse.result?.partidasAprobadas;
              this.lstOrdenesServiciosMiembros_UsuariosPuestos = objResponse.result?.ordenesServiciosMiembros_UsuariosPuestos;

              FormOptimizer.formEnable(this.formComponents);
          }, error => {
              FormOptimizer.formEnable(this.formComponents);
              console.log(error);
          });
  }

  public formOrdenServicioCreate_submitEvent(): void {
    (<any>Object).values(this.fgOrdenServicioDirecciones.controls).forEach( (itemControl : FormControl) => itemControl.markAsTouched());
    (<any>Object).values(this.fgOrdenServicioTiemposEjecucion.controls).forEach( (itemControl : FormControl) => itemControl.markAsTouched());

    let validateUnidades      : boolean = this.compUnidadesItem.toArray().length > 0;
    let validateFormOperadores: boolean = true;
    let validateFormUnidadPrincipal : boolean = true;

    this.compUnidadesItem.toArray().forEach( itemMiembroItem => {
      if (!itemMiembroItem.validateOperadores()) validateFormOperadores = false;
      if (!itemMiembroItem.validateUnidadPrincipal()) validateFormUnidadPrincipal = false;
    });

    if (this.fgOrdenServicioDirecciones.valid) {
      if (this.fgOrdenServicioTiemposEjecucion.valid) {
        if (validateUnidades) {
          if (validateFormUnidadPrincipal) {
            if (validateFormOperadores) {
              let validateOperadores: boolean = this.compUnidadesItem.toArray().some((itemOrdenServicioUnidad: any) => itemOrdenServicioUnidad.lstUnidad_Usuarios.length > 0);
              if (validateOperadores) {
                this.fnProcessCreate();
              } else {
                this.objMatDialog.open(ConfirmComponent, Globals.successConfig({
                  titulo: 'Nueva orden de servicio', mensaje: 'No tiene operadores agregados, ¿Desea guarda de todas maneras?', fnAccept: () => {
                    this.fnProcessCreate();
                  }
                }));
              }
            } else {
              this.objMatDialog.open(AlertComponent, Globals.confirmConfig({
                titulo: 'Error de registro de unidad',
                mensaje: 'La información de las unidades no está completa, debe llenar los campos obligatorios'
              }));
              this.objScene = ["OPERACION"];
            }
          } else {
            this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo: 'Error de registro de unidad', mensaje: 'No ha seleccionado una unidad principal, debe tener una obligatoriamente'}));
            this.objScene = ["OPERACION"];
          }
        } else {
          this.objMatDialog.open(ConfirmComponent, Globals.successConfig({
            titulo: 'Nueva orden de servicio', mensaje: 'No tiene miembros agregados\n¿Desea guarda de todas maneras?', fnAccept: () => {
              this.fnProcessCreate();
            }
          }));
        }
      } else {
        this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo: 'Error de formulario', mensaje: 'Los datos de la operación no están completos, complete la información.'}));
        this.objScene = ["OPERACION"];
      }
    } else {
      this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo: 'Error de formulario', mensaje: 'El formulario de datos del servicio no está completo, complete la información.'}));
      this.objScene = ["DIRECCIONES"];
    }
  }

  public fnProcessCreate() : void {
    let lstUnidadesMiembros: Array<any> = [];

    this.compUnidadesItem.toArray().forEach( itemComMiembroItem => {
      if (itemComMiembroItem.idUnidad != 0 && itemComMiembroItem.lstUnidad_Usuarios.length != 0) {
        lstUnidadesMiembros.push(itemComMiembroItem.getOrdenServicioUnidad());
      }
    })

    let objOrdenServicioStore : any = {
      idOrdenPedido         : FormOptimizer.formDataNumber( this.data.idOrdenPedido ),
      origen                : FormOptimizer.formDataString( this.fgOrdenServicioDirecciones.controls[ 'origen' ]),
      destino               : FormOptimizer.formDataString( this.fgOrdenServicioDirecciones.controls[ 'destino' ]),
      autoriza              : FormOptimizer.formDataString( this.fgOrdenServicioDirecciones.controls[ 'autoriza' ]),
      folioCliente          : FormOptimizer.formDataString( this.fgOrdenServicioDirecciones.controls[ 'folioCliente' ]),
      folioEmpresa          : FormOptimizer.formDataString( this.fgOrdenServicioDirecciones.controls[ 'folioEmpresa' ]),
      descripcion           : FormOptimizer.formDataString( this.fgOrdenServicioDirecciones.controls[ 'descripcion' ]),
      programadaFecha       : this.formatDateTime(this.fgOrdenServicioTiemposEjecucion.controls['programadaFecha'].value, this.fgOrdenServicioTiemposEjecucion.controls['programadaHora'].value),
      unidades              : lstUnidadesMiembros
    }

    this.compUnidadesItem.toArray().forEach( itemComUnidadItem => itemComUnidadItem.disableFormPartida());
    FormOptimizer.formDisable(this.formComponents);
    this.objOrdenesServiciosService.store(objOrdenServicioStore).subscribe( objResponse => {
      if( objResponse.action) {
        this.objOrdenServicio = objResponse.result?.ordenServicio;
        this.change();
        setTimeout( () => this.close(), 500);
      } else
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message}));
      this.compUnidadesItem.toArray().forEach( itemComUnidadItem => itemComUnidadItem.enableFormPartida());
      FormOptimizer.formEnable(this.formComponents);
    }, error => {
      this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: 'Error de comunicación', mensaje: 'Ocurrió un error de comunicación con el servidor.', autoCloseDelay: 3000}));
      this.compUnidadesItem.toArray().forEach( itemComUnidadItem => itemComUnidadItem.enableFormPartida());
      FormOptimizer.formEnable(this.formComponents);
    });

  }

  public btnCotizacionPdf_clickEvent() : void {
    if (Globals.exist(this.objOrdenPedido) && Globals.exist(this.objOrdenPedido?.idCotizacion)) {
      FormOptimizer.formDisable(this.formComponents);
      this.objCotizacionesService.documentPdf(this.objOrdenPedido.idCotizacion)
        .subscribe(objResponse => {
          let blob = new Blob([objResponse], {type: 'application/pdf'});
          let blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, '_blank');
          FormOptimizer.formEnable(this.formComponents);
        }, error => {
          FormOptimizer.formEnable(this.formComponents);
        });
    }
  }

  public chkClientesContactoInitSelected_changeEvent() : void {
    this.boolChkOrdenServicioUnidad = false;
    this.boolChkSomeOrdenServicioUnidad = false;
  }

  public chkOrdenServicioUnidadSelected_changeEvent(event : any ) : void {
    this.boolChkOrdenServicioUnidad = event;
    this.compUnidadesItem.toArray().forEach((itemOrdenServicioUnidad : any) => itemOrdenServicioUnidad.checked = this.boolChkOrdenServicioUnidad);
    this.boolChkSomeOrdenServicioUnidad = this.compUnidadesItem.toArray().some((itemOrdenServicioUnidad : any) => itemOrdenServicioUnidad.checked);
  }

  public chkItemOrdenServicioUnidadSelected_changeEvent() : void {
    this.boolChkOrdenServicioUnidad = this.compUnidadesItem.toArray().every( (itemOrdenServicioUnidad : any) => itemOrdenServicioUnidad.checked );
    this.boolChkSomeOrdenServicioUnidad = this.compUnidadesItem.toArray().some( (itemOrdenServicioUnidad : any) => itemOrdenServicioUnidad.checked );
  }

  public selectUnidadPrincipal(event : boolean, index : number) : void {
    this.hasPrincipal = event;
    if (event) {
      this.compUnidadesItem.toArray().forEach( (itemMiembro, indexMiembro) => {
        itemMiembro.boolUnidadPrincipal = (index === indexMiembro);
        if (!itemMiembro.boolUnidadPrincipal) {
          itemMiembro.lstUnidad_Usuarios.forEach( itemUnidad_usuario => {
            if (itemUnidad_usuario.idPuesto === 1) {
              itemMiembro.lstTxtPuesto.toArray()[index].objValue = null;
              itemUnidad_usuario.idPuesto = 0;
              itemUnidad_usuario.puesto   = '';
            }
          });
        }
      });
    }
  }

  public btnUnidadAppend_clickEvent() : void {
      this.lstUnidades_Usuarios.push({});
  }

  public btnUnidadDelete_clickEvent() : void {
    let arrIndex : Array<any> = [];
    this.compUnidadesItem.toArray().forEach( itemUnidad => {
      if( itemUnidad.checked ) {
        arrIndex.push(itemUnidad.index);
      }
    });
    const reversed = arrIndex.reverse();
    reversed.forEach( index => {
      this.lstUnidades_Usuarios.splice( index, 1);
    });
    this.chkClientesContactoInitSelected_changeEvent();
  }

  public btnPartidasDelete_onDeleteEvent(itemOrdenServicioUnidad : OrdenesServiciosUnidadItemComponent) : void {
      this.lstUnidades_Usuarios.splice(itemOrdenServicioUnidad.index, 1);

      // if(this.boolDynamicChkContratoPrestacion) {
      //     setTimeout( () => {
      //         let validate : boolean = this.compUnidadesItem.toArray().every( (itemOrdenServicioUnidad : any) => itemOrdenServicioUnidad.lstUnidad_Usuarios.length < 1 );
      //         if(validate) this.boolChkContratoPrestacion = false;
      //     });
      // }
  }

  public inputProgramadaTime_keyUpEvent(event : any) : void {
    if(event.target.value != '') {
      this.fgOrdenServicioTiemposEjecucion.controls['programadaHora'].setValue(event.target.value);
    }
  }

  public formatDateTime(date : string, hora : string) : string {
    let fecha   : string = this.objDatePipe.transform(  date  , 'yyyy-MM-dd') + '';
    fecha = (fecha != '' && fecha != 'null' && fecha != null)? fecha : '0000-00-00'
    hora = this.formatTime(hora);
    return (fecha != '0000-00-00' && hora != '00')? fecha + ' ' + hora : '';
  }

  public formatTime(hora : string) : string {
    let arrayHours : string[] = hora.split(':');

    return ((Globals.exist(arrayHours[0]))? (arrayHours[0].length < 2) ? '0' + arrayHours[0] : arrayHours[0] : '00') + ':' + ((Globals.exist(arrayHours[1]))? (arrayHours[1].length < 2) ? arrayHours[1] + '0' : arrayHours[1] : '00');
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

  public change(): void {
    this.onChange.emit(this);
  }

}
