<div class="mat-dialog-title">
  <div class="title"> {{folio}} Contrato</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content-full-area" [style.height.%]="100">
    <div class="bs-sidebar-left" [style.width.px]="250">
      <div class="bs-content">
        <div class="row">
          <div class="col text-center">
            <span class="material-icons" [style.fontSize.px]="80">person</span>
          </div>
        </div>

        <div class="bs-row">
          <div class="bs-col">
            <mat-selection-list [(ngModel)]="objScene" [multiple]="false">
              <mat-list-option  [value]="'CLIENTE'">Cliente</mat-list-option>
              <mat-list-option  [value]="'CONTRATO'">Datos del contrato</mat-list-option>
              <mat-list-option  [value]="'SERVICIOS'">Ordenes de servicios</mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>

    <div class="bs-viewport">
      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'CLIENTE'">
        <div class="bs-content">
          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Cliente</div>
                  </div>
                  <div class="card-row">
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">Nombre comercial</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Razón social</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">R.F.F</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Dirección fiscal</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Dirección social</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Correo</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Teléfono</div>
                      </div>
                    </div>
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objCliente.nombreComercial}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objCliente.razonSocial}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objCliente.rfc}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objCliente.direccionFiscal}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{objCliente.direccionSocial}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objCliente.email}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{objCliente.telefono}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Contacto</div>
                  </div>
                  <div class="card-row">
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">Nombre</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Puesto</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Correo</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Teléfono</div>
                      </div>
                    </div>
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContacto.nombreCompleto}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContacto.puesto}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContacto.email}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{objClienteContacto.telefono}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Información contrato</div>
                  </div>
                  <div class="card-row">
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">Representante legal</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Número</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Volumen</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Fecha</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Ciudad de registro</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Estado</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Nombre del licenciado</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Número</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Fecha</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Domicilio</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Estado</div>
                      </div>
                    </div>
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContrato.representanteLegal}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContrato.actaConstitutivaNumero}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContrato.actaConstitutivaVolumen}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContrato.actaConstitutivaFechaNatural}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContrato.actaConstitutivaCiudadRegistro}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContrato.notarioPublicoNumero}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContrato.notarioPublicoEstado}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContrato.notarioPublicoNombre}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContrato.escrituraNumero}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContrato.escrituraFechaNatural}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objClienteContrato.DomicilioFiscal}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{objClienteContrato.Estado}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </div>

      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'CONTRATO'">
        <div class="bs-content">
          <div class="bs-row">
            <div class="bs-col">
              <div class="section-title"><span>Duración del contrato</span></div>
            </div>
          </div>
          <form [formGroup]="fgOrdenServicioProgramacion">
            <div class="bs-row">
              <div class="bs-col-1" style="width: 120px;">
                <mat-form-field appearance="legacy">
                  <mat-label>Desde</mat-label>
                  <input matInput class="text-center" [matDatepicker]="pickerStart" formControlName="fechaDesde" [readonly]="true" (click)="pickerStart.open()">
                  <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
                  <mat-datepicker #pickerStart></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="bs-col-1" style="width: 120px;">
                <mat-form-field appearance="legacy">
                  <mat-label>Hasta</mat-label>
                  <input matInput class="text-center" [matDatepicker]="pickerEnd" formControlName="fechaHasta" [readonly]="true" (click)="pickerEnd.open()">
                  <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                  <mat-datepicker #pickerEnd></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="bs-col">
                <mat-form-field appearance="legacy">
                  <mat-label>Objeto del contrato</mat-label>
                  <input matInput formControlName="objetivo">
                </mat-form-field>
              </div>
            </div>

            <div class="bs-row">
              <div class="bs-col">
                <mat-form-field appearance="legacy">
                  <textarea matInput formControlName="descripcion" placeholder="Descripción del servicio" rows="10" cols="70"></textarea>
                </mat-form-field>
              </div>
            </div>

          </form>
            <div class="bs-row">
              <div class="bs-col">
                <table class="datatable">
                  <thead>
                  <tr>
                    <th [style.width.px]="40" class="text-center"></th>
                    <th>Folio</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let itemClienteFichero of lstOrdenesServicios; let index = index;">
                    <td class="text-center"><mat-checkbox [(ngModel)]="itemClienteFichero.checked" [disabled]="chckLstFichero.disabled"></mat-checkbox></td>
                    <td>{{itemClienteFichero.folio}}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
        </div>
      </div>

      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'SERVICIOS'">
        <div class="bs-content">
          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex="30">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">OP-EMP-SUC-22-000001</div>
                  </div>
                </div>
              </mat-card>
            </div>

            <div fxFlex="30">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">OP-EMP-SUC-22-000002</div>
                  </div>
                </div>
              </mat-card>
            </div>

            <div fxFlex="30">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">OP-EMP-SUC-22-000003</div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>
        </div>
      </div>
  </div>
  </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="formOrdenServicioCreate_submitEvent()">Aceptar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>
