import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {ApplicationConfig} from "../../libraries/application-config";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {PDFDocumentProxy} from "ng2-pdf-viewer";
import {Globals} from "../../libraries/globals";
import {FileViewerService} from "../../services/file-viewer.service";
import { FormOptimizer } from 'src/app/libraries/formOptimizer';
import {saveAs} from "file-saver";
import {AlertComponent} from "../alert/alert.component";

export interface SlideInterface {
  url: string;
  title: string;
}

@Component({
  selector: 'app-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss']
})
export class FileViewerComponent implements OnInit, AfterViewInit {

  @Output('onClose') onClose       : EventEmitter<FileViewerComponent> = new EventEmitter<FileViewerComponent>();
  @Output('onDownload') onDownload : EventEmitter<FileViewerComponent> = new EventEmitter<FileViewerComponent>();
  @Output('onPrint') onPrint       : EventEmitter<FileViewerComponent> = new EventEmitter<FileViewerComponent>();

  public objApplicationConfig = ApplicationConfig;
  public slides               : SlideInterface[] = [];
  public idUsuarioFichero     : number = 0;
  public fileName             : string = '';
  public zoom                 : number = 1;
  public page                 : number = 1;
  public totalPage            : number = 0;
  public zoomScale            : 'page-height' | 'page-fit' | 'page-width' = "page-height";
  public pdfSrc               : any = '';
  public extension            : string = ''
  public isFull               : boolean = false;
  public currentIndex         : number = 0;
  public timeoutId?           : number;

  public objLoader = {
    type            : 'loader',
    visible         : false
  }

  public formComponents : Array<any> = [
    this.objLoader
  ];

  constructor(
    private objDialogRef         : MatDialogRef<FileViewerComponent>,
    private objFileViewerService : FileViewerService,
    private objMatDialog         : MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.extension = this.data.extension;
    this.fileName = this.data.fileName;

    if (Globals.exist(this.data.isBlob) && this.data.isBlob) {
      let objFileReader = new FileReader();
      objFileReader.readAsDataURL(this.data.pdfSrc);
      objFileReader.onload = (_event) => {
        this.pdfSrc = objFileReader.result || '';
      }
    } else {
      this.pdfSrc = this.data?.pdfSrc;
    }

    if (Globals.exist(this.data?.slides)) {
      this.slides = this.data?.slides;
      this.resetTimer();
    }

    if (Globals.exist(this.data?.index)) {
      this.currentIndex = this.data?.index;
      this.fileName = this.slides[this.currentIndex]?.title;
    }
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (Globals.exist(this.data?.url)) {
      setTimeout(() => this.init(), 200);
    }
  }

  public init() {
    FormOptimizer.formDisable(this.formComponents);
    this.objFileViewerService.document(this.data.url)
      .subscribe( objResponse => {
        let blob = new Blob([objResponse], {type: 'application/pdf'});
        this.pdfSrc = URL.createObjectURL(blob);
        this.objLoader.visible = false;
        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        FormOptimizer.formEnable(this.formComponents);
        // console.log("error");
      });
  }

  public close() : void {
    if (Globals.exist(this.timeoutId)) {
      window.clearTimeout(this.timeoutId);
    }
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

  public btnUsuarioFicheroDownload_clickEvent() : void {
    if (Globals.exist(this.data?.url)) {
      FormOptimizer.formDisable(this.formComponents);
      this.objFileViewerService.document(this.data.url)
        .subscribe(objResponse => {
          saveAs(objResponse, this.data.fileName);
          FormOptimizer.formEnable(this.formComponents);
        }, error => {
          FormOptimizer.formEnable(this.formComponents);
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: "Error de comunicación", mensaje: "Ocurrió un error de comunicación con el servidor.", autoCloseDelay: 3000}));
        });
    } else {
      this.onDownload.emit(this);
    }
  }

  public btnUsuarioFicheroPrint_clickEvent() : void {
    if (Globals.exist(this.data?.url)) {
      FormOptimizer.formDisable(this.formComponents);
      this.objFileViewerService.document(this.data.url)
        .subscribe(objResponse => {
          let blob = new Blob([objResponse], {type: 'application/pdf'});
          let blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          if(iframe.contentWindow != null) {
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
          }
          FormOptimizer.formEnable(this.formComponents);
        }, () => {
          FormOptimizer.formEnable(this.formComponents);
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: "Error de comunicación", mensaje: "Ocurrió un error de comunicación con el servidor.", autoCloseDelay: 3000}));
        });
    } else {
      this.onPrint.emit(this);
    }
  }

  public zoomInit() : void {
    this.zoom = 1;
  }

  public zoomFull() : void {
    this.zoom = 2.5;
  }

  public zoomIn() : void {
    this.zoom = this.round(this.zoom + 0.5);
  }

  public zoomOut() : void {
    this.zoom = this.round(this.zoom - 0.5);
  }

  callBackFn(pdf: PDFDocumentProxy) {
    this.totalPage = pdf.numPages;
  }

  public round(num : number, decimales : number = 2) : number {
    let signo : number = (num >= 0 ? 1 : -1);
    let valor : string [] = [];
    num = num * signo;
    if (decimales === 0) //con 0 decimales
      return signo * Math.round(num);
    // round(x * 10 ^ decimales)
    valor = num.toString().split('e');
    num = Math.round(+(valor[0] + 'e' + (valor[1] ? (+valor[1] + decimales) : decimales)));
    // x * 10 ^ (-decimales)
    valor = num.toString().split('e');
    return signo * Number(valor[0] + 'e' + (valor[1] ? (+valor[1] - decimales) : -decimales));
  }

  // -------------------------------------------------------------------------------------------------------------------

  public resetTimer() {
    if (Globals.exist(this.timeoutId)) {
      window.clearTimeout(this.timeoutId);
    }
    this.timeoutId = window.setTimeout(() => this.goToNext(), 5000);
  }

  public goToPrevious(): void {
    const isFirstSlide = this.currentIndex === 0;
    const newIndex = isFirstSlide
      ? this.slides.length - 1
      : this.currentIndex - 1;

    this.resetTimer();
    this.currentIndex = newIndex;
    this.fileName = this.slides[this.currentIndex]?.title;
  }

  public goToNext(): void {
    const isLastSlide = this.currentIndex === this.slides.length - 1;
    const newIndex = isLastSlide ? 0 : this.currentIndex + 1;

    this.resetTimer();
    this.currentIndex = newIndex;
    this.fileName = this.slides[this.currentIndex]?.title;
  }

  public goToSlide(slideIndex: number): void {
    this.resetTimer();
    this.currentIndex = slideIndex;
    this.fileName = this.slides[this.currentIndex]?.title;
  }

  public getCurrentSlideUrl() {
    return `url('${this.slides[this.currentIndex]?.url}')`;
  }
}
