import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ZoneUploaderComponent } from './zone-uploader.component';




@NgModule({
  declarations: [
    ZoneUploaderComponent
  ],
  imports: [
    CommonModule
  ],
  exports : [
    ZoneUploaderComponent
  ]
})
export class ZoneUploaderModule { }
