import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, concatMap } from 'rxjs/operators';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Globals } from '../libraries/globals';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private objAuthService : AuthService) { }

  intercept(objReq: HttpRequest<any>, objNext: HttpHandler): Observable<HttpEvent<any>> {
    if(!this.objAuthService.isAuthenticaded()) {
      return objNext.handle(objReq);
    } else {


      return objNext.handle(objReq).pipe(catchError((objError : HttpErrorResponse, objCaught : Observable<any>) => {
        if( Globals.exist( objReq.body ) && (objReq.body.toString().includes('grant_type=refresh')) ) {
          this.objAuthService.logout();
          return of();
        }
        if(objError.status == 401) {
          return this.objAuthService.refresh().pipe(concatMap(objResponse => {
            this.objAuthService.start(objResponse);
            this.objAuthService.getPrivilegios().subscribe( objResponse => {
              if (objResponse.action) this.objAuthService.setPrivilegios(objResponse.result);
              else this.objAuthService.logout()
              }, () => this.objAuthService.logout());

            let objRetryReq = objReq.clone({ headers : objReq.headers.set('Authorization', 'Bearer ' + this.objAuthService.access_token)});
            return objNext.handle(objRetryReq).pipe(catchError((objError2 : HttpErrorResponse) => {
              this.objAuthService.logout();
              return throwError(objError2);
            }));
          }));

        } else {
          return throwError(objError);
        }

      }));
    }
  }
}
