import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../libraries/application-config';
import { Sessions } from '../libraries/sessions';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {

  constructor(  private http: HttpClient,
                private objAuthService : AuthService ) { }

  notification(): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/notificaciones", Sessions.headerSession(this.objAuthService.access_token));
  }

  index(): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/notificaciones/index", Sessions.headerSession(this.objAuthService.access_token));
  }

}
