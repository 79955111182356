import { Component, OnInit, AfterViewInit, ViewChild, ElementRef, Input, HostListener } from '@angular/core';
import { Globals } from 'src/app/libraries/globals';
import { SafeUrl, DomSanitizer } from '@angular/platform-browser';
import * as moment from "moment";
moment.locale("es");

declare var $ : any;

@Component({
  selector: 'comp-zone-uploader',
  templateUrl: './zone-uploader.component.html',
  styleUrls: ['./zone-uploader.component.css']
})
export class ZoneUploaderComponent implements OnInit, AfterViewInit {

  @ViewChild("zoneDragDropFile")  zoneDragDropFile  : ElementRef;
  @ViewChild('eleInput')          eleInput          : ElementRef;

  //PRIVATE ATTIBUTES
	private destination     : any;
  private uploadComplete  : any;
  private _estate         : string;
  private boolDisabled    : boolean;
  private imgType         : any = /image.*/;
  private videoType       : any = /video.*/;

  public id : string = Globals.uniqueId();

  @Input('files')      files      : Array<any>  = [];
  @Input('height')     height     : number      = 300;
  @Input('title')      title      : string      = "Agregar documentos";
  @Input("imgOnly")    imgOnly    : boolean     = false;
  @Input("videoOnly")  videoOnly  : boolean     = false;
  @Input('cssStyle')   cssStyle   : any;
  @Input('titleShow')  titleShow  : boolean     = true;
  @Input('accept')     accept     : string      = "";
  @Input('extensions') extensions : string      = "";
  @Input('config')     config     : boolean     = true;

  @Input()
    set estate(argEstate : string ) {
      this.disabled(argEstate);
    }
    get estate(): string {
      return this._estate;
    }

  // LISTENER BODY
  @HostListener('body:dragover', ['$event'])
  private onBodyDragOver(event : any) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('body:drop', ['$event'])
  private onBodyDrop(event : any) {
    event.preventDefault();
    event.stopPropagation();
  }

  // LISTINER ZONE-UPLOADER
  @HostListener('dragover', ['$event'])
  private onDragOver(event : any) {
    event.preventDefault();
    event.stopPropagation();

    if(!this.boolDisabled) {

      $(this.zoneDragDropFile.nativeElement).addClass("active");
      this.title = "Soltar documentos";

    }
  }

  @HostListener('dragleave', ['$event'])
  private onDragLeave(event : any) {
    event.preventDefault();
    event.stopPropagation();

    if(!this.boolDisabled) {

      $(this.zoneDragDropFile.nativeElement).removeClass("active");
      this.title = "Agregar documentos";

    }
  }

  @HostListener('dragenter', ['$event'])
  private onDragEnter(event : any) {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('drop', ['$event'])
  private onDrop(event : any) {
    event.preventDefault();
    event.stopPropagation();

    if(!this.boolDisabled) {

      $(this.zoneDragDropFile.nativeElement).removeClass("active");
      this.title = "Agregar documentos";

      let arrFiles = new Array();

      $.each(event.dataTransfer.files, (index : any, file : any) => {

        let idFile    = 'dataTransfer_' + Globals.uniqueId();
        let urlFile   : SafeUrl;
        let thumbUrl  : any = "";
        let videoDuration : any = '00:00:00';

        if (this.imgOnly) {
          if (file.type.match(this.imgType)) {
            urlFile = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
          } else {
            return;
          }
        } else {
          if (file.type.match(this.imgType)) {
            urlFile = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
          } else {
            urlFile = "";
          }
        }

        if (this.videoOnly) {
          if (file.type.match(this.videoType)) {
            const video = document.createElement('video');
            video.src = window.URL.createObjectURL(file);
            video.preload = 'metadata';
            video.onloadedmetadata = () => {
              if (moment.isDuration(moment.duration(video.duration, "s"))) {
                videoDuration = moment.utc(moment.duration(video.duration, "s").asMilliseconds()).format("HH:mm:ss");
                this.agregarArchivo('dataTransfer', idFile, file, urlFile, videoDuration, "", thumbUrl);
              }
            }
          }
        } else {
          if( !Globals.isStringNotEmpty(this.extensions) || this.extensions.toLowerCase().includes(this.getExtension(file.name).toLowerCase()) ) {
            this.agregarArchivo('dataTransfer', idFile, file, urlFile, videoDuration, "", thumbUrl);
          }
        }

        // const rsSize = file.size/1024;
        // const size   = Math.round(rsSize * 100)/100;
        // if( !Globals.isStringNotEmpty(this.extensions) || this.extensions.toLowerCase().includes(this.getExtension(file.name).toLowerCase()) ) {
        //   this.addFile('dataTransfer', idFile, this.getFileName(file.name), file, size, this.getExtension(file.name), urlFile, videoDuration);
        //   arrFiles.push({
        //     type	    : 'dataTransfer',
        //     idFile    : idFile,
        //     name 	    : this.getFileName(file.name),
        //     object 	  : file,
        //     size      : size,
        //     extension : this.getExtension(file.name),
        //     urlFile   : urlFile,
        //     duration  : videoDuration
        //   });
        // }
      });

      this.files.concat(arrFiles);

    }
  }

  @HostListener('mouseenter', ['$event'])
  private onMouseEnter(event : any) {
    if(!this.boolDisabled) {

      $(this.zoneDragDropFile.nativeElement).addClass("active");

    }
  }

  @HostListener('mouseleave', ['$event'])
  private onMouseLeave(event : any) {
    if(!this.boolDisabled) {

      $(this.zoneDragDropFile.nativeElement).removeClass("active");

    }
  }

  @HostListener('click', ['$event'])
  private onClick(event : any) {
    if(!this.boolDisabled) {

      $(this.eleInput.nativeElement).click();

    }
  }

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  //EVENTOS
  elemInput_changeEvent(evnElemInput : any) :void {

    $.each(evnElemInput.target.files, async (index : any, file : any) => {
      let arrFiles  = new Array();
      let idFile    = 'dataTransfer_' + Globals.uniqueId();
      let urlFile   : SafeUrl;
      let videoDuration : string = '00:00:00';
      let videoResolution : string = '';

      if (this.imgOnly) {
        if (file.type.match(this.imgType)) {
          urlFile = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
        } else {
          return;
        }
      } else {
        if (file.type.match(this.imgType)) {
          urlFile = this.sanitizer.bypassSecurityTrustUrl(window.URL.createObjectURL(file));
        } else {
          urlFile = "";
        }
      }

      if (this.videoOnly) {
        if (file.type.match(this.videoType)) {
          const video = document.createElement('video');
          const canvas = document.createElement("canvas");
          canvas.style.display = "none";

          video.src = window.URL.createObjectURL(file);
          video.preload = 'metadata';
          await new Promise((resolve, reject) => {
            video.onloadedmetadata = () => {
              video.currentTime = 1.25;
              canvas.width = video.videoWidth;
              canvas.height = video.videoHeight;
              videoResolution = `${video.videoWidth} x ${video.videoHeight}`;
              if (moment.isDuration(moment.duration(video.duration, "s"))) {
                videoDuration = moment.utc(moment.duration(video.duration, "s").asMilliseconds()).format("HH:mm:ss");
              }
            }
            video.onseeked = () => resolve();
          });

          if (canvas.getContext) {
            // @ts-ignore
            canvas.getContext("2d").drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            this.agregarArchivo('dataTransfer', idFile, file, urlFile, videoDuration, videoResolution, canvas);
          } else {
            this.agregarArchivo('dataTransfer', idFile, file, urlFile, videoDuration, videoResolution, null);
          }
        }
      } else {
        this.agregarArchivo('dataTransfer', idFile, file, urlFile, videoDuration, videoResolution, null);
      }

      // this.addFile('dataTransfer', idFile, this.getFileName(file.name), file, size, this.getExtension(file.name), urlFile, videoDuration);
      // arrFiles.push({
      //   type	    : 'dataTransfer',
      //   idFile    : idFile,
      //   name 	    : this.getFileName(file.name),
      //   object 	  : file,
      //   size      : size,
      //   extension : this.getExtension(file.name),
      //   urlFile   : urlFile,
      //   duration  : videoDuration
      // });
      //
      // this.files.concat(arrFiles);
    });

    evnElemInput.target.value = "";

  }

  private agregarArchivo(argType : string, idFile : string, file : any, urlFile : SafeUrl, videoDuration : any, videoResolution: any, videoCanvas : any) : void {
    const rsSize = file.size/1024;
    const size   = Math.round(rsSize * 100)/100;

    this.addFile('dataTransfer', idFile, this.getFileName(file.name), file, size, this.getExtension(file.name), urlFile, videoDuration, videoResolution, videoCanvas);
  }

  /* --> METODOS */
  private addFile(argType : any, idFile : any, argName : any, argObject : any, argSize : any, argExtension : any, argUrlFile : any, argDuration : any, argResolution : any, arcCanvas : any) : void {
		this.files.push({
			type	     : argType,
			name 	     : argName,
			idFile     : idFile,
      object     : argObject,
      size       : argSize,
      extension  : argExtension,
      urlFile    : argUrlFile,
      duration   : argDuration,
      resolution : argResolution,
      canvas     : arcCanvas
		});
  }

  private getFileName (rawName : any) : any {
		// let arrName = (rawName.split('\\').pop()).split(".");

		// let returnName = "";
		// for(let i = 0; i < arrName.length - 1; i++) {
		// 	returnName = ((returnName != '')?'.':'') + arrName[i];
    // }
    let returnName = rawName.split('.').slice(0, -1).join('.');

		return returnName;
  }

  private getExtension (rawName : any) : any {
    let returnExtension = rawName.split('.').pop();

		return returnExtension;
  }

  public disabled(argEstate : String) {

    if(argEstate == "disabled") {
      this.boolDisabled = true;
    } else if(argEstate == "enabled") {
      this.boolDisabled = false;
    }

  }

  public isDisabled() : void {
    this.boolDisabled = true;
  }

  public isEnabled() : void {
    this.boolDisabled = false;
  }


}
