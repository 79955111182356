import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {MatDialog} from "@angular/material/dialog";
import {DatePipe} from "@angular/common";
import {FileViewerComponent} from "../file-viewer/file-viewer.component";
import {saveAs} from "file-saver";
import {FormOptimizer} from "../../libraries/formOptimizer";
import { Globals } from 'src/app/libraries/globals';

@Component({
  selector: 'div[app-picture-grid-upload]',
  templateUrl: './picture-grid-upload.component.html',
  styleUrls: ['./picture-grid-upload.component.scss']
})
export class PictureGridUploadComponent implements OnInit {

  public fcNombre           : FormControl = new FormControl('', [Validators.required]);
  public JSON               = JSON;
  public objOriginalPreview : any;
  public preview            : any = '';
  public extension          : string = '';
  public Globals            = Globals;
  public checked            : boolean = false;

  private _itemFile : any= '';
  @Input('itemFile')
  get itemFile(): any { return this._itemFile; }
  set itemFile(value: any) {
    this._itemFile = value;
    this.fcNombre.setValue(this._itemFile.name);
    this.extension = this._itemFile.extension;
    this.file_changeEvent();
  }

  public defaultImage : string = 'assets/images/no-image.png';

  @Input('index')          index          : number;
  @Input('borderRadius')   borderRadius   : string = "100%";
  @Input('backgroundSize') backgroundSize : string = "cover";
  @Input('height')         height         : string = "100%";
  @Input('width')          width          : string = "auto";
  @Input('editable')       editable       : boolean = true;
  @Input('removable')      removable      : boolean = true;
  @Output('onDelete')      onDelete       : EventEmitter<PictureGridUploadComponent> = new EventEmitter<PictureGridUploadComponent>();
  @Output('onChange')      onChange       : EventEmitter<PictureGridUploadComponent> = new EventEmitter<PictureGridUploadComponent>();

  public uploadResponse = { status: '', uploadProgress: '', message : '', boolFinalize : false, boolInitial : true, boolSuccess : true };

  public btnEliminar = {
    type            : 'button',
    disabled        : false
  };

  public formComponents: Array<any> = [
    this.btnEliminar,
    this.fcNombre
  ];

  constructor(
    private objMatDialog : MatDialog,
    public objDatePipe : DatePipe
  ) { }

  ngOnInit(): void {
  }

  public btnUnidadImagen_eliminarEvent() : void {
    if(!this.btnEliminar.disabled) {
      this.onDelete.emit(this);
    }
  }

  public validate() : boolean {
    let objReturn : boolean = true;

    this.fcNombre.markAsTouched();

    // VALIDAR INPUT
    if( this.fcNombre.hasError('required') ) objReturn = false;

    return objReturn;
  }

  public isValid() : boolean {
    let objReturn : boolean = false;
    if( Globals.exist(this.itemFile)) {
      objReturn = ( this.fcNombre.value.trim() != '' );
    }
    return objReturn;
  }



  public file_changeEvent() : void {
    if( this.isValid() ) {
      if( Globals.exist(this.itemFile.object) ) {
        var objFileReader = new FileReader();
        objFileReader.readAsDataURL(this.itemFile.object);
        objFileReader.onload = (_event) => {
          this.preview = objFileReader.result || '';
        }
      } else {
        this.preview = '';
      }
    } else {
      if(this.objOriginalPreview != null) {
        this.preview = this.objOriginalPreview;
      }
    }
  }

  public getUnidadMantenimientoImagen() : Array<any> {

    let objReturn : any;

    if( this.validate() )  {
      objReturn = {
        nombre                : this.fcNombre.value,
        file                  : this.itemFile
      };
    }

    return objReturn;
  }

  public verPdf_clickEvent() {
    let objDialog = this.objMatDialog.open(FileViewerComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      data: {
        extension : this.extension,
        fileName : this.fcNombre.value,
        pdfSrc : this.preview
      },
      backdropClass: 'backdropBackground',
      panelClass : 'visualizarPanelClass',
      disableClose: true
    });

    objDialog.componentInstance.onDownload.subscribe( () => {
      saveAs(this.itemFile.object, this.itemFile.name);
    });
  }

  public formDisable() : void {
    FormOptimizer.formDisable(this.formComponents);
  }

  public formEnable() : void {
    FormOptimizer.formEnable(this.formComponents);
  }

}
