import {Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {Globals} from "../../libraries/globals";

@Component({
  selector: 'input[app-input-autocomplete]',
  templateUrl: './input-autocomplete.component.html',
  styleUrls: ['./input-autocomplete.component.scss']
})
export class InputAutocompleteComponent {

  constructor(  private objElementRef : ElementRef ) {
   }

  @Input('iconZoom') iconZoom : string = 'search';
  @Input('iconLoading') iconLoading : string = 'sync';
  @Input('iconCheck') iconCheck : string = 'check';
  @Input('display') display : Array<any> = [];

  @Output('onIconChangeEvent') onIconChangeEvent : EventEmitter<any> = new EventEmitter<any>();

  private _objValue:any;
  @Input('objValue')
  get objValue() : any {return this._objValue }
  set objValue(value: any) {
    this._objValue = value
    this.displayValue();
  }

  private _loading : boolean = false;
  @Input('loading')
  get loading() : boolean { return this._loading }
  set loading(value: boolean) { this._loading = value; }

  // crear metodo de validacion de object hasValue return boolean
  public hasValue() : boolean {
    let isValue = false;

    if(this.objValue != null) isValue = true;

    return isValue;
  }

  // crear input Gettter/setter para loading return boolean
  // input manejo icono
  // input icon:zoom, icon:loading, icon:check
//  crear metodo getIcon
  public getIcon() : string {
    let objIcon = '';
    if( !this.hasValue() && !this.loading )
      objIcon=this.iconZoom;
    else if( this.hasValue() && !this.loading )
      objIcon=this.iconCheck;
    else if (this.loading)
      objIcon=this.iconLoading;

    this.onIconChangeEvent.emit(objIcon);

    return objIcon;
  }

  private displayValue() : void {
    let strValue : string = '';

    if( Globals.exist(this.objValue) && Globals.exist(this.display)) {
      if( this.display.length>0 ) {
        for(let i:number = 0; i < this.display.length; i++){
          if(i > 0) strValue += ", ";
          strValue += this.objValue[this.display[i]];
        }
      }

      this.objElementRef.nativeElement.value = strValue;
    }
  }

}
