import {AfterViewInit, Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {AuthService} from "../../../../../../services/auth.service";
import {FormOptimizer} from "../../../../../../libraries/formOptimizer";
import {FileViewerComponent} from "../../../../../../components/file-viewer/file-viewer.component";
import { Globals } from "../../../../../../libraries/globals";
import {OrdenesServiciosService} from "../../../../../../services/ordenes-servicios.service";
import {AlertComponent} from "../../../../../../components/alert/alert.component";
import {OrdenesPedidosOrdenesServiciosCancelComponent} from "../cancel.component";
import {OrdenesPedidosOrdenesServiciosGerenciamientoIndexComponent} from "../../gerenciamiento/index/index.component";
import {ConfirmComponent} from "../../../../../../components/confirm/confirm.component";

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class OrdenesPedidosOrdenesServiciosCancelIndexComponent implements AfterViewInit {

  @Output('onChange') onChange : EventEmitter<OrdenesPedidosOrdenesServiciosCancelIndexComponent> = new EventEmitter<OrdenesPedidosOrdenesServiciosCancelIndexComponent>();
  @Output('onClose')  onClose  : EventEmitter<OrdenesPedidosOrdenesServiciosCancelIndexComponent> = new EventEmitter<OrdenesPedidosOrdenesServiciosCancelIndexComponent>();

  public Globals                     = Globals;
  public objPrivilegios              : any = {};
  public objOrdenPedido              : any = {};
  public objOrdenServicio            : any        = {}
  public lstOrdenesServicios         : Array<any> = [];
  public lstOrdenesServiciosMiembros : Array<any> = [];

  public objLoader = {
    type            : 'loader',
    visible         : false
  };

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  };

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  };

  public formComponents: Array<any> = [
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objOrdenesServiciosService   : OrdenesServiciosService,
    private objDialogRef                 : MatDialogRef<OrdenesPedidosOrdenesServiciosCancelIndexComponent>,
    private objMatDialog                 : MatDialog,
    private objAuthService               : AuthService,
    @Inject(MAT_DIALOG_DATA) public data : any
  ) { this.objPrivilegios = objAuthService.privilegios; }

  ngAfterViewInit(): void {
    setTimeout( () => this.init() );
  }

  private init(): void {
    FormOptimizer.formDisable(this.formComponents);
    this.objOrdenesServiciosService.ending(this.data.idOrdenServicio)
      .subscribe(objResponse => {
        if (Globals.exist(objResponse?.result)) {

          if (Globals.exist(objResponse.result?.ordenServicio)) {
            this.objOrdenServicio = objResponse.result.ordenServicio;
          }

          if (Globals.isArray(objResponse.result?.ordenesServiciosGerenciamientos)) {
            this.lstOrdenesServiciosMiembros = objResponse.result.ordenesServiciosGerenciamientos;
          }

        }
        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        FormOptimizer.formEnable(this.formComponents);
        console.log(error);
      });
  }

  public fnOrdenPedidoCancelar_submitEvent(): void {
    if ( this.validateCancelar() ) {
      this.objMatDialog.open(ConfirmComponent, Globals.successConfig({titulo: 'Cancelar orden de servicio', mensaje: '¿Desea cancelar la orden de servicio: ' + this.objOrdenServicio?.folio + '?', fnAccept: () => {
          this.fnCancelarOrdenServicio();
        }}, {maxWidth: '525px'}));
    } else {
      this.objMatDialog.open(AlertComponent, Globals.successConfig({titulo: 'Cancelar orden de servicio', mensaje: 'No se puede cancelar la orden de servicio ' + this.objOrdenServicio?.folio  + ', existen gerenciamientos que ya fueron completados.', autoCloseDelay: 5000}, {maxWidth: '575px'}));
    }
  }

  public fnCancelarOrdenServicio() : void {
    let objDialog = this.objMatDialog.open(OrdenesPedidosOrdenesServiciosCancelComponent, {
      width         : '76%',
      maxWidth      : '700px',
      height        : '76%',
      maxHeight     : '300px',
      data          : this.objOrdenServicio,
      disableClose  : true
    });

    objDialog.componentInstance.onChange.subscribe( (itemCotizacionesCancelComponent : OrdenesPedidosOrdenesServiciosCancelComponent) => {
      this.init();
      this.change();
      this.close();
    });
  }

  public verPdf_clickEvent(itemOrdenServicio : any) {
    let objDialog = this.objMatDialog.open(FileViewerComponent, {
      width     : '100%',
      maxWidth  : '100%',
      height    : '100%',
      maxHeight : '100%',
      data: {
        extension : 'pdf',
        fileName  : itemOrdenServicio?.ordenServicioFolio + '.pdf',
        url    : 'api/ordenes-servicios/pdf/' + itemOrdenServicio.idOrdenServicio
      },
      backdropClass : 'backdropBackground',
      panelClass    : 'visualizarPanelClass',
      disableClose  : true
    });
  }

  public btnOrdenServicioGerenciamiento_clickEvent(itemOrdenServicioMiembro : any) : void {
    let objDialog = this.objMatDialog.open(OrdenesPedidosOrdenesServiciosGerenciamientoIndexComponent, {
      width        : '76%'  ,
      maxWidth     : '900px',
      height       : '100%' ,
      maxHeight    : '630px',
      data          : itemOrdenServicioMiembro,
      disableClose  : true
    });

    objDialog.componentInstance.onChange.subscribe( () => this.init());
  }

  public validateCancelar() : boolean {
    return !this.lstOrdenesServiciosMiembros.some(itemOrdenServicioMiembro => itemOrdenServicioMiembro?.idGerenciamientoEstatus === 4);
  }

  public change(): void {
    this.onChange.emit(this);
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

}
