import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "../auth.service";
import {Observable} from "rxjs";
import {ApplicationConfig} from "../../libraries/application-config";
import {Sessions} from "../../libraries/sessions";

@Injectable({
  providedIn: 'root'
})
export class GerenciamientosService {

  constructor(private http: HttpClient,
              private objAuthService: AuthService) {
  }

  filtros(): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/gerenciamientos/evaluaciones-riesgos/filtros', Sessions.headerSession(this.objAuthService.access_token));
  }

  datatable( objParams : any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/gerenciamientos/evaluaciones-riesgos/datatable", objParams, Sessions.headerSession(this.objAuthService.access_token));
  }

  index(idOrdenServicio : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/gerenciamientos/evaluaciones-riesgos/' + idOrdenServicio + '/index', Sessions.headerSession(this.objAuthService.access_token));
  }

  manage( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/gerenciamientos/evaluaciones-riesgos/manage", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  create(idOrdenServicio : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/gerenciamientos/evaluaciones-riesgos/' + idOrdenServicio + '/create', Sessions.headerSession(this.objAuthService.access_token));
  }

  store( objParams : any ) : Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/gerenciamientos/evaluaciones-riesgos", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  edit(idGerenciamientoEvaluacionRiesgo : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/gerenciamientos/evaluaciones-riesgos/' + idGerenciamientoEvaluacionRiesgo + '/edit', Sessions.headerSession(this.objAuthService.access_token));
  }

  update( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/gerenciamientos/evaluaciones-riesgos", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  regreso( objParams : any ) : Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/gerenciamientos/evaluaciones-riesgos/regreso", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  cronograma( objParams : any ) : Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/gerenciamientos/evaluaciones-riesgos/cronograma", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  createPdf(idOrdenServicio : number): Observable<Blob> {
    return this.http.get<Blob>(ApplicationConfig.ENDPOINT + "api/gerenciamientos/evaluaciones-riesgos/" + idOrdenServicio + "/pdf", Sessions.headerFile(this.objAuthService.access_token));
  }

  exportExcel(objParams : any ): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/gerenciamientos/evaluaciones-riesgos/export/excel", objParams, Sessions.headerFile(this.objAuthService.access_token));
  }
}
