import { Mailbox } from './mailbox';

export const mailboxList: Mailbox[] = [
    {
        MailId: "bb428c03-1bc6-4f3d-9d5e-268ec44eebc3",
        fromId: "899d0e31-b71e-4d95-a8a0-6a8bceb314bd",



        Subject: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
        date:new Date('1-15-2020'),           //                mm/dd/year
        Message: "1.The phrase Lorem ipsum dolor sit amet consectetuer appears in Microsoft Word online Help. This phrase has the appearance of an intelligent Latin idiom. Actually, it is nonsense.",


        readStatus: false,
        seen: false,

        mailbox: 'Inbox',
        filter: ['Star'],
        label: ['Account','Personal']

    },
    {
        MailId: "a19bf9fc-e877-49e7-a75a-b089a2c35f18",
        fromId:  "3782c174-1f2c-4dc4-b75d-0bedf400e023",



        Subject: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.',
        date: new Date('1-10-2020'),
        Message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",


        readStatus: false,
        seen: false,


        mailbox: 'Inbox',
        filter: ['Important'],
        label: []
    },
    {
        MailId: "910d7e0a-f3b0-47a7-bb53-9036ed717298",
        fromId:  "eef93cb1-7766-4413-a5cf-ecbf71fa3674",



        Subject: 'consectetuer adipiscing elit.',
        date: new Date('11-25-2018'),
        Message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",


        readStatus: false,
        seen: false,

        mailbox: 'Inbox',
        filter: [],
        label: ['Invoice']
    },
    {
        MailId:  "c79cb60a-baf8-4349-b9c3-88d567b965a2",
        fromId: "899d0e31-b71e-4d95-a8a0-6a8bceb314bd",


        Subject: 'Aenean commodo ligula eget dolor. Aenean massa.',
        date: new Date('11-15-2019'),
        Message: "The phrase Lorem ipsum dolor sit amet consectetuer appears. This phrase has the appearance of an intelligent Latin idiom. Actually, it is nonsense.",


        readStatus: false,
        seen: false,


        mailbox: 'Inbox',
        filter: [],
        label: ['Personal']
    },
    {
        MailId: "910d7e0a-f3b0-47a7-bb53-9036ed717298",
        fromId: 'a41c6c4a-9cb1-45d1-8c6f-091044ba51ff',


        Subject: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.Aenean commodo ligula eget dolor. Aenean massa.',
        date: new Date('02-15-2019'),
        Message: "The phrase Lorem ipsum dolor sit amet consectetuer appears in Microsoft Word online Help. This phrase has the appearance of an intelligent Latin idiom. Actually, it is nonsense.",


        readStatus: false,
        seen: false,


        mailbox: 'Sent',
        filter: ['Star','Important'],
        label: []
    },
    {
        MailId:  "35bddac1-1046-4c96-a1ac-ffd75def7f5a",
        fromId: "a41c6c4a-9cb1-45d1-8c6f-091044ba51ff",



        Subject: 'Lorem ipsum dolor sit amet,Aenean commodo ligula eget dolor. Aenean massa.',
        date: new Date('03-15-2019'),
        Message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",


        readStatus: false,
        seen: false,


        mailbox: 'Inbox',
        filter: [],
        label: []
    },
    {
        MailId: "0c914dfd-be0d-4d46-b963-47bcb064154f",
        fromId:"3782c174-1f2c-4dc4-b75d-0bedf400e023",



        Subject: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.Aenean commodo ligula eget dolor. Aenean massa.',
        date: new Date('03-30-2019'),
        Message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",


        readStatus: false,
        seen: false,


        mailbox: 'Draft',
        filter: [],
        label: ['Work']
    },
    {
        MailId:  "11538c3a-f2a7-4a7c-a237-4e61aa96423b",
        fromId: 'a41c6c4a-9cb1-45d1-8c6f-091044ba51ff',



        Subject: 'Lorem ipsum dolor sit amet,Aenean commodo ligula eget dolor. Aenean massa.',
        date: new Date('11-15-2019'),
        Message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",


        readStatus: false,
        seen: false,


        mailbox: 'Inbox',
        filter: [],
        label: ['Personal','Invoice']
    },
    {
        MailId: "0f0a5482-8e4f-42a0-b3bb-1f96bc04d85a",
        fromId: 'a41c6c4a-9cb1-45d1-8c6f-091044ba51ff',

        Subject: 'Aenean commodo ligula eget dolor,Aenean commodo ligula eget dolor. Aenean massa.',
        date: new Date('11-15-2019'),
        Message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",


        readStatus: false,
        seen: false,

        mailbox: 'Inbox',
        filter: [],
        label: ['Account']
    },
    {
        MailId:  "2b0e4083-00e4-48fd-9cb3-05434767ca66",
        fromId: '36a1ead7-57a0-4275-8a21-956194ab7cdf',



        Subject: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.',
        date: new Date('5-20-2018'),
        Message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.",


        readStatus: false,
        seen: false,


        mailbox: 'Sent',
        filter: [],
        label: ['Work']
    },
    {
        MailId: "bf7ab76b-c076-4992-a580-13b1859cd395",
        fromId:  "eef93cb1-7766-4413-a5cf-ecbf71fa3674",


        Subject: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit.Aenean commodo ligula eget dolor. Aenean massa.',
        date: new Date('11-11-2018'),
        Message: "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa..",


        readStatus: false,
        seen: false,


        mailbox: 'Sent',
        filter: [],
        label: ['Payment']
    },





]


