import * as CryptoJS from "crypto-js";

export class Crypto {
  private static key = CryptoJS.enc.Utf8.parse("abcdefgabcdefg12");

  public static crifrar( value : string ) : string {
    let data = CryptoJS.enc.Utf8.parse(value);
    let encrypted = CryptoJS.AES.encrypt(data, this.key, {
      mode:CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });

    return encrypted.toString();
  }

  public static descrifrar( value : string ) : string {
    let decrypt = CryptoJS.AES.decrypt(value, this.key, {
      mode:CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });

    return CryptoJS.enc.Utf8.stringify(decrypt).toString();
  }
}
