import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-toast-alert',
  templateUrl: './toast-alert.component.html',
  styleUrls: ['./toast-alert.component.scss']
})
export class ToastAlertComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
