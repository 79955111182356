import {HttpHeaders} from '@angular/common/http';
import {ApplicationConfig} from './application-config';
import {Globals} from './globals';

export class Sessions {

    public static headerOauth() {
        const appCredentials = btoa(ApplicationConfig.CREDENTIALS_USER + ':' + ApplicationConfig.CREDENTIALS_PASS);

        const objHttpHeaders = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic ' + appCredentials
        });
        let objReturn = {
            headers: objHttpHeaders
        };

        return objReturn;
    }

    public static header() {
        let objReturn = {
            headers: {
                'Content-Type': 'application/json',
                // 'Authorization'     : 'Bearer ' + Sessions.getItem("token")
            }
        };

        return objReturn;
    }


    public static headerSession(access_token: string) {

        let objReturn = null;

        if (Globals.exist(access_token)) {
            const objHttpHeaders = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            });
            objReturn = {
                headers: objHttpHeaders
            };
        } else {
            objReturn = Sessions.header();
        }


        return objReturn;
    }

    public static headerFormData(access_token: string): any {

        let objReturn = null;

        if (Globals.exist(access_token)) {
            const objHttpHeaders = new HttpHeaders({
                // 'Content-Type'	: 'application/json',
                'Authorization': 'Bearer ' + access_token
            });
            objReturn = {
                headers: objHttpHeaders
            };
        } else {
            objReturn = Sessions.header();
        }


        return objReturn;
    }

    public static headerFile(access_token: string) {

        let objReturn = null;

        if (Globals.exist(access_token)) {
            const objHttpHeaders = new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + access_token
            });
            objReturn = {
                responseType: 'blob' as 'json',
                headers: objHttpHeaders
            };
        } else {
            objReturn = Sessions.header();
        }


        return objReturn;
    }

  public static headerFormDataFile(access_token: string) {

    let objReturn = null;

    if (Globals.exist(access_token)) {
      const objHttpHeaders = new HttpHeaders({
        // 'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + access_token
      });
      objReturn = {
        responseType: 'blob' as 'json',
        headers: objHttpHeaders
      };
    } else {
      objReturn = Sessions.header();
    }


    return objReturn;
  }
}
