import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {UnidadesMantenimientosTalleresService} from "../../../../services/unidades-mantenimientos-talleres.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Globals} from "../../../../libraries/globals";
import * as moment from "moment";
import {FormOptimizer} from "../../../../libraries/formOptimizer";
import {AlertComponent} from "../../../../components/alert/alert.component";
import {SuccessComponent} from "../../../../components/success/success.component";

@Component({
  selector: 'app-unidad-mantenimiento-taller-cancel-service',
  templateUrl: './cancel-service.component.html',
  styleUrls: ['./cancel-service.component.scss']
})
export class UnidadMantenimientoTalleresCancelServiceComponent implements OnInit, AfterViewInit {

  @Output('onClose') onClose : EventEmitter<UnidadMantenimientoTalleresCancelServiceComponent> = new EventEmitter<UnidadMantenimientoTalleresCancelServiceComponent>();

  public fgCotizacionesCancel : FormGroup = new FormGroup({
    cancelacionServicioDescripcion     : new FormControl('', Validators.required)
  });

  public taller                            : string = ''
  public objUnidadMantenimientoTaller      : any = {};

  public objLoader = {
    type            : 'loader',
    visible         : false
  };

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  };

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  };

  public formComponents: Array<any> = [
    this.fgCotizacionesCancel,
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objDialogRef: MatDialogRef<UnidadMantenimientoTalleresCancelServiceComponent>,
    private objMatDialog: MatDialog,
    private objUnidadesMantenimientosTalleresService : UnidadesMantenimientosTalleresService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { this.taller = this.data.unidadMantenimientoTaller; }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(()=> this.init());
  }

  public init() : void {
    FormOptimizer.formDisable(this.formComponents);
    let fdUnidadCancelar = new FormData();

    fdUnidadCancelar.append("idUnidadMantenimiento"       , this.data.idUnidadMantenimiento);
    fdUnidadCancelar.append("idUnidadMantenimientoTaller" , this.data.idUnidadMantenimientoTaller);
    this.objUnidadesMantenimientosTalleresService.cancel(fdUnidadCancelar)
      .subscribe(objResponse => {
        this.objUnidadMantenimientoTaller = objResponse.result.unidadesMantenimientosTaller;

        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        FormOptimizer.formEnable(this.formComponents);
      });
  }

  public formCotizacionCancelacion_submitEvent() : void {
    (<any>Object).values(this.fgCotizacionesCancel.controls).forEach( (itemControl : FormControl) => {
      itemControl.markAsTouched();
    });

    if(this.fgCotizacionesCancel.valid) {
      let fdUnidadCancelar = new FormData();

      fdUnidadCancelar.append("idUnidadMantenimiento"       , this.objUnidadMantenimientoTaller.idUnidadMantenimiento);
      fdUnidadCancelar.append("idUnidadMantenimientoTaller" , this.objUnidadMantenimientoTaller.idUnidadMantenimientoTaller);
      fdUnidadCancelar.append('cancelacionServicioDescripcion'      , FormOptimizer.formDataString(this.fgCotizacionesCancel.controls['cancelacionServicioDescripcion']));

      FormOptimizer.formDisable(this.formComponents);
      this.objUnidadesMantenimientosTalleresService.cancelarServicio(fdUnidadCancelar)
        .subscribe(objResponse => {
          if( objResponse.action) {
            this.objUnidadMantenimientoTaller = objResponse.result.unidadMantenimientoTaller;
            this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
            this.close();
          } else
            this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message}));
          FormOptimizer.formEnable(this.formComponents);
        }, error => {
          FormOptimizer.formEnable(this.formComponents);
        });
    } else {
      this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo : 'Error de formulario', mensaje : 'Los datos del cliente no están completos, complete la información.'}));
    }
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }


}
