import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Usuario } from '../../modelo/usuario';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public user: Usuario = new Usuario;
  public form: FormGroup = Object.create(null);
  public hizoLogin = true;
  public imgBackground!: string;
  public imgIlustracion!: string;
  public imgLogo!: string;
  public msgUsuario:string;
  //fotos = [];

  // usuarios: Observable<Usuario[]> | undefined;

  constructor(private fb: FormBuilder, private router: Router, private http: HttpClient) {
    this.imgBackground = "bg-login-001.jpg";
    this.imgIlustracion = "ilustracion001.jpg";
    this.imgLogo = "logo-login-flat-white-transparent.png"
  }

  ngOnInit() {

    this.form = this.fb.group({
      uname: [null, Validators.compose([Validators.required])],
      password: [null, Validators.compose([Validators.required])]
    });
    this.reloadData();
    this.msgUsuario="";
  }

  reloadData() {
    // this.usuarioServicio.realizarPrueba().subscribe(data=>{
    //   console.log("prueba api ");
    //   this.fotos=data;
    //   this.fotos.forEach(function(elemento, indice, array) {
    //     // console.log("nombre "+ elemento.title, indice);
    //     console.log(elemento, indice);

    //   })
    //   // console.log(JSON.parse(data[0]))
    //   // this.fotos=data;
    // })

  }

  onSubmit() {
    // this.router.navigate(['/dashboards/dashboard1']);
    this.msgUsuario="Procesando su solicitud.";
    console.log("prueba api, usuario " + this.form.controls['uname'].value + " clave " + this.form.controls['password'].value);
    this.loginPost(this.form.controls['uname'].value, this.form.controls['password'].value);
  }


  loginPost(usuario: string, clave: string) {
    //this.http.post<Usuario>("http://demo.deltaapps.mx/demodelta/api/procesologin",
   this.http.post<any>("/api/procesologin",
      {
        "email": usuario,
        "password": clave
      })
      .subscribe(data => {
        console.log("consumo de api post exitosa")
        this.user = data
        console.log("hubo repuesta");
        if (this.user === null) {
          console.log("usuario no encontrado");
          this.msgUsuario="Usuario o clave incorrectos";
          this.hizoLogin = false;
        } else {
          console.log("usuario fue encontrado")
          console.log(JSON.stringify(data));
          localStorage.setItem('auth_token',this.user.tokenGenerado);
          console.log("valor de token generado "+this.user.tokenGenerado);
          console.log("valor de token alamcenado "+localStorage.getItem('auth_token'));
          localStorage.setItem('usuarioLogueado', JSON.stringify(this.user));
          this.hizoLogin = true;
          if(this.user.debeCambiarClv==true){
            console.log("usuario debe hacer cambio de clave")
            //this.router.navigate(['cambioclave']);
            this.msgUsuario="El usuario debe cambiar la clave";

          }else{
            this.router.navigate(['/dashboards/dashboard1']);

          }
        }
      },
        err => {
          this.msgUsuario="Plataforma no disponible.";
          console.log('Error: ' + err.error);
          console.log('Name: ' + err.name);
          console.log('Message: ' + err.message);
          console.log('Status: ' + err.status);
        });
  }

  login(usuario: string, clave: string) {
    // this.usuarioServicio.procesoLogin(usuario,clave)
    //   .subscribe(data => console.log(data), error => console.log(error));
    // this.usuarioServicio.procesoLogin(usuario, clave).subscribe(data => {
    //   this.user = data
    //   console.log("hubo repuesta");
    //   if (this.user === null) {
    //     console.log("usuario no encontrado");
    //     this.hizoLogin = false;
    //   } else {
    //     console.log("usuario fue encontrado")
    //     localStorage.setItem('auth_token',this.user.tokenGenerado);
    //     console.log(JSON.stringify(data));
    //     this.hizoLogin = true;
    //     this.router.navigate(['/dashboards/dashboard1']);
    //   }
    // });
  }

  logout() {
    localStorage.removeItem('auth_token');
  }
}
