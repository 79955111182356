import {AfterViewInit, Component, ElementRef, EventEmitter, Inject, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {DatePipe} from "@angular/common";
import {CotizacionesService} from "../../../../services/cotizaciones.service";
import {CotizacionesFicherosService} from "../../../../services/cotizaciones-ficheros.service";
import {AuthService} from "../../../../services/auth.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ApplicationConfig} from "../../../../libraries/application-config";
import {Observable} from "rxjs/Observable";
import {InputAutocompleteComponent} from "../../../../components/input-autocomplete/input-autocomplete.component";
import {ZoneUploaderComponent} from "../../../../components/zone-uploader/zone-uploader.component";
import {Globals} from "../../../../libraries/globals";
import {map, startWith} from "rxjs/operators";
import {FormOptimizer} from "../../../../libraries/formOptimizer";
import {CotizacionesEditPartidaItemComponent} from "./partida-item/partida-item.component";
import {CotizacionesEditFileItemComponent} from "./file-item/file-item.component";
import {AlertComponent} from "../../../../components/alert/alert.component";
import {SuccessComponent} from "../../../../components/success/success.component";
import {ConfirmComponent} from "../../../../components/confirm/confirm.component";
import {FileViewerComponent} from "../../../../components/file-viewer/file-viewer.component";
import {saveAs} from "file-saver";
import {CotizacionesEditPartidaFormComponent} from "./partida-form/partida-form.component";
import {CotizacionPartidaCalc} from "../../../../libraries/calculos-cotizacion";
import * as moment from "moment";

interface cotizacionCondicion {
  idCotizacionCondicion : number;
  cotizacionCondicion   : string;
  checked?              : boolean;
  undo                  : boolean;
  redo                  : boolean;
}

@Component({
  selector: 'app-cotizaciones-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss']
})
export class CotizacionesEditComponent implements OnInit, AfterViewInit {

  listaUno = [{id : 1, nombre: 'Juan', estatus: true}, {id : 2, nombre: 'Maria', estatus: false}, {id : 3, nombre: 'Eduardo', estatus: false}];
  listaDos = [{id : 2, nombre: 'Maria', estatus: true}, {id : 2, nombre: 'Valeria', estatus: true}, {id : 2, nombre: 'Cristel', estatus: true}];

  @Output('onClose')  onClose  : EventEmitter<CotizacionesEditComponent> = new EventEmitter<CotizacionesEditComponent>();
  @Output('onChange') onChange : EventEmitter<CotizacionesEditComponent> = new EventEmitter<CotizacionesEditComponent>();
  @ViewChild('txtUsuarioSolicitud') txtUsuarioSolicitud : InputAutocompleteComponent;
  @ViewChild('txtCliente')          txtCliente          : InputAutocompleteComponent;
  @ViewChild('txtClienteContacto')  txtClienteContacto  : InputAutocompleteComponent;
  @ViewChild('txtCotizacionTipo')   txtCotizacionTipo   : InputAutocompleteComponent;
  @ViewChild('compZoneUploader')    compZoneUploader    : ZoneUploaderComponent;
  @ViewChildren('compPartidaItem')  compPartidaItem     : QueryList<CotizacionesEditPartidaItemComponent>;
  @ViewChildren('compFileItem')     compFileItem        : QueryList<CotizacionesEditFileItemComponent>;
  @ViewChildren('textareaCondicion') textareaCondicion        : QueryList<ElementRef>;

  public fgCotizacionesUpdateSolicitud : FormGroup = new FormGroup({
    solicitudUsuario         : new FormControl('', Validators.required),
    solicitudFecha           : new FormControl('', Validators.required),
    solicitudComentarios     : new FormControl(''),
    empresa                  : new FormControl('', Validators.required),
    sucursal                 : new FormControl('', Validators.required),
    clienteNombreComercial   : new FormControl('', Validators.required),
    contactoNombreCompleto   : new FormControl('', Validators.required),
  });

  public fgCotizacionesUpdateRequerimiento : FormGroup = new FormGroup({
    alcance     : new FormControl(''),
    descripcion : new FormControl(''),
  });

  public fgCotizacionesUpdatePropuesta : FormGroup = new FormGroup({
    moneda       : new FormControl('', Validators.required),
    equivalencia : new FormControl(''),
    formaPago    : new FormControl('', Validators.required)
  });

  public fgCotizacionesUpdateNotas : FormGroup = new FormGroup({
    comentarios  : new FormControl('')
  });

  public fcCotizacionTipo               : FormControl = new FormControl('');

  public objApplicationConfig           = ApplicationConfig;
  public objUsuario                     : any = {};
  public objMoneda                      : any = {};
  public objContizacionEstatus          : any = {};
  public lstUsuarios                    : Array<any>;
  public lstEmpresas                    : Array<any> = [];
  public lstSucursales                  : Array<any> = [];
  public lstSucursalesFiltered          : any;
  public lstClientes                    : Array<any> = [];
  public lstClientesContactos           : Array<any> = [];
  public lstClientesContactosFiltered   : Observable<any>;
  public lstCotizacionesTipos           : Array<any> = [];
  public lstServicios                   : Array<any> = [];
  public lstMonedas                     : Array<any> = [];
  public lstUnidadesMedidas             : Array<any> = [];
  public lstImpuestos                   : Array<any> = [];
  public lstFormasPago                  : Array<any> = [];
  public lstCotizacionesPartidas        : Array<any> = [];
  public lstCondiciones                 : Array<cotizacionCondicion> = [];
  public lstCotizacionesCondiciones     : Array<cotizacionCondicion> = [];
  public lstCotizacionesCondicionesSorted : Array<any> = [];
  public lstCotizacionesPartidasDelete  : Array<any> = [];
  public lstCotizacionesFicheros        : Array<any> = [];
  public lstCotizacionesFicherosDelete  : Array<any> = [];
  public valueConfiguracion             : boolean = false;
  public confDragDropFile               : boolean = false;
  public boolAutoCompleteUsuario        : boolean = false;
  public boolAutoCompleteCliente        : boolean = false;
  public boolAutoCompleteCotizacionTipo : boolean = false;

  public importe                  : number = 0.00;
  public descuento                : number = 0.00;
  public subtotal                 : number = 0.00;
  public total                    : number = 0.00;
  public equivalencia             : number = 0;

  public objCotizacion       : any = {};
  public idCotizacionEstatus : number = 0;
  public indexFicheroUpload  : number = 0;
  public countQueueUpload    : number = 0;
  public countQueueTotal     : number = 0;
  public lstQueueUploadFiles : Array<any> = [];

  public action              : boolean = false;
  public title               : string  = '';
  public message             : string  = '';

  public boolChkCotizacionPartida          : boolean = false;
  public boolChkSomeCotizacionPartida      : boolean = false;
  public boolChkCotizacionFichero          : boolean = false;
  public boolChkSomeCotizacionFichero      : boolean = false;

  public objScene : any = ["SOLICITUD"];

  public objLoader = {
    type            : 'loader',
    visible         : false
  };

  public objChkBox = {
    type     : 'checkbox',
    disabled : false
  };

  public btnOptionBar = {
    type            : 'button',
    disabled        : false
  };

  public btnAccion = {
    type            : 'button',
    disabled        : false
  };

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  };

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  };

  public formComponents: Array<any> = [
    this.btnOptionBar,
    this.btnAccion,
    this.objChkBox,
    this.objLoader,
    this.fgCotizacionesUpdateSolicitud,
    this.fgCotizacionesUpdatePropuesta,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objDialogRef: MatDialogRef<CotizacionesEditComponent>,
    private objMatDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public objDatePipe : DatePipe,
    private objCotizacionesService : CotizacionesService,
    private objCotizacionesFicherosService : CotizacionesFicherosService,
    private objAuthService : AuthService
  ) { }

  ngOnInit(): void {
    FormOptimizer.formDisable(this.formComponents);
  }

  ngAfterViewInit() :void {
    setTimeout( ()=> this.init(), 500);
  }

  public init() : void {
    let objCotizacionEdit : any = {
      idCotizacion : this.data.idCotizacion,
      isDuplicate : false
    }

    this.objCotizacionesService.edit(objCotizacionEdit)
      .subscribe( objResponse => {
        const objResult          = objResponse.result;
        this.objCotizacion      = objResult.cotizacion;
        const objUsuario         = this.objCotizacion.solicitudUsuario;
        const objCliente         = this.objCotizacion.cliente;
        const objClienteContacto = this.objCotizacion.clienteContacto;

        if (Globals.exist(objResult?.configuracion) && Globals.exist(objResult.configuracion?.value)) {
          this.valueConfiguracion = (objResult.configuracion.value === "true");
        }

        if (Globals.exist(objResult?.confDragDropFile) && Globals.exist(objResult.confDragDropFile?.value)) {
          this.confDragDropFile = (objResult.confDragDropFile.value === "true");
        }

        if (Globals.exist(objResult?.cotizacionTipo)) {
          const objCotizacionTipo = objResult.cotizacionTipo;
          this.txtCotizacionTipo.objValue = objCotizacionTipo;
          this.fcCotizacionTipo.setValue(objCotizacionTipo.cotizacionTipo);

          if (Globals.exist(objResult?.condiciones) && Globals.exist(objResult?.cotizacionesCondiciones)) {
            this.lstCondiciones = objResult.condiciones;
            this.lstCondiciones.forEach( itemCondiciones => {
              itemCondiciones['checked'] = false;
              itemCondiciones['undo'] = false;
              itemCondiciones['redo'] = false;
            });
            let lstCotizacionesCondiciones: Array<cotizacionCondicion> = objResult.cotizacionesCondiciones;
            lstCotizacionesCondiciones.forEach( itemCotizacionesCondiciones => {
              itemCotizacionesCondiciones['checked'] = true;
              itemCotizacionesCondiciones['undo'] = false;
              itemCotizacionesCondiciones['redo'] = false;
            });

            this.lstCotizacionesCondiciones = this.lstCondiciones.reduce((result: cotizacionCondicion[], currentItem: cotizacionCondicion) => {
              const existingItem = result.find((item) => item.idCotizacionCondicion === currentItem.idCotizacionCondicion);

              if (existingItem) {
                if (existingItem.cotizacionCondicion === currentItem.cotizacionCondicion) {
                  if (currentItem.checked) {
                    // Reemplazar elemento existente con el de la listaUno si el checked es true
                    return result.map((item) =>
                      item.idCotizacionCondicion === existingItem.idCotizacionCondicion && item.cotizacionCondicion === existingItem.cotizacionCondicion ? currentItem : item);
                  } else {
                    // Mantener elemento existente si el checked es false
                    return result;
                  }
                } else {
                  // Mantener ambos elementos con el mismo ID pero nombres diferentes
                  const objReturn = result;

                  objReturn.forEach(async itemCotizacionesCondiciones => {
                    if (itemCotizacionesCondiciones.idCotizacionCondicion === currentItem.idCotizacionCondicion && itemCotizacionesCondiciones.cotizacionCondicion !== currentItem.cotizacionCondicion) {
                      itemCotizacionesCondiciones.undo = true;
                    }
                  });

                  return objReturn;
                }
              } else {
                // Agregar nuevo elemento a la lista
                return result.concat(currentItem);
              }
            }, lstCotizacionesCondiciones);
            this.lstCotizacionesCondiciones.sort((a, b) => {
              if (a.idCotizacionCondicion === null || a.idCotizacionCondicion === 0) { return 1; /* Mover elementos con ID nulo o cero al final de la lista */}
              if (b.idCotizacionCondicion === null || b.idCotizacionCondicion === 0) { return -1; /* Mover elementos con ID nulo o cero al final de la lista */ }
              return a.idCotizacionCondicion - b.idCotizacionCondicion;
            }).forEach( itemCotizacionesCondiciones => {
              let objCotizacionCondicion : cotizacionCondicion = {
                idCotizacionCondicion    : itemCotizacionesCondiciones.idCotizacionCondicion,
                cotizacionCondicion      : itemCotizacionesCondiciones.cotizacionCondicion,
                checked                  : itemCotizacionesCondiciones.checked,
                undo                     : itemCotizacionesCondiciones.undo,
                redo                     : itemCotizacionesCondiciones.redo
              }

              this.lstCotizacionesCondicionesSorted.push(objCotizacionCondicion);
            });
          }
        }

        this.lstMonedas              = objResult.monedas;

        if(this.lstMonedas != null) {
          this.objMoneda['idMoneda']     =  this.objCotizacion.idMoneda;
          this.objMoneda['moneda']       =  this.objCotizacion.moneda;
          this.objMoneda['codigo']       =  this.objCotizacion.monedaCodigo;
          this.objMoneda['equivalencia'] =  this.objCotizacion.monedaEquivalencia;
          this.fgCotizacionesUpdatePropuesta.controls['moneda'].setValue(this.objMoneda.idMoneda);
        }

        this.lstServicios               = objResult.servicios;
        this.lstUnidadesMedidas         = objResult.unidadMedidas;
        this.lstFormasPago              = objResult.formasPago;
        this.lstImpuestos               = objResult.impuestos
        this.lstEmpresas                = objResult.empresas;
        this.lstEmpresas                = this.lstEmpresas.sort((a, b) => (a.nombreComercial < b.nombreComercial ? -1 : 1));
        this.lstSucursales              = objResult.sucursales;

        this.lstCotizacionesFicheros = this.objCotizacion.ficheros as Array<any>;
        this.objContizacionEstatus   = this.objCotizacion.cotizacionEstatus;

        this.fgCotizacionesUpdatePropuesta.controls['equivalencia'].setValue(this.objCotizacion.equivalencia);
        this.equivalencia = this.objCotizacion.equivalencia;

        this.txtUsuarioSolicitud.objValue = objUsuario;
        this.fgCotizacionesUpdateSolicitud.controls['solicitudUsuario'].setValue(objUsuario.nombreCompleto);
        this.boolAutoCompleteUsuario = true;
        this.txtCliente.objValue = objCliente;
        this.fgCotizacionesUpdateSolicitud.controls['clienteNombreComercial'].setValue(objCliente.nombreComercial);
        this.boolAutoCompleteCliente = true;
        this.txtClienteContacto.objValue = objClienteContacto;
        this.fgCotizacionesUpdateSolicitud.controls['contactoNombreCompleto'].setValue(objClienteContacto.nombreCompleto);
        this.fgCotizacionesUpdateSolicitud.controls['solicitudFecha'].setValue(moment(objResult.fechaSolicitud));

        this.fgCotizacionesUpdatePropuesta.controls['formaPago'].setValue(this.objCotizacion.idFormaPago);

        if( Globals.exist(this.objCotizacion.idEmpresa) ) {
          this.fgCotizacionesUpdateSolicitud.controls['empresa'].setValue(this.objCotizacion.idEmpresa);
          this.lstSucursalesFiltered = this.lstSucursales.filter(itemSucursal => Globals.exist(itemSucursal.empresa) &&  itemSucursal.empresa.idEmpresa == this.objCotizacion.idEmpresa).sort((a, b) => (a.sucursal < b.sucursal ? -1 : 1));
        }

        if( Globals.exist(this.objCotizacion.idSucursal) ) {
          this.fgCotizacionesUpdateSolicitud.controls['sucursal'].setValue(this.objCotizacion.idSucursal);
        }

        this.fgCotizacionesUpdatePropuesta.controls['equivalencia'].valueChanges.subscribe(value => {
          this.equivalencia = 0;
          if( Globals.exist(value) && value.toString().trim() != "" && Number(value.toString().trim()) != NaN ) {
            this.equivalencia = value;

            if (this.valueConfiguracion) {
              this.lstCotizacionesPartidas = CotizacionPartidaCalc.calcularEdit(this.lstCotizacionesPartidas, this.objMoneda, this.equivalencia);
              this.calcCotizacionPartidaItem();
            }
          }
        });

        this.fgCotizacionesUpdateNotas.controls['comentarios'].setValue(this.objCotizacion.comentarios);
        this.fgCotizacionesUpdateRequerimiento.controls['alcance'].setValue(this.objCotizacion.alcance);
        this.fgCotizacionesUpdateRequerimiento.controls['descripcion'].setValue(this.objCotizacion.descripcion);

        for( let index : number = 0; index < this.lstImpuestos.length; index++) {
          this.lstImpuestos[index]['total'] = 0;
        }

        if (this.valueConfiguracion) {
          this.cotizacionPartidaInit(this.objCotizacion.partidas as Array<any>);
        } else {
          this.lstCotizacionesPartidas = this.objCotizacion.partidas as Array<any>;
        }

        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        FormOptimizer.formEnable(this.formComponents);
      });
  }



  public cotizacionPartidaInit(lstPartidas : Array<any>) : void {
    // this.lstCotizacionesPartidas = [];

    if (lstPartidas.length > 0) {
      lstPartidas.forEach(itemCotizacionPartida => {
        let precioXCantidad: number = this.calcPrecioXCantidad(itemCotizacionPartida.moneda, itemCotizacionPartida.precioUnitario, itemCotizacionPartida.cantidad);
        let idCotizacionPartidaTipoDescuento: number = (Globals.exist(itemCotizacionPartida?.cotizacionPartidaTipoDescuento) && Globals.exist(itemCotizacionPartida?.cotizacionPartidaTipoDescuento?.idCotizacionPartidaTipoDescuento)) ? itemCotizacionPartida.cotizacionPartidaTipoDescuento.idCotizacionPartidaTipoDescuento : 0;

      let objReturn = {
        checked                          : false,
        idCotizacionPartida              : itemCotizacionPartida.idCotizacionPartida,
        servicio                         : itemCotizacionPartida.servicio.servicio,
        idServicio                       : Number(itemCotizacionPartida.servicio.idServicio),
        concepto                         : itemCotizacionPartida.concepto,
        unidadMedida                     : itemCotizacionPartida.unidadMedida.unidadMedida,
        idUnidadMedida                   : Number(itemCotizacionPartida.unidadMedida.idUnidadMedida),
        precioUnitario                   : Number(itemCotizacionPartida.precioUnitario).toFixed(2),
        precioXCantidad                  : precioXCantidad.toFixed(2),
        moneda                           : itemCotizacionPartida.moneda.codigo,
        idMoneda                         : Number(itemCotizacionPartida.moneda.idMoneda),
        cantidad                         : Number(itemCotizacionPartida.cantidad).toFixed(2),
        descuentoCantidad                : this.calcDescuentoCantidad(precioXCantidad.toFixed(2), Number(itemCotizacionPartida.importe).toFixed(2)).toFixed(2),
        descuentoPorcentaje              : this.calcDescuentoPorcentaje(precioXCantidad.toFixed(2), Number(itemCotizacionPartida.importe).toFixed(2)).toFixed(2),
        idCotizacionPartidaTipoDescuento : idCotizacionPartidaTipoDescuento,
        impuestos                        : this.lstInnerImpuestos(itemCotizacionPartida.impuestosPartida),
        importe                          : Number(itemCotizacionPartida.importe).toFixed(2)
      }

        this.lstCotizacionesPartidas.push(objReturn);
      });

      this.calcCotizacionPartidaItem();
    }
  }

  private calcPrecioXCantidad(monedaPartida : any, precioUnitario : number, cantidad : number): number {
    let precioXCantidad : number = 0;

    if (Globals.exist(this.objMoneda) && this.objMoneda != null && precioUnitario != 0 && this.equivalencia != 0) {
      if (this.objMoneda.codigo != monedaPartida.codigo) {
        let precioUnitarioXEquivalencia : number = 0;
        if (this.objMoneda?.idMoneda != 1) {
          precioUnitarioXEquivalencia = precioUnitario / this.equivalencia;
        } else if (this.objMoneda?.codigo != monedaPartida.codigo) {
          precioUnitarioXEquivalencia = precioUnitario * this.equivalencia;
        }
        precioXCantidad = cantidad * precioUnitarioXEquivalencia;
      } else {
        precioXCantidad = cantidad * precioUnitario;
      }
    }

    return precioXCantidad;
  }

  public calcDescuentoCantidad(precioXCantidad : string, importe : string): number {
    return Number(precioXCantidad) - Number(importe);
  }

  public calcDescuentoPorcentaje(precioXCantidad : string, importe : string): number {
    let descuento = Number(precioXCantidad) - Number(importe);

    return (descuento * 100) / Number(precioXCantidad);
  }

  public lstInnerImpuestos(lstImpuestosPartida : Array<any>) : Array<any> {
    let lstImpuestosReturn : Array<any> = []

    this.lstImpuestos.forEach(itemImpuesto => {
      let impuestoPartida = lstImpuestosPartida.find(itemImpuestoPartida => itemImpuestoPartida?.impuesto.idImpuesto == itemImpuesto.idImpuesto);

      let objImpuesto: any = {
        porcentajeNatural : itemImpuesto.porcentajeNatural,
        impuesto          : itemImpuesto.impuesto,
        impuestoTipo : {
          idImpuestoTipo : itemImpuesto.impuestoTipo.idImpuestoTipo,
          impuestoTipo   : itemImpuesto.impuestoTipo.impuestoTipo,
        },
        idImpuesto        : itemImpuesto.idImpuesto,
        porcentaje        : itemImpuesto.porcentaje,
        enabled           : itemImpuesto.enabled,

        idCotizacionPartidaImpuesto : (Globals.exist(impuestoPartida?.idCotizacionPartidaImpuesto))? impuestoPartida.idCotizacionPartidaImpuesto : 0,
        checked                     : (Globals.exist(impuestoPartida?.total) && impuestoPartida.total > 0),
        total                       : (Globals.exist(impuestoPartida?.total))? FormOptimizer.formDataDecimal(impuestoPartida.total) : 0
      }
      lstImpuestosReturn.push(objImpuesto);
    });

    return lstImpuestosReturn;
  }

  public formCotizacionesUpdate_submitEvent(): void {
    (<any>Object).values(this.fgCotizacionesUpdateSolicitud.controls).forEach( (itemControl : FormControl) => itemControl.markAsTouched());
    let boolContactosValidar      : boolean    = true;

    if(this.fgCotizacionesUpdateSolicitud.valid) {
      (<any>Object).values(this.fgCotizacionesUpdatePropuesta.controls).forEach( (itemControl : FormControl) => itemControl.markAsTouched());
      if(this.fgCotizacionesUpdatePropuesta.valid) {
        if( boolContactosValidar) {
          this.objMatDialog.open(ConfirmComponent, Globals.successConfig({
            titulo      : 'Modificar cotización',
            mensaje     : '¿Como desea guardar la cotización?',
            acceptLabel : 'Generar cotización',
            cancelLabel : 'Actualizar preliminar',
            fnAccept    : () => this.fnCotizacionUpdate(2),
            fnCancel    : () => this.fnCotizacionUpdate(1)
          } ));
        } else {
          this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo : 'Error de registro de propuesta ecónomica', mensaje : 'Complete los campos solicitados..'}));
          this.objScene = ["PROPUESTA"];
        }
      } else {
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Formulario incompleto", mensaje : "Complete los campos solicitados.", autoCloseDelay : 3000}));
        this.objScene = ['PROPUESTA'];
      }
    } else {
      this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Formulario incompleto", mensaje : "Complete los campos solicitados.", autoCloseDelay : 3000}));
      this.objScene = ['SOLICITUD'];
    }
  }

  public fnCotizacionUpdate(idCotizacionEstatus : number): void {
    this.cotizacionFormsDisable();

    let lstCotizacionesPartidasStore : Array<any> = [];
    let lstCotizacionesPartidasUpdate : Array<any> = [];

    if (this.valueConfiguracion) {
      this.lstCotizacionesPartidas.forEach( itemCotizacionPartida => {
        if (itemCotizacionPartida.idCotizacionPartida > 0) {
          lstCotizacionesPartidasUpdate.push(itemCotizacionPartida);
        } else {
          lstCotizacionesPartidasStore.push(itemCotizacionPartida);
        }
      });

    } else {
      const lstCotizacionesPartidas : Array<any> = this.compPartidaItem.toArray();

      for(let index : number = 0; index < lstCotizacionesPartidas.length; index++) {
        if (lstCotizacionesPartidas[index].idCotizacionPartida > 0) {
          lstCotizacionesPartidasUpdate.push(lstCotizacionesPartidas[index].getCotizacionPartida());
        } else {
          lstCotizacionesPartidasStore.push(lstCotizacionesPartidas[index].getCotizacionPartida());
        }
      }
    }

    let lstImpuestosFiltered : Array<any> = this.lstImpuestos.map( itemImpuesto => {
      return {
        idCotizacionPartidaImpuesto : Number(itemImpuesto.idCotizacionPartidaImpuesto),
        idImpuesto                  : Number(itemImpuesto.idImpuesto),
        idImpuestoTipo              : Number(itemImpuesto.impuestoTipo.idImpuestoTipo),
        total                       : Number(itemImpuesto.total).toFixed(2)
      }});

    let equivalencia : number = Number(FormOptimizer.formDataNumber(this.fgCotizacionesUpdatePropuesta.controls['equivalencia']));

    let lstCotizacionesCondiciones : Array<any> = [];
    this.lstCotizacionesCondicionesSorted.forEach( itemCotizacionCondicion => {
      if (itemCotizacionCondicion.checked) {
        let objCotizacionCondicion = {
          idCotizacionCondicion : Number(itemCotizacionCondicion.idCotizacionCondicion),
          cotizacionCondicion   : itemCotizacionCondicion.cotizacionCondicion
        }

        lstCotizacionesCondiciones.push(objCotizacionCondicion);
      }
    });

    let objCotizacionStore: any = {
      idCotizacion               : this.objCotizacion.idCotizacion,
      idUsuario                  : this.txtUsuarioSolicitud.objValue?.idUsuario,
      solicitudFecha             : FormOptimizer.formDataDate(this.fgCotizacionesUpdateSolicitud.controls['solicitudFecha']),
      solicitudComentarios       : FormOptimizer.formDataString(this.fgCotizacionesUpdateSolicitud.controls['solicitudComentarios']),
      idCotizacionEstatus        : idCotizacionEstatus,
      idEmpresa                  : Number(this.fgCotizacionesUpdateSolicitud.controls['empresa'].value),
      idSucursal                 : Number(this.fgCotizacionesUpdateSolicitud.controls['sucursal'].value),
      idCliente                  : Number(this.txtCliente.objValue?.idCliente),
      idContacto                 : Number(this.txtClienteContacto.objValue?.idClienteContacto),
      idMoneda                   : Number(this.fgCotizacionesUpdatePropuesta.controls['moneda'].value),
      idFormaPago                : Number(this.fgCotizacionesUpdatePropuesta.controls['formaPago'].value),
      equivalencia               : equivalencia.toFixed(2),
      cotizacionesPartidasStore  : lstCotizacionesPartidasStore,
      cotizacionesPartidasUpdate : lstCotizacionesPartidasUpdate,
      cotizacionesPartidasDelete : this.lstCotizacionesPartidasDelete,
      cotizacionesFicherosDelete : this.lstCotizacionesFicherosDelete,
      comentarios                : FormOptimizer.formDataString(this.fgCotizacionesUpdateNotas.controls['comentarios']),
      alcance                    : FormOptimizer.formDataString(this.fgCotizacionesUpdateRequerimiento.controls['alcance']),
      descripcion                : FormOptimizer.formDataString(this.fgCotizacionesUpdateRequerimiento.controls['descripcion']),
      idCotizacionTipo           : Number(this.txtCotizacionTipo.objValue?.idCotizacionTipo),
      cotizacionesCondiciones    : lstCotizacionesCondiciones,
      impuestos                  : lstImpuestosFiltered,
      subtotal                   : this.subtotal.toFixed(2),
      total                      : this.total.toFixed(2)
    }

    this.objCotizacionesService.update(objCotizacionStore)
      .subscribe(objResponse => {
        if( objResponse.action) {
          this.objCotizacion = objResponse.result;

          if (this.compFileItem.toArray().length != 0) {
            this.action = objResponse.action;
            this.title = objResponse.title;
            this.message = objResponse.message;
            this.fnCotizacionFicheroStore(true);
          } else {
            if (this.objCotizacion.idcotizacionEstatus != 0 && this.objCotizacion.idcotizacionEstatus != 1) {
              this.cotizacionFormsEnable();
              this.change();
              this.verPdf_clickEvent();
            } else {
              this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
              this.cotizacionFormsEnable();
              this.change();
              this.close();
            }
          }
        } else {
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000 }));
          this.cotizacionFormsEnable();
        }
      }, error => {
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: 'Error de comunicación', mensaje: 'Ocurrió un error de comunicación con el servidor.', autoCloseDelay: 3000}));
        this.cotizacionFormsEnable();
      });
  }

  public fnCotizacionFicheroStore(boolBegin : boolean = false ) : void {
    let validate : boolean = false;
    if(boolBegin) {
      this.indexFicheroUpload = 0;
      let arrFileItems = this.compFileItem.toArray();
      this.lstQueueUploadFiles = [];

      let boolValid : boolean = true;
      for( let index : number = 0; index < arrFileItems.length; index++ ) {
        if( !arrFileItems[index].validate() ) boolValid = false;
      }

      if(boolValid) {
        validate = true;
        for( let index : number = 0; index < arrFileItems.length; index++ ) {
          let objFileItem = arrFileItems[index];
          this.lstQueueUploadFiles.push(objFileItem.getCotizacionFichero());
          this.countQueueTotal++;

          objFileItem.formDisable();
        }
      }
    } else {
      validate = true;
    }

    if(validate) {
      if(this.countQueueUpload < this.countQueueTotal) {
        let objCotizacionFichero = this.lstQueueUploadFiles[this.indexFicheroUpload];

        let formCotizacionFicheroStore = new FormData();

        formCotizacionFicheroStore.append("idCotizacion", this.objCotizacion.idCotizacion.toString());
        formCotizacionFicheroStore.append("nombre"      , objCotizacionFichero.nombre);
        formCotizacionFicheroStore.append("tamano",       objCotizacionFichero.tamano);
        formCotizacionFicheroStore.append("file"        , objCotizacionFichero.file.object);

        this.objCotizacionesFicherosService.store(formCotizacionFicheroStore)
          .subscribe( objResponse => {

            if(Globals.exist(objResponse)) {
              if(objResponse.status == 'completed'){
                if(objResponse.body.action) {

                  let arrFileItems = this.compFileItem.toArray();
                  arrFileItems[this.indexFicheroUpload].uploadResponse.boolFinalize  = true;
                  arrFileItems[this.indexFicheroUpload].uploadResponse.message       = 'Archivo cargado satisfactoriamente';
                  arrFileItems[this.indexFicheroUpload].uploadResponse.boolSuccess   = true;

                  this.lstQueueUploadFiles.splice(this.indexFicheroUpload, 1);
                  this.compZoneUploader.files.splice(this.indexFicheroUpload, 1);

                  this.countQueueUpload++;

                  setTimeout(() => {
                    this.fnCotizacionFicheroStore();
                  }, 1000);


                } else {
                  let arrFileItems = this.compFileItem.toArray();

                  arrFileItems[this.indexFicheroUpload].uploadResponse.boolFinalize  = true;
                  arrFileItems[this.indexFicheroUpload].uploadResponse.boolSuccess   = false;
                  arrFileItems[this.indexFicheroUpload].uploadResponse.message       = 'No fue posible subir el documento. Error: ' + objResponse.body.message;
                  // arrFileItems[this.indexFicheroUpload].enableForm(true);

                  this.countQueueUpload++;
                  this.indexFicheroUpload++;
                  setTimeout(() => {
                    this.fnCotizacionFicheroStore();
                  }, 1000);
                }
              } else if(objResponse.status == 'progress') {
                this.compFileItem.toArray()[this.indexFicheroUpload].uploadResponse = objResponse;
              }
            }
          }, error => {

          });

      } else {
        let UploadFilesComplete = this.lstQueueUploadFiles.length;

        if(UploadFilesComplete == 0) {

          if(this.objCotizacion.idcotizacionEstatus != 0 && this.objCotizacion.idcotizacionEstatus != 1) {
            this.cotizacionFormsEnable();
            this.change();
            this.verPdf_clickEvent();
          } else {
            if( this.action) {
              this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: this.title, mensaje: this.message, autoCloseDelay: 3000}));
              this.cotizacionFormsEnable();
              this.change();
              this.close();
            } else {
              this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: this.title, mensaje: this.message}));
              this.cotizacionFormsEnable();
            }
          }
        } else {
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
          this.cotizacionFormsEnable();
        }

      }
    } else {
      this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Formulario incompleto", mensaje : "Complete los campos solicitados.", autoCloseDelay : 3000}));
      this.cotizacionFormsEnable();
    }
  }

  public verPdf_clickEvent() {
    let objDialog = this.objMatDialog.open(FileViewerComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      data: {
        extension : 'pdf',
        fileName : this.objCotizacion.folio + '.pdf',
        pdfSrc : this.objApplicationConfig.ENDPOINT + 'api/cotizaciones/pdf/' + this.objCotizacion.idCotizacion
      },
      backdropClass: 'backdropBackground',
      panelClass : 'visualizarPanelClass',
      disableClose: true
    });

    objDialog.componentInstance.onClose.subscribe( () => {
      this.close();
    });

    objDialog.componentInstance.onDownload.subscribe( () => {
      this.objCotizacionesService.documentPdf(this.objCotizacion.idCotizacion)
        .subscribe(objResponse => {
          saveAs(objResponse, this.objCotizacion.folio + '.pdf');
        }, error => {

        });
    });

    objDialog.componentInstance.onPrint.subscribe( () => {
      this.objCotizacionesService.documentPdf(this.objCotizacion.idCotizacion)
        .subscribe(objResponse => {
          let blob = new Blob([objResponse], {type: 'application/pdf'});
          let blobUrl = URL.createObjectURL(blob);
          const iframe = document.createElement('iframe');
          iframe.style.display = 'none';
          iframe.src = blobUrl;
          document.body.appendChild(iframe);
          if(iframe.contentWindow != null) {
            iframe.contentWindow.focus();
            iframe.contentWindow.print();
          }
        }, error => {

        });
    });
  }

  // Inicio Métodos Solicitud

  public txtUsuario_keyUpEvent( event : any) : void {
    let user : string = this.fgCotizacionesUpdateSolicitud.controls['solicitudUsuario'].value;

    if (event.keyCode == 13 && !this.boolAutoCompleteUsuario) {
      this.boolAutoCompleteUsuario = true;
      this.txtUsuarioSolicitud.loading = true;
      this.objCotizacionesService.findUsuarios(user)
        .subscribe( objResponse => {
          this.lstUsuarios = objResponse.result.usuarios;
          this.txtUsuarioSolicitud.loading = false;
        }, error => {
          this.txtUsuarioSolicitud.loading = false;
        });
    } else if (!(event.keyCode > 36 && event.keyCode < 41) && event.keyCode != 13) {
      this.txtUsuario_clearEvent();
    } else if (user == '') {
      this.txtUsuario_clearEvent();
    }
  }

  public txtUsuario_OnFocusOutEvent() : void {
    if(!this.boolAutoCompleteUsuario) {
      this.txtUsuario_clearEvent();
      this.fgCotizacionesUpdateSolicitud.controls['solicitudUsuario'].setValue("");
    } else if(this.txtUsuarioSolicitud.objValue == null) {
      this.fgCotizacionesUpdateSolicitud.controls['solicitudUsuario'].setValue("");
    }
  }

  public txtUsuario_clearEvent() : void {
    this.boolAutoCompleteUsuario = false;
    this.txtUsuarioSolicitud.objValue = null;
    this.lstUsuarios = [];
  }

  public getUsuarioSelected_selectedEvent(idUsuario: number) : void {
    let objUsuario = this.lstUsuarios.find(itemUsuario => itemUsuario.idUsuario == idUsuario);
    this.txtUsuarioSolicitud.objValue = objUsuario;
    this.fgCotizacionesUpdateSolicitud.controls['solicitudUsuario'].setValue(objUsuario.nombreCompleto);
  }

  public getEmpresaSelected_selectedEvent(idEmpresa: number) : void {
    this.fgCotizacionesUpdateSolicitud.controls['sucursal'].setValue('');
    this.lstSucursalesFiltered = this.lstSucursales.filter(itemSucursal => Globals.exist(itemSucursal.empresa) &&  itemSucursal.empresa.idEmpresa == idEmpresa).sort((a, b) => (a.sucursal < b.sucursal ? -1 : 1));
  }

  public txtClientes_keyUpEvent( event : any) : void {
    let strSearch : string = this.fgCotizacionesUpdateSolicitud.controls['clienteNombreComercial'].value;

    if (event.keyCode == 13 && !this.boolAutoCompleteCliente) {
      this.boolAutoCompleteCliente = true;
      this.txtCliente.loading = true;
      this.objCotizacionesService.findClientes(strSearch)
        .subscribe( objResponse => {
          this.lstClientes = objResponse.result.clientes;
          this.lstClientes.forEach( itemCliente => {
            let substring = itemCliente.nombreComercial.split(' ');
            itemCliente['avatarIcon'] = this.fnAvatarIcon_event(substring);
          });
          this.txtCliente.loading = false;
        }, error => {
          this.txtCliente.loading = false;
        });
    } else if (!(event.keyCode > 36 && event.keyCode < 41) && event.keyCode != 13) {
      this.txtCliente_clearEvent();
    } else if (strSearch == '') {
      this.txtCliente_clearEvent();
    }
  }

  public txtCliente_OnFocusOutEvent() : void {
    if (!this.boolAutoCompleteCliente) {
      this.txtCliente_clearEvent();
      this.fgCotizacionesUpdateSolicitud.controls['clienteNombreComercial'].setValue("");
    } else if (this.txtCliente.objValue == null) {
      this.fgCotizacionesUpdateSolicitud.controls['clienteNombreComercial'].setValue("");
    }
  }

  public txtCliente_clearEvent() : void {
    this.boolAutoCompleteCliente = false;
    this.txtCliente.objValue = null;
    this.txtClienteContacto.objValue = null;
    this.fgCotizacionesUpdateSolicitud.controls['contactoNombreCompleto'].setValue('');
    this.fgCotizacionesUpdateSolicitud.controls['contactoNombreCompleto'].disable();
    this.lstClientes = [];
    this.lstClientesContactos = [];
  }

  public txtClienteContactos_keyUpEvent( event : any) : void {
    this.txtClienteContacto.objValue = null;
  }

  public txtClienteContactos_OnFocusOutEvent() : void {
    if(this.txtClienteContacto.objValue == null) {
      this.fgCotizacionesUpdateSolicitud.controls['contactoNombreCompleto'].setValue("");
    }
  }

  public getClienteSelected_selectedEvent(idCliente: number) : void {
    this.fgCotizacionesUpdateSolicitud.controls['contactoNombreCompleto'].enable();
    let objCliente = this.lstClientes.find(itemCliente => itemCliente.idCliente == idCliente);
    this.txtCliente.objValue = objCliente;
    this.fgCotizacionesUpdateSolicitud.controls['clienteNombreComercial'].setValue(objCliente.nombreComercial);
    this.lstClientesContactos = objCliente.contactos;
    this.lstClientesContactosFiltered = this.fgCotizacionesUpdateSolicitud.controls['contactoNombreCompleto'].valueChanges.pipe(
      startWith(''),
      map(value => {
        const filterValue = value.toLowerCase();
        return this.lstClientesContactos.filter(itemClienteContacto => itemClienteContacto.nombreCompleto.toLowerCase().includes(filterValue));
      })
    );
  }

  public getClienteContactoSelected_selectedEvent(value: string) : void {
    let objClienteContacto = this.lstClientesContactos.find(itemClienteContacto => itemClienteContacto.nombreCompleto.includes(value));
    this.txtClienteContacto.objValue = objClienteContacto
    this.fgCotizacionesUpdateSolicitud.controls['contactoNombreCompleto'].setValue(objClienteContacto.nombreCompleto);
  }

  public objValueValidate(objValue : number, itemValue : string) : boolean {
    let objValidate : boolean = false;

    switch (objValue) {
      case 1:
        if(this.txtCliente.objValue[itemValue] != "") objValidate = true;
        break;
      case 2:
        if(this.txtClienteContacto.objValue[itemValue] != "") objValidate = true;
        break;
    }

    return objValidate;
  }

  public fnAvatarIcon_event(substring : string[]) : string {
    let avatarIcon : string = '';

    for(let i : number = 0; i<substring.length; i++) {
      if(i > 0)
        if ( substring[i] != 'de' && (i < 2)) avatarIcon += substring[i].charAt(0).toUpperCase();
      if (i == 0) avatarIcon += substring[i].charAt(0).toUpperCase();
    }

    return avatarIcon;
  }

  // Fin Métodos Solicitud

  // Inicio métodos de propuesta económica
  public chkCotizacionPartidaInitSelected_changeEvent() : void {
    this.boolChkCotizacionPartida = false;
    this.boolChkSomeCotizacionPartida = false;
  }

  public chkCotizacionesPartidaSelected_changeEvent( event : any ) : void {
    this.boolChkCotizacionPartida = event;
    if (this.valueConfiguracion) {
      this.lstCotizacionesPartidas.forEach((itemCotizacionPartida : any) => itemCotizacionPartida.checked = this.boolChkCotizacionPartida);
      this.boolChkSomeCotizacionPartida = this.lstCotizacionesPartidas.some((itemCotizacionPartida : any) => itemCotizacionPartida.checked);
    } else {
      this.compPartidaItem.toArray().forEach((itemCotizacionPartida: any) => itemCotizacionPartida.checked = this.boolChkCotizacionPartida);
      this.boolChkSomeCotizacionPartida = this.compPartidaItem.toArray().some((itemCotizacionPartida: any) => itemCotizacionPartida.checked);
    }
  }

  public chkItemCotizacionesPartidaSelected_changeEvent(event : any) : void {
    if (this.valueConfiguracion) {
      this.boolChkCotizacionPartida = this.lstCotizacionesPartidas.every( (itemCotizacionPartida : any) => itemCotizacionPartida.checked );
      this.boolChkSomeCotizacionPartida = this.lstCotizacionesPartidas.some( (itemCotizacionPartida : any) => itemCotizacionPartida.checked );
    } else {
      this.boolChkCotizacionPartida = this.compPartidaItem.toArray().every((itemCotizacionPartida: any) => itemCotizacionPartida.checked);
      this.boolChkSomeCotizacionPartida = this.compPartidaItem.toArray().some((itemCotizacionPartida: any) => itemCotizacionPartida.checked);
    }
  }

  public selectMoneda_selectedEvent(event : Event) : void {
    let idMoneda : number = Number((event.target as HTMLSelectElement).value);
    this.objMoneda = this.lstMonedas.find(itemMoneda => itemMoneda.idMoneda == idMoneda);

    if (this.valueConfiguracion) {
      this.lstCotizacionesPartidas = CotizacionPartidaCalc.calcularEdit(this.lstCotizacionesPartidas, this.objMoneda, this.equivalencia);
      this.calcCotizacionPartidaItem();
    }
  }

  public btnPartidaAppend_clickEvent() : void {
    if (this.valueConfiguracion) {
      let data = {
        monedas         : this.lstMonedas,
        servicios       : this.lstServicios,
        unidadesMedidas : this.lstUnidadesMedidas,
        impuestos       : this.lstImpuestos,
        equivalencia    : this.equivalencia,
        moneda          : this.objMoneda
      }
      let objDialog = this.objMatDialog.open(CotizacionesEditPartidaFormComponent, {
        width        : '98%',
        maxWidth     : '550px',
        minWidth     : '440px',
        height       : '96%',
        maxHeight    : '550px',
        minHeight    : '590px',
        data         : data,
        disableClose : true
      });

      objDialog.componentInstance.onAceptar.subscribe( ( itemCotizacionEditComponent: CotizacionesEditPartidaFormComponent) => {
        this.lstCotizacionesPartidas.push(itemCotizacionEditComponent.getCotizacionPartida());
        this.calcCotizacionPartidaItem();
      });
    } else {
      this.lstCotizacionesPartidas.push({});
    }
  }

  public btnPartidaDelete_clickEvent() : void {
    if (this.valueConfiguracion) {
      this.fnCotizacionPartidaStaticDelete_functionEvent();
    } else {
      this.fnCotizacionPartidaCompDelete_functionEvent();
    }
  }

  public fnCotizacionPartidaCompDelete_functionEvent() : void {
    let arrIndex: Array<any> = [];
    this.compPartidaItem.toArray().forEach(itemCotizacionPartida => {
      if (itemCotizacionPartida.checked) {
        if (itemCotizacionPartida.idCotizacionPartida > 0) this.lstCotizacionesPartidasDelete.push(this.lstCotizacionesPartidas[itemCotizacionPartida.index].idCotizacionPartida);
        arrIndex.push(itemCotizacionPartida.index);
      }
    });
    const reversed = arrIndex.reverse();
    reversed.forEach(index => {
      this.lstCotizacionesPartidas.splice(index, 1);
    });
    this.chkCotizacionPartidaInitSelected_changeEvent();
  }

  public fnCotizacionPartidaStaticDelete_functionEvent() : void {
    this.lstCotizacionesPartidas.reverse().forEach( (itemCotizacionPartida, index) => {
      if( itemCotizacionPartida.checked ) {
        this.importe -= Number(itemCotizacionPartida.precioXCantidad);
        this.descuento -= Number(itemCotizacionPartida.descuentoCantidad);
        this.subtotal -= Number(itemCotizacionPartida.importe);
        this.total = this.subtotal;
        for (let index: number = 0; index < this.lstImpuestos.length; index++) {
          if (itemCotizacionPartida.impuestos[index].checked) {
            this.lstImpuestos[index].total -= Number(itemCotizacionPartida.impuestos[index].total);
          }
          if (itemCotizacionPartida.impuestos[index].impuestoTipo.idImpuestoTipo == 1) {
            this.total += this.lstImpuestos[index].total;
          } else {
            this.total -= this.lstImpuestos[index].total;
          }
        }

        if(Globals.exist(itemCotizacionPartida.idCotizacionPartida) && itemCotizacionPartida.idCotizacionPartida > 0) {
          this.lstCotizacionesPartidasDelete.push(itemCotizacionPartida.idCotizacionPartida);
        }

        this.lstCotizacionesPartidas.splice(index, 1);
      }
    });
    this.chkCotizacionPartidaInitSelected_changeEvent();
  }

  public calcTableWidth() : number {
    let totalWidth : number = 0;
    totalWidth += 40; // Checkbox
    totalWidth += 50; // Acciones
    totalWidth += 90; // Servicio
    totalWidth += 250; // Descripción
    totalWidth += 50; // Unidad Medida
    totalWidth += 80; // Precio Unitario
    totalWidth += 70; // Moneda
    totalWidth += 50; // Cantidad
    totalWidth += 100; // Descuento Cantidad
    totalWidth += 60; // Descuento Porcentaje
    totalWidth += 40 * this.lstImpuestos.length; // Impuestos
    totalWidth += 110; // Importe

    return totalWidth;
  }

  public btnPartidasDelete_onDeleteEvent(itemCotizacionPartida : CotizacionesEditPartidaItemComponent) : void {
    this.importe   -= itemCotizacionPartida.precioXCantidad;
    this.descuento -= itemCotizacionPartida.descuentoCantidad;
    this.subtotal  -= itemCotizacionPartida.importe;
    this.total      = this.subtotal;
    for( let index : number = 0; index < this.lstImpuestos.length; index++) {
      if(itemCotizacionPartida.lstInnerImpuestos[index].checked) {
        this.lstImpuestos[index].total -= Number(itemCotizacionPartida.lstInnerImpuestos[index].total);
      }
      if(itemCotizacionPartida.lstInnerImpuestos[index].impuestoTipo.idImpuestoTipo == 1) {
        this.total += this.lstImpuestos[index].total;
      } else {
        this.total -= this.lstImpuestos[index].total;
      }
    }

    if(Globals.exist(this.lstCotizacionesPartidas[itemCotizacionPartida.index].idCotizacionPartida)) {
      this.lstCotizacionesPartidasDelete.push(this.lstCotizacionesPartidas[itemCotizacionPartida.index].idCotizacionPartida);
    }

    this.lstCotizacionesPartidas.splice(itemCotizacionPartida.index, 1);
  }

  public btnPartidaEdit_clickEvent(index : number) : void {
      let data = {
        itemCotizacionPartida : this.lstCotizacionesPartidas[index],
        monedas         : this.lstMonedas,
        servicios       : this.lstServicios,
        unidadesMedidas : this.lstUnidadesMedidas,
        impuestos       : this.lstImpuestos,
        equivalencia    : this.equivalencia,
        moneda          : this.objMoneda
      }
      let objDialog = this.objMatDialog.open(CotizacionesEditPartidaFormComponent, {
        width         : '98%',
        maxWidth      : '550px',
        minWidth      : '440px',
        height        : '96%',
        maxHeight     : '550px',
        minHeight     : '590px',
        data          : data,
        disableClose  : true
      });

      objDialog.componentInstance.onAceptar.subscribe( ( itemCotizacionEditComponent: CotizacionesEditPartidaFormComponent) => {
        this.lstCotizacionesPartidas[index] = itemCotizacionEditComponent.getCotizacionPartida();
        this.calcCotizacionPartidaItem();
      });
  }

  public btnPartidasStaticDelete_onDeleteEvent(index : number) : void {
    this.importe   -= Number(this.lstCotizacionesPartidas[index].precioXCantidad);
    this.descuento -= this.lstCotizacionesPartidas[index].descuentoCantidad;
    this.subtotal  -= this.lstCotizacionesPartidas[index].importe;
    this.total      = this.subtotal;
    for( let j : number = 0; j < this.lstImpuestos.length; j++) {
      if(this.lstCotizacionesPartidas[index].impuestos[j].checked) {
        this.lstImpuestos[j].total -= Number(this.lstCotizacionesPartidas[index].impuestos[j].total);
      }
      if(this.lstCotizacionesPartidas[index].impuestos[j].impuestoTipo.idImpuestoTipo == 1) {
        this.total += this.lstImpuestos[j].total;
      } else {
        this.total -= this.lstImpuestos[j].total;
      }
    }

    if(Globals.exist(this.lstCotizacionesPartidas[index].idCotizacionPartida) && this.lstCotizacionesPartidas[index].idCotizacionPartida > 0) {
      this.lstCotizacionesPartidasDelete.push(this.lstCotizacionesPartidas[index].idCotizacionPartida);
    }

    this.lstCotizacionesPartidas.splice(index, 1);
  }

  public compCotizacionPartidaItem_changeEvent() : void {
    setTimeout(() => {
      this.importe = 0;
      this.descuento = 0;
      this.subtotal = 0;
      this.total = 0;
      for (let index: number = 0; index < this.lstImpuestos.length; index++) {
        this.lstImpuestos[index].total = 0;
      }

      let lstCotizacionesPartidas: Array<any> = this.compPartidaItem?.toArray();
      if (Globals.exist(lstCotizacionesPartidas)) {
        lstCotizacionesPartidas.forEach(itemCotizacionPartida => {
          let total : number = 0;
          this.importe += itemCotizacionPartida.precioXCantidad;
          this.descuento += itemCotizacionPartida.descuentoCantidad;
          this.subtotal += itemCotizacionPartida.importe;
          this.total = this.subtotal;
          for (let index: number = 0; index < this.lstImpuestos.length; index++) {
            if (itemCotizacionPartida.lstInnerImpuestos[index].checked) {
              this.lstImpuestos[index].total += Number(itemCotizacionPartida.lstInnerImpuestos[index].total);
            }
            if(itemCotizacionPartida.lstInnerImpuestos[index].impuestoTipo.idImpuestoTipo == 1) {
              this.total += this.lstImpuestos[index].total;
            } else {
              this.total -= this.lstImpuestos[index].total;
            }
          }
        });
      }
    });
  }

  public calcCotizacionPartidaItem() : void {
    this.importe = 0;
    this.descuento = 0;
    this.subtotal = 0;
    this.total = 0;

    for (let index: number = 0; index < this.lstImpuestos.length; index++) {
      this.lstImpuestos[index].total = 0;
    }

    let lstCotizacionesPartidas: Array<any> = this.compPartidaItem?.toArray();
    if (Globals.exist(lstCotizacionesPartidas)) {
      this.lstCotizacionesPartidas.forEach(itemCotizacionPartida => {
        this.importe += Number(itemCotizacionPartida.precioXCantidad);
        this.descuento += Number(itemCotizacionPartida.descuentoCantidad);
        this.subtotal += Number(itemCotizacionPartida.importe);
        this.total = this.subtotal;
        for (let index: number = 0; index < this.lstImpuestos.length; index++) {
          if (itemCotizacionPartida.impuestos[index].checked) {
            this.lstImpuestos[index].total += Number(itemCotizacionPartida.impuestos[index].total);
          } else {
            itemCotizacionPartida.impuestos[index].total = Number(0).toFixed(2);
          }
          if(itemCotizacionPartida.impuestos[index].impuestoTipo.idImpuestoTipo == 1) {
            this.total += this.lstImpuestos[index].total;
          } else {
            this.total -= this.lstImpuestos[index].total;
          }
        }
      });
    }
  }

  // Fin métodos de propuesta económica

  // Inicio métodos de fichero adjunto

  public compCotizacionFicheroFileItem_deleteEvent(target : CotizacionesEditFileItemComponent ) : void {
    this.compZoneUploader.files.splice(target.index, 1);
  }

  public compCotizacionFicheroFileItem_changeEvent(event : any) {

  }

  public btnCotizacionFicheroEliminarSeleccionados_clickEvent() : void {
    let arrIndex : Array<any> = [];
    this.lstCotizacionesFicheros.forEach( itemCotizacionFichero => {
      if( itemCotizacionFichero.checked ) {
        if(itemCotizacionFichero.idCotizacionFichero > 0) this.lstCotizacionesFicherosDelete.push(this.lstCotizacionesFicheros[itemCotizacionFichero.index].idCotizacionFichero);
        arrIndex.push(itemCotizacionFichero.index);
      }
    });
    const reversed = arrIndex.reverse();
    reversed.forEach( index => {
      this.lstCotizacionesFicheros.splice( index, 1);
    });
    this.chkCotizacionFicheroInitSelected_changeEvent();
  }

  public chkCotizacionFicheroInitSelected_changeEvent() : void {
    this.boolChkCotizacionFichero = false;
    this.boolChkSomeCotizacionFichero = false;
  }

  public chkCotizacionesFicheroSelected_changeEvent( event : any ) : void {
    this.boolChkCotizacionFichero = event;
    this.lstCotizacionesFicheros.forEach((itemCotizacionFichero : any) => itemCotizacionFichero.checked = this.boolChkCotizacionFichero);
    this.boolChkSomeCotizacionFichero = this.lstCotizacionesFicheros.some((itemCotizacionFichero : any) => itemCotizacionFichero.checked);
  }

  public chkItemCotizacionesFicheroSelected_changeEvent(event : any) : void {
    this.boolChkCotizacionFichero = this.lstCotizacionesFicheros.every( (itemCotizacionFichero : any) => itemCotizacionFichero.checked );
    this.boolChkSomeCotizacionFichero = this.lstCotizacionesFicheros.some( (itemCotizacionFichero : any) => itemCotizacionFichero.checked );
  }

  public btnCotizacionFicheroDelete_clickEvent(itemCotizacionFichero : any, index : number) : void {
    if(Globals.exist(itemCotizacionFichero.idCotizacionFichero)) {
      this.lstCotizacionesFicherosDelete.push(itemCotizacionFichero.idCotizacionFichero);
    }
    this.lstCotizacionesFicheros.splice(index, 1);
  }

  public txtCotizacionTipo_keyUpEvent( event : any) : void {
    let strSearch : string = this.fcCotizacionTipo.value;

    if(event.keyCode == 13 && !this.boolAutoCompleteCotizacionTipo) {
      this.boolAutoCompleteCotizacionTipo = true;
      this.txtCotizacionTipo.loading = true;
      this.objCotizacionesService.findContizacionTipo(strSearch)
        .subscribe( objResponse => {
          this.lstCotizacionesTipos = objResponse.result.cotizacionesTipos;
          this.lstCotizacionesTipos.forEach( itemCotizacionTipo => {
            let substring = itemCotizacionTipo.cotizacionTipo.split(' ');
            itemCotizacionTipo['avatarIcon'] = this.fnAvatarIcon_event(substring);
          });
          this.txtCotizacionTipo.loading = false;
        }, error => {
          this.txtCotizacionTipo.loading = false;
        });
    } else if(!(event.keyCode > 36 && event.keyCode < 41) && event.keyCode != 13) {
      this.txtCotizacionTipo_clearEvent();
    } else if(strSearch == '') {
      this.txtCotizacionTipo_clearEvent();
    }
  }

  public txtCotizacionTipo_OnFocusOutEvent() : void {
    if(!this.boolAutoCompleteCotizacionTipo) {
      this.txtCotizacionTipo_clearEvent();
      this.fcCotizacionTipo.setValue("");
    } else if(this.txtCotizacionTipo.objValue == null) {
      this.fcCotizacionTipo.setValue("");
    }
  }

  public txtCotizacionTipo_clearEvent() : void {
    this.boolAutoCompleteCotizacionTipo = false;
    this.txtCotizacionTipo.objValue = null;
    this.lstCotizacionesTipos = [];

    let arrIndex : Array<any> = [];
    this.lstCotizacionesCondicionesSorted.forEach( (itemCotizacionCondicion, index) => {
      if (Globals.exist(itemCotizacionCondicion?.idCotizacionCondicion) && itemCotizacionCondicion.idCotizacionCondicion != 0) {
        arrIndex.push(index);
      }
    });
    const reversed = arrIndex.reverse();
    reversed.forEach( index => {
      this.lstCotizacionesCondicionesSorted.splice( index, 1);
    });
  }

  public getCotizacionTipo_selectedEvent(idCotizacionTipo: number) : void {
    let objCotizacionTipo = this.lstCotizacionesTipos.find(itemCotizacionTipo => itemCotizacionTipo.idCotizacionTipo == idCotizacionTipo);
    this.txtCotizacionTipo.objValue = objCotizacionTipo;
    this.fcCotizacionTipo.setValue(objCotizacionTipo.cotizacionTipo);

    if (this.lstCotizacionesCondicionesSorted.length > 0) {
      let lstCotizacionesCondiciones : Array<any> = this.lstCotizacionesCondicionesSorted;
      this.lstCotizacionesCondicionesSorted = (objCotizacionTipo.cotizacionesCondiciones as Array<any>).concat(lstCotizacionesCondiciones);
    } else {
      this.lstCotizacionesCondicionesSorted = objCotizacionTipo.cotizacionesCondiciones;
    }

    this.lstCotizacionesCondicionesSorted.forEach( itemCotizacionCondicion => {
      itemCotizacionCondicion['checked'] = true;
    });
  }

  public btnAddCotizacionesCondiciones_clickEvent() : void {
    let objCotizacionCondicion : cotizacionCondicion = {
      checked               : true,
      idCotizacionCondicion : 0,
      cotizacionCondicion   : '',
      undo : false,
      redo : false
    }

    this.lstCotizacionesCondicionesSorted.push(objCotizacionCondicion);
  }

  public btnDeleteCotizacionesCondiciones_clickEvent() : void {
    let arrIndex : Array<any> = [];
    this.lstCotizacionesCondicionesSorted.forEach( (itemCotizacionCondicion, index) => {
      if( itemCotizacionCondicion.checked && (!Globals.exist(itemCotizacionCondicion?.idCotizacionCondicion) || itemCotizacionCondicion.idCotizacionCondicion == 0)) {
        arrIndex.push(index);
      }
    });
    const reversed = arrIndex.reverse();
    reversed.forEach( index => {
      this.lstCotizacionesCondicionesSorted.splice( index, 1);
    });
  }

  public btnItemDeleteCotizacionesCondiciones_clickEvent(index : number) : void {
    this.lstCotizacionesCondicionesSorted.splice( index, 1);
  }

  public btnItemUndoCotizacionesCondiciones_clickEvent(index : number) : void {
    let objCotizacionCondicion = this.lstCondiciones.find( itemCondicion => itemCondicion.idCotizacionCondicion === this.lstCotizacionesCondicionesSorted[index].idCotizacionCondicion );

    if (objCotizacionCondicion) {
      objCotizacionCondicion.redo = true;
      objCotizacionCondicion.undo = false;
      objCotizacionCondicion.checked = this.lstCotizacionesCondicionesSorted[index].checked;
      this.lstCotizacionesCondicionesSorted[index] = objCotizacionCondicion;
    }
  }

  public btnItemRedoCotizacionesCondiciones_clickEvent(index : number) : void {
    let objCotizacionCondicion = this.lstCotizacionesCondiciones.find( itemCondicion => itemCondicion.idCotizacionCondicion === this.lstCotizacionesCondicionesSorted[index].idCotizacionCondicion );

    if (objCotizacionCondicion) {
      objCotizacionCondicion.redo = false;
      objCotizacionCondicion.undo = true;
      objCotizacionCondicion.checked = this.lstCotizacionesCondicionesSorted[index].checked;
      this.lstCotizacionesCondicionesSorted[index] = objCotizacionCondicion;
    }
  }

  public textareat(event: KeyboardEvent, index : number) : void {
    this.textareaCondicion.toArray()[index].nativeElement.style.height = `25px`;
    this.textareaCondicion.toArray()[index].nativeElement.style.height = `${(event.target as HTMLSelectElement).scrollHeight + 2}px`;
  }

  public cotizacionFormsDisable() : void {
    FormOptimizer.formDisable(this.formComponents);
    this.compPartidaItem.toArray().forEach( itemPartida => itemPartida.disableFormPartida());
  }

  public cotizacionFormsEnable() : void {
    FormOptimizer.formEnable(this.formComponents);
    this.compPartidaItem.toArray().forEach( itemPartida => itemPartida.enableFormPartida());
  }

  public change(): void {
    this.onChange.emit(this);
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }
}
