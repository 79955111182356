import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numeroOrdinal'
})
export class NumeroOrdinalPipe implements PipeTransform {

  transform(numero: number): any {
    if ( numero === 1 || numero === 3) return numero + 'ro';
    else if ( numero === 2 ) return numero + 'do';
    else if ( numero >= 4 && numero <= 6 ) return numero + 'to';
    else if ( numero === 9 || numero === 19) return numero + 'no';
    else if ( numero === 7 || numero === 10) return numero + 'mo';


    return numero + 'vo';
  }
}
