import { FormControl, FormGroup } from '@angular/forms';
import { Globals } from './globals';
import {isMoment} from "moment";
import * as moment from "moment/moment";
import {isNumeric} from "rxjs/internal-compatibility";


export  class FormOptimizer {

  public static formDataString( param : any ) : string {
    let objReturn : string = "";

    if(Globals.exist(param) && Globals.exist(param.value) ) {
      objReturn = param.value;
    } else if ( typeof param === 'string') {
      objReturn = param;
    }

    return objReturn;
  }

  public static formDataBoolean( param : any ) : string {
    let boolResult : boolean = false;
    let objReturn : string = 'false';

    if(Globals.exist(param) && Globals.exist(param.value) ) {
      boolResult = param.value;
    } else if (typeof param === 'boolean' && param) {
      boolResult = param;
    }

    if( boolResult ) {
      objReturn = 'true';
    }



    return objReturn;
  }

  public static formDataNumber( param : any ) : string {
    let numericValue : number = 0;
    let objReturn : string = '0';
    if( param instanceof FormControl ) {
      if(Globals.exist(param) && Globals.exist(param.value) && Globals.isNumeric(param.value) ) {
        numericValue = param.value;
        objReturn = numericValue.toString();
      }
    } else if( Globals.isNumeric(param) ) {
      numericValue = param;
      objReturn = numericValue.toString();
    }
    return objReturn;
  }

  public static formDataDecimal( param : any ) : string {
    let numericValue : number = 0.00;
    let objReturn : string = '0.00';
    if( param instanceof FormControl ) {
      if(Globals.exist(param) && Globals.exist(param.value) && Globals.isNumeric(param.value) ) {
        numericValue = param.value;
        objReturn = Number(numericValue).toFixed(2);
      }
    } else if( Globals.isNumeric(param) ) {
      numericValue = param;
      objReturn = Number(numericValue).toFixed(2);
    }
    return objReturn;
  }

  public static formDataDate(param : any, format: string = 'YYYY-MM-DD') : string {
    let objReturn : string = '';
    if(Globals.exist(param) && Globals.exist(param.value) && isMoment(param.value) && param.value.isValid() ) {
      objReturn = param.value.format(format);
    } else if(Globals.exist(param) && isMoment(param) && param.isValid() ) {
      objReturn = param.format(format);
    }

    return objReturn;
  }

  public static formDataTime(param : any) : string {
    let objReturn : string = '';
    if(Globals.exist(param) && Globals.exist(param.value) ) {
      objReturn = moment(param.value, "HH:mm").format("HH:mm");
    } else if(Globals.exist(param) ) {
      objReturn = moment(param, "HH:mm").format("HH:mm");
    }

    return objReturn;
  }

  public static formDuration(duration : number) : string {
    let objReturn : string = '00:00:00';

    if (Globals.exist(duration) && isNumeric(duration)) {
      const time = moment.duration(duration, 'seconds');

      let hours = time.hours().toString().padStart(2, '0');
      let minutes = time.minutes().toString().padStart(2, '0');
      let seconds = time.seconds().toString().padStart(2, '0');

      objReturn = `${hours}:${minutes}:${seconds}`;
    }

    return objReturn;
  }

  public static ( param : any ) : string {
    let numericValue : number = 0;
    let objReturn = '0';

    if(Globals.exist(param) && Globals.exist(param.value) ) {
      numericValue = param.value;
      objReturn = numericValue.toString();
    }
    return objReturn;
  }

  public static formDisable( lstComponents : Array<any> ) {
    lstComponents.forEach(itemComponent => {
      if(Globals.exist( itemComponent )) {
        if( itemComponent instanceof FormGroup ) {
          for(let key in itemComponent.controls) {
            itemComponent.controls[key].disable();
          }
        } else if( itemComponent instanceof FormControl ) {
            itemComponent.disable();
        } else if( itemComponent.type == 'button' || itemComponent.type == 'input' || itemComponent.type == 'componentForm' || itemComponent.type == 'select' || itemComponent.type == 'checkbox') {
          if( Globals.exist(itemComponent.disabled) ) {
            itemComponent.disabled = true;
          }
        } else if(itemComponent.type == 'loader') {
          if( Globals.exist(itemComponent.visible) ) {
            itemComponent.visible = true;
          }
        }
      }
    });
  }

  public static formEnable( lstComponents : Array<any> ) {
    lstComponents.forEach(itemComponent => {
      if(Globals.exist( itemComponent )) {
        if( itemComponent instanceof FormGroup ) {
          for(let key in itemComponent.controls) {
            itemComponent.controls[key].enable();
          }
        } else if( itemComponent instanceof FormControl ) {
          itemComponent.enable();
        } else if( itemComponent.type == 'button' || itemComponent.type == 'input' || itemComponent.type == 'componentForm' || itemComponent.type == 'select' || itemComponent.type == 'checkbox') {
          if( Globals.exist(itemComponent.disabled) ) {
            itemComponent.disabled = false;
          }
        } else if(itemComponent.type == 'loader') {
          if( Globals.exist(itemComponent.visible) ) {
            itemComponent.visible = false;
          }
        }
      }
    });
  }





}
