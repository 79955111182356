import { AfterViewInit, Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef }               from "@angular/material/dialog";
import * as moment                                                from "moment";
import { isMoment }                                               from "moment";

import { AlertComponent }                                          from "../../../../../components/alert/alert.component";
import { ConfirmComponent }                                        from "../../../../../components/confirm/confirm.component";
import { FileViewerComponent }                                     from "../../../../../components/file-viewer/file-viewer.component";
import { ApplicationConfig }                                       from "../../../../../libraries/application-config";
import { FormOptimizer }                                           from "../../../../../libraries/formOptimizer";
import { Globals }                                                 from "../../../../../libraries/globals";
import { AuthService }                                             from "../../../../../services/auth.service";
import { GerenciamientosService }                                  from "../../../../../services/gerenciamientos/gerenciamientos.service";
import { OrdenesServiciosService }                                 from "../../../../../services/ordenes-servicios.service";
import { UsuariosService }                                         from "../../../../../services/usuarios.service";
import { OrdenesServiciosGerenciamientosCreateComponent }          from "../create/create.component";
import { OrdenesServiciosGerenciamientosUbicacionesEditComponent } from "../edit/edit.component";

@Component({
  selector: 'app-ordenes-servicios-gerenciamientos-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class OrdenesServiciosGerenciamientosIndexComponent implements AfterViewInit {

  @Output ('onClose')  onClose  : EventEmitter<OrdenesServiciosGerenciamientosIndexComponent> = new EventEmitter<OrdenesServiciosGerenciamientosIndexComponent>();
  @Output ('onChange') onChange : EventEmitter<OrdenesServiciosGerenciamientosIndexComponent> = new EventEmitter<OrdenesServiciosGerenciamientosIndexComponent>();

  public objApplicationConfig        = ApplicationConfig;
  public Globals                     = Globals;
  public event                       : any = {}
  public today                       : string = '';
  public hours                       : string = '';
  public indexSelected               : number  = 0;
  public objOrdenPedido              : any = {};
  public objOrdenServicio            : any = {};
  public objPrivilegios              : any = {};
  public lstUnidades                 : Array<any> = [];
  public lstGerenciamientosTipos     : Array<any> = [];
  public lstGerenciamientosLogsTipos : Array<any> = [];
  public lstGerenciamientosPSLSegmentos : Array<any> = [];
  public isGerenciamientosPSL        : boolean = false;

  public btnAccion = {
    type            : 'button',
    disabled        : false
  };

  public objLoader = {
    type      : 'loader',
    visible   : false
  };

  public btnAceptar = {
    type      : 'button',
    disabled  : false
  };

  public btnCancelar = {
    type      : 'button',
    disabled  : false
  };

  public formComponents: Array<any> = [
    this.objLoader,
    this.btnAccion,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(private objDialogRef: MatDialogRef<OrdenesServiciosGerenciamientosIndexComponent>,
              private objMatDialog: MatDialog,
              private objGerenciamientosService  : GerenciamientosService,
              private objOrdenesServiciosService : OrdenesServiciosService,
              private objUsuariosService         : UsuariosService,
              private objAuthService : AuthService,
              @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.objPrivilegios = objAuthService.privilegios;
  }

  ngAfterViewInit() : void {
    setTimeout( ()=> this.init(), 200 );
  }

  public init() : void {
    this.lstUnidades = [];
    FormOptimizer.formDisable(this.formComponents);
    this.objGerenciamientosService.index(this.data.idOrdenServicio)
      .subscribe(objResponse => {
        if (Globals.exist(objResponse?.action) && objResponse.action) {
          if (Globals.exist(objResponse?.result)) {
            if (Globals.exist(objResponse.result?.ordenPedido)) {
              this.objOrdenPedido = objResponse.result.ordenPedido;
            }

            if (Globals.exist(objResponse.result?.ordenServicio)) {
              this.objOrdenServicio = objResponse.result.ordenServicio;
            }

            if (Globals.exist(objResponse.result?.today)) {
              this.today = objResponse.result.today;
            }

            if (Globals.exist(objResponse.result?.hours)) {
              this.hours = objResponse.result.hours;
            }

            if (Globals.exist(objResponse.result?.gerenciamientosLogsTipos)) {
              this.lstGerenciamientosLogsTipos = objResponse.result.gerenciamientosLogsTipos;
            }

            if (Globals.exist(objResponse.result?.gerenciamientosPSLSegmento)) {
              this.lstGerenciamientosPSLSegmentos = objResponse.result.gerenciamientosPSLSegmento;
            }

            if (Globals.exist(objResponse.result?.isGerenciamientosPSL)) {
              this.isGerenciamientosPSL = objResponse.result.isGerenciamientosPSL;
            }

            if (Globals.exist(objResponse.result?.unidades)) {
              let unidades : Array<any> = objResponse.result.unidades as Array<any>;
              unidades.forEach( itemUnidad => {
                if (Globals.exist(itemUnidad?.hasPuntosVerificacion) && itemUnidad?.hasPuntosVerificacion) {
                  if(this.isJSON(itemUnidad.json)) {
                    itemUnidad['gerenciamientos'] = this.gerenciamientosInit( JSON.parse(itemUnidad.json) );
                  } else {
                    itemUnidad['gerenciamientos'] = [
                      {folio: "N/A", idGerenciamientoTrayecto: 1, enable: true , trayectoria: "Ida"},
                      {folio: "N/A", idGerenciamientoTrayecto: 2, enable: false, trayectoria: "Regreso"}
                    ];
                  }
                  itemUnidad['checked']         = true;
                  itemUnidad['hasChange']       = false;
                } else {
                  itemUnidad['idGerenciamientoTipo'] = '';
                  itemUnidad['gerenciamientosTipoValido'] = true;
                  itemUnidad['idGerenciamientoPSLSegmento'] = '';
                  itemUnidad['isViajeNecesario']     = '';
                  itemUnidad['hasReunionPrevia']     = '';
                  itemUnidad['ticket']               = '';
                  itemUnidad['autoriza']             = '';
                  itemUnidad['autorizaSalida']       = '';
                  itemUnidad['checkPoint']           = (Globals.exist(objResponse.result?.check_point))? objResponse.result?.check_point: '';
                  itemUnidad['puntosVerificaciones'] = [];
                  itemUnidad['salidaOrigenFecha'] = moment(this.today, "DD-MM-YYYY");
                  itemUnidad['salidaOrigenHora'] = this.hours;
                  itemUnidad['salidaDestinoFecha'] = moment(this.today, "DD-MM-YYYY");
                  itemUnidad['salidaDestinoHora'] = '';
                }
                itemUnidad['checkPointDisabled']   = false;
                itemUnidad['selectValidate']       = false;
                itemUnidad['onChance']             = false;
                if (Globals.exist(objResponse.result?.check_point)) {
                  this.calcCheckPoint(itemUnidad);
                }

                this.lstUnidades.push(itemUnidad);
              });
            }

            if (this.lstUnidades.length > 0) {
              this.lstUnidades.forEach( (itemUnidad, index) => {
                itemUnidad['active'] = (index == this.indexSelected);
              });
            }

            if (Globals.exist(objResponse.result?.gerenciamientosTipos)) {
              this.lstGerenciamientosTipos = objResponse.result.gerenciamientosTipos;
            }
          }
        }
        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        FormOptimizer.formEnable(this.formComponents);

      });
  }

  public gerenciamientosInit(lstGerenciamientos : Array<any>) : Array<any> {
    if (lstGerenciamientos.length > 1) {
      return lstGerenciamientos;
    } else {
      let lstGerenciamientosReturn : Array<any> = [];
      let gerenciamiento = lstGerenciamientos[0];
      if ( gerenciamiento?.idGerenciamientoTrayectoria === 1) {
        lstGerenciamientosReturn.push(gerenciamiento);
        lstGerenciamientosReturn.push({folio: "N/A", idGerenciamientoTrayecto: 2, trayectoria: "Regreso"});
      } else {
        lstGerenciamientosReturn.push({folio: "N/A", idGerenciamientoTrayecto: 1, trayectoria: "Ida"});
        lstGerenciamientosReturn.push(gerenciamiento);
      }
          return lstGerenciamientosReturn;
    }
  }

  public formOrdenServicioCreate_submitEvent(itemUnidad : any): void {
    if (this.validate( itemUnidad )) {
      if ( (itemUnidad?.checked && !Globals.exist(itemUnidad?.gerenciamientos)) || (Globals.exist(itemUnidad?.gerenciamientos) && Globals.exist(itemUnidad?.hasChange) && itemUnidad.hasChange)) {
        let fdUsuarioStore = new FormData();

        let indexUnidades = 0;
        fdUsuarioStore.append("idOrdenServicioMiembro[" + indexUnidades + "]"      , FormOptimizer.formDataNumber(itemUnidad.idOrdenServicioMiembro));
        fdUsuarioStore.append("idUnidad[" + indexUnidades + "]"                    , FormOptimizer.formDataNumber(itemUnidad.idUnidad));
        fdUsuarioStore.append("checkPoint[" + indexUnidades + "]"                  , FormOptimizer.formDataTime(itemUnidad?.checkPoint));
        fdUsuarioStore.append("ticket[" + indexUnidades + "]"                      , itemUnidad?.ticket);
        fdUsuarioStore.append("autoriza[" + indexUnidades + "]"                    , FormOptimizer.formDataString(itemUnidad?.autoriza));
        fdUsuarioStore.append("autorizaSalida[" + indexUnidades + "]"              , FormOptimizer.formDataString(itemUnidad?.autorizaSalida));
        fdUsuarioStore.append("isViajeNecesario[" + indexUnidades + "]"            , FormOptimizer.formDataBoolean(itemUnidad?.isViajeNecesario));
        fdUsuarioStore.append("hasReunionPrevia[" + indexUnidades + "]"            , FormOptimizer.formDataBoolean(itemUnidad?.hasReunionPrevia));
        fdUsuarioStore.append("idGerenciamientoTipo[" + indexUnidades + "]"        , FormOptimizer.formDataNumber(itemUnidad?.idGerenciamientoTipo));
        fdUsuarioStore.append("idGerenciamientoPSLSegmento[" + indexUnidades + "]" , FormOptimizer.formDataNumber(itemUnidad?.idGerenciamientoPSLSegmento));
        fdUsuarioStore.append("origen[" + indexUnidades + "]"                      , this.objOrdenServicio?.direccionOrigen);
        fdUsuarioStore.append("salidaOrigenFecha[" + indexUnidades + "]"           , FormOptimizer.formDataDate(itemUnidad?.salidaOrigenFecha));
        fdUsuarioStore.append("salidaOrigenHora[" + indexUnidades + "]"            , FormOptimizer.formDataTime(itemUnidad?.salidaOrigenHora));
        fdUsuarioStore.append("destino[" + indexUnidades + "]"                     , this.objOrdenServicio?.direccionDestino);
        fdUsuarioStore.append("salidaDestinoFecha[" + indexUnidades + "]"          , FormOptimizer.formDataDate(itemUnidad?.salidaDestinoFecha));
        fdUsuarioStore.append("salidaDestinoHora[" + indexUnidades + "]"           , FormOptimizer.formDataTime(itemUnidad?.salidaDestinoHora));
        fdUsuarioStore.append("puntoVerificacionOdometro[" + indexUnidades + "]"   , FormOptimizer.formDataNumber(itemUnidad?.salidaOrigenOdometro));
        fdUsuarioStore.append("puntoVerificacionHorometro[" + indexUnidades + "]"  , FormOptimizer.formDataNumber(itemUnidad?.salidaOrigenHorometro));

        itemUnidad.puntosVerificaciones.forEach((itemUbicaciones : any, index : number) => {
          fdUsuarioStore.append("ubicacion[" + indexUnidades + "][" + index + "]", itemUbicaciones.gerenciamientoPuntoVerificacion);
          fdUsuarioStore.append("fecha[" + indexUnidades + "][" + index + "]", FormOptimizer.formDataDate(itemUbicaciones.puntoVerificacionFechaIso));
          fdUsuarioStore.append("hora[" + indexUnidades + "][" + index + "]", itemUbicaciones.puntoVerificacionHoraIso);
        });
        indexUnidades++;

        FormOptimizer.formDisable(this.formComponents);
        this.objGerenciamientosService.manage(fdUsuarioStore).subscribe(objResponse => {
          if (Globals.exist(objResponse?.action) && objResponse.action) {
            this.change();
            this.init();
          } else {
            this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: 'Error de comunicación', mensaje: 'Ocurrió un error de comunicación con el servidor.', autoCloseDelay: 3000}));
            FormOptimizer.formEnable(this.formComponents);
          }
        }, error => {
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: "Error de comunicación", mensaje: "Ocurrió un error de comunicación con el servidor.", autoCloseDelay: 3000}));
          FormOptimizer.formEnable(this.formComponents);
        });
      }
    } else {
      this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo : "Error de formulario", mensaje : "Complete los campos solicitados.", autoCloseDelay : 3000}));
    }
  }

  public btnAddCotizacionesCondiciones_clickEvent() : void {
    if (Globals.exist(this.lstUnidades[this.indexSelected]?.puntosVerificaciones)) {
      let objUbicacion = {
        gerenciamientoPuntoVerificacion: '',
        puntoVerificacionFecha: Globals.isStringNotEmpty(this.today)? moment(this.today, "DD-MM-YYYY").format("DD/MM/YYYY") : '',
        puntoVerificacionHora: ''
      }

      this.lstUnidades[this.indexSelected].puntosVerificaciones.push(objUbicacion) ;
      this.calcCheckPoint(this.lstUnidades[this.indexSelected]);
      this.fnGerenciamiento_changeEvent();
    }
  }

  public btnItemCotizacionCondicionEliminar_clickEvent(index : number) : void {
    if (Globals.exist(this.lstUnidades[this.indexSelected]?.puntosVerificaciones)) {
      this.lstUnidades[this.indexSelected].puntosVerificaciones.splice(index, 1);
      this.calcCheckPoint(this.lstUnidades[this.indexSelected]);
      this.fnGerenciamiento_changeEvent();
    }
  }

  public selectGerenciamiento_changeEvent() : void {
    this.fnGerenciamiento_changeEvent();
    if ( Globals.exist(this.lstUnidades[this.indexSelected].gerenciamientosTipoValido) ) {
      this.lstUnidades[this.indexSelected].gerenciamientosTipoValido = true;
    }
  }

  selectTouched() {
    this.lstUnidades.forEach( (itemUnidad: any, index: number) => {
      itemUnidad.selectValidate = (Globals.exist(itemUnidad?.hasChange) && itemUnidad?.hasChange && Globals.isStringNotEmpty(itemUnidad.idGerenciamientoTipo)) || (itemUnidad.checked && !Globals.exist(itemUnidad?.hasChange) && Globals.isStringNotEmpty(itemUnidad.idGerenciamientoTipo));
    });
  }

  public inputGerenciamiento_changeEvent() : void {
    this.fnGerenciamiento_changeEvent();
  }

  public inputCheckpointTime_keyUpEvent(event : KeyboardEvent) : void {
    let value = (event.target as HTMLInputElement).value;
    if(Globals.isStringNotEmpty(value) && (value.length === 2 || value.length === 5)) {
      // this.lstUnidades[this.indexSelected].checkPoint = value;
      this.calcCheckPoint(this.lstUnidades[this.indexSelected]);
    } else {
      this.calcCheckPoint(this.lstUnidades[this.indexSelected]);
    }
  }

  public inputSalidaOrigenTime_keyUpEvent(event : KeyboardEvent) : void {
    this.calcCheckPoint(this.lstUnidades[this.indexSelected]);
  }

  public inputSalidaDestinoDate_changeEvent() : void {
    if (Globals.exist(this.lstUnidades[this.indexSelected].onChance) && !this.lstUnidades[this.indexSelected].onChance) {
      this.lstUnidades[this.indexSelected].onChance = true;
    }
  }

  public inputSalidaDestinoTime_keyUpEvent(event : KeyboardEvent) : void {
    let value = (event.target as HTMLInputElement).value;
    if(Globals.isStringNotEmpty(value) && value.length === 5) {
      this.lstUnidades[this.indexSelected].salidaDestinoHora = value;
    }
    this.inputSalidaDestinoDate_changeEvent();
  }

  public calcCheckPoint( itemOrdenServicioMiembro : any ) : void {
    if (Globals.exist(itemOrdenServicioMiembro?.puntosVerificaciones)) {
      let checkpoint : moment.Duration = moment.duration(FormOptimizer.formDataTime(itemOrdenServicioMiembro?.checkPoint));
      itemOrdenServicioMiembro.puntosVerificaciones.forEach( (itemPuntoVerificacion : any, index: number) => {
        if (Globals.exist(itemPuntoVerificacion.puntoVerificacionFecha)) {
          if (index === 0) {
            let salidaOrigenFechaHora : moment.Moment = moment(FormOptimizer.formDataDate(itemOrdenServicioMiembro?.salidaOrigenFecha) + " " + itemOrdenServicioMiembro.salidaOrigenHora, "YYYY-MM-DD HH:mm");
            itemPuntoVerificacion['puntoVerificacionFechaIso'] = (checkpoint.isValid() && isMoment(salidaOrigenFechaHora) && salidaOrigenFechaHora.isValid())? salidaOrigenFechaHora.add(checkpoint) : (isMoment(salidaOrigenFechaHora) && salidaOrigenFechaHora.isValid())? salidaOrigenFechaHora : '';
            itemPuntoVerificacion.puntoVerificacionFecha = (isMoment(itemPuntoVerificacion?.puntoVerificacionFechaIso) && itemPuntoVerificacion?.puntoVerificacionFechaIso.isValid())? itemPuntoVerificacion?.puntoVerificacionFechaIso.format("DD/MM/YYYY") : '';
          } else {
            let puntoVerificacionFechaHora : moment.Moment = moment(FormOptimizer.formDataDate(itemOrdenServicioMiembro.puntosVerificaciones[index - 1]?.puntoVerificacionFechaIso) + " " + itemOrdenServicioMiembro.puntosVerificaciones[index - 1]?.puntoVerificacionHoraIso, "YYYY-MM-DD HH:mm");
            itemPuntoVerificacion['puntoVerificacionFechaIso'] = (checkpoint.isValid() && isMoment(puntoVerificacionFechaHora) && puntoVerificacionFechaHora.isValid())? puntoVerificacionFechaHora.add(checkpoint) : (isMoment(puntoVerificacionFechaHora) && puntoVerificacionFechaHora.isValid())? puntoVerificacionFechaHora : '';
            itemPuntoVerificacion.puntoVerificacionFecha = (isMoment(itemPuntoVerificacion?.puntoVerificacionFechaIso) && itemPuntoVerificacion?.puntoVerificacionFechaIso.isValid())? itemPuntoVerificacion?.puntoVerificacionFechaIso.format("DD/MM/YYYY") : '';
          }
        }

        if (Globals.exist(itemPuntoVerificacion.puntoVerificacionHora)) {
          if (index === 0) {
            let salidaOrigenHora: moment.Moment = moment(itemOrdenServicioMiembro.salidaOrigenHora, "HH:mm");
            itemPuntoVerificacion['puntoVerificacionHoraIso'] = (checkpoint.isValid() && isMoment(salidaOrigenHora) && salidaOrigenHora.isValid()) ? salidaOrigenHora.add(checkpoint).format("HH:mm") : '';
            itemPuntoVerificacion.puntoVerificacionHora = (Globals.isStringNotEmpty(itemPuntoVerificacion?.puntoVerificacionHoraIso)) ? itemPuntoVerificacion.puntoVerificacionHoraIso + " Hrs." : '';
          } else {
            let puntoVerificacionHora: moment.Moment = moment(itemOrdenServicioMiembro.puntosVerificaciones[index - 1]?.puntoVerificacionHora, "HH:mm");
            itemPuntoVerificacion['puntoVerificacionHoraIso'] = (checkpoint.isValid() && isMoment(puntoVerificacionHora) && puntoVerificacionHora.isValid()) ? puntoVerificacionHora.add(checkpoint).format("HH:mm") : '';
            itemPuntoVerificacion.puntoVerificacionHora = (Globals.isStringNotEmpty(itemPuntoVerificacion?.puntoVerificacionHoraIso)) ? itemPuntoVerificacion.puntoVerificacionHoraIso + " Hrs." : '';
          }
        }
      });

      if (Globals.exist(itemOrdenServicioMiembro.onChance) && !itemOrdenServicioMiembro.onChance && Globals.exist(itemOrdenServicioMiembro?.puntosVerificaciones)) {
        let puntosVeriicaciones : Array<any> = itemOrdenServicioMiembro?.puntosVerificaciones as Array<any>;
        if (Globals.exist(itemOrdenServicioMiembro?.salidaDestinoFecha)) {
          if (puntosVeriicaciones.length > 0) {
            let puntoVerificacionFechaHora : moment.Moment = moment(itemOrdenServicioMiembro.puntosVerificaciones[puntosVeriicaciones.length - 1]?.puntoVerificacionFecha + " " + itemOrdenServicioMiembro.puntosVerificaciones[puntosVeriicaciones.length - 1]?.puntoVerificacionHora, "DD-MM-YYYY HH:mm");
            itemOrdenServicioMiembro.salidaDestinoFecha = (checkpoint.isValid() && isMoment(puntoVerificacionFechaHora) && puntoVerificacionFechaHora.isValid())? puntoVerificacionFechaHora.add(checkpoint) : (isMoment(puntoVerificacionFechaHora) && puntoVerificacionFechaHora.isValid())? puntoVerificacionFechaHora : '';
          } else {
            let salidaOrigenFechaHora : moment.Moment = moment(FormOptimizer.formDataDate(itemOrdenServicioMiembro?.salidaOrigenFecha) + " " + itemOrdenServicioMiembro.salidaOrigenHora, "YYYY-MM-DD HH:mm");
            itemOrdenServicioMiembro.salidaDestinoFecha = (checkpoint.isValid() && isMoment(salidaOrigenFechaHora) && salidaOrigenFechaHora.isValid())? salidaOrigenFechaHora.add(checkpoint) : (isMoment(salidaOrigenFechaHora) && salidaOrigenFechaHora.isValid())? salidaOrigenFechaHora : '';
          }
        }

        if (Globals.exist(itemOrdenServicioMiembro?.salidaDestinoHora)) {
          if (puntosVeriicaciones.length > 0) {
            let puntoVerificacionHora: moment.Moment = moment(itemOrdenServicioMiembro.puntosVerificaciones[puntosVeriicaciones.length - 1]?.puntoVerificacionHora, "HH:mm");
            itemOrdenServicioMiembro.salidaDestinoHora = (checkpoint.isValid() && isMoment(puntoVerificacionHora) && puntoVerificacionHora.isValid()) ? puntoVerificacionHora.add(checkpoint).format("HH:mm") : '';
          } else {
            let salidaOrigenHora: moment.Moment = moment(itemOrdenServicioMiembro.salidaOrigenHora, "HH:mm");
            itemOrdenServicioMiembro.salidaDestinoHora = (checkpoint.isValid() && isMoment(salidaOrigenHora) && salidaOrigenHora.isValid()) ? salidaOrigenHora.add(checkpoint).format("HH:mm") : '';
          }
        }
      }
    }
  }

  public chkGerenciamiento_changeEvent() : void {
    this.fnGerenciamiento_changeEvent();
  }

  public mantenimientoTaller_checkEvent(event : boolean, index : number) : void {
    this.lstUnidades[index].checked = event;
    this.selectScene_clickEvent(index);
  }

  public selectScene_clickEvent(event : number) : void {
    this.indexSelected = event;
    this.lstUnidades.forEach( (itemUidad, index) => {
      itemUidad.active = index == this.indexSelected;
    })
  }

  public fnGerenciamiento_changeEvent() : void {
    if (Globals.exist(this.lstUnidades[this.indexSelected]?.gerenciamientos) && Globals.exist(this.lstUnidades[this.indexSelected]?.hasChange)) {
      this.lstUnidades[this.indexSelected].hasChange = true;
    }
  }

  public selectGerenciamientoLogTipo_clickEvent(itemGerenciamientoLogTipo : any) {
    this.lstUnidades[this.indexSelected]['gerenciamientoLog']    = {
      idGerenciamientoLogTipo : itemGerenciamientoLogTipo.idGerenciamientoLogTipo,
      gerenciamientoLogTipo   : itemGerenciamientoLogTipo.gerenciamientoLogTipo,
      puntoVerificacion       : '',
      comentarios             : '',
      actualFechaIso          : moment(this.today, "DD-MM-YYYY"),
      actualHora              : this.hours,
      actualOdometro          : '',
      actualHorometro         : '',
    };
  }

  public aceptarGerenciamientoLogTipo_clickEvent() {
    if (Globals.exist(this.lstUnidades[this.indexSelected]?.gerenciamientoLog?.idGerenciamientoLogTipo)) {
      let itemGerenciamientoLog : any = this.lstUnidades[this.indexSelected].gerenciamientoLog;
      let fdUsuarioStore = new FormData();
      fdUsuarioStore.append("idOrdenServicio"          , FormOptimizer.formDataNumber(this.lstUnidades[this.indexSelected]?.idOrdenServicio));
      fdUsuarioStore.append("idOrdenServicioMiembro"   , FormOptimizer.formDataNumber(this.lstUnidades[this.indexSelected]?.idOrdenServicioMiembro));
      fdUsuarioStore.append("idUnidad"                 , FormOptimizer.formDataNumber(this.lstUnidades[this.indexSelected]?.idUnidad));
      fdUsuarioStore.append("idGerenciamientoLogTipo"  , FormOptimizer.formDataNumber(itemGerenciamientoLog?.idGerenciamientoLogTipo));
      fdUsuarioStore.append("ubicacion"                , FormOptimizer.formDataString(itemGerenciamientoLog?.puntoVerificacion));
      fdUsuarioStore.append("comentarios"              , FormOptimizer.formDataString(itemGerenciamientoLog?.comentarios));
      fdUsuarioStore.append("fecha"                    , FormOptimizer.formDataDate(itemGerenciamientoLog?.actualFechaIso));
      fdUsuarioStore.append("hora"                     , FormOptimizer.formDataTime(itemGerenciamientoLog?.actualHora));
      fdUsuarioStore.append("odometro"                 , FormOptimizer.formDataNumber(itemGerenciamientoLog?.actualOdometro));
      fdUsuarioStore.append("horometro"                , FormOptimizer.formDataNumber(itemGerenciamientoLog?.actualHorometro));

      FormOptimizer.formDisable(this.formComponents);
      this.objGerenciamientosService.cronograma(fdUsuarioStore).subscribe(objResponse => {
        if (Globals.exist(objResponse?.action) && objResponse.action) {
          this.change();
          this.init();
        } else {
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: 'Error de comunicación', mensaje: 'Ocurrió un error de comunicación con el servidor.', autoCloseDelay: 3000}));
          FormOptimizer.formEnable(this.formComponents);
        }
      }, error => {
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: "Error de comunicación", mensaje: "Ocurrió un error de comunicación con el servidor.", autoCloseDelay: 3000}));
        FormOptimizer.formEnable(this.formComponents);
      });
    } else {
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: "Cronograma de gerenciamiento", mensaje: "No ha seleccionado un tipo de estatus.", autoCloseDelay: 3000}));
    }
  }

  public cancelGerenciamientoLogTipo_clickEvent() {
    this.lstUnidades[this.indexSelected].gerenciamientoLog = null;
  }

  public geremciamientoManage_dialogEvent(itemUbicacion : any) {
    let objDialog = this.objMatDialog.open(OrdenesServiciosGerenciamientosCreateComponent, {
      width        : '96%',
      maxWidth     : '1150px',
      minWidth     : '800px',
      height       : '96%',
      maxHeight    : '900px',
      data         :  {
        idOrdenServicioMiembro   : this.lstUnidades[this.indexSelected].idOrdenServicioMiembro,
        idGerenciamientoTrayecto : itemUbicacion.idGerenciamientoTrayecto
      },
      disableClose : true
    });

    objDialog.componentInstance.onClose.subscribe( (ordenesServiciosGerenciamientosUbicacionesManageComponent : OrdenesServiciosGerenciamientosCreateComponent) => {
      if (Globals.exist(ordenesServiciosGerenciamientosUbicacionesManageComponent.objGerenciamiento?.idGerenciamientoEvaluacionRiesgo)) {
        this.verPdf_clickEvent(ordenesServiciosGerenciamientosUbicacionesManageComponent.objGerenciamiento);
        this.init();
      }
    });
  }

  public geremciamientoEdit_dialogEvent(itemUbicacion : any) {
    let objDialog = this.objMatDialog.open(OrdenesServiciosGerenciamientosUbicacionesEditComponent, {
      width         : '96%',
      maxWidth      : '1150px',
      minWidth      : '800px',
      height        : '96%',
      maxHeight     : '900px',
      data         : itemUbicacion,
      disableClose  : true
    });

    objDialog.componentInstance.onClose.subscribe( (ordenesServiciosGerenciamientosUbicacionesManageComponent : OrdenesServiciosGerenciamientosUbicacionesEditComponent) => {
      if (ordenesServiciosGerenciamientosUbicacionesManageComponent.saveGerenciamiento) {
        this.verPdf_clickEvent(itemUbicacion);
        this.init();
      }
    });
  }

  public verPdf_clickEvent(itemUbicacion : any) {
    let objDialog = this.objMatDialog.open(FileViewerComponent, {
      width     : '100%',
      maxWidth  : '100%',
      height    : '100%',
      maxHeight : '100%',
      data: {
        extension : 'pdf',
        fileName  : itemUbicacion.folio + '.pdf',
        url    : 'api/gerenciamientos/evaluaciones-riesgos/' + itemUbicacion.idGerenciamientoEvaluacionRiesgo + "/pdf"
      },
      backdropClass : 'backdropBackground',
      panelClass    : 'visualizarPanelClass',
      disableClose  : true
    });
  }

  public validate(itemUnidad: any) : boolean {
    let boolReturn = true;
    if ( itemUnidad?.checked && (!Globals.isStringNotEmpty( itemUnidad?.idGerenciamientoTipo ) || (Globals.isNumeric(itemUnidad?.idGerenciamientoTipo) && (itemUnidad?.idGerenciamientoTipo < 1))) ) {
      boolReturn = false;
      itemUnidad['gerenciamientosTipoValido'] = false;
    } else {
      itemUnidad['gerenciamientosTipoValido'] = true;
    }

    return boolReturn;
  }

  private isJSON(text: string) : boolean {
    try {
      JSON.parse(text);
      return true;
    } catch (error) {
      return false;
    }
  }

  public boolGerenciamientoChange() : boolean {
    return this.lstUnidades.some( itemUnidad => (Globals.exist(itemUnidad?.hasChange) && itemUnidad?.hasChange) || (itemUnidad.checked && !Globals.exist(itemUnidad?.hasChange)) );
  }

  public change(): void {
    this.onChange.emit(this);
  }

  public closeGerenciamiento(): void {
    let boolCloseGerenciamiento : boolean = this.lstUnidades.some( itemUnidad => (itemUnidad?.checked && !Globals.exist(itemUnidad?.idGerenciamientoEstatus)) );
    if ( !boolCloseGerenciamiento ) {
      this.close();
    } else {
      this.objMatDialog.open(ConfirmComponent, Globals.successConfig({titulo: 'Gerenciamiento', mensaje: 'Se perderán todos los datos activados ¿Desea cerrar el gerenciamiento?', fnAccept: () => this.close()}));
    }
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

}
