import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { ApplicationConfig } from 'src/app/libraries/application-config';
import { Globals } from 'src/app/libraries/globals';
import { ComponentForm } from '../ComponentForm';

@Component({
  selector: 'comp-picture-upload',
  templateUrl: './picture-upload.component.html',
  styleUrls: ['./picture-upload.component.scss']
})
export class PictureUploadComponent implements OnInit, AfterViewInit {

  @Input('border') border : string = "none";
  @Input('borderRadius') borderRadius : string = "100%";
  @Input('backgroundColor') backgroundColor : string = "#cccccc";
  @Input('backgroundSize') backgroundSize : string = "cover";
  @Input('height') height : string = "100%";
  @Input('width') width : string = "auto";
  @Input('accept') accept : string = "image/*";
  @Input('disabled') disabled : boolean = false;
  private _defaultImage : string = 'assets/images/no-image.png';
  @Input('defaultImage')
  get defaultImage() : string { return this._defaultImage; }
  set defaultImage(url : string) {
    this._defaultImage = url + '?v=' + Globals.uniqueId();
  }
  @Input('canvas')
  set canvas(canvas : any) {
    if (Globals.exist(canvas) && Globals.exist(canvas.toDataURL())) {
      this.preview = canvas.toDataURL("image/png");
    }
  }

  @Output('comFile_selectedPictureChange') comFile_selectedPictureChange : EventEmitter<PictureUploadComponent> = new EventEmitter<PictureUploadComponent>();

  @ViewChild('inputFile') inputFile : ElementRef;

  public objOriginalPreview : any;
  public preview : any = '';
  public Globals = Globals;
  public ApplicationConfig = ApplicationConfig;


  constructor() {}

  ngOnInit(): void {
  }

  ngAfterViewInit() : void {
  }

  public btnUpload_clickEvent() : void {
    this.inputFile.nativeElement.click();
  }

  public isValid() : boolean {
    let objReturn : boolean = false;
    if( Globals.exist(this.inputFile) && Globals.exist(this.inputFile.nativeElement) && Globals.exist(this.inputFile.nativeElement.value) ) {
      objReturn = ( this.inputFile.nativeElement.value.trim() != '' );
    }
    return objReturn;
  }



  public file_changeEvent(evento : any) : void {
    if( this.isValid() ) {
      if( this.inputFile.nativeElement.files.length == 1 ) {
        var objFileReader = new FileReader();
        objFileReader.readAsDataURL(this.inputFile.nativeElement.files[0]);
        objFileReader.onload = (_event) => {
          this.preview = objFileReader.result || '';
        }
        this.comFile_selectedPictureChange.emit(this);
      } else {
        this.preview = '';
      }
    } else {
      if(this.objOriginalPreview != null) {
        this.preview = this.objOriginalPreview;
      }
    }
  }

  public selectedFile() : any {
    let objReturn = null;
    if( Globals.exist(this.inputFile) && Globals.exist(this.inputFile.nativeElement) && Globals.exist(this.inputFile.nativeElement.files) && this.inputFile.nativeElement.files.length == 1 ) {
      objReturn = this.inputFile.nativeElement.files[0];
    }

    return objReturn;
  }

  public setPreview( preview : string ) : void {
    this.inputFile.nativeElement.value = "";
    this.objOriginalPreview = preview;
    this.preview = this.objOriginalPreview;
  }
}
