
<td class="text-center"></td>
<td class="text-center">
  <button class="datatable-button">
    <mat-icon matTooltip="Eliminar" class="datatable-button-icon" (click)="btnFicheroUsuario_eliminarEvent(itemFile)">clear</mat-icon>
  </button>
</td>
<td class="text-left">
  <input matInput class="table-input" maxlength="256" [style.width.%]="100" [formControl]="fcNombre" required [class.required]="fcNombre.hasError('required')  && fcNombre.touched">
</td>

<td class="text-right">
  {{fcTamano.value | FileSize}}
</td>

<td class="text-left">
  <select class="table-select" [class.required]="JSON.stringify(fcFicheroTipo.value) === '{}' && fcFicheroTipo.touched" [formControl]="fcFicheroTipo" required [(ngModel)]="usuarioFicheroTipo" [style.width.%]="100" (change)="usuarioFicheroTipo_selectionChangeEvent($event)">
    <option *ngFor="let itemUsuarioFicheroTipo of usuariosFicherosTipos;" [ngValue]="itemUsuarioFicheroTipo">{{itemUsuarioFicheroTipo.usuarioFicheroTipo}}</option>
    <option [ngValue]="">Otros</option>
  </select>
</td>

<td class="text-left" [style.width.px]="130">
  <div *ngIf="usuarioFicheroTipo?.vigencia">
    <div class="table-input-calendar" [class.required]="fcVigenciaFechaInicio.hasError('required')  && fcVigenciaFechaInicio.touched">
      <label>de: </label>
      <input matInput [formControl]="fcVigenciaFechaInicio" [matDatepicker]="matDateInicio" [readonly]="true" (click)="matDateInicio.open()">
      <mat-datepicker #matDateInicio></mat-datepicker>
      <button mat-raised-button (click)="matDateInicio.open()"><span class="material-icons">today</span></button>
    </div>
  </div>
</td>

<td class="text-left" [style.width.px]="130">
  <div *ngIf="usuarioFicheroTipo?.vigencia">
    <div class="table-input-calendar" [class.required]="fcVigenciaFechaFin.hasError('required')  && fcVigenciaFechaFin.touched">
      <label>a: </label>
      <input matInput [formControl]="fcVigenciaFechaFin" [matDatepicker]="matDateFin" [readonly]="true" (click)="matDateFin.open()">
      <mat-datepicker #matDateFin></mat-datepicker>
      <button mat-raised-button (click)="matDateFin.open()"><span class="material-icons">today</span></button>
    </div>
  </div>
</td>
