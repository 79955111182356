import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FormOptimizer} from "../../../../../libraries/formOptimizer";
import {OrdenesPedidosService} from "../../../../../services/ordenes-pedidos.service";
import {AlertComponent} from "../../../../../components/alert/alert.component";
import {Globals} from "../../../../../libraries/globals";
import {SuccessComponent} from "../../../../../components/success/success.component";

@Component({
  selector: 'app-ordenes-pedidos-generate-agreement',
  templateUrl: './generate-agreement.component.html',
  styleUrls: ['./generate-agreement.component.scss']
})
export class OrdenPedidoGenerateAgreementComponent implements OnInit, AfterViewInit {

  @Output('onClose') onClose : EventEmitter<OrdenPedidoGenerateAgreementComponent> = new EventEmitter<OrdenPedidoGenerateAgreementComponent>();

  public fgOrdenServicioProgramacion: FormGroup = new FormGroup({
    fechaDesde  : new FormControl('', Validators.required),
    fechaHasta  : new FormControl('', Validators.required),
    objetivo    : new FormControl(''),
    descripcion : new FormControl('')
  });

  public folio               : string = '';
  public idOrdenPedido       : number = 0;
  public idCliente           : number = 0;
  public idClienteContacto   : number = 0;
  public idEmpresa           : number = 0;
  public objCliente          : any = {};
  public objClienteContacto  : any = {};
  public objClienteContrato  : any = {};
  public lstOrdenesServicios : Array<any> = [];
  public lstOrdenesPedidos   : Array<any> = [];
  public objScene                 : any = ["CLIENTE"];

  public objLoader = {
    type            : 'loader',
    visible         : false
  };

  public chckLstFichero = {
    type            : 'button',
    disabled        : false
  }

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  };

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  };

  public formComponents: Array<any> = [
    this.objLoader,
    this.chckLstFichero,
    this.fgOrdenServicioProgramacion,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objOrdenesPedidosService : OrdenesPedidosService,
    private objDialogRef: MatDialogRef<OrdenPedidoGenerateAgreementComponent>,
    private objMatDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.idOrdenPedido       = this.data.ordenPedido.idOrdenPedido;
    this.idCliente           = this.data.ordenPedido.idCliente;
    this.idClienteContacto   = this.data.ordenPedido.idClienteContacto;
    this.idEmpresa           = this.data.ordenPedido.idEmpresa;
    this.lstOrdenesServicios = this.data.ordenesServicios;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    setTimeout( ()=> this.init(), 200);
  }

  public init() : void {
    FormOptimizer.formDisable(this.formComponents);
    this.objOrdenesPedidosService.contratoCreate(this.idOrdenPedido)
      .subscribe(objResponse => {
        console.log(objResponse.result);
        console.log(objResponse.result.cliente);
        console.log(objResponse.result.clienteContacto);
        console.log(objResponse.result.clienteContrato);
        this.objCliente         = objResponse.result.cliente;
        this.objClienteContacto = objResponse.result.clienteContacto;
        this.objClienteContrato = objResponse.result.clienteContrato;

        FormOptimizer.formEnable(this.formComponents);
      }, error => {

      });
  }

  public formOrdenServicioCreate_submitEvent(): void {
    (<any>Object).values(this.fgOrdenServicioProgramacion.controls).forEach((itemControl: FormControl) => itemControl.markAsTouched());

    if (this.fgOrdenServicioProgramacion.valid) {
      let objGenerateAgreement = new FormData();
      objGenerateAgreement.append('idOrdenPedido'      , FormOptimizer.formDataNumber(this.idOrdenPedido));
      objGenerateAgreement.append('idCliente'          , FormOptimizer.formDataNumber(this.idCliente));
      objGenerateAgreement.append('idClienteContacto'  , FormOptimizer.formDataNumber(this.idClienteContacto));
      objGenerateAgreement.append('idEmpresa'          , FormOptimizer.formDataNumber(this.idEmpresa));
      objGenerateAgreement.append('duracionDesdeFecha' , FormOptimizer.formDataDate(  this.fgOrdenServicioProgramacion.controls['fechaDesde']));
      objGenerateAgreement.append('duracionHastaFecha' , FormOptimizer.formDataDate(  this.fgOrdenServicioProgramacion.controls['fechaHasta']));
      objGenerateAgreement.append('objetoContrato'     , FormOptimizer.formDataString(this.fgOrdenServicioProgramacion.controls['objetivo']));
      objGenerateAgreement.append('descripcion'        , FormOptimizer.formDataString(this.fgOrdenServicioProgramacion.controls['descripcion']));

      let index : number = 0;
      this.lstOrdenesServicios.forEach( itemOrdenServicio => {
        if (itemOrdenServicio.checked) {
          objGenerateAgreement.append('idOrdenServicio' + index , FormOptimizer.formDataNumber(itemOrdenServicio.idOrdenServicio));
          index++;
        }
      });

      FormOptimizer.formDisable(this.formComponents);
      this.objOrdenesPedidosService.generateAgreement(objGenerateAgreement)
        .subscribe(objResponse => {
          if( objResponse.action) {
            this.close();
            this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: "Generar contrato", mensaje: "El contrato se ha generado satisfactoriamente", autoCloseDelay: 3000}));
            // } else
            //   this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message}));
          }
          FormOptimizer.formEnable(this.formComponents);
        }, error => {
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: 'Error de comunicación', mensaje: 'Ocurrió un error de comunicación con el servidor.', autoCloseDelay: 3000}));
          FormOptimizer.formEnable(this.formComponents);
        });
    } else {
      this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo : 'Error de formulario', mensaje : 'Complete los campos solicitados.'}));
    }
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }
}
