import {Injectable} from "@angular/core";
import {HttpClient, HttpEventType} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {Observable} from "rxjs";
import {ApplicationConfig} from "../libraries/application-config";
import {Sessions} from "../libraries/sessions";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class UnidadesMantenimientosService {

  constructor(private http: HttpClient,
              private objAuthService: AuthService) {
  }

  index(objParams : number): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + 'api/mantenimientos/index', objParams, Sessions.headerSession(this.objAuthService.access_token));
  }

  datatable( objParams : any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/datatable", objParams, Sessions.headerSession(this.objAuthService.access_token));
  }

  create(): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/create", Sessions.headerSession(this.objAuthService.access_token));
  }

  store( objParams : any ) : Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  storeImage(objUnidadMantenimiento : any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/image", objUnidadMantenimiento, { headers: Sessions.headerFormData(this.objAuthService.access_token).headers, reportProgress: true, observe: 'events'}).pipe(map((event) => {

      switch (event.type) {
        case HttpEventType.UploadProgress:
          let total : any = event?.total;
          const progress = Math.round(100 * event.loaded / total);
          return {body : null, status: 'progress', uploadProgress: progress };

        case HttpEventType.Response:
          return {body : event.body, status: 'completed', uploadProgress: 100};
      }
    }));
  }

  findUsuarios(strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/" + strSearch + "/findUsuarios", Sessions.headerSession(this.objAuthService.access_token));
  }

  findUnidad(strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/" + strSearch + "/findUnidad", Sessions.headerSession(this.objAuthService.access_token));
  }

  findOrdenServicio(strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/" + strSearch + "/findOrdenServicio", Sessions.headerSession(this.objAuthService.access_token));
  }

  refresh( idUnidadMantenimiento : number ): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/" + idUnidadMantenimiento + '/refresh', Sessions.headerSession(this.objAuthService.access_token));
  }

  finalize( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/finalize", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  finalizeForce( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/finalize/force", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  details(idUnidadMantenimiento : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/details/" + idUnidadMantenimiento, Sessions.headerSession(this.objAuthService.access_token));
  }

  talleres(idUnidadMantenimiento : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/" + idUnidadMantenimiento + "/talleres", Sessions.headerSession(this.objAuthService.access_token));
  }

  updateTalleres(objParams : any): Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/update", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  requestExtension(idUnidadMantenimiento : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/" + idUnidadMantenimiento + "/request_extension", Sessions.headerSession(this.objAuthService.access_token));
  }

  extension_date( objParams : any ) : Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/prorroga", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  generatePdf(idUnidadMantenimiento : number): Observable<Blob> {
    return this.http.get<Blob>(ApplicationConfig.ENDPOINT + "api/mantenimientos/pdf/" + idUnidadMantenimiento, Sessions.headerFile(this.objAuthService.access_token));
  }

  exportExcel(objParams : any ): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/export/excel", objParams, Sessions.headerFile(this.objAuthService.access_token));
  }
}
