import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CuentaRoutes } from './cuenta.routing';
import { RouterModule } from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CuentaIndexComponent} from "../../cp/views/cuenta/index/index.component";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatIconModule} from "@angular/material/icon";
import {MatListModule} from "@angular/material/list";
import {MatCardModule} from "@angular/material/card";
import {FlexModule} from "@angular/flex-layout";
import {MatBadgeModule} from "@angular/material/badge";
import {ContextMenuModule} from "primeng/contextmenu";
import {UsuariosFicheroModule} from "../../cp/views/usuarios/ficheros/index/usuarios-fichero.module";
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from "@angular/material/button";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {PictureUploadModule} from "../../components/picture-upload/picture-upload.module";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatSelectModule} from "@angular/material/select";
import {ClientesFilePendingComponent} from "../../cp/views/inicio/ficheros/cliente-file-pending/file-pending.component";

@NgModule({
  declarations: [
    CuentaIndexComponent,
  ],
  imports: [
    // C
    CommonModule,
    ContextMenuModule,

    // F
    FlexModule,
    FormsModule,

    // M
    MatProgressBarModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatBadgeModule,
    MatMenuModule,
    MatButtonModule,
    MatCheckboxModule,

    // P
    PictureUploadModule,

    // R
    ReactiveFormsModule,
    // RouterModule.forChild(CuentaRoutes),

    // U
    UsuariosFicheroModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
  exports : [
    CuentaIndexComponent
  ]
})
export class CuentaModule { }
