import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {FormOptimizer} from "../../../../../libraries/formOptimizer";
import {ApplicationConfig} from "../../../../../libraries/application-config";
import {AlertComponent} from "../../../../../components/alert/alert.component";
import {Globals} from "../../../../../libraries/globals";
import {SuccessComponent} from "../../../../../components/success/success.component";
import {OrdenesServiciosService} from "../../../../../services/ordenes-servicios.service";

@Component({
  selector: 'app-ordenes-servicios-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class OrdenesServiciosPaymentComponent implements OnInit {

  @Output('onClose') onClose : EventEmitter<OrdenesServiciosPaymentComponent> = new EventEmitter<OrdenesServiciosPaymentComponent>();
  public objApplicationConfig = ApplicationConfig;

  public fgOrdenesServiciosPayment : FormGroup = new FormGroup({
    monto     : new FormControl('', Validators.required)
  });

  public objLoader = {
    type            : 'loader',
    visible         : false
  };

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  };

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  };

  public formComponents: Array<any> = [
    this.fgOrdenesServiciosPayment,
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objDialogRef: MatDialogRef<OrdenesServiciosPaymentComponent>,
    private objMatDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private objOrdenesServiciosService: OrdenesServiciosService,
  ) { }

  ngOnInit() {
  }

  public formCotizacionCancelacion_submitEvent() : void {
    (<any>Object).values(this.fgOrdenesServiciosPayment.controls).forEach( (itemControl : FormControl) => itemControl.markAsTouched());


    if(this.fgOrdenesServiciosPayment.valid) {
      FormOptimizer.formDisable(this.formComponents);

      let fdOrdenesServiciosPayment = new FormData();
      fdOrdenesServiciosPayment.append("idOrdenServicio" , FormOptimizer.formDataNumber(this.data.idOrdenServicio));
      fdOrdenesServiciosPayment.append("monto"           , FormOptimizer.formDataNumber(this.fgOrdenesServiciosPayment.controls['monto']));

      this.objOrdenesServiciosService.payment(fdOrdenesServiciosPayment)
        .subscribe( objResponse => {
          if( objResponse.action) {
            this.close();
            this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
          } else this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
            FormOptimizer.formEnable(this.formComponents);
        }, error => {
          FormOptimizer.formEnable(this.formComponents);
        });
    } else {
      this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Formulario incompleto", mensaje : "Complete los campos solicitados.", autoCloseDelay : 3000}));
      FormOptimizer.formEnable(this.formComponents);
    }

  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }
}
