<div class="mat-dialog-title">
  <div class="title"> Configuración correo envío </div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content">
    <div class="bs-row">
      <div class="bs-col text-center">
        <p-selectButton [options]="stateOptions" [(ngModel)]="value1" optionLabel="label" optionValue="value" (onChange)="selectButton_clickEvent($event.value)" ></p-selectButton>
      </div>
    </div>
    <div *ngIf="value1=='on'" class="bs-row text-center" [style.margin]="'5px 0'" >
      <div class="bs-col">
        <a class="link-google" href="https://support.google.com/accounts/answer/185833?hl=es" target="_blank">Debes utilizar contraseñas para aplicaciones</a>
      </div>
    </div>
    <form [formGroup]="fgUsuarioSettingSendEmail" autocomplete="off">
      <div class="bs-row">
        <div class="bs-col">
          <mat-form-field>
            <input type="text" matInput formControlName="correo" maxlength="256" placeholder="Correo" autocomplete="off">
            <span *ngIf="value1=='on'" matSuffix>{{strGmail}}</span>
          </mat-form-field>
        </div>
      </div>
      <div class="bs-row">
        <div class="bs-col">
          <mat-form-field>
            <input type="text" matInput formControlName="remitente" maxlength="256" placeholder="Remitente" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="bs-row">
        <div class="bs-col">
          <mat-form-field>
            <input [type]="(visibility)?'text':'password'" matInput formControlName="password" maxlength="256" placeholder="Contraseña" autocomplete="new-password">
            <button type="button" matSuffix mat-icon-button aria-label="Clear" matTooltip="{{(visibility?'Ocultar contraseña':'Mostrar contraseña')}}" (click)="visibility = !visibility">
              <mat-icon>{{(visibility)?'visibility_off':'visibility'}}</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="bs-row">
        <div class="bs-col">
          <mat-form-field>
            <input type="text" matInput formControlName="servidor" maxlength="128" placeholder="Servidor" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="bs-row">
        <div class="bs-col">
          <mat-form-field>
            <input type="text" matInput formControlName="puerto" maxlength="30" placeholder="Puerto" autocomplete="off">
          </mat-form-field>
        </div>
      </div>
      <div class="bs-row">
        <div class="bs-col">
          <mat-form-field>
            <mat-label>Método de cifrado</mat-label>
            <mat-select formControlName="cifrado">
              <mat-option *ngFor="let itemCifradoTipo of lstCifradosTipos" [value]="itemCifradoTipo.idCifradoTipo">{{itemCifradoTipo.cifradoTipo}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="formUsuarioConfiguracionCorreoEnvio_submitEvent()">Aceptar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>
