import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {NotificacionesService} from "../../services/notificaciones.service";
import {FormOptimizer} from "../../libraries/formOptimizer";
import {CotizacionApproveComponent} from "../views/cotizaciones/approve/approve.component";
import {CotizacionesEditComponent} from "../views/cotizaciones/edit/edit.component";
import {OrdenesServiciosIndexComponent} from "../views/ordenesPedidos/ordenes-servicios/index/index.component";
import {OrdenesServiciosFinalizarComponent} from "../views/ordenesServicios/finalize/finalize.component";
import {
  UnidadMantenimientoTalleresInitServiceComponent
} from "../views/servicioMantenimiento/init-service/init-service.component";
import {
  UnidadMantenimientoTalleresFinalizeServiceComponent
} from "../views/servicioMantenimiento/finalize-service/finalize-service.component";
import {AuthService} from "../../services/auth.service";
import {Globals} from "../../libraries/globals";
import {UnidadesMantenimientosService} from "../../services/unidades-mantenimientos.service";
import {ConfirmComponent} from "../../components/confirm/confirm.component";
import {SuccessComponent} from "../../components/success/success.component";
import {AlertComponent} from "../../components/alert/alert.component";
import {UsuariosFicherosIndexComponent} from "../views/usuarios/ficheros/index/index.component";
import {CotizacionesCancelComponent} from "../views/cotizaciones/cancel/cancel.component";
import {
  UnidadMantenimientoTalleresCancelServiceComponent
} from "../views/servicioMantenimiento/cancel-service/cancel-service.component";

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, AfterViewInit {

  @Output('onClose') onClose: EventEmitter<NotificationsComponent> = new EventEmitter<NotificationsComponent>();

  public objScene                      : string  = 'cotizacion';
  public length                        : number  = 0;
  public pageCotizacion                : number  = 1;
  public pagePedido                    : number  = 1;
  public pageServicio                  : number  = 1;
  public pageMantenimiento             : number  = 1;
  public lstCotizaciones               : Array<any> = [];
  public lstOrdenesPedidos             : Array<any> = [];
  public lstOrdenesServicios           : Array<any> = [];
  public lstOrdenesMantenimiento       : Array<any> = [];
  public boolNewNotifyCotizacion       : boolean = true;
  public boolNewNotifyOrdPedido        : boolean = true;
  public boolNewNotifyOrdServicio      : boolean = true;
  public boolNewNotifyOrdMantenimiento : boolean = true;
  public objPrivilegio                 : any     = {};

  public objLoader = {
    type            : 'loader',
    visible         : false
  };

  public formComponents: Array<any> = [
    this.objLoader
  ];

  constructor(
    public objMatDialog              : MatDialog,
    private objDialogRef             : MatDialogRef<NotificationsComponent>,
    private objNotificacionesService : NotificacionesService,
    private objMantenimientosService : UnidadesMantenimientosService,
    private objAuthService           : AuthService,
    @Inject(MAT_DIALOG_DATA) public data : any
  ) {
    this.changeScene_clickEvent(this.data);
    this.objPrivilegio = objAuthService.privilegios;
    if(!this.objPrivilegio.COTIZACIONES_LIST)      this.objScene = 'pedido';
    if(!this.objPrivilegio.ORDENES_PEDIDOS_LIST)   this.objScene = 'servicio';
    if(!this.objPrivilegio.ORDENES_SERVICIOS_LIST) this.objScene = 'mantenimiento';
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout( () => this.init());
  }

  public init() : void {
    FormOptimizer.formDisable(this.formComponents);

    this.objNotificacionesService.index()
      .subscribe( objResponse => {
        if(this.objPrivilegio.COTIZACIONES_LIST) {
          this.lstCotizaciones = objResponse.result?.cotizaciones;
          this.lstCotizaciones.forEach( (itemCotizacion, index) => {
            if (Globals.exist(itemCotizacion?.jsonReference)) {
              itemCotizacion['cotizacion'] = JSON.parse(itemCotizacion.jsonReference);
            }
          });
        }

        if(this.objPrivilegio.ORDENES_PEDIDOS_LIST) {
          this.lstOrdenesPedidos = objResponse.result?.ordenesPedidos;
          this.lstOrdenesPedidos.forEach( itemOrdenPedido => {
            if (Globals.exist(itemOrdenPedido?.jsonReference)) {
              itemOrdenPedido['ordenPedido'] = JSON.parse(itemOrdenPedido.jsonReference);
            }
          });
        }

        if(this.objPrivilegio.ORDENES_SERVICIOS_LIST) {
          this.lstOrdenesServicios = objResponse.result?.ordenesServicios;
          this.lstOrdenesServicios.forEach( itemOrdenServicio => {
            if (Globals.exist(itemOrdenServicio?.jsonReference)) {
              itemOrdenServicio['ordenServicio'] = JSON.parse(itemOrdenServicio.jsonReference);
            }
          });
        }

        if(this.objPrivilegio.MANTENIMIENTO_LIST) {
          this.lstOrdenesMantenimiento = objResponse.result?.ordenesMantenimientos;
          this.lstOrdenesMantenimiento.forEach( itemMantenimiento => {
            if (Globals.exist(itemMantenimiento?.jsonReference)) {
              itemMantenimiento['mantenimiento'] = JSON.parse(itemMantenimiento.jsonReference);
            }
          });
        }

        if(this.lstCotizaciones.length > 0 || this.lstOrdenesPedidos.length > 0 || this.lstOrdenesServicios.length > 0 || this.lstOrdenesMantenimiento.length > 0) {
          if(this.lstCotizaciones.length > 0)         this.boolNewNotifyCotizacion       = false;
          if(this.lstOrdenesPedidos.length > 0)       this.boolNewNotifyOrdPedido        = false;
          if(this.lstOrdenesServicios.length > 0)     this.boolNewNotifyOrdServicio      = false;
          if(this.lstOrdenesMantenimiento.length > 0) this.boolNewNotifyOrdMantenimiento = false;
        }

        this.changeScene_clickEvent(this.data);
        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        console.log(error);
        FormOptimizer.formEnable(this.formComponents);
      });
  }

  public changeScene_clickEvent(event : string) : void {
    this.objScene = event;
    switch (event) {
      case 'cotizacion':
        this.length = this.lstCotizaciones.length;
        this.boolNewNotifyCotizacion       = true;
        break;

      case 'pedido':
        this.length = this.lstOrdenesPedidos.length;
        this.boolNewNotifyOrdPedido        = true;
        break;

      case 'servicio':
        this.length = this.lstOrdenesServicios.length;
        this.boolNewNotifyOrdServicio      = true;
        break;

      case 'mantenimiento':
        this.boolNewNotifyOrdMantenimiento = true;
        this.length = this.lstOrdenesMantenimiento.length;
        break;
    }
  }

  public btnCotizacionAprobar_clickEvent(itemCotizacion: any) : void {
    let objDialog = this.objMatDialog.open(CotizacionApproveComponent, {
      width         : '96%',
      maxWidth      : '96%',
      height        : '96%',
      data          : itemCotizacion,
      disableClose  : true
    });

    objDialog.componentInstance.onClose.subscribe( () => {
      this.init();
    });
  }

  public btnCotizacionEdit_clickEvent(itemCotizacion: any) {
    let objDialog = this.objMatDialog.open(CotizacionesEditComponent, {
      width         : '98%',
      maxWidth      : '98%',
      minWidth      : '800px',
      height        : '96%',
      data          : itemCotizacion,
      disableClose  : true
    });

    objDialog.componentInstance.onClose.subscribe( () => {
      this.init();
    });
  }

  public btnCotizacionCancel_clickEvent(itemCotizacion: any) : void {
    let objDialog = this.objMatDialog.open(CotizacionesCancelComponent, {
      width         : '76%',
      maxWidth      : '700px',
      height        : '76%',
      maxHeight     : '300px',
      data          : itemCotizacion,
      disableClose  : true
    });

    objDialog.componentInstance.onClose.subscribe( () => {
      this.init();
    });
  }

  public btnListOrdenServicio_clickEvent(itemOrdenPedido : any):void {
    let objDialog = this.objMatDialog.open(OrdenesServiciosIndexComponent, {
      width: '98%',
      maxWidth: '1300px',
      minWidth: '800px',
      height: '96%',
      maxHeight: '640px',
      data: {
        idOrdenPedido: itemOrdenPedido.idOrdenPedido,
        folio: itemOrdenPedido.folio,
        scene: 'SERVICIOS'
      },
      disableClose: true
    });

    objDialog.componentInstance.onClose.subscribe( () => {
      this.init();
    });
  }

  public btnFinalizarOrdenServicio_clickEvent(itemOrdenServicio : any) : void {
    let objDialog = this.objMatDialog.open(OrdenesServiciosFinalizarComponent, {
      width: '98%',
      maxWidth: '98%',
      minWidth: '800px',
      height: '96%',
      data: itemOrdenServicio,
      disableClose: true
    });

    objDialog.componentInstance.onClose.subscribe( () => {
      this.init();
    });
  }

  public btnInit_clickEvent(itemUnidadMantenimientoTaller : any) : void {
    let objDialog = this.objMatDialog.open(UnidadMantenimientoTalleresInitServiceComponent, {
      width         : '96%',
      maxWidth      : '1150px',
      height        : '96%',
      maxHeight     : '900px',
      data          : itemUnidadMantenimientoTaller,
      disableClose  : true
    });

    objDialog.componentInstance.onClose.subscribe( () => {
      this.init();
    });
  }

  public btnFinalize_clickEvent(itemUnidadMantenimientoTaller : any) : void {
    let objDialog = this.objMatDialog.open(UnidadMantenimientoTalleresFinalizeServiceComponent, {
      width         : '96%',
      maxWidth      : '1150px',
      height        : '96%',
      maxHeight     : '900px',
      data          : itemUnidadMantenimientoTaller,
      disableClose  : true
    });

    objDialog.componentInstance.onClose.subscribe( () => {
      this.init();
    });
  }

  public btnCancel_clickEvent(itemUnidadMantenimientoTaller : any) : void {
    let objDialog = this.objMatDialog.open(UnidadMantenimientoTalleresCancelServiceComponent, {
      width         : '76%',
      maxWidth      : '600px',
      height        : '76%',
      maxHeight     : '310px',
      data          : itemUnidadMantenimientoTaller,
      disableClose  : true
    });

    objDialog.componentInstance.onClose.subscribe( () => {
      this.init();
    });
  }

  public btnOrdenMantenimientoFinalize_clickEvent(itemUnidadMantenimientoTaller : any) : void {
    this.objMatDialog.open(ConfirmComponent, Globals.confirmConfig({titulo : "Finalizar orden de mantenimiento", mensaje : "¿Está seguro que desea finalizar la orden de mantenimiento " + itemUnidadMantenimientoTaller.folio + "?.", fnAccept : ()=> {
        let fdUnidadMantenimientoFinalize = new FormData();
        fdUnidadMantenimientoFinalize.append("idUnidadMantenimiento"       , itemUnidadMantenimientoTaller.idUnidadMantenimiento);

        this.objMantenimientosService.finalize(fdUnidadMantenimientoFinalize)
          .subscribe( objResponse => {
            if( objResponse.action) {
              this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
              this.init();
            } else
              this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
          }, error => {
            this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
          });
      }}));
  }

  public btnOrdenMantenimientoForceFinalize_clickEvent(itemUnidadMantenimientoTaller : any) : void {
    this.objMatDialog.open(ConfirmComponent, Globals.confirmConfig({titulo : "Finalizar orden de mantenimiento", mensaje : "¿Está seguro que desea finalizar forzadamente la orden de mantenimiento " + itemUnidadMantenimientoTaller.folio + "?. Al finalizar, no podrá modificar los estatus de los talleres", fnAccept : ()=> {
        let fdUnidadMantenimientoFinalize = new FormData();
        fdUnidadMantenimientoFinalize.append("idUnidadMantenimiento"       , itemUnidadMantenimientoTaller.idUnidadMantenimiento);

        this.objMantenimientosService.finalizeForce(fdUnidadMantenimientoFinalize)
          .subscribe( objResponse => {
            this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: "Finalizar orden de mantenimiento", mensaje: "La orden de mantenimiento " + itemUnidadMantenimientoTaller.folio + " se ha finalizado satisfactoriamente", autoCloseDelay: 3000}));
            if( objResponse.action) {
              this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
              this.init();
            } else
              this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
          }, error => {
            this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
          });
      }}));
  }

  public gradientPercentageMantenimientoTaller(itemUnidadMantenimientoTaller : any) : string {
    let strReturn : string = 'rgb(233, 236, 239)';
    let porcentaje : number = (itemUnidadMantenimientoTaller.idUnidadMantenimientoTallerEstatus != 5)? itemUnidadMantenimientoTaller.diasLaboralesPorcentaje : 100;
    if (itemUnidadMantenimientoTaller.isEnTiempo) {
      switch (itemUnidadMantenimientoTaller.idUnidadMantenimientoTallerEstatus) {
        case 2:
          strReturn = 'rgb(64, 154, 233)';
          break;
        case 3:
          strReturn = 'rgb(33, 193, 214)';
          break;
        case 4:
          strReturn = 'rgb(230, 81, 0)';
          break;
        case 5:
          strReturn = 'rgb(252, 75, 108)';
          break;
      }
    } else {
      strReturn = 'rgb(252, 75, 108)';
    }

    return strReturn + ' ' + porcentaje + '%, #E9ECEF ' + porcentaje + '%';
  }

  public gradientPercentageMantenimiento(itemUnidadMantenimientoTaller : any) : string {
    let strReturn : string = 'rgb(233, 236, 239)';
    let porcentaje : number = (itemUnidadMantenimientoTaller.idUnidadMantenimientoEstatus != 5)? itemUnidadMantenimientoTaller.unidadMantenimientoPorcentaje : 100;
    if (itemUnidadMantenimientoTaller.unidadMantenimientoIsEnTiempo) {
      switch (itemUnidadMantenimientoTaller.idUnidadMantenimientoEstatus) {
        case 2:
          strReturn = 'rgb(64, 154, 233)';
          break;
        case 3:
          strReturn = 'rgb(33, 193, 214)';
          break;
        case 4:
          strReturn = 'rgb(230, 81, 0)';
          break;
        case 5:
          strReturn = 'rgb(252, 75, 108)';
          break;
      }
    } else {
      strReturn = 'rgb(252, 75, 108)';
    }

    return strReturn + ' ' + porcentaje + '%, #E9ECEF ' + porcentaje + '%';
  }

  public gradientPercentageOrdenMantenimiento(itemUnidadMantenimientoTaller : any) : string {
    let strReturn : string = 'rgb(233, 236, 239)';
    let porcentaje : number = (itemUnidadMantenimientoTaller.idUnidadMantenimientoEstatus != 5)? itemUnidadMantenimientoTaller.diasLaboralesPorcentaje : 100;
    if (itemUnidadMantenimientoTaller.isEnTiempo) {
      switch (itemUnidadMantenimientoTaller.idUnidadMantenimientoEstatus) {
        case 2:
          strReturn = 'rgb(64, 154, 233)';
          break;
        case 3:
          strReturn = 'rgb(33, 193, 214)';
          break;
        case 4:
          strReturn = 'rgb(230, 81, 0)';
          break;
        case 5:
          strReturn = 'rgb(252, 75, 108)';
          break;
      }
    } else {
      strReturn = 'rgb(252, 75, 108)';
    }

    return strReturn + ' ' + porcentaje + '%, #E9ECEF ' + porcentaje + '%';
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }
}
