import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CotizacionesService} from "../../../../services/cotizaciones.service";
import {AuthService} from "../../../../services/auth.service";
import {FormOptimizer} from "../../../../libraries/formOptimizer";
import {Globals} from "../../../../libraries/globals";
import {ConfirmComponent} from "../../../../components/confirm/confirm.component";
import {SuccessComponent} from "../../../../components/success/success.component";
import {AlertComponent} from "../../../../components/alert/alert.component";

@Component({
  selector: 'app-cotizacion-approve',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.scss']
})
export class CotizacionApproveComponent implements OnInit, AfterViewInit {

  @Output('onClose') onClose : EventEmitter<CotizacionApproveComponent> = new EventEmitter<CotizacionApproveComponent>();

  public lstCotizacionesPartidas : Array<any> = [];
  public lstCotizacionesImpuestos: Array<any> = [];
  public objCotizacion           : any = {};
  public solicitudUsuario        : string = '';
  public solicitudFecha          : string = '';
  public empresa                 : string = '';
  public sucursal                : string = '';
  public comentarios             : string = '';
  public cliente                 : any;
  public clienteContacto         : any;
  public moneda                  : any = {};
  public formaPago               : any = {};
  public idCotizacion            : number = 0;
  public equivalencia            : number = 0;
  public subtotal                : number = 0;
  public aprobacionSubtotal      : number = 0;
  public total                   : number = 0;
  public aprobacionTotal         : number = 0;
  public enableForm              : boolean = true;

  public objLoader = {
    type            : 'loader',
    visible         : false
  };

  public objInput = {
    type     : 'input',
    disabled : false
  };

  public objChkBox = {
    type     : 'checkbox',
    disabled : false
  };

  public btnOptionBar = {
    type            : 'button',
    disabled        : false
  };

  public btnAccion = {
    type            : 'button',
    disabled        : false
  };

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  };

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  };

  public formComponents: Array<any> = [
    this.objChkBox,
    this.objInput,
    this.btnOptionBar,
    this.btnAccion,
    this.objLoader,
    this.btnAceptar,
    this.btnCancelar
  ];

  public boolChkCotizacionPartida          : boolean = false;
  public boolChkSomeCotizacionPartida      : boolean = false;

  constructor(
    private objDialogRef: MatDialogRef<CotizacionApproveComponent>,
    private objMatDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private objCotizacionesService : CotizacionesService,
    private objAuthService : AuthService
  ) { }

  ngOnInit(): void {
    FormOptimizer.formDisable(this.formComponents);
  }

  ngAfterViewInit() : void {
    setTimeout( ()=> this.init(), 500);
  }

  public init() : void {
    let objCotizacionEdit : any = {
      idCotizacion : this.data.idCotizacion,
      isDuplicate : false
    }

    this.objCotizacionesService.edit(objCotizacionEdit)
      .subscribe( objResponse => {
        const objResult          = objResponse.result;
        const objCotizacion      = objResult.cotizacion;
        const objUsuario         = objCotizacion.solicitudUsuario;
        const objCliente         = objCotizacion.cliente;
        const objClienteContacto = objCotizacion.clienteContacto;

        this.idCotizacion        = objCotizacion.idCotizacion;
        this.solicitudFecha      = objCotizacion.solicitudFechaNatural;
        this.solicitudUsuario    = objUsuario.nombreCompleto;
        this.comentarios         = objCotizacion.comentarios;
        if(Globals.exist(objCotizacion.empresaNombreComercial)) this.empresa         = objCotizacion.empresaNombreComercial;
        if(Globals.exist(objCotizacion.sucursal))               this.sucursal        = objCotizacion.sucursal;
        if(Globals.exist(objCotizacion.cliente))                this.cliente         = objCliente;
        if(Globals.exist(objCotizacion.clienteContacto))        this.clienteContacto = objClienteContacto;
        if(Globals.exist(objCotizacion.idMoneda)) {
          this.moneda['idMoneda']           = objCotizacion.idMoneda;
          this.moneda['moneda']             = objCotizacion.moneda;
          this.moneda['codigo']             = objCotizacion.monedaCodigo;
          this.moneda['equivalencia']       = objCotizacion.monedaEquivalencia;
        }
        if(Globals.exist(objCotizacion.formaPago)) {
          this.formaPago['idFormaPago'] = objCotizacion.idFormaPago;
          this.formaPago['formaPago']   = objCotizacion.formaPago;
        }
        this.lstCotizacionesImpuestos = objCotizacion.impuestos as Array<any>;
        this.lstCotizacionesPartidas  = objCotizacion.partidas  as Array<any>;
        this.equivalencia             = objCotizacion.equivalencia;
        this.subtotal                 = objCotizacion.subtotal
        this.aprobacionSubtotal       = objCotizacion.subtotal
        this.total                    = objCotizacion.total
        this.aprobacionTotal          = objCotizacion.total

        this.iniciacionPartida();
        this.iniciacionImpuestos();

        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        FormOptimizer.formEnable(this.formComponents);
      });
  }

  public iniciacionPartida() : void {
    for( let index = 0; index < this.lstCotizacionesPartidas.length; index++) {

      this.lstCotizacionesPartidas[index]['aprobacionCantidad'] = this.lstCotizacionesPartidas[index].cantidad;
      this.lstCotizacionesPartidas[index]['aprobacionImporte'] = this.lstCotizacionesPartidas[index].importe;
      if(!Globals.exist(this.lstCotizacionesPartidas[index].cotizacionPartidaTipoDescuento)) {
        this.lstCotizacionesPartidas[index]['cotizacionPartidaTipoDescuento'] = {
          idCotizacionPartidaTipoDescuento : 1
        }
      }
      this.lstCotizacionesPartidas[index]['disabled'] = false;

      let aprobacionImpuestos : Array<any> = [];

      this.lstCotizacionesPartidas[index].impuestosPartida.forEach( (itemImpuestos : any) => {
        itemImpuestos['checked'] = (itemImpuestos.total > 0);
        let total : number = itemImpuestos.total;
        aprobacionImpuestos.push({
          idImpuesto     : itemImpuestos.impuesto.idImpuesto,
          impuesto       : itemImpuestos.impuesto.impuesto,
          idImpuestoTipo : itemImpuestos.impuestoTipo.idImpuestoTipo,
          porcentaje     : itemImpuestos.porcentaje,
          total          : total.toFixed(2),
          checked        : itemImpuestos.checked
        });
      });

      this.lstCotizacionesPartidas[index]['aprobacionImpuestos'] = aprobacionImpuestos;
      this.calcPrecioXCantidad(index);
      this.calcularDescuento(index);
    }
  }

  public iniciacionImpuestos() : void {
    for(let index : number = 0; index < this.lstCotizacionesImpuestos.length; index++) {
      this.lstCotizacionesImpuestos[index]['aprobacionTotal'] = this.lstCotizacionesImpuestos[index].total;
    }
  }

  public formCotizacionAprobacion_submitEvent() : void {
    this.objMatDialog.open(ConfirmComponent, Globals.successConfig({
      titulo : 'Aprobar cotización',
      mensaje : '¿Desea aprobar la cotización?',
      fnAccept : () => {
        this.enableForm = false;
        this.fnCotizacionAprobada();
      }} ));
  }

  public fnCotizacionAprobada() : void {
    let lstPartidaStore  : Array<any> = [];
    let lstImpuestoStore : Array<any> = [];

    this.lstCotizacionesPartidas.forEach( itemCotizacionPartida => {
      if(!itemCotizacionPartida.disabled) {
        let importe           : number = Number(itemCotizacionPartida.aprobacionImporte);
        let cantidad          : number = Number(itemCotizacionPartida.aprobacionCantidad);
        let precioUnitario    : number = Number(itemCotizacionPartida.precioUnitario);
        let descuentoCantidad : number = Number(itemCotizacionPartida.descuentoCantidad);
        let partidaAprobada   : any = {
          concepto          : itemCotizacionPartida.concepto,
          idMoneda          : itemCotizacionPartida.moneda.idMoneda,
          idServicio        : itemCotizacionPartida.servicio.idServicio,
          idUnidadMedida    : itemCotizacionPartida.unidadMedida.idUnidadMedida,
          idTipoDescuento   : itemCotizacionPartida.cotizacionPartidaTipoDescuento.idCotizacionPartidaTipoDescuento,
          idLogisticaTipo   : itemCotizacionPartida.logisticaTipo.idLogisticaTipo,
          cantidad          : cantidad.toFixed(2),
          precioUnitario    : precioUnitario.toFixed(2),
          importe           : importe.toFixed(2),
          impuestos         : itemCotizacionPartida.aprobacionImpuestos,
          descuentoCantidad : descuentoCantidad.toFixed(2),
        }
        lstPartidaStore.push(partidaAprobada);
      }
    });

    this.lstCotizacionesImpuestos.forEach( itemImpuestos => {
      let total : number = itemImpuestos.aprobacionTotal;
      let impuestoAprobado : any = {
        total          : total.toFixed(2),
        idImpuesto     : itemImpuestos.impuesto.idImpuesto,
        idImpuestoTipo : itemImpuestos.impuestoTipo.idImpuestoTipo
      }

      lstImpuestoStore.push(impuestoAprobado);
    });

    let cotizacionAprobada : any = {
      idCotizacion : this.idCotizacion,
      lstPartidas  : lstPartidaStore,
      lstImpuestos : lstImpuestoStore
    }

    FormOptimizer.formDisable(this.formComponents);
    this.objCotizacionesService.updateEstatusAprobar(cotizacionAprobada)
      .subscribe( objResponse => {
        if( objResponse.action) {
          this.objCotizacion = objResponse.result.cotizacion;
          this.close();
          this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
        } else
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
        FormOptimizer.formEnable(this.formComponents);
      }, error => {
        this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
        FormOptimizer.formEnable(this.formComponents);
      });
  }

  public chkCotizacionPartidaInitSelected_changeEvent() : void {
    this.boolChkCotizacionPartida = false;
    this.boolChkSomeCotizacionPartida = false;
  }

  public chkCotizacionesPartidaSelected_changeEvent( event : any ) : void {
    this.boolChkCotizacionPartida = event;
    this.lstCotizacionesPartidas.forEach((itemCotizacionPartida : any) => {
      if(!itemCotizacionPartida.disabled) itemCotizacionPartida.checked = this.boolChkCotizacionPartida
    });
    this.boolChkSomeCotizacionPartida = this.lstCotizacionesPartidas.some((itemCotizacionPartida : any) => itemCotizacionPartida.checked);
  }

  public chkItemCotizacionesPartidaSelected_changeEvent() : void {
    this.boolChkCotizacionPartida = this.lstCotizacionesPartidas.every( (itemCotizacionPartida : any) => itemCotizacionPartida.checked );
    this.boolChkSomeCotizacionPartida = this.lstCotizacionesPartidas.some( (itemCotizacionPartida : any) => itemCotizacionPartida.checked );
  }

  public calcPrecioXCantidad(index : number) : void {
    const objMoneda                 : any    = this.lstCotizacionesPartidas[index].moneda;
    const precioUnitario            : number = Number(this.lstCotizacionesPartidas[index].precioUnitario);
    const cantidad                  : number = Number(this.lstCotizacionesPartidas[index].aprobacionCantidad);
    let precioUnitarioXEquivalencia : number = 0;
    let precioUnitarioXCantidad     : number = 0;

    if (Globals.exist(this.moneda.idMoneda) && this.moneda != null) {
      if (this.moneda.idMoneda != objMoneda.idMoneda) {
        if (this.moneda.idMoneda != 1) {
          precioUnitarioXEquivalencia = precioUnitario / this.equivalencia;
        } else if (objMoneda.idMoneda != 1) {
          precioUnitarioXEquivalencia = precioUnitario * this.equivalencia;
        }
        precioUnitarioXCantidad = cantidad * precioUnitarioXEquivalencia;
      } else {
        precioUnitarioXCantidad = cantidad * precioUnitario;
      }
    }
    this.lstCotizacionesPartidas[index]['precioUnitarioXCantidad'] = precioUnitarioXCantidad.toFixed(2);
  }

  public calcularDescuento(index : number) : void {
    let precioUnitarioXCantidad : number = Number(this.lstCotizacionesPartidas[index].precioUnitarioXCantidad);
    let importe                 : number = Number(this.lstCotizacionesPartidas[index].importe);
    let descuentoCantidad       : number = precioUnitarioXCantidad - importe;
    let descuentoPorcentaje     : number = 0;

    this.lstCotizacionesPartidas[index]['descuentoCantidad'] = descuentoCantidad.toFixed(2);
    descuentoPorcentaje = (descuentoCantidad * 100) / precioUnitarioXCantidad;
    this.lstCotizacionesPartidas[index]['descuentoPorcentaje'] = descuentoPorcentaje.toFixed(2);
  }

  public calcDescuento(index : number) : void {
    const tipoDescuento : number = this.lstCotizacionesPartidas[index].cotizacionPartidaTipoDescuento.idCotizacionPartidaTipoDescuento;
    switch (tipoDescuento) {
      case 1:
        this.calcDescuentoCantidad(index);
        break;
      case 2:
        this.calcDescuentoPorcentaje(index);
        break;
    }
  }

  public calcDescuentoCantidad(index : number) : void {
      let descuentoCantidad   : number = 0;
      let descuentoPorcentaje : number = 0;

      if (Globals.exist(this.lstCotizacionesPartidas[index].descuentoCantidad) && this.lstCotizacionesPartidas[index].descuentoCantidad != null && this.lstCotizacionesPartidas[index].descuentoCantidad != 0) {
        let precioUnitarioXCantidad : number = Number(this.lstCotizacionesPartidas[index].precioUnitarioXCantidad);
        descuentoCantidad = Number(this.lstCotizacionesPartidas[index].descuentoCantidad);
          descuentoPorcentaje = (descuentoCantidad / precioUnitarioXCantidad) * 100;
      }

    this.lstCotizacionesPartidas[index].descuentoPorcentaje = descuentoPorcentaje.toFixed(2);
  }

  public calcDescuentoPorcentaje(index : number) : void {
      let descuentoCantidad   : number = 0;
      let descuentoPorcentaje : number = 0;

    if (Globals.exist(this.lstCotizacionesPartidas[index].descuentoPorcentaje) && this.lstCotizacionesPartidas[index].descuentoPorcentaje != null && this.lstCotizacionesPartidas[index].descuentoPorcentaje != 0) {
      let precioUnitarioXCantidad : number = Number(this.lstCotizacionesPartidas[index].precioUnitarioXCantidad);
      descuentoPorcentaje = Number(this.lstCotizacionesPartidas[index].descuentoPorcentaje);
      descuentoCantidad = (precioUnitarioXCantidad * descuentoPorcentaje) / 100;
    }

    this.lstCotizacionesPartidas[index].descuentoCantidad = descuentoCantidad.toFixed(2);
  }

  public btnLstPartidasDelete_clickEvent() : void {
    this.lstCotizacionesPartidas.forEach((itemCotizacionPartida : any) => {
      if (itemCotizacionPartida.checked) {
        itemCotizacionPartida.disabled = true;
        itemCotizacionPartida.checked = false;
      }
    });
    this.chkCotizacionPartidaInitSelected_changeEvent();
  }

  public btnPartidaDelete_clickEvent(index : number) : void {
    this.lstCotizacionesPartidas[index].disabled = true;
    this.calcPrecioXCantidad(index);
    this.calcDescuento(index);
    this.calcImporte(index);
  }

  public btnPartidaDone_clickEvent(index : number) : void {
    this.lstCotizacionesPartidas[index].disabled = false;
    this.calcPrecioXCantidad(index);
    this.calcDescuento(index);
    this.calcImporte(index);
  }

  public calcImporte_inputChangeEvent(event : any, index : number) : void {
    const cantidad : number = event.target.value;
    this.lstCotizacionesPartidas[index].aprobacionCantidad = cantidad;
    this.calcPrecioXCantidad(index);
    this.calcDescuento(index);
    this.calcImporte(index);
  }

  public calcImporte(index : number) : void {
    let cantidad : number = Number(this.lstCotizacionesPartidas[index].aprobacionCantidad);
    this.lstCotizacionesImpuestos.forEach(itemCotizacionImpuesto => itemCotizacionImpuesto.aprobacionTotal = 0);
    if( cantidad != null && cantidad != 0 && !this.lstCotizacionesPartidas[index].disabled) {
      let precioUnitarioXCantidad : number = Number(this.lstCotizacionesPartidas[index].precioUnitarioXCantidad);
      let descuentoCantidad       : number = Number(this.lstCotizacionesPartidas[index].descuentoCantidad);
      let importe                 : number = precioUnitarioXCantidad - descuentoCantidad;

      this.lstCotizacionesPartidas[index].aprobacionImporte = importe.toFixed(2);
      this.lstCotizacionesPartidas[index].aprobacionImpuestos.forEach( (itemImpuesto : any) => {
        if(itemImpuesto.checked) {
          let impuesto: number = precioUnitarioXCantidad * (itemImpuesto.porcentaje / 100);
          itemImpuesto.total = impuesto.toFixed(2);
        }
      });

    } else {
      this.lstCotizacionesPartidas[index].aprobacionImporte = 0;
      this.lstCotizacionesPartidas[index].aprobacionImpuestos.forEach((itemImpuesto : any) => {
        itemImpuesto.total = '0.00';
      });
    }

    this.aprobacionSubtotal = this.calcSubtotalAprobado();
    this.calcImpuestoPartidaAprobado();
    this.aprobacionTotal = this.calcTotalAprobado();
  }

  public calcSubtotalAprobado() : number {
    let subtotal : number = 0;

    this.lstCotizacionesPartidas.forEach(itemCotizacionPartida => {
      subtotal += Number(itemCotizacionPartida.aprobacionImporte);
    });

    return subtotal;
  }

  public calcImpuestoPartidaAprobado() : void {
    this.lstCotizacionesPartidas.forEach(itemCotizacionPartida => {
      if(Globals.exist(itemCotizacionPartida.aprobacionImpuestos)) {
        let lstImpuestosAprobado: Array<any> = itemCotizacionPartida.aprobacionImpuestos as Array<any>;
        for (let index: number = 0; index < lstImpuestosAprobado.length; index++) {
          this.lstCotizacionesImpuestos[index].aprobacionTotal += Number(lstImpuestosAprobado[index].total);
        }
      }
    });
  }

  public calcTotalAprobado() : number {
    let totalImpuestoTraslado : number = 0;
    let totalImpuestoRetenido : number = 0;

    for (let index: number = 0; index < this.lstCotizacionesImpuestos.length; index++) {
      if(this.lstCotizacionesImpuestos[index].impuestoTipo.idImpuestoTipo == 1) {
        totalImpuestoTraslado += this.lstCotizacionesImpuestos[index].aprobacionTotal;
      } else {
        totalImpuestoRetenido += this.lstCotizacionesImpuestos[index].aprobacionTotal;
      }
    }

    return (this.aprobacionSubtotal + totalImpuestoTraslado) - totalImpuestoRetenido;
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

}
