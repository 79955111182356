import { DatePipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { FormOptimizer } from 'src/app/libraries/formOptimizer';
import { Globals } from 'src/app/libraries/globals';

@Component({
  selector: 'tr[app-usuarios-ficheros-file-item]',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss']
})
export class UsuariosFicherosFileItemComponent implements OnInit {

  public fcNombre              : FormControl = new FormControl('', [Validators.required]);
  public fcTamano              : FormControl = new FormControl('');
  public fcFicheroTipo         : FormControl = new FormControl('', [Validators.required]);
  public fcVigenciaFechaInicio : FormControl = new FormControl('');
  public fcVigenciaFechaFin    : FormControl = new FormControl('');
  public JSON = JSON;

  private _itemFile : any= '';
  @Input('itemFile')
  get itemFile(): any { return this._itemFile; }
  set itemFile(value: any) {
    this._itemFile = value;
    this.fcNombre.setValue(this._itemFile.name);
    this.fcTamano.setValue(this._itemFile.size);
    this.usuarioFicheroTipo_initEvent();
  }
  @Input('index') index : number;
  @Input('usuariosFicherosTipos') usuariosFicherosTipos : Array<any> = [];
  @ViewChild('sltUsuarioFicheroTipo') sltUsuarioFicheroTipo : ElementRef;

  @Output('onDelete') onDelete : EventEmitter<UsuariosFicherosFileItemComponent> = new EventEmitter<UsuariosFicherosFileItemComponent>();
  @Output('onChange') onChange : EventEmitter<UsuariosFicherosFileItemComponent> = new EventEmitter<UsuariosFicherosFileItemComponent>();
  @Output('onInit') onInit     : EventEmitter<UsuariosFicherosFileItemComponent> = new EventEmitter<UsuariosFicherosFileItemComponent>();

  public usuarioFicheroTipo : any = {};
  public uploadResponse = { status: '', uploadProgress: '', message : '', boolFinalize : false, boolInitial : true, boolSuccess : true };

  constructor(  public objDatePipe : DatePipe ) { }

  ngOnInit(): void {
  }

  public btnFicheroUsuario_eliminarEvent( evento : any ) : void {
    this.onDelete.emit(this);
  }

  public validate() : boolean {
    let objReturn : boolean = true;

    this.fcNombre.markAsTouched();
    this.fcFicheroTipo.markAsTouched();
    this.fcVigenciaFechaInicio.markAsTouched();
    this.fcVigenciaFechaFin.markAsTouched();
    this.vigenciaAppaerance();

    // DATES
    if(this.usuarioFicheroTipo.vigencia && (this.fcVigenciaFechaInicio.hasError('required') || this.fcVigenciaFechaFin.hasError('required')) ) objReturn = false;

    // VALIDAR INPUT
    if( this.fcNombre.hasError('required') ) objReturn = false;

    // VALIDAR SELECT
    if(JSON.stringify(this.fcFicheroTipo.value) === '{}' ) objReturn = false;

    return objReturn;
  }

  private vigenciaAppaerance() :void {
    if( this.usuarioFicheroTipo.vigencia ) {
      this.fcVigenciaFechaInicio.setValidators([Validators.required]);
      this.fcVigenciaFechaInicio.updateValueAndValidity();
      this.fcVigenciaFechaFin.setValidators([Validators.required]);
      this.fcVigenciaFechaFin.updateValueAndValidity();
    } else {
      this.fcVigenciaFechaInicio.clearValidators();
      this.fcVigenciaFechaInicio.updateValueAndValidity();
      this.fcVigenciaFechaInicio.setValue("");
      this.fcVigenciaFechaFin.clearValidators();
      this.fcVigenciaFechaFin.updateValueAndValidity();
      this.fcVigenciaFechaFin.setValue("");
    }
  }

  public getUsuarioFichero() : Array<any> {

    let objReturn : any;

    if( this.validate() )  {
      objReturn = {
        nombre                : this.fcNombre.value,
        tamano                : FormOptimizer.formDataNumber( this.fcTamano ),
        idUsuarioFicheroTipo  : (Globals.exist(this.usuarioFicheroTipo)) ? this.usuarioFicheroTipo.idUsuarioFicheroTipo : null,
        vigencia              : (Globals.exist(this.usuarioFicheroTipo)) ? this.usuarioFicheroTipo.vigencia : false,
        vigenciaFechaInicio   : (Globals.exist(this.usuarioFicheroTipo) && this.usuarioFicheroTipo.vigencia) ? (( Globals.exist(this.fcVigenciaFechaInicio.value) )? this.objDatePipe.transform( this.fcVigenciaFechaInicio.value, 'yyyy-MM-dd') : '') : '',
        vigenciaFechaFin      : (Globals.exist(this.usuarioFicheroTipo) && this.usuarioFicheroTipo.vigencia) ? (( Globals.exist(this.fcVigenciaFechaFin.value) )? this.objDatePipe.transform( this.fcVigenciaFechaFin.value, 'yyyy-MM-dd') : '') : '',
        file                  : this.itemFile
      };
    }

    return objReturn;
  }

  public usuarioFicheroTipo_selectionChangeEvent(event : any) : void {
    this.vigenciaAppaerance();
    this.onChange.emit(this);
  }

  public usuarioFicheroTipo_initEvent() {
    this.onInit.emit(this);
  }

  public formDisable() : void {
    FormOptimizer.formDisable([this.fcNombre, this.fcFicheroTipo, this.fcVigenciaFechaInicio, this.fcVigenciaFechaFin]);
  }

  public formEnable() : void {
    FormOptimizer.formEnable([this.fcNombre, this.fcFicheroTipo, this.fcVigenciaFechaInicio, this.fcVigenciaFechaFin]);
  }

}
