import { Routes } from '@angular/router';
import { AppBlankComponent } from './layouts/blank/blank.component';
import { FullComponent } from './layouts/full/full.component';
import { CpGuard } from './guards/cp.guard';

export const AppRoutes: Routes = [
  {
    path        : '',
    redirectTo  : 'auth/login',
    pathMatch   : 'full'
  },
  {
    path: 'dashboard/mantenimiento',
    loadChildren  : () => import('./routes/dashboardMantenimiento/dashboard-mantenimientos.module').then(m => m.DashboardMantenimientosModule)
  },
  {
    path: 'dashboard/mantenimiento/taller',
    loadChildren  : () => import('./routes/dashboardMantenimientoTaller/dashboard-mantenimientos-taller.module').then(m => m.DashboardMantenimientosTallerModule)
  },
  {
    path          : 'cp',
    component     : FullComponent,
    canActivate   : [CpGuard],
    children: [
      {
          path: '',
          redirectTo: 'inicio',
          pathMatch: 'full'
      }
      ,{
        path: 'inicio',
        loadChildren  : () => import('./routes/inicio/inicio.module').then(m => m.InicioModule)
      }
      ,{
        path: 'usuarios',
        loadChildren  : () => import('./routes/usuarios/usuarios.module').then(m => m.UsuariosModule)
      }
      ,{
        path: 'organigrama',
        loadChildren  : () => import('./routes/organigrama/organigrama.module').then(m => m.OrganigramaModule)
      }
      ,{
        path: 'clientes',
        loadChildren  : () => import('./routes/clientes/clientes.module').then(m => m.ClientesModule)
      },
      {
        path: 'proveedores',
        loadChildren  : () => import('./routes/proveedores/proveedores.module').then(m => m.ProveedoresModule)
      },
      {
        path: 'cotizaciones',
        loadChildren  : () => import('./routes/cotizaciones/cotizaciones.module').then(m => m.CotizacionesModule)
      },
      {
        path: 'productos',
        loadChildren  : () => import('./routes/productos/productos.module').then(m => m.ProductosModule)
      },
      {
        path: 'unidades',
        loadChildren  : () => import('./routes/unidades/unidades.module').then(m => m.UnidadesModule)
      },
      {
        path: 'mantenimientos',
        loadChildren  : () => import('./routes/mantenimientos/mantenimientos.module').then(m => m.MantenimientosModule)
      },
      {
        path: 'taller/:idUnidadMantenimientoTaller',
        loadChildren  : () => import('./routes/mantenimientosTalleres/unidades-mantenimientos-talleres.module').then(m => m.UnidadesMantenimientosTalleresModule)
      },
      {
        path: 'ordenes-pedidos',
        loadChildren  : () => import('./routes/ordenesPedidos/ordenesPedidos.module').then(m => m.OrdenesPedidosModule)
      },
      {
        path: 'ordenes-servicios',
        loadChildren  : () => import('./routes/ordenesServicios/ordenesServicios.module').then(m => m.OrdenesServiciosModule)
      },
      {
        path: 'mesa-control',
        loadChildren  : () => import('./routes/mesa_control/mesa-control.module').then(m => m.MesaControlModule)
      },
      {
        path: 'contratos',
        loadChildren  : () => import('./routes/contratos/contratos.module').then(m => m.ContratosModule)
      },{
        path: 'gerenciamientos',
        loadChildren  : () => import('./routes/gerenciamientos/gerenciamientos.module').then(m => m.GerenciamientosModule)
      },{
        path: 'concursos',
        loadChildren  : () => import('./routes/concursos/concursos.module').then(m => m.ConcursosModule)
      },
      {
        path: 'configuraciones',
        loadChildren  : () => import('./routes/configuraciones/configuraciones.module').then(m => m.configuracionesModule)
      },
      {
        path: 'tutoriales',
        loadChildren  : () => import('./routes/tutoriales/tutoriales.module').then(m => m.TutorialesModule)
      },
      {
        path: 'capacitacion',
        loadChildren  : () => import('./routes/capacitacion/capacitacion.module').then(m => m.CapacitacionModule)
      },
      {
        path: 'preguntas',
        loadChildren  : () => import('./routes/asked-questions/asked-questions.module').then(m => m.AskedQuestionsModule)
      },
      {
        path: 'account',
        loadChildren  : () => import('./routes/cuenta/cuenta.module').then(m => m.CuentaModule)
      }
    ]
  },
  {
    path          : 'auth',
    component     : AppBlankComponent,
    loadChildren  : () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
      path: '**',
      redirectTo: 'authentication/404'
  }
];
