<div class="mat-dialog-title">
  <div class="title">Evaluación de riesgos</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content-full-area" [style.height.%]="100">
    <div class="bs-sidebar-left" [style.width.px]="240">
      <div class="bs-content">
        <div class="row">
          <div class="col text-center">
            <span class="material-icons" [style.fontSize.px]="80">description</span>
          </div>
        </div>
        <div class="bs-row">
          <div class="bs-col">
            <mat-selection-list [(ngModel)]="objScene" [multiple]="false">
              <mat-list-option [value]="'DETALLES'">Detalles</mat-list-option>
              <mat-list-option [value]="'REQUERIMIENTO'">Criterio de evaluación</mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>

    <div class="bs-viewport">
      <!--   INICIO SOLICITUD   -->
      <div class="datatable-responsive layout" [style.height.%]="100" [hidden]="objScene[0] != 'DETALLES'">
        <div class="bs-content">
          <div class="bs-row">
            <div class="bs-col">
              <div class="section-title"><span>Información de solicitud</span></div>
            </div>
          </div>

          <div class="bs-row">
            <div class="bs-col-6 bs-col-flex">
              <div class="label">Cliente</div>
              <div class="field">{{objOrdenServicio?.clienteNombreComercial}}</div>
            </div>
            <div class="bs-col-3 bs-col-flex">
              <div class="label">Viaje es necesario</div>
              <div class="field">
                <div class="badge" [class]="objOrdenServicioMiembro?.isViajeNecesario? 'success' : 'danger'">{{objOrdenServicioMiembro?.isViajeNecesario?'Sí':'No'}}</div>
              </div>
            </div>
            <div class="bs-col-3 bs-col-flex">
              <div class="label">Reunión previa</div>
              <div class="field">
                <div class="badge" [class]="objOrdenServicioMiembro?.hasReunionPrevia? 'success' : 'danger'">{{objOrdenServicioMiembro?.hasReunionPrevia?'Sí':'No'}}</div>
              </div>
            </div>
          </div>

          <div class="bs-row" [style.marginTop.px]="10">
            <div class="bs-col-2 bs-col-flex">
              <div class="label">Fecha de solicitud</div>
              <div class="field">{{objOrdenServicio?.fechaNatural}}</div>
            </div>

            <div class="bs-col-5 bs-col-flex">
              <div class="label">Origen</div>
              <div class="field">{{objOrdenServicio?.direccionOrigen}}</div>
            </div>

            <div class="bs-col-5 bs-col-flex">
              <div class="label">Destino</div>
              <div class="field">{{objOrdenServicio?.direccionDestino}}</div>
            </div>
          </div>

          <div class="bs-row" [style.marginTop.px]="10">
            <div class="bs-col-6 bs-col-flex">
              <div class="label">Orden servicio</div>
              <div class="field">{{objOrdenServicio?.folio}}</div>
            </div>

            <div class="bs-col-6 bs-col-flex">
              <div class="label">Tipo de gerenciamiento</div>
              <div class="field">{{objOrdenServicioMiembro?.gerenciamientoTipo}}</div>
            </div>
          </div>

          <div class="bs-row" [style.marginTop.px]="10">
            <div class="bs-col bs-col-flex">
              <div class="label">Descripción de carga</div>
              <div class="field">
                <textarea class="table-textarea" [formControl]="fcDescripcionCarga" cdkTextareaAutosize rows="3" cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" [style.width.%]="100" maxlength="1023"></textarea>
              </div>
            </div>
          </div>

          <div class="bs-row" [style.marginTop.px]="10">
            <div class="bs-col-2 bs-col-flex">
              <div class="label">Unidad</div>
              <div class="field">{{objOrdenServicioMiembro?.unidadNumeroEconomico}}</div>
            </div>

            <div class="bs-col-2 bs-col-flex">
              <div class="label">Pasajeros</div>
              <div class="field">{{numPasajeros}}</div>
            </div>

            <div class="bs-col-2 bs-col-flex">
              <div class="label">Km de salida</div>
              <div class="field">{{objOrdenServicioMiembro?.unidadOdometroNatural}}</div>
            </div>

            <div class="bs-col-2 bs-col-flex">
              <div class="label">Placas</div>
              <div class="field">{{objOrdenServicioMiembro?.unidadPlacaFederal}}</div>
            </div>

            <div class="bs-col-3 bs-col-flex">
              <div class="label">Tipo de unidad</div>
              <div class="field">{{objOrdenServicioMiembro?.unidadTipo}}</div>
            </div>

            <div class="bs-col-1 bs-col-flex">
              <div class="label">GPS</div>
              <div class="field">
                <div class="badge" [class]="objOrdenServicioMiembro?.hasUnidadGgs? 'success' : 'danger'">{{objOrdenServicioMiembro?.hasUnidadGgs?'Sí':'No'}}</div>
              </div>
            </div>
          </div>

          <div class="bs-row" [style.marginTop.px]="10">
            <div class="datatable-responsive">
              <table class="datatable">
                <thead>
                <tr>
                  <th>Nombre operador</th>
                  <th [style.width.px]="170">puesto</th>
                  <th [style.width.px]="220">Teléfono</th>
                  <th [style.width.px]="150">Horas dormidas</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let itemOperadorUsuario of lstOperadoresUsuarios; let index = index;">
                  <td>{{itemOperadorUsuario?.nombreCompleto}}</td>
                  <td>{{itemOperadorUsuario?.puesto}}</td>
                  <td>
                    <input type="text" class="table-input text-center" matInput [style.width.%]="100" [(ngModel)]="itemOperadorUsuario['telefono']" maxlength="63" autocomplete="off">
                  </td>
                  <td>
                    <div class="inputs-content" [style.width.px]="100">
                      <input type="text" class="table-input text-center" matInput [style.width.%]="100" [(ngModel)]="itemOperadorUsuario['horasDormidas']" maxlength="10" autocomplete="off">
                      <span class="suffixSpan">Hrs.</span>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="datatable-responsive layout" [style.height.%]="100" [hidden]="objScene[0] != 'REQUERIMIENTO'">
        <div class="bs-content">
          <form [formGroup]="fgGerenciamientoUbicacionesEvaluacionRiesgo" #formDirective="ngForm">
            <div class="main table-border">
              <mat-radio-group formControlName="tablaA">
                <table class="datatable">
                  <caption>A</caption>
                  <thead>
                  <tr>
                    <th>Distancia de la base</th>
                    <th [style.width.px]="100">Pts</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Menor que 80 Km</td>
                    <td><mat-radio-button class="example-margin" value="1">1</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Más de 250 Km</td>
                    <td><mat-radio-button class="example-margin" value="8">8</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Menos de 250 Km</td>
                    <td><mat-radio-button class="example-margin" value="5">5</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Menos que 160 Km</td>
                    <td><mat-radio-button class="example-margin" value="2">2</mat-radio-button></td>
                  </tr>
                  </tbody>
                </table>
              </mat-radio-group>
            </div>

            <div class="main table-border">
              <mat-radio-group formControlName="tablaB">
                <table class="datatable">
                  <caption>B</caption>
                  <thead>
                  <tr>
                    <th>Clima</th>
                    <th [style.width.px]="100">Pts</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Neblina, humo, niebla, penumbra</td>
                    <td><mat-radio-button class="example-margin" value="8">8</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Lluvia</td>
                    <td><mat-radio-button class="example-margin" value="4">4</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>viento fuerte</td>
                    <td><mat-radio-button class="example-margin" value="2">2</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Seco</td>
                    <td><mat-radio-button class="example-margin" value="1">1</mat-radio-button></td>
                  </tr>
                  </tbody>
                </table>
              </mat-radio-group>
            </div>

            <div class="main table-border">
              <mat-radio-group formControlName="tablaC">
                <table class="datatable">
                  <caption>C</caption>
                  <thead>
                  <tr>
                    <th>Vehiculos y pasajeros</th>
                    <th [style.width.px]="100">Pts</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>1 vehiculo y no pasajeros (solo conductor)</td>
                    <td><mat-radio-button class="example-margin" value="8">8</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Un solo vehículo y Dos o más pasajeros</td>
                    <td><mat-radio-button class="example-margin" value="3">3</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Dos o más vehículos y no pasajeros (solo conductores)</td>
                    <td><mat-radio-button class="example-margin" value="2">2</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Dos o más vehículos y Dos o más pasajeros por vehículo</td>
                    <td><mat-radio-button class="example-margin" value="1">1</mat-radio-button></td>
                  </tr>
                  </tbody>
                </table>
              </mat-radio-group>
            </div>

            <div class="main table-border">
              <mat-radio-group formControlName="tablaD">
                <table class="datatable">
                  <caption>D</caption>
                  <thead>
                  <tr>
                    <th>Condiciones de camino</th>
                    <th [style.width.px]="100">Pts</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Terraceria</td>
                    <td><mat-radio-button class="example-margin" value="4">4</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Comb (Terraceria/Pavimentado)</td>
                    <td><mat-radio-button class="example-margin" value="2">2</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Pavimentado</td>
                    <td><mat-radio-button class="example-margin" value="1">1</mat-radio-button></td>
                  </tr>
                  </tbody>
                </table>
              </mat-radio-group>
            </div>

            <div class="main table-border">
              <mat-radio-group formControlName="tablaE">
                <table class="datatable">
                  <caption>E</caption>
                  <thead>
                  <tr>
                    <th>Comunicación</th>
                    <th [style.width.px]="100">Pts</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>No comunicación, un solo vehículo</td>
                    <td><mat-radio-button class="example-margin" value="4">4</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>No comunicación pero en convoy</td>
                    <td><mat-radio-button class="example-margin" value="2">2</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Teléfono celular o satelital/radio </td>
                    <td><mat-radio-button class="example-margin" value="0">0</mat-radio-button></td>
                  </tr>
                  </tbody>
                </table>
              </mat-radio-group>
            </div>

            <div class="main table-border">
              <mat-radio-group formControlName="tablaF">
                <table class="datatable">
                  <caption>F</caption>
                  <thead>
                  <tr>
                    <th>Horas trabajando + duración del viaje</th>
                    <th [style.width.px]="100">Pts</th>
                  </tr>
                  </thead>
                  <thead>
                  <tr>
                    <th>Si el conductor NO durmio 8 horas consecutivas, entonces</th>
                    <th [style.width.px]="100">Pts</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Horas trabajando + horas planeadas de viaje < 16 hs </td>
                    <td><mat-radio-button class="example-margin" value="8">8</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Horas trabajando + horas planeadas de viaje < 14 hs </td>
                    <td><mat-radio-button class="example-margin" value="5">5</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Horas trabajando + horas planeadas de viaje < 12 hs </td>
                    <td><mat-radio-button class="example-margin" value="2">2</mat-radio-button></td>
                  </tr>
                  <thead>
                  <tr>
                    <th>Si el conductor durmio mas de 8 horas consecutivas, entonces</th>
                    <th [style.width.px]="100">Pts</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>Horas trabajando + horas planeadas de viaje < 16 hs </td>
                    <td><mat-radio-button class="example-margin" value="6">6</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Horas trabajando + horas planeadas de viaje < 14 hs </td>
                    <td><mat-radio-button class="example-margin" value="3">3</mat-radio-button></td>
                  </tr>
                  <tr>
                    <td>Horas trabajando + horas planeadas de viaje < 12 hs </td>
                    <td><mat-radio-button class="example-margin" value="1">1</mat-radio-button></td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <th>Horas trabajando + viaje > 16 horas</th>
                    <th>Debe descansar</th>
                  </tr>
                  </tfoot>
                </table>
              </mat-radio-group>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="formOrdenServicioCreate_submitEvent()">Aceptar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>
