<div class="mat-dialog-title">
  <div class="title"> Monto del servicio</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content">
    <form [formGroup]="fgOrdenesServiciosPayment" #formDirective="ngForm">
      <div class="bs-row" [style.marginTop.px]="10">
        <div class="bs-col">
          <mat-form-field>
            <span matPrefix [class.required]="fgOrdenesServiciosPayment.controls['monto'].hasError('required')  && fgOrdenesServiciosPayment.controls['monto'].touched">$ &nbsp;</span>
            <input class="text-right" matInput formControlName="monto" required mask="separator.2" thousandSeparator="," maxlength="1024" placeholder="Monto">
          </mat-form-field>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="formCotizacionCancelacion_submitEvent()">Aceptar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>
