<td class="text-center"></td>

<td class="text-center">
  <button class="datatable-button">
    <mat-icon matTooltip="Eliminar" class="datatable-button-icon" (click)="btnFicheroCliente_eliminarEvent(itemFile)">clear</mat-icon>
  </button>
</td>

<td class="text-left">
  <input matInput class="table-input" maxlength="256" [style.width.%]="100" [formControl]="fcNombre" [class.required]="fcNombre.hasError('required')  && fcNombre.touched">
</td>

<td class="text-right">
  {{fcTamano.value | FileSize}}
</td>

<td></td>
