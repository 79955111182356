<div class="mat-dialog-title">
  <div class="title"> Taller {{unidadMantenimientoTaller}} - Finalizar servicio</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content-full-area" [style.height.%]="100">
    <div class="bs-sidebar-left" [style.width.px]="180">
      <div class="bs-content">
        <div class="row">
          <div class="col text-center">
            <div class="custom-icon-formar">
              <span *ngIf="!objLoader.visible">{{(objGlobal.exist(objTaller) && objGlobal.exist(objTaller?.idUnidadMantenimientoTallerTipo) && objTaller.idUnidadMantenimientoTallerTipo===2)? 'Taller foraneo' : 'Taller interno'}}</span>
              <span class="material-icons" [style.fontSize.px]="80">car_repair</span>
            </div>
          </div>

          <div class="bs-row">
            <div class="bs-col">
              <mat-selection-list [(ngModel)]="objScene" [multiple]="false">
                <mat-list-option  [value]="'DETALLES'">Mantenimiento</mat-list-option>
                <mat-list-option  [value]="'SERVICIOS'">Taller</mat-list-option>
                <mat-list-option  [value]="'GALERIA'">Imagenes</mat-list-option>
              </mat-selection-list>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bs-viewport">
      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'DETALLES'">
        <div class="bs-content">
          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="card-info-content">
                <div class="card-header">Orden mantenimiento</div>
                <div class="card-body">
                  <div class="card-text">
                    <div class="card-label">Folio</div>
                    <div class="card-field">{{objUnidadMantenimiento.folio}}</div>
                  </div>
                  <div class="card-text">
                    <div class="card-label">Estatus</div>
                    <div class="card-field">
                      <span class="badge" [class]="['estatus-' + objUnidadMantenimiento.idUnidadMantenimientoEstatus]">{{objUnidadMantenimiento.unidadMantenimientoEstatus}}</span>
                    </div>
                  </div>
                  <div class="card-text">
                    <div class="card-label">Fecha de ingreso</div>
                    <div class="card-field">{{objUnidadMantenimiento.ingresoFechaNatural}}</div>
                  </div>
                  <div class="card-text">
                    <div class="card-label">Fecha de entrega</div>
                    <div class="card-field">{{objUnidadMantenimiento.tiempoEstimadoActualNatural}}</div>
                  </div>
                </div>
              </mat-card>
            </div>

            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="card-info-content">
                <div class="card-header">Taller</div>
                <div class="card-body">
                  <div class="card-text">
                    <div class="card-label">Folio</div>
                    <div class="card-field">{{objUnidadMantenimientoTaller.folio}}</div>
                  </div>
                  <div class="card-text" *ngIf="objGlobal.exist(objTaller?.nombreComercial)">
                    <div class="card-label">Nombre comercial</div>
                    <div class="card-field">{{objTaller.nombreComercial}}</div>
                  </div>
                  <div class="card-text" *ngIf="objGlobal.exist(objTaller?.razonSocial)">
                    <div class="card-label">Razón social</div>
                    <div class="card-field">{{objTaller.razonSocial}}</div>
                  </div>
                  <div class="card-text">
                    <div class="card-label">Estatus</div>
                    <div class="card-field">
                      <span class="badge" [class]="['estatus-' + objUnidadMantenimientoTaller.idUnidadMantenimientoTallerEstatus]">{{objUnidadMantenimientoTaller.unidadMantenimientoTallerEstatus}}</span>
                    </div>
                  </div>
                  <div class="card-text" *ngIf="objGlobal.exist(objTaller?.responsable)">
                    <div class="card-label">Nombre responsable</div>
                    <div class="card-field">{{objTaller.responsable}}</div>
                  </div>
                  <div class="card-text">
                    <div class="card-label">Fecha de inicio</div>
                    <div class="card-field">{{objUnidadMantenimientoTaller.servicioInicioFechaNatural}}</div>
                  </div>
                  <div class="card-text">
                    <div class="card-label">Fecha a finalizar</div>
                    <div class="card-field">{{objUnidadMantenimientoTaller.servicioFinPrevistoFechaNatural}}</div>
                  </div>
                  <div class="card-text" *ngIf="objGlobal.exist(objTaller?.telefono)">
                    <div class="card-label">Teléfono</div>
                    <div class="card-field">{{objTaller.telefono}}</div>
                  </div>
                  <div class="card-text" *ngIf="objGlobal.exist(objTaller?.direccionFiscal)">
                    <div class="card-label">Dirección</div>
                    <div class="card-field">{{objTaller.direccionFiscal}}</div>
                  </div>
                  <div class="card-text" *ngIf="objUnidadMantenimientoTaller.ficheroName != ''">
                    <div class="card-label">archivo adjunto</div>
                    <div class="card-field">
                      <div>
                        <span>{{objUnidadMantenimientoTaller.ficheroName}}.{{objUnidadMantenimientoTaller.extension}}</span>
                        <span class="material-icons">visibility</span>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>

            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="card-info-content">
                <div class="card-header">Unidad</div>
                <div class="card-body">
                  <div class="card-text">
                    <div class="card-label">No. Económico</div>
                    <div class="card-field">{{objUnidad?.numeroEconomico}}</div>
                  </div>
                  <div class="card-text">
                    <div class="card-label">Estatus</div>
                    <div class="card-field">{{objUnidad?.unidadEstatus}}</div>
                  </div>
                  <div class="card-text">
                    <div class="card-label">Tipo de unidad</div>
                    <div class="card-field">{{objUnidad?.unidadTipo}}</div>
                  </div>
                  <form [formGroup]="fgMantenimientoFinServicio">
                    <div class="card-text card-input-content" *ngIf="objGlobal.exist(objUnidad?.hasHorometro) && objUnidad.hasHorometro">
                      <div class="card-label">Horómetro</div>
                      <div class="card-field" style="width: 350px;">{{objUnidad?.horometroNatural}}</div>
                      <div class="card-input">
                        <span class="material-icons prefix" *ngIf="boolHorometro" matTooltip="El valor no puede ser menor">warning</span>
                        <input class="table-input unidad text-center" type="text" formControlName="horometro" matInput mask="separator.2" thousandSeparator="," separatorLimit="1000000000" autocomplete="off" (keyup)="inputHoroometro_changeEvent()" [class.required]="fgMantenimientoFinServicio.controls['horometro'].hasError('required') && fgMantenimientoFinServicio.controls['horometro'].touched">
                        <span class="suffix unidad">Hrs.</span>
                      </div>
                      <div class="card-field"></div>
                    </div>
                    <div class="card-text card-input-content" *ngIf="objGlobal.exist(objUnidad?.hasOdometro) && objUnidad.hasOdometro">
                      <div class="card-label">Odómetro</div>
                      <div class="card-field" style="width: 350px;">{{objUnidad?.odometroNatural}}</div>
                      <div class="card-input">
                        <span class="material-icons prefix" *ngIf="boolOdometro" matTooltip="El valor no puede ser menor">warning</span>
                        <input class="table-input unidad text-right" type="text" formControlName="odometro" matInput mask="separator.2" thousandSeparator="," separatorLimit="1000000000" autocomplete="off" (keyup)="inputOdometro_changeEvent()" [class.required]="fgMantenimientoFinServicio.controls['odometro'].hasError('required') && fgMantenimientoFinServicio.controls['odometro'].touched">
                        <span class="suffix unidad">Kms.</span>
                      </div>
                      <div class="card-field"></div>
                    </div>
                  </form>
                </div>
              </mat-card>
            </div>

            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="card-info-content">
                <div class="card-header">Solicitante</div>
                <div class="card-body">
                  <div class="card-text">
                    <div class="card-label">Nombre</div>
                    <div class="card-field">{{objUsuario.nombreCompleto}}</div>
                  </div>
                  <div class="card-text" *ngIf="objGlobal.exist(objUsuario?.idSucursal)">
                    <div class="card-label">Sucursal</div>
                    <div class="card-field">{{objUsuario.sucursal}}</div>
                  </div>
                  <div class="card-text" *ngIf="objGlobal.exist(objUsuario?.idPuesto)">
                    <div class="card-label">Puesto</div>
                    <div class="card-field">{{objUsuario.puesto}}</div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

        </div>
      </div>

      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'SERVICIOS'">
        <form [formGroup]="fgMantenimientoFinalize">
          <div class="bs-content">
            <div class="bs-row">
              <div class="bs-col-2">
                <mat-form-field appearance="legacy">
                  <mat-label>Fecha de inicio</mat-label>
                  <input matInput [matDatepicker]="inicioServicioFecha" readonly formControlName="inicioServicioFecha">
                  <mat-datepicker #inicioServicioFecha></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="bs-col-2">
                <mat-form-field appearance="legacy">
                  <mat-label>Fecha a finalizar</mat-label>
                  <input matInput [matDatepicker]="finPrevistoServicioFecha" readonly formControlName="finPrevistoServicioFecha">
                  <mat-datepicker #finPrevistoServicioFecha></mat-datepicker>
                </mat-form-field>
              </div>

              <div class="bs-col-2" *ngIf="objUnidadMantenimientoTaller?.isProrroga">
                <mat-form-field appearance="legacy">
                  <mat-label>Fecha de prorroga</mat-label>
                  <input matInput [matDatepicker]="finPrevistoServicioFechaActual" readonly formControlName="finPrevistoServicioFechaActual">
                  <mat-datepicker #finPrevistoServicioFechaActual></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="bs-row">
              <div class="bs-col">
                <mat-form-field appearance="legacy">
                  <textarea matInput formControlName="inicioServicioDescripcion" maxlength="2047" placeholder="Descripción de inicio del servicio" readonly rows="10"></textarea>
                </mat-form-field>
              </div>
            </div>
            <div class="bs-row">
              <div class="bs-col-2">
                <mat-form-field appearance="legacy">
                  <mat-label>Fecha de finalización</mat-label>
                  <input matInput [matDatepicker]="finServicioFecha" [readonly]="true" formControlName="finServicioFecha" required (click)="finServicioFecha.open()">
                  <mat-datepicker-toggle matSuffix [for]="finServicioFecha"></mat-datepicker-toggle>
                  <mat-datepicker #finServicioFecha></mat-datepicker>
                </mat-form-field>
              </div>
            </div>
            <div class="bs-row">
              <div class="bs-col">
                <mat-form-field appearance="legacy">
                  <textarea matInput formControlName="finServicioDescripcion" maxlength="2047" placeholder="Descripción de finalización del servicio" rows="10"></textarea>
                </mat-form-field>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'GALERIA'">

        <div class="menu-content">
          <div class="menu-item">
            <div class="menu-category" (click)="opcGallery_clickEvent()" [class.active]="openGallery">
              <span class="material-icons arrow">keyboard_arrow_right</span>
              <span class="material-icons">build</span>
              <span class="item-label">Generales de mantenimiento</span>
            </div>

            <div class="gallery-content" *ngIf="lstUnidadesMantenimientosImagenes.length != 0" [class.active]="openGallery">
              <div class="image-grid">
                <div class="image-grid-item" *ngFor="let itemUnidadMantenimientoImagen of lstUnidadesMantenimientosImagenes;">
                  <div class="image-grid-content-preview">
                    <div class="image-grid-preview" [style.background]="'url(' + objApplicationConfig.ENDPOINT + 'api/mantenimientos/imagenes/foto/' + itemUnidadMantenimientoImagen.idUnidadMantenimientoImagen + ')'"></div>
                  </div>
                  <div class="image-grid-input no-grid-button">
                    <div class="image-grid-title">{{itemUnidadMantenimientoImagen.nombre}}.{{itemUnidadMantenimientoImagen.extension}}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="menu-content">
          <div class="menu-item" *ngFor="let itemUnidadMantenimientoImagenTaller of lstUnidadesMantenimientosImagenesTalleres; let indexTaller = index">
            <div class="menu-category" [class.active]="itemUnidadMantenimientoImagenTaller.open" (click)="opcMenu_clickEvent(indexTaller)">
              <span class="material-icons arrow">keyboard_arrow_right</span>
              <span class="material-icons">emoji_transportation</span>
              <span class="item-label">{{itemUnidadMantenimientoImagenTaller.unidadesMantenimientosTaller}}</span>
            </div>

            <div class="sub-menu-content" [class.active]="itemUnidadMantenimientoImagenTaller.open">
              <div class="sub-menu-item" *ngFor="let itemUnidadMantenimientoImagenEstatus of itemUnidadMantenimientoImagenTaller.unidadesMantenimientosImagenEstatus; let indexEstatus = index;">
                <div class="sub-menu-category" [class.active]="itemUnidadMantenimientoImagenEstatus.open" (click)="opcSubMenu_clickEvent(indexTaller, indexEstatus)">
                  <span class="material-icons arrow">keyboard_arrow_right</span>
                  <span class="material-icons" *ngIf="itemUnidadMantenimientoImagenEstatus.idUnidadesMantenimientosImagenEstatus == 1">car_crash</span>
                  <span class="material-icons" *ngIf="itemUnidadMantenimientoImagenEstatus.idUnidadesMantenimientosImagenEstatus == 2">no_crash</span>
                  <div class="sub-item-label">{{itemUnidadMantenimientoImagenEstatus.unidadesMantenimientosImagenEstatus}}</div>
                </div>

                <div class="upload-gallery-content" *ngIf="idUnidadMantenimientoTaller == itemUnidadMantenimientoImagenTaller.idUnidadesMantenimientosTaller && itemUnidadMantenimientoImagenEstatus.idUnidadesMantenimientosImagenEstatus == 2" [class.active]="itemUnidadMantenimientoImagenEstatus.open">
                  <div class="image-upload">
                    <comp-zone-uploader #compZoneUploader [hidden]="objLoader.visible" [title]="'Agregar imágenes'" [height]="40" [config]="valueConfiguracion"></comp-zone-uploader>
                  </div>

                  <div style="overflow: hidden">
                    <div class="datatable-responsive" style="padding: 0 5px 0 5px" *ngIf="compZoneUploader.files.length > 0">
                      <div class="image-grid no-wrap">
                        <div app-picture-grid-upload #compImageItem class="image-grid-item"
                             *ngFor="let itemFile of compZoneUploader.files; let index = index;"
                             [itemFile]="itemFile"
                             [index]="index"
                             (onDelete)="compUnidadImagenFileItem_deleteEvent($event)">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="gallery-content" *ngIf="itemUnidadMantenimientoImagenEstatus.unidadesMantenimientosImagen.length != 0" [class.active]="itemUnidadMantenimientoImagenEstatus.open">
                  <div class="image-grid">
                    <div class="image-grid-item" *ngFor="let itemUnidadMantenimientoImagen of itemUnidadMantenimientoImagenEstatus.unidadesMantenimientosImagen;">
                      <div class="image-grid-content-preview" (click)="verImagen_clickEvent(itemUnidadMantenimientoImagen)">
                        <div class="image-grid-preview" [style.background]="'url(' + objApplicationConfig.ENDPOINT + 'api/mantenimientos/imagenes/foto/' + itemUnidadMantenimientoImagen.idUnidadMantenimientoImagen + ')'"></div>
                      </div>
                      <div class="image-grid-input no-grid-button">
                        <div class="image-grid-title">{{itemUnidadMantenimientoImagen.nombre}}.{{itemUnidadMantenimientoImagen.extension}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="fromTallerHojalateriaPinturaFinalize_submitEvent()">Aceptar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>
