import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../libraries/application-config';
import { Sessions } from '../libraries/sessions';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class CotizacionesService {

  constructor(private http: HttpClient,
              private objAuthService: AuthService) {
  }

  index(idCliente : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/cotizaciones/' + idCliente + '/index', Sessions.headerSession(this.objAuthService.access_token));
  }

  datatable(objParams: any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/datatable", objParams, Sessions.headerSession(this.objAuthService.access_token));
  }

  store(objParams: any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  create(idUsuario : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/" + idUsuario + "/create", Sessions.headerSession(this.objAuthService.access_token));
  }

  findUsuarios(strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/" + strSearch + "/findUsuarios", Sessions.headerSession(this.objAuthService.access_token));
  }

  findClientes( strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/" + strSearch + "/findClientes", Sessions.headerSession(this.objAuthService.access_token));
  }

  findContizacionTipo( strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/" + strSearch + "/find_cotizacion_tipo", Sessions.headerSession(this.objAuthService.access_token));
  }

  edit(objParams: any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/edit", objParams, Sessions.headerSession(this.objAuthService.access_token));
  }

  refresh( idCotizacion : number ): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/" + idCotizacion + '/refresh', Sessions.headerSession(this.objAuthService.access_token));
  }

  update(objParams: any): Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  updateEstatusCancelar(objParams: any): Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/updateEstatusCancelar", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  updateEstatusAprobar(objParams: any): Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/updateEstatusAprobar", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  delete( idCotizacion : number ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/" + idCotizacion + "/eliminar", Sessions.headerFormData(this.objAuthService.access_token));
  }

  destroy( idCotizacion : number ) : Observable<any> {
    return this.http.delete<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/" + idCotizacion, Sessions.headerSession(this.objAuthService.access_token));
  }

  deleteList(lstCotizaciones : any): Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/deleteSelected", lstCotizaciones, Sessions.headerSession(this.objAuthService.access_token));
  }

  documentPdf(idCotizacion : number): Observable<Blob> {
    return this.http.get<Blob>(ApplicationConfig.ENDPOINT + "api/cotizaciones/pdf/" + idCotizacion, Sessions.headerFile(this.objAuthService.access_token));
  }

  documentAprobadoPdf(idCotizacion : number): Observable<Blob> {
    return this.http.get<Blob>(ApplicationConfig.ENDPOINT + "api/cotizaciones/pdf/" + idCotizacion + "/aprobado", Sessions.headerFile(this.objAuthService.access_token));
  }

  infoEmail(objParams: any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/infoEmail", objParams, Sessions.headerSession(this.objAuthService.access_token));
  }

  sendEmail(objParams: any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/cotizaciones/sendEmail", objParams, Sessions.headerSession(this.objAuthService.access_token));
  }
}
