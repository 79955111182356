import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../libraries/application-config';
import { Sessions } from '../libraries/sessions';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root'
})
export class OrdenesServiciosService {

    constructor(private http: HttpClient,
              private objAuthService: AuthService) {
    }

    index(idCliente : number): Observable<any> {
        return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-servicios/' + idCliente + '/index', Sessions.headerSession(this.objAuthService.access_token));
    }

    findClientes( strSearch : string): Observable<any> {
        return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/" + strSearch + "/findClientes", Sessions.headerSession(this.objAuthService.access_token));
    }

    datatable( objParams : any): Observable<any> {
        return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/datatable", objParams, Sessions.headerSession(this.objAuthService.access_token));
    }

    create(idOrdenPedido : number): Observable<any> {
      if(idOrdenPedido != 0) {
        return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-servicios/' + idOrdenPedido + '/create', Sessions.headerSession(this.objAuthService.access_token));
      } else {
        return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-servicios/create', Sessions.headerSession(this.objAuthService.access_token));
      }
    }

  refresh( idOrdenServicio : number ): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/" + idOrdenServicio + '/refresh', Sessions.headerSession(this.objAuthService.access_token));
  }

  findOrdenPedido( strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/" + strSearch + "/find-orden-pedido", Sessions.headerSession(this.objAuthService.access_token));
  }

  findOrdenServicio( strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/" + strSearch + "/find-orden-servicio", Sessions.headerSession(this.objAuthService.access_token));
  }

  findCotizacion( idOrdenPedido : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/" + idOrdenPedido + "/find-cotizacion", Sessions.headerSession(this.objAuthService.access_token));
  }

    store( objParams : any ) : Observable<any> {
        return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios", objParams, Sessions.headerFormData(this.objAuthService.access_token));
    }

    ending(idOrdenServicio : number): Observable<any> {
        return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-servicios/' + idOrdenServicio + '/ending', Sessions.headerSession(this.objAuthService.access_token));
    }

    finalize( objParams : any ) : Observable<any> {
        return this.http.put<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-servicios', objParams, Sessions.headerFormData(this.objAuthService.access_token));
    }

  cancel( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-servicios/cancel', objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  payment( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-servicios/payment', objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

    findUsuarios(strSearch : string): Observable<any> {
        return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/" + strSearch + "/findUsuarios", Sessions.headerSession(this.objAuthService.access_token));
    }

    findUnidad(strSearch : string): Observable<any> {
        return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/" + strSearch + "/findUnidad", Sessions.headerSession(this.objAuthService.access_token));
    }

  gerenciamiento( idOrdenServicio : number ): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/" + idOrdenServicio + '/gerenciamiento', Sessions.headerSession(this.objAuthService.access_token));
  }

    contratoPdf(idOrdenServicio : number): Observable<Blob> {
        return this.http.get<Blob>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/contrato/" + idOrdenServicio, Sessions.headerFile(this.objAuthService.access_token));
    }

    createPdf(idOrdenServicio : number): Observable<Blob> {
        return this.http.get<Blob>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/pdf/" + idOrdenServicio, Sessions.headerFile(this.objAuthService.access_token));
    }

    ordenServicio_contratoPdf(idOrdenServicio : number): Observable<Blob> {
        return this.http.get<Blob>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/concat/" + idOrdenServicio + "/pdf", Sessions.headerFile(this.objAuthService.access_token));
    }

  contrato( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-servicios/contrato', objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  exportExcel(objParams : any ): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/export/excel", objParams, Sessions.headerFile(this.objAuthService.access_token));
  }
}
