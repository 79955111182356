import {CommonModule, DatePipe} from '@angular/common';
import { NgModule } from '@angular/core';
import {UsuariosFicherosIndexComponent} from "./index.component";
import {MatListModule} from "@angular/material/list";
import {MatIconModule} from "@angular/material/icon";
import {ZoneUploaderModule} from "../../../../../components/zone-uploader/zone-uploader.module";
import {UsuariosFicherosFileItemComponent} from "./file-item/file-item.component";
import {FileSizeModule} from "../../../../../pipes/file-size.module";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {ContextMenuModule} from "primeng/contextmenu";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {FileViewerModule} from "../../../../../components/file-viewer/file-viewer.module";
import {MatMenuModule} from '@angular/material/menu';



@NgModule({
  declarations: [
    UsuariosFicherosIndexComponent,
    UsuariosFicherosFileItemComponent
  ],
  imports: [
    CommonModule,
    MatListModule,
    MatIconModule,
    ZoneUploaderModule,
    FileSizeModule,
    MatCheckboxModule,
    ContextMenuModule,
    MatProgressBarModule,
    FormsModule,
    PdfViewerModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatInputModule,
    MatMenuModule,
    FileViewerModule
  ],
  exports : [
    UsuariosFicherosIndexComponent
  ],
  providers: [
    DatePipe
  ]
})
export class UsuariosFicheroModule { }
