<div class="mat-dialog-title">
  <div class="title"> Aprobar cotización </div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
    <div class="bs-content-full-area" [style.height.%]="100">
        <div class="datatable-responsive layout">
            <div class="bs-content">
                <div fxLayout="row wrap">
                    <!-- Card column -->
                    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-card class="panel-content-card">
                            <div class="content-card">
                                <div class="card-row br_btm">
                                    <div class="card-col-title">Información de solicitud</div>
                                </div>
                                <div class="card-row">
                                    <div class="card-col">
                                        <div class="card-row" style="margin: 5px 20px 0 20px"><div class="card-col-label card-label">Solicitante</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-label card-label">Fecha</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-label card-label">Empresa</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-label card-label">Sucursal</div></div>
                                    </div>
                                    <div class="card-col">
                                        <div class="card-row" style="margin: 5px 20px 0 20px"><div class="card-col-fiel card-fiel">{{solicitudUsuario}}</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-fiel card-fiel">{{solicitudFecha}}</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-fiel card-fiel">{{empresa}}</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-fiel card-fiel">{{sucursal}}</div></div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>

                <div fxLayout="row wrap">
                    <!-- Card column -->
                    <div fxFlex.gt-lg="100" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-card class="panel-content-card">
                            <div class="content-card">
                                <div class="card-row br_btm">
                                    <div class="card-col-title">Cliente</div>
                                </div>
                                <div class="card-row">
                                    <div class="card-col">
                                        <div class="card-row" style="margin: 5px 20px 0 20px"><div class="card-col-label card-label">Nombre comercial</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-label card-label">Razón social</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-label card-label">R.F.F</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-label card-label">Teléfono</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-label card-label">Correo</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-label card-label">Dirección fiscal</div></div>
                                        <!--                                    <div class="card-row" style="margin: 0 20px; padding-bottom: 15px"><div class="card-col-label card-label">Dirección social</div></div>-->
                                    </div>
                                    <div class="card-col">
                                        <div class="card-row" style="margin: 5px 20px 0 20px"><div class="card-col-fiel card-fiel">{{cliente?.nombreComercial}}</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-fiel card-fiel">{{cliente?.rfc}}</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-fiel card-fiel">{{cliente?.razonSocial}}</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-fiel card-fiel">{{cliente?.telefono}}</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-fiel card-fiel">{{cliente?.email}}</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-fiel card-fiel">{{cliente?.direccionFiscal}}</div></div>
                                        <!--                                    <div class="card-row" style="margin: 0 20px; padding-bottom: 15px"><div class="card-col-fiel card-fiel">{{cliente.DireccionSocial}}</div></div>-->
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>

                    <div fxFlex.gt-lg="100" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-card class="panel-content-card">
                            <div class="content-card">
                                <div class="card-row br_btm">
                                    <div class="card-col-title">Contacto</div>
                                </div>
                                <div class="card-row">
                                    <div class="card-col">
                                        <div class="card-row" style="margin: 5px 20px 0 20px"><div class="card-col-label card-label">Nombre</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-label card-label">Puesto</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-label card-label">Teléfono</div></div>
                                        <div class="card-row" style="margin: 0 20px; padding-bottom: 15px"><div class="card-col-label card-label">Correo</div></div>
                                    </div>
                                    <div class="card-col">
                                        <div class="card-row" style="margin: 5px 20px 0 20px"><div class="card-col-fiel card-fiel">{{clienteContacto?.nombreCompleto}}</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-fiel card-fiel">{{clienteContacto?.puesto}}</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-fiel card-fiel">{{clienteContacto?.telefono}}</div></div>
                                        <div class="card-row" style="margin: 0 20px; padding-bottom: 15px"><div class="card-col-fiel card-fiel">{{clienteContacto?.email}}</div></div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>

                <div fxLayout="row wrap">
                    <!-- Card column -->
                    <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                        <mat-card class="panel-content-card">
                            <div class="content-card">
                                <div class="card-row br_btm">
                                    <div class="card-col-title">Propuesta económica</div>
                                </div>
                                <div class="card-row">
                                    <div class="card-col">
                                        <div class="card-row" style="margin: 5px 20px 0 20px"><div class="card-col-label card-label">Moneda</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-label card-label">Equivalencia</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-label card-label">Forma de pago</div></div>
                                    </div>
                                    <div class="card-col">
                                        <div class="card-row" style="margin: 5px 20px 0 20px"><div class="card-col-fiel card-fiel">{{moneda?.codigo}}</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-fiel card-fiel">{{(equivalencia != 0)?equivalencia:''}}</div></div>
                                        <div class="card-row" style="margin: 0 20px 0 20px"><div class="card-col-fiel card-fiel">{{formaPago?.formaPago}}</div></div>
                                    </div>
                                </div>
                            </div>
                        </mat-card>
                    </div>
                </div>
            </div>
            <div class="title-card-bar">
                <span class="title">Partidas aprobadas</span>
            </div>
            <div class="options-bar" style="margin: 0 25px; border-left: 1px solid #cccccc; border-right: 1px solid #cccccc; border-top-width: 0;">
                <div class="option">
                    <button type="button" matTooltip="Eliminar seleccionados" [disabled]="!boolChkSomeCotizacionPartida || btnOptionBar.disabled" (click)="btnLstPartidasDelete_clickEvent()">
                        <span class="material-icons">clear</span>
                    </button>
                </div>
            </div>
            <div class="main">
                <div class="datatable-responsive card-layout">
                    <table class="datatable">
                        <thead>
                            <tr>
                              <th [style.width.px]="30"><mat-checkbox [disabled]="objChkBox.disabled" [checked]="boolChkCotizacionPartida" (change)="chkCotizacionesPartidaSelected_changeEvent($event.checked)"></mat-checkbox></th>
                              <th [style.width.px]="30">Acc.</th>
                              <th>Descripción</th>
                              <th [style.width.px]="70">Cantidad</th>
                              <th [style.width.px]="60">Unidad</th>
                              <th [style.width.px]="110">Precio Unitario</th>
                              <th class="descuento" [style.width.px]="110">Descuento<span class="material-icons info-icon" matTooltip="Descuento al importe aprobado">error_outline</span></th>
                              <th [style.width.px]="40 * lstCotizacionesImpuestos.length">Impuestos</th>
                              <th [style.width.px]="110">Importe</th>
                            </tr>
                        </thead>
                    <tbody>
                    <tr *ngFor="let itemCotizacionPartida of lstCotizacionesPartidas; let index = index;" [style.color]="(itemCotizacionPartida.disabled)?'#797D7F':''">
                      <td><mat-checkbox [disabled]="objChkBox.disabled || itemCotizacionPartida.disabled" [(ngModel)]="itemCotizacionPartida.checked" (ngModelChange)="chkItemCotizacionesPartidaSelected_changeEvent()"></mat-checkbox></td>
                      <td class="text-center">
                        <button type="button" class="datatable-button-disabled" [hidden]="!itemCotizacionPartida.disabled" [disabled]="btnAccion.disabled" matTooltip="Habilitar" (click)="btnPartidaDone_clickEvent(index)">
                          <mat-icon class="datatable-button-icon" >done</mat-icon>
                        </button>

                        <button type="button" class="datatable-button" [hidden]="itemCotizacionPartida.disabled" [disabled]="btnAccion.disabled" matTooltip="Eliminar" (click)="btnPartidaDelete_clickEvent(index)">
                          <mat-icon class="datatable-button-icon" >clear</mat-icon>
                        </button>
                      </td>
                      <td>{{itemCotizacionPartida.concepto}}</td>
                      <td class="text-center">
                        <input class="table-input text-center"
                               [disabled]="objInput.disabled || itemCotizacionPartida.disabled"
                               [style.width.px]="50" type="text"
                               matInput
                               maxlength="256"
                               [ngModel]="itemCotizacionPartida.aprobacionCantidad"
                               autocomplete="off"
                               (keyup)="calcImporte_inputChangeEvent($event, index)">
                      </td>
                      <td>{{itemCotizacionPartida?.unidadMedida.unidadMedida}}</td>
                      <td class="text-right">{{itemCotizacionPartida.precioUnitario | currency}} {{itemCotizacionPartida?.moneda.codigo}}</td>
                      <td [hidden]="(itemCotizacionPartida?.cotizacionPartidaTipoDescuento.idCotizacionPartidaTipoDescuento != 1)"
                          class="text-right">{{itemCotizacionPartida?.descuentoCantidad | currency}} {{moneda?.codigo}}</td>
                      <td [hidden]="(itemCotizacionPartida?.cotizacionPartidaTipoDescuento.idCotizacionPartidaTipoDescuento != 2)"
                          class="text-right" matTooltip="{{itemCotizacionPartida?.descuentoCantidad | currency}} {{moneda?.codigo}}">
                        % {{itemCotizacionPartida?.descuentoPorcentaje}}</td>
                      <td class="text-center">
                        <div class="bs-content" style="padding: 0">
                          <div class="bs-row">
                            <div class="bs-col" *ngFor="let itemImpuestoAprobado of itemCotizacionPartida?.aprobacionImpuestos; let index = index;">
                              <mat-checkbox
                                [disabled]="true"
                                [(ngModel)]="itemImpuestoAprobado.checked"
                                matTooltip="{{itemImpuestoAprobado.impuesto}}: {{itemImpuestoAprobado.total | currency}} {{moneda?.codigo}}"
                              ></mat-checkbox>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td class="text-right">{{itemCotizacionPartida.aprobacionImporte | currency}} {{moneda?.codigo}}</td>
                    </tr>
                    </tbody>
                      <tfoot>
                      <tr>
                        <td colspan="7"></td>
                        <td class="title">Generado</td>
                        <td class="title">Aprobado</td>
                      </tr>
                      <tr>
                        <td colspan="6"></td>
                        <td >Subtotal</td>
                        <td class="field generado">{{subtotal | currency}} {{moneda?.codigo}}</td>
                        <td class="field aprobado">{{aprobacionSubtotal | currency}} {{moneda?.codigo}}</td>
                      </tr>
                      <tr *ngFor="let itemImpuesto of lstCotizacionesImpuestos;">
                        <td colspan="6"></td>
                        <td >{{itemImpuesto.impuesto.impuesto}}</td>
                        <td class="field generado">{{itemImpuesto.total | currency}} {{moneda?.codigo}}</td>
                        <td class="field aprobado">{{itemImpuesto.aprobacionTotal | currency}} {{moneda?.codigo}}</td>
                      </tr>
                      <tr>
                        <td colspan="6"></td>
                        <td >Total</td>
                        <td class="generado-total">{{total | currency}} {{moneda?.codigo}}</td>
                        <td class="aprobado-total">{{aprobacionTotal | currency}} {{moneda?.codigo}}</td>
                      </tr>
                      </tfoot>
                  </table>
                </div>
            </div>

            <div class="bs-content">
                <div fxLayout="row wrap">
                <!-- Card column -->
                <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
                  <mat-card class="panel-content-card">
                    <div class="content-card">
                      <div class="card-row br_btm">
                        <div class="card-col-title">Comentarios</div>
                      </div>
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">{{comentarios}}</div>
                      </div>
                    </div>
                  </mat-card>
                </div>
              </div>
            </div>
        </div>
    </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="formCotizacionAprobacion_submitEvent()">Aceptar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>
