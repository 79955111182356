import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../libraries/application-config';
import { Sessions } from '../libraries/sessions';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class OrdenesPedidosService {

  constructor(private http: HttpClient,
              private objAuthService: AuthService) {
  }

  index(idCliente : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-pedidos/' + idCliente + '/index', Sessions.headerSession(this.objAuthService.access_token));
  }

  datatable( objParams : any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/ordenes-pedidos/datatable", objParams, Sessions.headerSession(this.objAuthService.access_token));
  }

  indexOrdenServicio(idOrdenPedido : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-pedidos/' + idOrdenPedido + '/ordenes-servicios', Sessions.headerSession(this.objAuthService.access_token));
  }

  refresh( idOrdenPedido : number ): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/ordenes-pedidos/" + idOrdenPedido + '/refresh', Sessions.headerSession(this.objAuthService.access_token));
  }

  create(): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/ordenes-pedidos/create", Sessions.headerSession(this.objAuthService.access_token));
  }

  store( objParams : any ) : Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-pedidos', objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  edit(idOrdenPedido : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/ordenes-pedidos/" + idOrdenPedido + "/edit", Sessions.headerSession(this.objAuthService.access_token));
  }

  update( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-pedidos', objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  finalize( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-pedidos/finalize', objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  endingForce( idOrdenPedido : number ) : Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-pedidos/' + idOrdenPedido + '/ending', Sessions.headerSession(this.objAuthService.access_token));
  }

  cancel(objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-pedidos/cancel', objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  findClientes( strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/ordenes-servicios/" + strSearch + "/findClientes", Sessions.headerSession(this.objAuthService.access_token));
  }

  documentPdf(idOrdenPedido : number): Observable<Blob> {
    return this.http.get<Blob>(ApplicationConfig.ENDPOINT + "api/ordenes-pedidos/pdf/" + idOrdenPedido, Sessions.headerFile(this.objAuthService.access_token));
  }

  exportExcel(objParams : any ): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/ordenes-pedidos/export/excel", objParams, Sessions.headerFile(this.objAuthService.access_token));
  }

  contratoCreate(idOrdenPedido : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/ordenes-pedidos/contrato/' + idOrdenPedido + '/create', Sessions.headerSession(this.objAuthService.access_token));
  }

  generateAgreement(objParams : any ): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/ordenes-pedidos/contrato/store", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }
}
