<div class="mat-dialog-title">
  <div class="title"> {{ folio + ' - Finalizar orden de servicio' }}</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content-full-area" [style.height.%]="100">
    <div class="bs-sidebar-left" [style.width.px]="250">
      <div class="bs-content">
        <div class="row">
          <div class="col text-center">
            <span class="material-icons" [style.fontSize.px]="80">description</span>
          </div>
        </div>
        <div class="bs-row">
          <div class="bs-col">
            <mat-selection-list [(ngModel)]="objScene" [multiple]="false">
              <mat-list-option [value]="'CLIENTE'">Cliente</mat-list-option>
              <mat-list-option [value]="'OPERACION'">Operación</mat-list-option>
              <mat-list-option [value]="'GERENCIAMIENTOS'">Gerenciamientos</mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>
    <div class="bs-viewport">
      <!--   INICIO SOLICITUD   -->
      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'CLIENTE'">
        <div class="bs-content">
          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Orden de pedido</div>
                  </div>
                  <div class="card-row">
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">Empresa</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Sucursal</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Folio</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Cotizacion</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px" *ngIf="Globals.isStringNotEmpty(objOrdenPedido?.ticket)">
                        <div class="card-col-label card-label">Ticket</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Fecha</div>
                      </div>
                    </div>
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{ objOrdenPedido?.empresaRazonSocial }}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{ objOrdenPedido?.sucursal }}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">
                          <div class="badge label-badge" style="width: 165px;">{{ objOrdenPedido?.folio }}</div>
                        </div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">
                          <div class="badge button-badge" style="width: 165px;" *ngIf="Globals.isStringNotEmpty( objOrdenPedido?.cotizacionFolio )"
                               (click)="btnCotizacionPdf_clickEvent()">{{ objOrdenPedido?.cotizacionFolio }}
                          </div>
                          <div class="badge label-badge" style="width: 165px;" *ngIf="!Globals.isStringNotEmpty( objOrdenPedido?.cotizacionFolio )">N/A</div>
                        </div>
                      </div>
                      <div class="card-row" style="margin: 0 20px" *ngIf="Globals.isStringNotEmpty(objOrdenPedido?.ticket)">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido?.ticket}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{ objOrdenPedido?.fechaNatural }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Cliente</div>
                  </div>
                  <div class="card-row">
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">Nombre comercial</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Razón social</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">R.F.F</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Dirección fiscal</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Dirección social</div>
                      </div>
                    </div>
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{ objOrdenPedido.clienteNombreComercial }}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{ objOrdenPedido.clienteRazonSocial }}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{ objOrdenPedido.clienteRfc }}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{ objOrdenPedido.clienteDireccionFiscal }}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{ objOrdenPedido.clienteDireccionSocial }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>

            <div fxFlex.gt-lg="100" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Contacto</div>
                  </div>
                  <div class="card-row">
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">Nombre</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Puesto</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Teléfono</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Correo</div>
                      </div>
                    </div>
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{ objOrdenPedido.contactoNombreCompleto }}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{ objOrdenPedido.contactoPuesto }}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{ objOrdenPedido.contactoTelefono }}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{ objOrdenPedido.contactoEmail }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

        </div>
      </div>
      <!--   FIN SOLICITUD   -->

      <!--   INICIO COMENTARIOS   -->
      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'OPERACION'">
        <div class="content-custom-table">
          <div class="custom-table">
            <div class="custom-column">
              <div class="custom-row header" [style.width.px]="135">Tiempos de ejecución</div>
              <div class="custom-row header" [style.width.px]="135"></div>
              <div class="custom-row" [style.width.px]="135">Fecha programada</div>
              <div class="custom-row" [style.width.px]="135">Salida origen</div>
              <div class="custom-row" [style.width.px]="135">Llegada destino</div>
              <div class="custom-row" [style.width.px]="135">Salida destino</div>
              <div class="custom-row" [style.width.px]="135">Llegada origen</div>
            </div>

            <form [formGroup]="fgOrdenServicioTiemposEjecucion">
              <div class="custom-column">
                <div class="custom-row header" [style.width.px]="130"></div>
                <div class="custom-row header" [style.width.px]="130">Fecha</div>
                <div class="custom-row" [style.width.px]="130">
                  <div class="table-input-calendar">
                    <input class="text-center" formControlName="programadaFecha" [matDatepicker]="matDateProgramada" [readonly]="true">
                    <mat-datepicker #matDateProgramada></mat-datepicker>
                    <button disabled><span class="material-icons">today</span></button>
                  </div>
                </div>
                <div class="custom-row" [style.width.px]="130">
                  <div class="table-input-calendar"
                       [class.required]="fgOrdenServicioTiemposEjecucion.controls['salidaOrigenFecha'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['salidaOrigenFecha'].touched">
                    <input class="text-center" formControlName="salidaOrigenFecha" [matDatepicker]="matDateSalidaOrigen" [readonly]="true" (click)="matDateSalidaOrigen.open()">
                    <mat-datepicker #matDateSalidaOrigen></mat-datepicker>
                    <button mat-raised-button (click)="matDateSalidaOrigen.open()"><span class="material-icons">today</span></button>
                  </div>
                </div>
                <div class="custom-row" [style.width.px]="130">
                  <div class="table-input-calendar"
                       [class.required]="fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoFecha'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoFecha'].touched">
                    <input class="text-center" formControlName="llegadaDestinoFecha" [matDatepicker]="matDateLlegadaDestino" [readonly]="true" (click)="matDateLlegadaDestino.open()">
                    <mat-datepicker #matDateLlegadaDestino></mat-datepicker>
                    <button mat-raised-button (click)="matDateLlegadaDestino.open()"><span class="material-icons">today</span></button>
                  </div>
                </div>
                <div class="custom-row" [style.width.px]="130">
                  <div class="table-input-calendar">
                    <input class="text-center" formControlName="salidaDestinoFecha" [matDatepicker]="matDateSalidaDestino" [readonly]="true" (click)="matDateSalidaDestino.open()">
                    <mat-datepicker #matDateSalidaDestino></mat-datepicker>
                    <button mat-raised-button (click)="matDateSalidaDestino.open()"><span class="material-icons">today</span></button>
                  </div>
                </div>
                <div class="custom-row" [style.width.px]="130">
                  <div class="table-input-calendar">
                    <input class="text-center" formControlName="llegadaOrigenFecha" [matDatepicker]="matDateLlegadaOrigen" [readonly]="true" (click)="matDateLlegadaOrigen.open()">
                    <mat-datepicker #matDateLlegadaOrigen></mat-datepicker>
                    <button mat-raised-button (click)="matDateLlegadaOrigen.open()"><span class="material-icons">today</span></button>
                  </div>
                </div>
              </div>

              <div class="custom-column">
                <div class="custom-row header" [style.width.px]="110"></div>
                <div class="custom-row header" [style.width.px]="110">Hora</div>
                <div class="custom-row" [style.width.px]="110">
                  <div class="inputs-content">
                    <input type="text" class="table-input text-center" formControlName="programadaHora" [readonly]="true">
                    <span class="suffixSpan">Hrs.</span>
                  </div>
                </div>
                <div class="custom-row" [style.width.px]="110">
                  <div class="inputs-content">
                    <input type="text"
                           class="table-input text-center"
                           mask="Hh:m0"
                           [leadZeroDateTime]="true"
                           matInput
                           maxlength="5"
                           autocomplete="off"
                           formControlName="salidaOrigenHora"
                           [class.required]="fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHora'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHora'].touched">
                    <span class="suffixSpan"
                          [class.required]="fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHora'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['salidaOrigenHora'].touched">Hrs.</span>
                  </div>
                </div>
                <div class="custom-row" [style.width.px]="110">
                  <div class="inputs-content">
                    <input type="text"
                           class="table-input text-center"
                           mask="Hh:m0"
                           [leadZeroDateTime]="true"
                           matInput
                           maxlength="5"
                           autocomplete="off"
                           formControlName="llegadaDestinoHora"
                           [class.required]="fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHora'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHora'].touched">
                    <span class="suffixSpan"
                          [class.required]="fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHora'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['llegadaDestinoHora'].touched">Hrs. *</span>
                  </div>
                </div>
                <div class="custom-row" [style.width.px]="110">
                  <div class="inputs-content">
                    <input type="text"
                           class="table-input text-center"
                           mask="Hh:m0"
                           [leadZeroDateTime]="true"
                           matInput
                           maxlength="5"
                           autocomplete="off"
                           formControlName="salidaDestinoHora">
                    <span class="suffixSpan">Hrs. *</span>
                  </div>
                </div>
                <div class="custom-row" [style.width.px]="110">
                  <div class="inputs-content">
                    <input type="text"
                           class="table-input text-center"
                           mask="Hh:m0"
                           [leadZeroDateTime]="true"
                           matInput
                           maxlength="5"
                           autocomplete="off"
                           formControlName="llegadaOrigenHora">
                    <span class="suffixSpan">Hrs. *</span>
                  </div>
                </div>
              </div>
            </form>

            <div class="custom-column unidad" *ngFor="let itemUnidadMiembro of lstUnidadesMiembros; let index = index;">
              <div class="custom-row header" [style.width.px]="301">
                <span>{{ itemUnidadMiembro.unidadNumeroEconomico }}</span>
                <span>{{ itemUnidadMiembro.unidadTipo }}</span>
              </div>

              <div class="custom-row container header" [style.width.px]="301">
                <div class="item" [style.width.px]="150">Odómetro</div>
                <div class="item" [style.width.px]="150">Horómetro</div>
              </div>

              <div class="custom-row container" [style.width.px]="301">
                <div class="item content text-center" [style.width.px]="150"></div>
                <div class="item content text-center" [style.width.px]="150"></div>
              </div>

              <div class="custom-row container" [style.width.px]="301">
                <div class="item content" [style.width.px]="150" *ngIf="Globals.exist(itemUnidadMiembro.salidaOrigenOdometro)">
                  <div class="inputs-content">
                    <input type="text"
                           class="table-input text-right"
                           [(ngModel)]="itemUnidadMiembro.salidaOrigenOdometro"
                           matInput
                           mask="separator.2"
                           thousandSeparator=","
                           separatorLimit="1000000000"
                           maxlength="127"
                           autocomplete="off"
                           (keyup)="inputSalidaOrigenOdometro_changeEvent($event, index)">
                    <span class="suffixSpan">Kms.</span>
                  </div>
                </div>
                <div class="item content text-center" [style.width.px]="150" *ngIf="!Globals.exist(itemUnidadMiembro.salidaOrigenOdometro)"></div>
                <div class="item content" [style.width.px]="150" *ngIf="Globals.exist(itemUnidadMiembro.salidaOrigenHorometro)">
                  <div class="inputs-content">
                    <input type="text"
                           class="table-input text-right"
                           [(ngModel)]="itemUnidadMiembro.salidaOrigenHorometro"
                           matInput
                           mask="separator.2"
                           thousandSeparator=","
                           separatorLimit="1000000000"
                           maxlength="127"
                           autocomplete="off"
                           (keyup)="inputSalidaOrigenHorometro_changeEvent($event, index)">
                    <span class="suffixSpan">Hrs.</span>
                  </div>
                </div>
                <div class="item content text-center" [style.width.px]="150" *ngIf="!Globals.exist(itemUnidadMiembro.salidaOrigenHorometro)"></div>
              </div>

              <div class="custom-row container" [style.width.px]="301">
                <div class="item content" [style.width.px]="150" *ngIf="Globals.exist(itemUnidadMiembro.salidaOrigenOdometro)">
                  <div class="inputs-content">
                    <span class="material-icons prefix" *ngIf="boolValidationLlegadaDestinoOdometro(index)" matTooltip="El valor no puede ser menor">warning</span>
                    <input type="text"
                           class="table-input text-right"
                           [(ngModel)]="itemUnidadMiembro.llegadaDestinoOdometro"
                           matInput
                           mask="separator.2"
                           thousandSeparator=","
                           separatorLimit="1000000000"
                           maxlength="127"
                           autocomplete="off"
                           (keyup)="inputLlegadaDestinoOdometro_changeEvent($event, index)">
                    <span class="suffixSpan">Kms.</span>
                  </div>
                </div>
                <div class="item content text-center" [style.width.px]="150" *ngIf="!Globals.exist(itemUnidadMiembro.salidaOrigenOdometro)"></div>
                <div class="item content" [style.width.px]="150" *ngIf="Globals.exist(itemUnidadMiembro.salidaOrigenHorometro)">
                  <div class="inputs-content">
                    <span class="material-icons prefix" *ngIf="boolValidationLlegadaDestinoHorometro(index)" matTooltip="El valor no puede ser menor">warning</span>
                    <input type="text"
                           class="table-input text-right"
                           [(ngModel)]="itemUnidadMiembro.llegadaDestinoHorometro"
                           matInput
                           mask="separator.2"
                           thousandSeparator=","
                           separatorLimit="1000000000"
                           maxlength="127"
                           autocomplete="off"
                           (keyup)="inputLlegadaDestinoHorometro_changeEvent($event, index)">
                    <span class="suffixSpan">Hrs.</span>
                  </div>
                </div>
                <div class="item content text-center" [style.width.px]="150" *ngIf="!Globals.exist(itemUnidadMiembro.salidaOrigenHorometro)"></div>
              </div>

              <div class="custom-row container" [style.width.px]="301">
                <div class="item content" [style.width.px]="150" *ngIf="Globals.exist(itemUnidadMiembro.salidaOrigenOdometro)">
                  <div class="inputs-content">
                    <span class="material-icons prefix" *ngIf="boolValidationSalidaDestinoOdometro(index)" matTooltip="El valor no puede ser menor">warning</span>
                    <input type="text"
                           class="table-input text-right"
                           [(ngModel)]="itemUnidadMiembro.salidaDestinoOdometro"
                           matInput
                           mask="separator.2"
                           thousandSeparator=","
                           separatorLimit="1000000000"
                           maxlength="127"
                           autocomplete="off"
                           (keyup)="inputSalidaDestinoOdometro_changeEvent($event, index)">
                    <span class="suffixSpan">Kms.</span>
                  </div>
                </div>
                <div class="item content text-center" [style.width.px]="150" *ngIf="!Globals.exist(itemUnidadMiembro.salidaOrigenOdometro)"></div>
                <div class="item content" [style.width.px]="150" *ngIf="Globals.exist(itemUnidadMiembro.salidaOrigenHorometro)">
                  <div class="inputs-content">
                    <span class="material-icons prefix" *ngIf="boolValidationSalidaDestinoHorometro(index)" matTooltip="El valor no puede ser menor">warning</span>
                    <input type="text"
                           class="table-input text-right"
                           [(ngModel)]="itemUnidadMiembro.salidaDestinoHorometro"
                           matInput
                           mask="separator.2"
                           thousandSeparator=","
                           separatorLimit="1000000000"
                           maxlength="127"
                           autocomplete="off"
                           (keyup)="inputSalidaDestinoHorometro_changeEvent($event, index)">
                    <span class="suffixSpan">Hrs.</span>
                  </div>
                </div>
                <div class="item content text-center" [style.width.px]="150" *ngIf="!Globals.exist(itemUnidadMiembro.salidaOrigenHorometro)"></div>
              </div>

              <div class="custom-row container" [style.width.px]="301">
                <div class="item content" [style.width.px]="150" *ngIf="Globals.exist(itemUnidadMiembro.salidaOrigenOdometro)">
                  <div class="inputs-content">
                    <span class="material-icons prefix" *ngIf="boolValidationLlegadaOrigenOdometro(index)" matTooltip="El valor no puede ser menor">warning</span>
                    <input type="text"
                           class="table-input text-right"
                           [(ngModel)]="itemUnidadMiembro.llegadaOrigenOdometro"
                           matInput
                           mask="separator.2"
                           thousandSeparator=","
                           separatorLimit="1000000000"
                           maxlength="127"
                           autocomplete="off"
                           (keyup)="inputLlegadaOrigenOdometro_changeEvent($event, index)">
                    <span class="suffixSpan">Kms.</span>
                  </div>
                </div>
                <div class="item content text-center" [style.width.px]="150" *ngIf="!Globals.exist(itemUnidadMiembro.salidaOrigenOdometro)"></div>
                <div class="item content" [style.width.px]="150" *ngIf="Globals.exist(itemUnidadMiembro.salidaOrigenHorometro)">
                  <div class="inputs-content">
                    <span class="material-icons prefix" *ngIf="boolValidationLlegadaOrigenHorometro(index)" matTooltip="El valor no puede ser menor">warning</span>
                    <input type="text"
                           class="table-input text-right"
                           [(ngModel)]="itemUnidadMiembro.llegadaOrigenHorometro"
                           matInput
                           mask="separator.2"
                           thousandSeparator=","
                           separatorLimit="1000000000"
                           maxlength="127"
                           autocomplete="off"
                           (keyup)="inputLlegadaOrigenHorometro_changeEvent($event, index)">
                    <span class="suffixSpan">Hrs.</span>
                  </div>
                </div>
                <div class="item content text-center" [style.width.px]="150" *ngIf="!Globals.exist(itemUnidadMiembro.salidaOrigenHorometro)"></div>
              </div>

            </div>
          </div>
        </div>

        <div class="bs-content">
          <div class="bs-row">
            <div class="bs-col">
              <form [formGroup]="fgOrdenServicioTiemposEjecucion">
                <mat-form-field appearance="legacy">
                  <textarea matInput formControlName="observaciones" placeholder="Observaciones" rows="10" cols="70"></textarea>
                </mat-form-field>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!--   FIN COMENTARIOS   -->

      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'GERENCIAMIENTOS'">
        <div class="bs-content">
          <div class="bs-row">
            <div class="bs-col">
              <div class="notification alert-warning">
                <span class="material-icons">info</span><span>Para finalizar debe de completar o cancelar las operaciones aun activas</span>
              </div>
            </div>
          </div>

          <div class="bs-row" [style.marginTop.px]="15">
            <div class="bs-col">
              <div class="card-table-content">
                <div class="title-card-bar">
                  <span class="title">Gerenciamientos</span>
                </div>
                <table class="datatable">
                  <thead>
                  <tr>
                    <th [style.width.px]="40" class="text-center">No.</th>
                    <th [style.width.px]="30" class="text-center"></th>
                    <th>Folio</th>
                    <th [style.width.px]="160">Orden Servicio</th>
                    <th [style.width.px]="150">Estatus</th>
                    <th [style.width.px]="110">Fecha</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let itemOrdenServicioMiembro of lstOrdenesServiciosMiembros; let index = index;">
                    <td class="text-center">{{index + 1}}</td>
                    <td class="text-center">
                      <button class="menu-option" mat-icon-button matTooltip="Gerenciamiento" (click)="btnOrdenServicioGerenciamiento_clickEvent(itemOrdenServicioMiembro)">
                        <mat-icon>place</mat-icon>
                      </button>
                    </td>
                    <td>
                      <span class="badge label-badge">{{ itemOrdenServicioMiembro?.folio }}</span>
                    </td>
                    <td class="text-center">
                      <span class="badge button-badge" (click)="verPdf_clickEvent(itemOrdenServicioMiembro)">{{itemOrdenServicioMiembro?.ordenServicioFolio}}</span>
                    </td>
                    <td class="text-center">
                      <span class="badge" [class]="['gerenciamiento-estatus-' + itemOrdenServicioMiembro?.idGerenciamientoEstatus]">{{itemOrdenServicioMiembro?.gerenciamientoEstatus}}</span>
                    </td>
                    <td class="text-center">{{ itemOrdenServicioMiembro?.fechaNatural }}</td>
                  </tr>
                  <tr *ngIf="Globals.isArray(lstOrdenesServiciosMiembros) && lstOrdenesServiciosMiembros.length === 0">
                    <td colspan="6" class="text-center"> No hay gerenciamientos</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="formOrdenServicioCreate_submitEvent()">Aceptar
  </button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>

