<div class="mat-dialog-title">
  <div class="title"> Nueva partida</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="form-content">
    <div class="fm-row">
      <div class="col-label" [class.required]="fcServicio.hasError('required')  && fcServicio.touched">Servicio *</div>

      <div class="col-field" [style.width]="'calc(100% - 100px)'">
        <select class="table-select" [formControl]="fcServicio" [style.width.%]="100" [class.required]="fcServicio.hasError('required')  && fcServicio.touched">
          <option *ngFor="let itemServicio of lstServicios" [value]="itemServicio.idServicio">{{itemServicio.servicio}}</option>
        </select>
      </div>
    </div>

    <div class="fm-row descripcion">
      <div class="col-label">Descripción</div>

      <div class="col-field" [style.width]="'calc(100% - 100px)'" [style.height.px]="100">
        <textarea class="table-input descripcion" type="text" matInput maxlength="256" [formControl]="fcDescripcion"></textarea>
      </div>
    </div>

    <div class="fm-row">
      <div class="col-label" [class.required]="fcCantidad.hasError('required')  && fcCantidad.touched">Cantidad *</div>

      <div class="col-field" [style.width]="'calc(100% - 265px)'">
        <input class="table-input text-center" type="number" matInput maxlength="256" [formControl]="fcCantidad" autocomplete="off" [class.required]="fcCantidad.hasError('required')  && fcCantidad.touched">
      </div>
    </div>

    <div class="fm-row">
      <div class="col-label" [class.required]="fcUnidadMedida.hasError('required')  && fcUnidadMedida.touched">Unidad *</div>

      <div class="col-field" [style.width]="'calc(100% - 100px)'">
        <select class="table-select" [formControl]="fcUnidadMedida" [style.width.%]="100" [class.required]="fcUnidadMedida.hasError('required')  && fcUnidadMedida.touched">
          <option *ngFor="let itemUnidadMedida of lstUnidadesMedidas" [value]="itemUnidadMedida.idUnidadMedida">{{itemUnidadMedida.unidadMedida}}</option>
        </select>
      </div>
    </div>

    <div class="fm-row">
      <div class="col-label" [class.required]="fcPrecioUnitario.hasError('required')  && fcPrecioUnitario.touched">Precio *</div>

      <div class="col-field" [style.width]="'calc(100% - 170px)'">
        <span class="suffix" [class.required]="fcPrecioUnitario.hasError('required')  && fcPrecioUnitario.touched">$</span>
        <input class="table-input text-right precioInput" type="text" mask="separator.2" thousandSeparator="," matInput maxlength="256" [formControl]="fcPrecioUnitario" autocomplete="off" [class.required]="fcPrecioUnitario.hasError('required')  && fcPrecioUnitario.touched">
      </div>
      <div class="col-field" [style.width.px]="50">
        <select class="table-select" [formControl]="fcMoneda" [class.required]="fcMoneda.hasError('required')  && fcMoneda.touched" [style.width.%]="100" (change)="selectMoneda_selectedEvent($event)">
          <option *ngFor="let itemMoneda of lstMonedas" [value]="itemMoneda.idMoneda">{{itemMoneda.codigo}}</option>
        </select>
      </div>
    </div>

    <div class="fm-row-descuento">
      <div class="col-label" [style.marginBottom.px]="5">Descuento por</div>

      <div class="col-field" [style.width]="'calc(100% - 265px)'" [style.marginRight.px]="10">
        <div class="input-label" >Cantidad</div>
        <div class="input-content">
          <span class="suffix">$</span>
          <input class="table-input text-right" type="text" mask="separator.2" thousandSeparator="," matInput maxlength="256" [formControl]="fcDescuentoCantidad" autocomplete="off" (keydown)="setDescuentoActive($event, 1)" (keyup)="calcDescuento()">
        </div>
      </div>

      <div class="col-field" [style.width.px]="72">
        <div class="input-label" >Porcentaje</div>

        <div class="input-content">
          <input class="table-input text-center" type="number" matInput maxlength="256" [formControl]="fcDescuentoPorcentaje" autocomplete="off" (keyup)="calcDescuento()" (keydown)="setDescuentoActive($event, 2)">
          <span class="prefix" >%</span>
        </div>
      </div>
    </div>

    <div class="fm-row-impuestos">
      <div class="col-label">Impuestos</div>

      <div class="col-field" [style.width]="'calc(100% - 100px)'">
        <div class="cs-row" *ngFor="let itemImpuesto of lstInnerImpuestos; let index = index; first as isFirst; last as isLast" [style.width]="'calc(100% /' + lstInnerImpuestos.length + ')'">
          <div class="cs-col header" [class.border-top-left]="isFirst" [class.border-top-right]="isLast">
            <mat-checkbox [disabled]="objChkBox.disabled" [(ngModel)]="itemImpuesto['checked']"></mat-checkbox>
            <div class="hd-label" (click)="chkItemImpuesto_clickEvent(index)">{{itemImpuesto.impuesto}}</div>
          </div>
          <div class="cs-col"><div class="cs-label">{{itemImpuesto.porcentaje}}%</div></div>
          <div class="cs-col"><div class="cs-label">{{itemImpuesto.total | currency}} {{codigoMonedaPartida}}</div></div>
        </div>
      </div>
    </div>

    <div class="fm-row">
      <div class="col-label">Importe</div>

      <div class="col-field" [style.width]="'calc(100% - 265px)'">
        <span class="suffix">$</span>
        <div class="table-input text-right claseInput" style="padding: 0 30px 0 0 !important">{{importe | number:'1.2-2'}}</div>
        <span class="prefix" [style.top.px]="1">{{objMoneda?.codigo}}</span>
      </div>
    </div>
  </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="formPartida_submitEvent()">Aceptar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>
