
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild


} from '@angular/core';
import { MenuItems } from '../../shared/menu-items/menu-items';

import { AuthService } from 'src/app/services/auth.service';
import { CookiesService } from 'src/app/services/cookies.service';

/** @title Responsive sidenav */
@Component({
  selector: 'app-full-layout',
  templateUrl: 'full.component.html',
  styleUrls: ['full.component.scss']
})
export class FullComponent implements OnDestroy {

  public boolDragable : boolean = false;
  public toogle : boolean = true;
  public showSearch = false;
  public dragStartX = 0;

  // public config: PerfectScrollbarConfigInterface = {};
  // private _mobileQueryListener: () => void;

  clickEvent() {
    // this.status = !this.status;
  }
  constructor(
    public router: Router,
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public objAuthService : AuthService,
    public objCookiesService : CookiesService
  ) {
    objCookiesService.start();
  }

  ngOnDestroy(): void {
    // tslint:disable-next-line: deprecation
    // this.mobileQuery.removeListener(this._mobileQueryListener);
  }


  // Mini sidebar

  // public incremento() {
  //   this.menuWidth = 50;
  // }

  public onDragStart( evento : any) : void {
    if(this.boolDragable) {
      var img = document.createElement('img'); img.src = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==';
      evento.dataTransfer.setDragImage(img, 10, 10);
      console.log("onDragStart");
      this.dragStartX = evento.x;
    }

  }

  // public onDrag( evento : any) : void {
  //   if(this.boolDragable) {
  //     let diff_x = evento.x - this.dragStartX;
  //     this.menuWidth = this.dragStartX + diff_x;
  //   }
  // }

  // public onDragEnd( evento : any) : void {
  //   if(this.boolDragable) {
  //     let diff_x = evento.x - this.dragStartX;
  //     this.menuWidth = this.dragStartX + diff_x;
  //   }
  // }

  public btnResizeMenu_clickEvent() : void {
    this.resizeMenu()
  }

  private resizeMenu() : void {
    if( this.objCookiesService.check('mainMenuToogle') ) {
      this.objCookiesService.setBoolean('mainMenuToogle', !this.objCookiesService.getBoolean('mainMenuToogle'));
    } else {
      this.objCookiesService.setBoolean('mainMenuToogle', true);
    }
  }

}
