<div *ngIf="config" #zoneDragDropFile class="zoneUploader-container text-center" [ngStyle]="cssStyle" [style.height.px]="height">
  <h5 class="zoneUploader-title" [style.marginTop.px]="(height / 2) - 15" *ngIf="titleShow">{{title}}</h5>
</div>

<div *ngIf="!config" #zoneDragDropFile class="file-upload-content"  [style.height.px]="height">
  <span class="material-icons">cloud_upload</span>
  <div class="title" *ngIf="titleShow">{{title}}</div>
</div>

<input #eleInput type="file" name="{{id}}" id="{{id}}" accept="{{accept}}" (change)="elemInput_changeEvent($event)" multiple/>
