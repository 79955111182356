<div class="mat-dialog-title">
  <div class="title"> {{folio}}Nueva orden de servicio</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content-full-area" [style.height.%]="100">
    <div class="bs-sidebar-left" [style.width.px]="250">
      <div class="bs-content">
        <div class="row">
          <div class="col text-center">
            <span class="material-icons" [style.fontSize.px]="80">description</span>
          </div>
        </div>
        <div class="bs-row">
          <div class="bs-col">
            <mat-selection-list [(ngModel)]="objScene" [multiple]="false">
              <mat-list-option  [value]="'CLIENTE'">Cliente</mat-list-option>
              <mat-list-option  [value]="'DIRECCIONES'">Datos del servicio</mat-list-option>
              <mat-list-option  [value]="'OPERACION'">Operación</mat-list-option>
            </mat-selection-list>
          </div>
        </div>
      </div>
    </div>
    <div class="bs-viewport">
      <!--   INICIO SOLICITUD   -->
      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'CLIENTE'">
        <div class="bs-content">
          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Orden de pedido</div>
                  </div>
                  <div class="card-row">
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">Empresa</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Sucursal</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Folio</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Cotizacion</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px" *ngIf="Globals.isStringNotEmpty(objOrdenPedido?.ticket)">
                        <div class="card-col-label card-label">Ticket</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Fecha</div>
                      </div>
                    </div>
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.empresaRazonSocial}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.sucursal}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">
                          <div class="badge label-badge" style="width: 165px;" >{{objOrdenPedido.folio}}</div>
                        </div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">
                          <div class="badge button-badge" style="width: 165px;" *ngIf="Globals.isStringNotEmpty( objOrdenPedido?.cotizacionFolio )" (click)="btnCotizacionPdf_clickEvent()">{{objOrdenPedido.cotizacionFolio}}</div>
                          <div class="badge label-badge" style="width: 165px;" *ngIf="!Globals.isStringNotEmpty( objOrdenPedido?.cotizacionFolio )">N/A</div>
                        </div>
                      </div>
                      <div class="card-row" style="margin: 0 20px" *ngIf="Globals.isStringNotEmpty(objOrdenPedido?.ticket)">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido?.ticket}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.fechaNatural}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

          <div fxLayout="row wrap">
            <!-- Card column -->
            <div fxFlex.gt-lg="100" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Cliente</div>
                  </div>
                  <div class="card-row">
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">Nombre comercial</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Razón social</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">R.F.F</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Dirección fiscal</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Dirección social</div>
                      </div>
                    </div>
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteNombreComercial}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteRazonSocial}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteRfc}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteDireccionFiscal}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.clienteDireccionSocial}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>

            <div fxFlex.gt-lg="100" fxFlex.gt-md="50" fxFlex.gt-xs="100" fxFlex="100">
              <mat-card class="panel-content-card">
                <div class="content-card">
                  <div class="card-row br_btm">
                    <div class="card-col-title">Contacto</div>
                  </div>
                  <div class="card-row">
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-label card-label">Nombre</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Puesto</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-label card-label">Teléfono</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-label card-label">Correo</div>
                      </div>
                    </div>
                    <div class="card-col">
                      <div class="card-row" style="margin: 5px 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoNombreCompleto}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoPuesto}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px 0 20px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoTelefono}}</div>
                      </div>
                      <div class="card-row" style="margin: 0 20px; padding-bottom: 15px">
                        <div class="card-col-fiel card-fiel">{{objOrdenPedido.contactoEmail}}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </div>

        </div>
      </div>
      <!--   FIN SOLICITUD   -->

      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'DIRECCIONES'">
        <div class="bs-content">
          <form [formGroup]="fgOrdenServicioDirecciones">
            <div class="bs-row">
              <div class="bs-col">
                <mat-form-field>
                  <input type="text" matInput formControlName="origen" maxlength="4095" placeholder="Origen" autocomplete="off">
                </mat-form-field>
              </div>
            </div>

            <div class="bs-row" [style.marginTop.px]="10">
              <div class="bs-col">
                <mat-form-field>
                  <input type="text" matInput formControlName="destino" maxlength="4095" placeholder="Destino" autocomplete="off">
                </mat-form-field>
              </div>
            </div>

            <div class="bs-row" [style.marginTop.px]="10">
              <div class="bs-col">
                <mat-form-field appearance="legacy">
                  <input type="text" placeholder="Autoriza" matInput formControlName="autoriza" maxlength="511" autocomplete="off">
                </mat-form-field>
              </div>

              <div class="bs-col">
                <mat-form-field appearance="legacy">
                  <input type="text" placeholder="Folio cliente" matInput formControlName="folioCliente" maxlength="511" autocomplete="off">
                </mat-form-field>
              </div>

              <div class="bs-col">
                <mat-form-field appearance="legacy">
                  <input type="text" placeholder="Folio empresa" matInput formControlName="folioEmpresa" maxlength="511" autocomplete="off">
                </mat-form-field>
              </div>
            </div>

            <div class="bs-row" [style.marginTop.px]="10">
              <div class="bs-col input-comentario">
                <mat-form-field>
                  <textarea matInput formControlName="descripcion" maxlength="4095" placeholder="Descripción del servicio" rows="10"></textarea>
                </mat-form-field>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!--   INICIO COMENTARIOS   -->
      <div class="datatable-responsive layout" [hidden]="objScene[0] != 'OPERACION'">
        <div class="options-bar">
          <div class="bs-content">
            <div class="bs-row">
              <div class="bs-col">
                Tiempos de ejecución
              </div>
            </div>
          </div>
        </div>
        <div class="main">
          <form [formGroup]="fgOrdenServicioTiemposEjecucion">
            <table class="datatable" style="min-width: 50%">
              <thead>
              <tr>
                <th [style.width.px]="135"></th>
                <th [style.width.px]="130">Fecha</th>
                <th [style.width.px]="110">Hora</th>
                <th [style.width.px]="130">Odometro</th>
                <th [style.width.px]="130">Horometro</th>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Fecha programada</td>
                <td>
                  <div class="table-input-calendar" [class.required]="fgOrdenServicioTiemposEjecucion.controls['programadaFecha'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['programadaFecha'].touched">
                    <input class="text-center" matInput formControlName="programadaFecha" [matDatepicker]="matDateProgramada" [readonly]="true" (click)="matDateProgramada.open()">
                    <mat-datepicker #matDateProgramada></mat-datepicker>
                    <button mat-raised-button (click)="matDateProgramada.open()"><span class="material-icons">today</span></button>
                  </div>
                </td>
                <td>
                  <div class="inputs-content">
                    <input type="text"
                           class="table-input text-center timeInput"
                           mask="Hh:m0"
                           [leadZeroDateTime]="true"
                           matInput
                           maxlength="5"
                           autocomplete="off"
                           formControlName="programadaHora"
                           [class.required]="fgOrdenServicioTiemposEjecucion.controls['programadaHora'].hasError('required')  && fgOrdenServicioTiemposEjecucion.controls['programadaHora'].touched"
                           (keyup)="inputProgramadaTime_keyUpEvent($event)">
                    <span class="suffixSpan">Hrs.</span>
                  </div>
                </td>
                <td class="text-center">---</td>
                <td class="text-center">---</td>
              </tr>
              </tbody>
            </table>
          </form>
        </div>

        <div class="options-bar" style="margin-top:20px">
          <div class="bs-content">
            <div class="bs-row">
              <div class="bs-col">
                Unidades y operadores
              </div>
            </div>
          </div>
        </div>
        <div class="options-bar">
          <div class="option">
            <button type="button" matTooltip="Nuevo" [disabled]="btnOptionBar.disabled" (click)="btnUnidadAppend_clickEvent()">
              <span class="material-icons">add</span>
            </button>
          </div>
          <div class="option">
            <button type="button" matTooltip="Eliminar" [disabled]="!boolChkSomeOrdenServicioUnidad || btnOptionBar.disabled" (click)="btnUnidadDelete_clickEvent()">
              <span class="material-icons">clear</span>
            </button>
          </div>
        </div>
        <div>
          <table class="datatable">
            <thead>
            <tr [style.maxHeight.px]="20">
              <th [style.width.px]="40" class="text-center"><mat-checkbox [disabled]="objChkBox.disabled" [checked]="boolChkOrdenServicioUnidad" (change)="chkOrdenServicioUnidadSelected_changeEvent($event.checked)"></mat-checkbox></th>
              <th [style.width.px]="50">Acc.</th>
              <th>Unidad</th>
              <th [style.width.%]="50">Operadores</th>
            </tr>
            </thead>
            <tbody>
            <tr app-ordenes-servicio-unidad-item style="height: 100px"
                #compUnidadesItem
                *ngFor="let itemUnidad of lstUnidades_Usuarios; let index = index;"
                [index]="index"
                [hasPrincipal]="hasPrincipal"
                [lstOrdenesServiciosMiembros_UsuariosPuestos]="lstOrdenesServiciosMiembros_UsuariosPuestos"
                (onDelete)="btnPartidasDelete_onDeleteEvent($event)"
                (onChecked)="chkItemOrdenServicioUnidadSelected_changeEvent()"
                (onPrincipal)="selectUnidadPrincipal($event, index)"
            ></tr>
            </tbody>
          </table>
        </div>
      </div>
      <!--   FIN COMENTARIOS   -->

    </div>
  </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="formOrdenServicioCreate_submitEvent()">Aceptar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>

