import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PictureUploadComponent } from './picture-upload.component';



@NgModule({
  declarations: [
    PictureUploadComponent
  ],
  imports: [
    CommonModule
  ],
  exports : [
    PictureUploadComponent
  ]
})
export class PictureUploadModule { }
