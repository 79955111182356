import {HttpClient, HttpEventType} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationConfig } from '../libraries/application-config';
import { Sessions } from '../libraries/sessions';
import { AuthService } from './auth.service';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ConfiguracionesService {

  constructor(  private http: HttpClient,
                private objAuthService : AuthService ) { }

  index(): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + 'api/configuraciones/index', Sessions.headerSession(this.objAuthService.access_token));
  }

  // update( objParams : any ) : Observable<any> {
  //   return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/configuraciones", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  // }

  update( objParams : any ) : Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/configuraciones", objParams, { headers: Sessions.headerFormData(this.objAuthService.access_token).headers, reportProgress: true, observe: 'events'}).pipe(map((event) => {

      switch (event.type) {
        case HttpEventType.UploadProgress:
          let total : any = event?.total;
          const progress = Math.round(100 * event.loaded / total);
          return {body : null, status: 'progress', uploadProgress: progress };

        case HttpEventType.Response:
          return {body : event.body, status: 'completed', uploadProgress: 100};
      }
    }));
  }

  validatedEmail( objParams : any ) : Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/configuraciones/test-email", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }
}
