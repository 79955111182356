import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {Observable} from "rxjs";
import {ApplicationConfig} from "../libraries/application-config";
import {Sessions} from "../libraries/sessions";

@Injectable({
  providedIn: 'root'
})

export class FileViewerService {

  constructor(  private http: HttpClient,
                private objAuthService : AuthService ) { }

  document(url : string): Observable<Blob> {
    return this.http.get<Blob>(ApplicationConfig.ENDPOINT + url, Sessions.headerFile(this.objAuthService.access_token));
  }
}
