import { Injectable }        from "@angular/core";
import { HttpClient }        from "@angular/common/http";
import { AuthService }       from "./auth.service";
import { Observable }        from "rxjs";
import { ApplicationConfig } from "../libraries/application-config";
import { Sessions }          from "../libraries/sessions";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import {UnidadMantenimientoTaller} from "../modelo/unidad-mantenimiento-taller";

@Injectable({
  providedIn: 'root'
})
export class UnidadesMantenimientosTalleresService {

  constructor(private http: HttpClient,
              private objAuthService: AuthService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UnidadMantenimientoTaller> {
    const idUnidadMantenimientoTaller = route.params['idUnidadMantenimientoTaller'];
    return this.http.get<UnidadMantenimientoTaller>(ApplicationConfig.ENDPOINT + 'api/mantenimientos/talleres/' + idUnidadMantenimientoTaller, Sessions.headerSession(this.objAuthService.access_token));
  }
  index(objParams : number): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + 'api/mantenimientos/talleres/index', objParams, Sessions.headerSession(this.objAuthService.access_token));
  }

  datatable( objParams : any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/datatable", objParams, Sessions.headerSession(this.objAuthService.access_token));
  }

  findUnidad(strSearch : string): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/" + strSearch + "/findUnidad", Sessions.headerSession(this.objAuthService.access_token));
  }

  edit(idUnidadMantenimiento : number): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/" + idUnidadMantenimiento + "/edit", Sessions.headerSession(this.objAuthService.access_token));
  }

  refresh( objParams : any ): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/refresh", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  inicioServicio( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/inicio_servicio", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  finalize( objParams : any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/finalize", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  finServicio( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/fin_servicio", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  cancel( objParams : any): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/cancel", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  cancelarServicio( objParams : any ) : Observable<any> {
    return this.http.put<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/cancelar_servicio", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  requestExtension(): Observable<any> {
    return this.http.get<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/request_extension", Sessions.headerSession(this.objAuthService.access_token));
  }

  extension_date( objParams : any ) : Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/prorroga", objParams, Sessions.headerFormData(this.objAuthService.access_token));
  }

  generatePdf(idUnidadMantenimiento : number, idUnidadMantenimientoTaller : number): Observable<Blob> {
    return this.http.get<Blob>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/pdf/" + idUnidadMantenimiento + "/" + idUnidadMantenimientoTaller, Sessions.headerFile(this.objAuthService.access_token));
  }

  exportExcel(objParams : any ): Observable<any> {
    return this.http.post<any>(ApplicationConfig.ENDPOINT + "api/mantenimientos/talleres/export/excel", objParams, Sessions.headerFile(this.objAuthService.access_token));
  }

}
