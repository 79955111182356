import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'FileSize'
})
export class FileSizePipe implements PipeTransform {


  private units = ['KB', 'MB', 'GB', 'TB', 'PB'];


  transform(bytes: number = 0, precision: number = 2 ): string {

    if ( isNaN( parseFloat( String(bytes) )) || ! isFinite( bytes ) ) {
      return '?';
    }

    let unit = 0;

    while ( bytes >= 1024 ) {
      bytes /= 1024;
      unit ++;
    }

    if(unit < 1)
      return Math.round(bytes) + ' ' + this.units[ unit ];
    else
      return bytes.toFixed( + precision ) + ' ' + this.units[ unit ];
  }

}
