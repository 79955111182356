<div class="mat-dialog-title white">
  <div class="title">{{titulo}}</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="btnClose_clickEvent()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content">
    <div class="bs-row">
      <div class="bs-col col-icon">
        <span class="material-icons">check_circle</span>
      </div>

      <div class="bs-col col-message">
        <div *ngIf="mensaje?.trim() !=''">{{mensaje}}</div>
        <div *ngIf="mensajeHTML?.trim() !=''" [innerHTML]="mensajeHTML"></div>
      </div>
    </div>
  </div>



</div>
<div class="mat-dialog-actions">
  <button class="secundary" type="button" (click)="btnAceptar_clickEvent()">{{successLabel}}</button>
</div>
