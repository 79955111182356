import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Globals} from 'src/app/libraries/globals';

@Component({
  selector: 'comp-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, AfterViewInit {

  @Input('index')           index           : number  = -1;
  @Input('icon')            icon            : string  = 'file_upload';
  @Input('title')           title           : string  = "Seleccionar archivo";
  @Input('backgroundColor') backgroundColor : string  = "#26C6DA";
  @Input('accept')          accept          : string  = "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  @Input('disabled')        disabled        : boolean = false;

  private file : File | null = null;
  private _fileName : string = '';
  @Input('fileName')
  get fileName(): string { return this._fileName; }
  set fileName(value: string) {
    if(value.length > 25) {
      this._fileName = value.substr(1,25) + '...';
    } else {
      this._fileName = value;
    }
  }

  @Output('comFile_selectedFileChange') comFile_selectedFileChange : EventEmitter<FileUploadComponent> = new EventEmitter<FileUploadComponent>();

  @ViewChild('inputFile') inputFile : ElementRef;

  public Globals = Globals;

  constructor() {}

  ngOnInit(): void {
  }

  ngAfterViewInit() : void {
  }

  public btnUpload_clickEvent() : void {
    this.inputFile.nativeElement.click();
  }

  public isValid() : boolean {
    let objReturn : boolean = false;
    if(Globals.exist(this.inputFile) && Globals.exist(this.inputFile.nativeElement) && Globals.exist(this.inputFile.nativeElement.value)) {
      objReturn = ( this.inputFile.nativeElement.value.trim() != '');
    }

    if(this.fileName.trim() != '') objReturn = true;

    return objReturn;
  }



  public file_changeEvent(evento : any) : void {
    if( this.isValid() ) {
    if( this.inputFile.nativeElement.files.length == 1 ) {
      var objFileReader = new FileReader();
      objFileReader.readAsDataURL(this.inputFile.nativeElement.files[0]);
      this.file = this.inputFile.nativeElement.files[0];
      let name : string = this.inputFile.nativeElement.files[0].name;
      if(name.length > 25) {
        this.fileName = name.substr(1,25) + '...';
      } else {
        this.fileName = name;
      }
      this.comFile_selectedFileChange.emit(this);
    }
  }

}

  public selectedFile() : any {
    let objReturn = null;
    if( Globals.exist(this.inputFile) && Globals.exist(this.inputFile.nativeElement) && Globals.exist(this.inputFile.nativeElement.files) && this.inputFile.nativeElement.files.length == 1 ) {
      let tamano : number = 0;
      if(this.file != null) tamano = this.file.size;
      objReturn = {
        nombre  : this.getFileName(this.fileName),
        extension : this.getExtension(this.fileName),
        tamano  : this.getSize(tamano),
        fichero : this.inputFile.nativeElement.files[0]
      };
    }

    return objReturn;
  }

  private getSize(size : number ) : number {
    const rsSize = size/1024;
    return Math.round(rsSize * 100) / 100
  }

  private getFileName (rawName : any) : any {
    return  rawName.split('.').slice(0, -1).join('.');
  }

  private getExtension (rawName : any) : any {
    return  rawName.split('.').pop();
  }
}
