<div class="mat-dialog-title">
  <div class="title"> Cancelar orden de servicio {{folio}} </div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content bs-fix-scroll">
    <div class="bs-content">
      <div class="bs-row" [style.marginTop.px]="10">
        <div class="bs-col">
          <mat-form-field>
            <textarea matInput [formControl]="fcComentario" required maxlength="1024" placeholder="Motivo cancelación" rows="6" cols="70"></textarea>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="submit" [disabled]="btnAceptar.disabled" (click)="formCotizacionCancelacion_submitEvent()">Aceptar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cancelar</button>
</div>
