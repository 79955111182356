import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormControl, Validators} from "@angular/forms";
import {Globals} from "../../../../../libraries/globals";
import {FormOptimizer} from "../../../../../libraries/formOptimizer";

@Component({
  selector: 'app-cotizaciones-edit-partida-form',
  templateUrl: './partida-form.component.html',
  styleUrls: ['./partida-form.component.scss']
})
export class CotizacionesEditPartidaFormComponent implements OnInit {

  @Output('onClose') onClose : EventEmitter<CotizacionesEditPartidaFormComponent> = new EventEmitter<CotizacionesEditPartidaFormComponent>();
  @Output('onAceptar') onAceptar : EventEmitter<CotizacionesEditPartidaFormComponent> = new EventEmitter<CotizacionesEditPartidaFormComponent>();

  public fcServicio            : FormControl = new FormControl('', [Validators.required]);
  public fcDescripcion         : FormControl = new FormControl('');
  public fcUnidadMedida        : FormControl = new FormControl('', [Validators.required]);
  public fcPrecioUnitario      : FormControl = new FormControl('', [Validators.required]);
  public fcMoneda              : FormControl = new FormControl('', [Validators.required]);
  public fcCantidad            : FormControl = new FormControl('', [Validators.required]);
  public fcDescuentoCantidad   : FormControl = new FormControl('');
  public fcDescuentoPorcentaje : FormControl = new FormControl('');

  public codigoMonedaPartida         : string = '';
  public codigoMoneda                : string = '';
  public idCotizacionPartida         : number = 0;
  public cantidad                    : number = 0;
  public precioUnitario              : number = 0;
  public precioUnitarioXEquivalencia : number = 0;
  public precioXCantidad             : number = 0;
  public descuentoCantidad           : number = 0;
  public descuentoPorcentaje         : number = 0;
  public descuentoActive             : number = 0; // CANT:1 || PORC:2
  public importe                     : number = 0;
  public checked                     : boolean = false;

  private _lstServicios : Array<any> = [];
  @Input('lstServicios')
  get lstServicios(): Array<any> { return this._lstServicios; }
  set lstServicios(value: Array<any>) {
    this._lstServicios = value;
    if( Globals.exist(this.lstServicios) && this.lstServicios.length > 0 ) this.fcServicio.setValue(this.lstServicios[0].idServicio);
  }

  private _lstMonedas : Array<any> = [];
  @Input('lstMonedas')
  get lstMonedas(): Array<any> { return this._lstMonedas; }
  set lstMonedas(value: Array<any>) {
    this._lstMonedas = value;
    if( Globals.exist(this.lstMonedas) && this.lstMonedas.length > 0 ) {
      this.fcMoneda.setValue(this.lstMonedas[0].idMoneda);
      this.codigoMonedaPartida = this.lstMonedas[0].codigo;
      this.codigoMoneda = this.lstMonedas[0].codigo;
    }
  }

  private _lstUnidadesMedidas : Array<any> = [];
  @Input('lstUnidadesMedidas')
  get lstUnidadesMedidas(): Array<any> { return this._lstUnidadesMedidas; }
  set lstUnidadesMedidas(value: Array<any>) {
    this._lstUnidadesMedidas = value;
    if( Globals.exist(this.lstUnidadesMedidas) && this.lstUnidadesMedidas.length > 0) this.fcUnidadMedida.setValue(this.lstUnidadesMedidas[0].idUnidadMedida);
  }

  private _lstImpuestos : Array<any> = [];
  public lstInnerImpuestos : Array<any> = [];
  @Input('lstImpuestos')
  get lstImpuestos(): Array<any> { return this._lstImpuestos; }
  set lstImpuestos(value: Array<any>) {
    this._lstImpuestos = value;
    this.lstInnerImpuestos = [];
    if( Globals.exist(this.lstImpuestos) && this.lstImpuestos.length > 0) {
      this.lstImpuestos.forEach( itemImpuesto => {
        let objImpuesto : any = {
          porcentajeNatural           : itemImpuesto.porcentajeNatural,
          impuesto                    : itemImpuesto.impuesto,
          impuestoTipo : {
            idImpuestoTipo            : itemImpuesto.impuestoTipo.idImpuestoTipo,
            impuestoTipo              : itemImpuesto.impuestoTipo.impuestoTipo,
          },
          idImpuesto                  : itemImpuesto.idImpuesto,
          porcentaje                  : itemImpuesto.porcentaje,
          enabled                     : itemImpuesto.enabled,

          idCotizacionPartidaImpuesto : 0,
          checked                     : false,
          total                       : 0
        }
        this.lstInnerImpuestos.push(objImpuesto);
      });
    }
  }

  private _equivalencia : number = 0;
  @Input('equivalencia')
  get equivalencia(): number { return this._equivalencia; }
  set equivalencia(value: number) {
    this._equivalencia = value;
    this.calcPrecioXCantidad();
    this.calcDescuento();
    this.calcImporte();
  }

  private _objMoneda : any;
  @Input('objMoneda')
  get objMoneda(): any {return this._objMoneda; }
  set objMoneda(value: any) {
    this._objMoneda = value;
    this.codigoMoneda = this.objMoneda?.codigo;
    this.calcPrecioXCantidad();
    this.calcDescuento();
    this.calcImporte();
  }

  public objLoader = {
    type            : 'loader',
    visible         : false
  };

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  };

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  };

  public objChkBox = {
    type     : 'checkbox',
    disabled : false
  };

  public btnAccion = {
    type            : 'button',
    disabled        : false
  };

  public formComponents: Array<any> = [
    this.objChkBox,
    this.btnAccion,
    this.fcServicio,
    this.fcDescripcion,
    this.fcUnidadMedida,
    this.fcPrecioUnitario,
    this.fcMoneda,
    this.fcCantidad,
    this.fcDescuentoCantidad,
    this.fcDescuentoPorcentaje
  ];

  constructor(
    private objDialogRef: MatDialogRef<CotizacionesEditPartidaFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.lstMonedas         = this.data.monedas;
    this.lstServicios       = this.data.servicios;
    this.lstUnidadesMedidas = this.data.unidadesMedidas;
    this.lstImpuestos       = this.data.impuestos;
    this.equivalencia       = this.data.equivalencia;
    this.objMoneda          = this.data.moneda;

    this.fcCantidad.valueChanges.subscribe(value => {
      this.cantidad = 0;
      if( Globals.exist(value) && value != "" && Number(value) != NaN ) {
        this.cantidad = Number(this.fcCantidad.value);
        this.calcPrecioXCantidad();
        this.calcDescuento();
        this.calcImporte();
      }
    });

    this.fcPrecioUnitario.valueChanges.subscribe(value => {
      this.precioUnitario = 0;
      if( Globals.exist(value) && value != "" && Number(value) != NaN ) {
        this.precioUnitario = Number(this.fcPrecioUnitario.value);
        this.calcPrecioXCantidad();
        this.calcDescuento();
        this.calcImporte();
      }
    });

    if(Globals.exist(this.data?.itemCotizacionPartida)) {
      this.checked             = this.data.itemCotizacionPartida.checked;
      this.idCotizacionPartida = this.data.itemCotizacionPartida.idCotizacionPartida;
      this.descuentoActive     = this.data.itemCotizacionPartida.idCotizacionPartidaTipoDescuento;
      let lstImpuestos : Array<any> = this.data.itemCotizacionPartida.impuestos;
      if( Globals.exist(lstImpuestos) && lstImpuestos.length > 0) {
        this.lstInnerImpuestos.forEach( (itemImpuesto, index) => {
          itemImpuesto.porcentajeNatural           = lstImpuestos[index].porcentajeNatural;
          itemImpuesto.impuesto                    = lstImpuestos[index].impuesto;
          itemImpuesto.impuestoTipo = {
            idImpuestoTipo : lstImpuestos[index].impuestoTipo.idImpuestoTipo,
            impuestoTipo   : lstImpuestos[index].impuestoTipo.impuestoTipo,
          };
          itemImpuesto.idImpuesto                  = lstImpuestos[index].idImpuesto;
          itemImpuesto.porcentaje                  = lstImpuestos[index].porcentaje;
          itemImpuesto.enabled                     = lstImpuestos[index].enabled;

          itemImpuesto.idCotizacionPartidaImpuesto = lstImpuestos[index].idCotizacionPartidaImpuesto;
          itemImpuesto.checked                     = lstImpuestos[index].checked;
          itemImpuesto.total                       = (lstImpuestos[index].checked)? Number(lstImpuestos[index].total) : 0;
        });
      }
      this.fcMoneda.setValue(this.data.itemCotizacionPartida.idMoneda);
      this.fcCantidad.setValue(this.data.itemCotizacionPartida.cantidad);
      this.fcServicio.setValue(this.data.itemCotizacionPartida.idServicio);
      this.fcDescripcion.setValue(this.data.itemCotizacionPartida.concepto);
      this.fcUnidadMedida.setValue(this.data.itemCotizacionPartida.idUnidadMedida);
      this.fcPrecioUnitario.setValue(this.data.itemCotizacionPartida.precioUnitario);
      this.fcDescuentoCantidad.setValue(this.data.itemCotizacionPartida.descuentoCantidad);
      this.fcDescuentoPorcentaje.setValue(this.data.itemCotizacionPartida.descuentoPorcentaje);
    }
  }

  ngOnInit(): void {
  }

  public formPartida_submitEvent(): void {
    if(this.validate()) {
      this.onAceptar.emit(this);
      this.close();
    }
  }

  public getCotizacionPartida() : any {
    let objReturn : any;

    this.lstInnerImpuestos.forEach((itemImpuesto : any ) => {
      if(!itemImpuesto['checked']) {
        let total : number = 0;
        itemImpuesto.total = total.toFixed(2);
      }
    });

    let servicio     = this.lstServicios.find(itemMoneda => itemMoneda.idServicio == Number(this.fcServicio.value));
    let unidadMedida = this.lstUnidadesMedidas.find(itemMoneda => itemMoneda.idUnidadMedida == Number(this.fcUnidadMedida.value));
    let moneda       = this.lstMonedas.find(itemMoneda => itemMoneda.idMoneda == Number(this.fcMoneda.value));

    objReturn = {
      checked                          : this.checked,
      idCotizacionPartida              : this.idCotizacionPartida,
      servicio                         : servicio.servicio,
      idServicio                       : Number(servicio.idServicio),
      concepto                         : this.fcDescripcion.value,
      unidadMedida                     : unidadMedida.unidadMedida,
      idUnidadMedida                   : Number(unidadMedida.idUnidadMedida),
      precioUnitario                   : this.precioUnitario.toFixed(2),
      precioXCantidad                  : this.precioXCantidad.toFixed(2),
      moneda                           : moneda.codigo,
      idMoneda                         : Number(moneda.idMoneda),
      cantidad                         : this.cantidad.toFixed(2),
      descuentoCantidad                : this.descuentoCantidad.toFixed(2),
      descuentoPorcentaje              : this.descuentoPorcentaje.toFixed(2),
      idCotizacionPartidaTipoDescuento : this.descuentoActive,
      impuestos                        : this.lstInnerImpuestos,
      importe                          : this.importe.toFixed(2)
    }

    return  objReturn;
  }

  private calcPrecioXCantidad() : void {
    if( this.codigoMoneda != this.codigoMonedaPartida) {
      if(this.objMoneda?.idMoneda != 1) {
        this.precioUnitarioXEquivalencia = this.precioUnitario / this.equivalencia;
      } else if(this.objMoneda?.codigo != this.codigoMonedaPartida) {
        this.precioUnitarioXEquivalencia = this.precioUnitario * this.equivalencia;
      }
      this.precioXCantidad = this.cantidad * this.precioUnitarioXEquivalencia;
    } else {
      this.precioXCantidad = this.cantidad * this.precioUnitario;
    }
  }

  public setDescuentoActive($event : any, idDesc: number) {
    if($event.keyCode != 9 && !$event.shiftKey ) {
      this.descuentoActive = idDesc;
    }
  }

  public calcDescuento() : void {
    switch(this.descuentoActive) {
      case 1:
        this.calcDescuentoCantidad();
        break;
      case 2:
        this.calcDescuentoPorcentaje();
        break;
    }

    this.calcImporte();
  }

  public calcDescuentoCantidad() : void {
    if(this.descuentoActive == 1) {
      this.descuentoCantidad   = 0.00;
      this.descuentoPorcentaje = 0;

      let regex = '[0-9]+';
      if(Globals.exist(this.fcDescuentoCantidad.value) && this.fcDescuentoCantidad.value != '' && this.fcDescuentoCantidad.value.toString().match(regex)) {
        if(Number(this.fcDescuentoCantidad.value) != 0 && this.fnValidate(this.fcPrecioUnitario.value) && this.fnValidate(this.fcCantidad.value)) {
          this.descuentoCantidad = Number(this.fcDescuentoCantidad.value);
          this.descuentoPorcentaje = (this.descuentoCantidad / this.precioXCantidad) * 100;
          this.fcDescuentoPorcentaje.setValue(this.descuentoPorcentaje.toFixed(2));
        } else {
          this.fcDescuentoPorcentaje.setValue(0);
          this.descuentoActive = 0;
        }
      } else {
        this.fcDescuentoPorcentaje.setValue('');
        this.fcDescuentoCantidad.setValue('');
        this.descuentoActive = 0;
      }
    }
  }

  public calcDescuentoPorcentaje() : void {
    if(this.descuentoActive == 2) {
      this.descuentoPorcentaje = 0;
      this.descuentoCantidad   = 0;
      let regex = '[0-9]+';
      if( Globals.exist(this.fcDescuentoPorcentaje.value) && this.fcDescuentoPorcentaje.value != '' && this.fcDescuentoPorcentaje.value.toString().match(regex)) {
        if (Number(this.fcDescuentoPorcentaje.value) != 0 && this.fnValidate(this.fcPrecioUnitario.value) && this.fnValidate(this.fcCantidad.value) ) {
          this.descuentoPorcentaje = Number(this.fcDescuentoPorcentaje.value);
          this.descuentoCantidad = (this.precioXCantidad * this.descuentoPorcentaje) / 100;
          this.fcDescuentoCantidad.setValue(this.descuentoCantidad.toFixed(2));
        } else {
          this.fcDescuentoCantidad.setValue(0);
          this.descuentoActive = 0;
        }
      } else {
        this.fcDescuentoPorcentaje.setValue('');
        this.fcDescuentoCantidad.setValue('');
        this.descuentoActive = 0;
      }
    }
  }

  public calcImporte() : void {
    this.importe = this.precioXCantidad - this.descuentoCantidad;
    this.lstInnerImpuestos.forEach(itemImpuesto => {
      let total : number = this.precioXCantidad * (itemImpuesto.porcentaje / 100);
      itemImpuesto.total = total.toFixed(2);
    });
  }

  public selectMoneda_selectedEvent(event : any) : void {
    let idMoneda : number = event.target.value;
    let objMoneda = this.lstMonedas.find(itemMoneda => itemMoneda.idMoneda == idMoneda);
    this.codigoMonedaPartida = objMoneda.codigo;
    this.calcPrecioXCantidad();
    this.calcDescuento();
  }

  public fnValidate( value : string ) : boolean {
    let validate : boolean = false;

    if (Globals.exist(value) && value != "" && !isNaN(Number(value)) && Number(value) != 0) validate = true;

    return validate;
  }

  public validate() : boolean {
    let objReturn : boolean = true;

    this.fcServicio.markAsTouched();
    this.fcDescripcion.markAsTouched();
    this.fcUnidadMedida.markAsTouched();
    this.fcPrecioUnitario.markAsTouched();
    this.fcMoneda.markAsTouched();
    this.fcCantidad.markAsTouched();
    this.fcDescuentoCantidad.markAsTouched();
    this.fcDescuentoPorcentaje.markAsTouched();

    if( this.fcServicio.hasError('required') ) objReturn = false;
    if( this.fcPrecioUnitario.hasError('required') ) objReturn = false;
    if( this.fcCantidad.hasError('required') ) objReturn = false;
    if( this.fcMoneda.hasError('required') ) objReturn = false;
    if( this.fcUnidadMedida.hasError('required') ) objReturn = false;

    return objReturn;
  }

  public chkItemImpuesto_clickEvent(index : number) : void {
    this.lstInnerImpuestos[index].checked = !this.lstInnerImpuestos[index].checked;
  }

  public disableFormPartida() : void {
    FormOptimizer.formDisable(this.formComponents);
  }

  public enableFormPartida() : void {
    FormOptimizer.formEnable(this.formComponents);
  }

  public close(): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

  public (): void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }
}
