<div class="mat-dialog-title">
  <div class="title">Cancelación de orden de servicio</div>
  <div class="spacer"></div>
  <div class="buttons">
    <button type="button" class="title-button" (click)="close()">
      <mat-icon class="title-button-icon">clear</mat-icon>
    </button>
  </div>
</div>

<div class="mat-dialog-body">
  <div class="bs-content bs-fix-scroll">
    <div class="bs-content">
      <div class="bs-row">
        <div class="bs-col">
          <mat-card class="card-info-content no-border">
            <div class="card-body">
              <div class="card-text">
                <mat-icon class="card-icon">description</mat-icon>
                <div class="card-label">Folio</div>
                <div class="card-field">
                  <span class="badge label-badge" style="width: 165px;" >{{objOrdenServicio?.folio}}</span>
                </div>
              </div>
              <div class="card-text">
                <mat-icon class="card-icon">fact_check</mat-icon>
                <div class="card-label">Estatus</div>
                <div class="card-field">
                  <span class="badge" [class]="['estatus-' + objOrdenServicio?.idOrdenServicioEstatus]">
                    {{objOrdenServicio?.ordenServicioEstatus}}
                  </span>
                </div>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

      <div class="bs-row">
        <div class="bs-col">
          <div class="notification alert-danger">
            <span class="material-icons">info</span><span>No pueden existir actividades activas o completadas para poder cancelar</span>
          </div>
        </div>
      </div>

      <div class="bs-row" [style.marginTop.px]="15">
        <div class="bs-col">
          <div class="card-table-content">
            <div class="title-card-bar">
              <span class="title">Gerenciamientos</span>
            </div>
            <table class="datatable">
              <thead>
              <tr>
                <th [style.width.px]="40" class="text-center">No.</th>
                <th [style.width.px]="30" class="text-center"></th>
                <th>Folio</th>
                <th [style.width.px]="160">Orden Servicio</th>
                <th [style.width.px]="150">Estatus</th>
                <th [style.width.px]="110">Fecha</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let itemOrdenServicioMiembro of lstOrdenesServiciosMiembros; let index = index;">
                <td class="text-center">{{index + 1}}</td>
                <td class="text-center">
                  <button class="menu-option" mat-icon-button matTooltip="Gerenciamiento" (click)="btnOrdenServicioGerenciamiento_clickEvent(itemOrdenServicioMiembro)">
                    <mat-icon>place</mat-icon>
                  </button>
                </td>
                <td>
                  <span class="badge label-badge">{{ itemOrdenServicioMiembro?.folio }}</span>
                </td>
                <td class="text-center">
                  <span class="badge button-badge" (click)="verPdf_clickEvent(itemOrdenServicioMiembro)">{{itemOrdenServicioMiembro?.ordenServicioFolio}}</span>
                </td>
                <td class="text-center">
                  <span class="badge" [class]="['gerenciamiento-estatus-' + itemOrdenServicioMiembro?.idGerenciamientoEstatus]">{{itemOrdenServicioMiembro?.gerenciamientoEstatus}}</span>
                </td>
                <td class="text-center">{{ itemOrdenServicioMiembro?.fechaNatural }}</td>
              </tr>
              <tr *ngIf="Globals.isArray(lstOrdenesServiciosMiembros) && lstOrdenesServiciosMiembros.length === 0">
                <td colspan="6" class="text-center"> No hay gerenciamientos</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="mat-dialog-loader">
  <mat-progress-bar class="loader" *ngIf="objLoader.visible" color="accent" mode="indeterminate"></mat-progress-bar>
</div>

<div class="mat-dialog-actions">
  <button class="primary" type="button" [disabled]="btnAceptar.disabled" (click)="fnOrdenPedidoCancelar_submitEvent()">Cancelar</button>
  <button class="gray-color" type="button" [disabled]="btnCancelar.disabled" (click)="close()">Cerrar</button>
</div>
