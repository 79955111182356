import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl, Validators} from "@angular/forms";
import {FormOptimizer} from "../../../../../libraries/formOptimizer";

@Component({
  selector: 'tr[app-cotizaciones-edit-file-item]',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.scss']
})
export class CotizacionesEditFileItemComponent implements OnInit {

  public idCotizacionFichero : number = 0;
  public fcNombre : FormControl = new FormControl('', [Validators.required]);
  public fcTamano : FormControl = new FormControl('');

  private _itemFile : any= '';
  @Input('itemFile')
  get itemFile(): any { return this._itemFile; }
  set itemFile(value: any) {
    this._itemFile = value;
    this.fcNombre.setValue(this._itemFile.name);
    this.fcTamano.setValue(this._itemFile.size);
    this.usuarioFicheroTipo_initEvent();
  }

  @Input('index') index : number;

  @Output('onDelete') onDelete : EventEmitter<CotizacionesEditFileItemComponent> = new EventEmitter<CotizacionesEditFileItemComponent>();
  @Output('onChange') onChange : EventEmitter<CotizacionesEditFileItemComponent> = new EventEmitter<CotizacionesEditFileItemComponent>();
  @Output('onInit') onInit     : EventEmitter<CotizacionesEditFileItemComponent> = new EventEmitter<CotizacionesEditFileItemComponent>();

  public uploadResponse = {
    status: '',
    uploadProgress: '',
    message : '',
    boolFinalize : false,
    boolInitial : true,
    boolSuccess : true
  };

  constructor() { }

  ngOnInit(): void {
  }

  public validate() : boolean {
    let objReturn : boolean = true;

    this.fcNombre.markAsTouched();

    // VALIDAR INPUT
    if( this.fcNombre.hasError('required') ) objReturn = false;

    return objReturn;
  }

  public getCotizacionFichero() : Array<any> {

    let objReturn : any;

    if( this.validate() )  {
      objReturn = {
        nombre                : FormOptimizer.formDataString(this.fcNombre ),
        tamano                : FormOptimizer.formDataNumber( this.fcTamano ),
        file                  : this.itemFile
      };
    }

    return objReturn;
  }

  public clienteFicheroTipo_selectionChangeEvent(event : any) : void {
    this.onChange.emit(this);
  }

  public usuarioFicheroTipo_initEvent() {
    this.onInit.emit(this);
  }

  public formDisable() : void {
    FormOptimizer.formDisable([this.fcNombre]);
  }

  public formEnable() : void {
    FormOptimizer.formEnable([this.fcNombre]);
  }

  public btnFicheroCliente_eliminarEvent(evento : any ) : void {
    this.onDelete.emit(this);
  }

}
