import {AfterViewInit, Component, EventEmitter, Inject, OnInit, Output, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";

import {UnidadesMantenimientosImagenesService} from "../../../../services/unidades-mantenimientos-imagenes.service";
import {FormOptimizer} from "../../../../libraries/formOptimizer";
import {ApplicationConfig} from "../../../../libraries/application-config";
import {Globals} from "../../../../libraries/globals";
import {ZoneUploaderComponent} from "../../../../components/zone-uploader/zone-uploader.component";
import {UnidadesMantenimientosTalleresService} from "../../../../services/unidades-mantenimientos-talleres.service";
import {SuccessComponent} from "../../../../components/success/success.component";
import {AlertComponent} from "../../../../components/alert/alert.component";
import * as moment from 'moment';
import { PictureGridUploadComponent } from "../../../../components/picture-grid-upload/picture-grid-upload.component";
import {FileViewerComponent} from "../../../../components/file-viewer/file-viewer.component";
import {saveAs} from "file-saver";

@Component({
  selector: 'app-unidad-mantenimiento-taller-finalize-service',
  templateUrl: './finalize-service.component.html',
  styleUrls: ['./finalize-service.component.scss']
})
export class UnidadMantenimientoTalleresFinalizeServiceComponent implements OnInit, AfterViewInit {

  @Output('onClose') onClose: EventEmitter<UnidadMantenimientoTalleresFinalizeServiceComponent> = new EventEmitter<UnidadMantenimientoTalleresFinalizeServiceComponent>();
  @ViewChild('compZoneUploader') compZoneUploader : ZoneUploaderComponent;
  @ViewChildren('compImageItem') compImageItem    : QueryList<PictureGridUploadComponent>;

  public isProrroga                        : boolean = false;
  public fgMantenimientoFinalize : FormGroup = new FormGroup({
    inicioServicioFecha             : new FormControl(''),
    finPrevistoServicioFecha        : new FormControl({value: '', disabled: this.isProrroga}),
    finPrevistoServicioFechaActual  : new FormControl(''),
    inicioServicioDescripcion       : new FormControl(''),
    finServicioFecha                : new FormControl('', Validators.required),
    finServicioDescripcion          : new FormControl(''),
  });

  public fgMantenimientoFinServicio : FormGroup = new FormGroup({
    horometro              : new FormControl(''),
    odometro               : new FormControl(''),
  });

  public unidadMantenimientoTaller         : string = '';
  public idUnidadMantenimientoTaller       : number = 0;
  public objApplicationConfig                    = ApplicationConfig;
  public objGlobal                               = Globals;
  public objUnidadMantenimiento            : any = {};
  public objUnidadMantenimientoTaller      : any = {};
  public objUnidad                         : any = {};
  public objUsuario                        : any = {};
  public objTaller                         : any = {};
  public objScene                          : any = ["DETALLES"];
  public titleSuccess                      : string = '';
  public messageSuccess                    : string = '';
  public lstUnidadesMantenimientosImagenes : Array<any> = [];
  public lstUnidadesMantenimientosImagenesTalleres : Array<any> = [];
  public openGallery                       : boolean = false;
  public valueConfiguracion                : boolean = false;
  public boolHorometro                     : boolean = false;
  public boolOdometro                      : boolean = false;

  public indexFicheroUpload : number = 0;
  public countQueueUpload : number = 0;
  public countQueueTotal : number = 0;
  public lstQueueUploadFiles : Array<any> = [];

  public btnAceptar = {
    type            : 'button',
    disabled        : false
  };

  public btnCancelar = {
    type            : 'button',
    disabled        : false
  };

  public objLoader = {
    type            : 'loader',
    visible         : true
  };

  public formComponents: Array<any> = [
    this.objLoader,
    this.fgMantenimientoFinalize,
    this.btnAceptar,
    this.btnCancelar
  ];

  constructor(
    private objUnidadesMantenimientosTalleresService : UnidadesMantenimientosTalleresService,
    private objDialogRef: MatDialogRef<UnidadMantenimientoTalleresFinalizeServiceComponent>,
    private objMatDialog: MatDialog,
    private objUnidadesMantenimientosImagenesService : UnidadesMantenimientosImagenesService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.unidadMantenimientoTaller = this.data.unidadMantenimientoTaller;
    this.idUnidadMantenimientoTaller = Number(this.data.idUnidadMantenimientoTaller)
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    setTimeout(()=> this.init());
  }

  public init() : void {
    FormOptimizer.formDisable(this.formComponents);
    let fdUnidadFinalize = new FormData();

    fdUnidadFinalize.append("idUnidadMantenimiento"       , this.data.idUnidadMantenimiento);
    fdUnidadFinalize.append("idUnidadMantenimientoTaller" , this.data.idUnidadMantenimientoTaller);
    this.objUnidadesMantenimientosTalleresService.finalize(fdUnidadFinalize)
      .subscribe(objResponse => {
        this.objUnidadMantenimiento       = objResponse.result.unidadMantenimiento;
        this.objUnidadMantenimientoTaller = objResponse.result.unidadesMantenimientosTaller;
        this.objUnidad  = objResponse.result.unidad;
        this.objUsuario = objResponse.result.usuario;
        this.objTaller  = objResponse.result.taller;
        let unidadMantenimientoImagen : any = objResponse.result.unidadesMantenimientosImagenes;

        if (Globals.exist(objResponse.result?.configuracion) && Globals.exist(objResponse.result.configuracion?.value)) {
          this.valueConfiguracion = (objResponse.result.configuracion.value === "true");
        }

        if (Globals.exist(this.objUnidadMantenimientoTaller?.servicioInicioFechaIso)) {
          this.fgMantenimientoFinalize.controls['inicioServicioFecha'].setValue(moment(this.objUnidadMantenimientoTaller.servicioInicioFechaIso, "DD-MM-YYYY"));
        }

        if (Globals.exist(this.objUnidadMantenimientoTaller?.servicioFinPrevistoFechaIso)) {
          this.fgMantenimientoFinalize.controls['finPrevistoServicioFecha'].setValue(moment(this.objUnidadMantenimientoTaller.servicioFinPrevistoFechaIso, "DD-MM-YYYY"));
          this.fgMantenimientoFinalize.controls['finPrevistoServicioFecha'].disable();
        }

        if (Globals.exist(this.objUnidadMantenimientoTaller?.servicioFinPrevistoFechaActualIso)) {
          this.fgMantenimientoFinalize.controls['finPrevistoServicioFechaActual'].setValue(moment(this.objUnidadMantenimientoTaller.servicioFinPrevistoFechaActualIso, "DD-MM-YYYY"));
        }

        this.fgMantenimientoFinalize.controls['inicioServicioDescripcion'].setValue(this.objUnidadMantenimientoTaller?.servicioInicioDescripcion);

        if (Globals.exist(unidadMantenimientoImagen?.unidadesMantenimientosGlobals)) {
          this.lstUnidadesMantenimientosImagenes = unidadMantenimientoImagen.unidadesMantenimientosGlobals;
        }

        this.isProrroga = this.objUnidadMantenimientoTaller?.isProrroga;

        if (Globals.exist(unidadMantenimientoImagen?.unidadesMantenimientosTalleres)) {
          this.lstUnidadesMantenimientosImagenesTalleres = unidadMantenimientoImagen.unidadesMantenimientosTalleres;
          this.unidadMantenimientoImagenTallerInit();
        }
        FormOptimizer.formEnable(this.formComponents);

        this.unidadMedicionInit();
      }, error => {
        FormOptimizer.formEnable(this.formComponents);
      });
  }

  public unidadMantenimientoImagenTallerInit() : void {
    this.lstUnidadesMantenimientosImagenesTalleres.forEach( itemUnidadMantenimientoImagenTaller => {
      if (itemUnidadMantenimientoImagenTaller.idUnidadesMantenimientosTaller == this.idUnidadMantenimientoTaller) itemUnidadMantenimientoImagenTaller.open = true;
      else itemUnidadMantenimientoImagenTaller.open = false;
      itemUnidadMantenimientoImagenTaller.unidadesMantenimientosImagenEstatus.forEach( (itemUnidadMantenimientoImagenEstatus:any) => {
        if (itemUnidadMantenimientoImagenTaller.idUnidadesMantenimientosTaller == this.idUnidadMantenimientoTaller && itemUnidadMantenimientoImagenEstatus.idUnidadesMantenimientosImagenEstatus == 2) itemUnidadMantenimientoImagenEstatus.open = true;
        else itemUnidadMantenimientoImagenEstatus.open = false;
      });
    });
  }

  public unidadMedicionInit() : void {
    if (Globals.exist(this.objUnidad) && Globals.exist(this.objUnidad?.hasHorometro) && this.objUnidad.hasHorometro) {
      this.fgMantenimientoFinServicio.controls['horometro'].setValidators([Validators.required]);
      this.fgMantenimientoFinServicio.controls['horometro'].updateValueAndValidity();
    } else {
      this.fgMantenimientoFinServicio.controls['horometro'].clearValidators();
      this.fgMantenimientoFinServicio.controls['horometro'].updateValueAndValidity();
    }

    if (Globals.exist(this.objUnidad) && Globals.exist(this.objUnidad?.hasOdometro) && this.objUnidad.hasOdometro) {
      this.fgMantenimientoFinServicio.controls['odometro'].setValidators([Validators.required]);
      this.fgMantenimientoFinServicio.controls['odometro'].updateValueAndValidity();
    } else {
      this.fgMantenimientoFinServicio.controls['odometro'].clearValidators();
      this.fgMantenimientoFinServicio.controls['odometro'].updateValueAndValidity();
    }
  }

  public fromTallerHojalateriaPinturaFinalize_submitEvent(): void {
    (<any>Object).values(this.fgMantenimientoFinalize.controls).forEach( (itemControl : FormControl) => {
      itemControl.markAsTouched();
    });

    if(this.fgMantenimientoFinServicio.valid) {
      if(this.fgMantenimientoFinalize.valid) {
        let fdUnidadFinalize = new FormData();

        fdUnidadFinalize.append("idUnidad"                    , FormOptimizer.formDataNumber(this.objUnidad?.idUnidad));
        fdUnidadFinalize.append("idUnidadMantenimiento"       , this.objUnidadMantenimientoTaller.idUnidadMantenimiento);
        fdUnidadFinalize.append("idUnidadMantenimientoTaller" , this.objUnidadMantenimientoTaller.idUnidadMantenimientoTaller);
        fdUnidadFinalize.append('horometro'                   , FormOptimizer.formDataNumber(this.fgMantenimientoFinServicio.controls['horometro']));
        fdUnidadFinalize.append('odometro'                    , FormOptimizer.formDataNumber(this.fgMantenimientoFinServicio.controls['odometro']));
        fdUnidadFinalize.append('finServicioFecha'            , FormOptimizer.formDataDate(this.fgMantenimientoFinalize.controls['finServicioFecha']));
        fdUnidadFinalize.append('finServicioDescripcion'      , FormOptimizer.formDataString(this.fgMantenimientoFinalize.controls['finServicioDescripcion']));

        FormOptimizer.formDisable(this.formComponents);
        this.compImageItem.toArray().forEach( itemComImage => itemComImage.formDisable());
        this.objUnidadesMantenimientosTalleresService.finServicio(fdUnidadFinalize)
          .subscribe(objResponse => {
            if( objResponse.action) {
              this.objUnidadMantenimientoTaller = objResponse.result.unidadMantenimientoTaller;
              if(this.compImageItem.toArray().length < 1) {
                this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo: objResponse.title, mensaje: objResponse.message, autoCloseDelay: 3000}));
                this.close();
                FormOptimizer.formEnable(this.formComponents);
                this.compImageItem.toArray().forEach( itemComImage => itemComImage.formEnable());
              } else {
                this.titleSuccess = objResponse.title;
                this.messageSuccess = objResponse.message;
                this.ficherosStore(true);
              }
            } else this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: objResponse.title, mensaje: objResponse.message}));
          }, error => {
            FormOptimizer.formEnable(this.formComponents);
            this.compImageItem.toArray().forEach( itemComImage => itemComImage.formEnable());
            this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo: 'Error de comunicación', mensaje: 'Ocurrió un error de comunicación con el servidor.',  autoCloseDelay: 3000 }));
          });
      } else {
        this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo : 'Error de formulario', mensaje : 'Los datos del servicio no están completos, complete la información.'}));
        this.objScene = ["SERVICIOS"];
      }
    } else {
      this.objMatDialog.open(AlertComponent, Globals.confirmConfig({titulo : 'Error de formulario', mensaje : 'Los datos del mantenimiento no están completos, complete la información.'}));
      this.objScene = ["DETALLES"];
    }
  }

  public ficherosStore( boolBegin : boolean = false) : void {
    let validate : boolean = false;
    if(boolBegin) {
      this.indexFicheroUpload = 0;
      let arrFileItems = this.compImageItem.toArray();
      this.lstQueueUploadFiles = [];

      let boolValid : boolean = true;
      for( let index : number = 0; index < arrFileItems.length; index++ ) {
        if( !arrFileItems[index].validate() ) boolValid = false;
      }

      if(boolValid) {
        validate = true;
        for( let index : number = 0; index < arrFileItems.length; index++ ) {
          let objFileItem = arrFileItems[index];
          this.lstQueueUploadFiles.push(objFileItem.getUnidadMantenimientoImagen());
          this.countQueueTotal++;

          objFileItem.formDisable();
        }
        this.objLoader.visible = true;
      }
    } else {
      validate = true;
    }

    if(validate) {
      if(this.countQueueUpload < this.countQueueTotal) {
        let objUnidadMantenimientoImagen = this.lstQueueUploadFiles[this.indexFicheroUpload];

        let formUnidadMantenimientoImagenStore = new FormData();

        formUnidadMantenimientoImagenStore.append("idUnidadMantenimiento"              , this.objUnidadMantenimientoTaller.idUnidadMantenimiento);
        formUnidadMantenimientoImagenStore.append("idUnidadMantenimientoTaller"        , this.objUnidadMantenimientoTaller.idUnidadMantenimientoTaller);
        formUnidadMantenimientoImagenStore.append("idUnidadMantenimientoImagenEstatus" , "2");
        formUnidadMantenimientoImagenStore.append("nombre"                             , objUnidadMantenimientoImagen.nombre);
        formUnidadMantenimientoImagenStore.append("file"                               , objUnidadMantenimientoImagen.file.object);

        this.objUnidadesMantenimientosImagenesService.store(formUnidadMantenimientoImagenStore)
          .subscribe( objResponse => {

            if(Globals.exist(objResponse)) {
              if(objResponse.status == 'completed'){
                if(objResponse.body.action) {

                  let arrFileItems = this.compImageItem.toArray();
                  arrFileItems[this.indexFicheroUpload].uploadResponse.boolFinalize  = true;
                  arrFileItems[this.indexFicheroUpload].uploadResponse.message       = 'Archivo cargado satisfactoriamente';
                  arrFileItems[this.indexFicheroUpload].uploadResponse.boolSuccess   = true;
                  this.compImageItem.toArray()[this.indexFicheroUpload].checked = true;

                  this.countQueueUpload++;
                  this.indexFicheroUpload++;

                  setTimeout(() => {
                    this.ficherosStore();
                  }, 1000);


                } else {
                  let arrFileItems = this.compImageItem.toArray();

                  arrFileItems[this.indexFicheroUpload].uploadResponse.boolFinalize  = true;
                  arrFileItems[this.indexFicheroUpload].uploadResponse.boolSuccess   = false;
                  arrFileItems[this.indexFicheroUpload].uploadResponse.message       = 'No fue posible subir el documento. Error: ' + objResponse.body.message;

                  this.countQueueUpload++;
                  this.indexFicheroUpload++;
                  setTimeout(() => {
                    this.ficherosStore();
                  }, 1000);
                }
              } else if(objResponse.status == 'progress') {
                this.compImageItem.toArray()[this.indexFicheroUpload].uploadResponse = objResponse;
              }
            }
          }, error => {

          });

      } else {
        let UploadFilesComplete = this.lstQueueUploadFiles.length;

        let boolSuccess : boolean = this.compImageItem.toArray().every( itemCompImage => itemCompImage.checked );
        if(boolSuccess) {
          this.objMatDialog.open(SuccessComponent, Globals.successConfig({titulo : this.titleSuccess, mensaje : this.messageSuccess,  autoCloseDelay: 3000 }));
            this.objLoader.visible = false;
            this.close();

        } else {
          this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Error de comunicación", mensaje : "Ocurrió un error de comunicación con el servidor.", autoCloseDelay : 3000}));
          this.objLoader.visible = false;
          FormOptimizer.formEnable(this.formComponents);
          this.compImageItem.toArray().forEach( itemComImage => itemComImage.formEnable());
        }

      }
    } else {
      this.objMatDialog.open(AlertComponent, Globals.alertConfig({titulo : "Formulario incompleto", mensaje : "Complete los campos solicitados.", autoCloseDelay : 3000}));
      FormOptimizer.formEnable(this.formComponents);
      this.compImageItem.toArray().forEach( itemComImage => itemComImage.formEnable());
    }
  }

  public inputHoroometro_changeEvent() : void {
    if (Globals.exist(this.objUnidad?.horometro) && Globals.isNumeric(this.objUnidad.horometro)) {
      if (Globals.isNumeric(this.fgMantenimientoFinServicio.controls['horometro'].value) && this.fgMantenimientoFinServicio.controls['horometro'].value < this.objUnidad.horometro) {
        this.boolHorometro = true;
      } else {
        this.boolHorometro = false;
      }
    } else {
      this.boolHorometro = false;
    }
  }

  public inputOdometro_changeEvent() : void {
    if (Globals.exist(this.objUnidad?.odometro) && Globals.isNumeric(this.objUnidad.odometro)) {
      if (Globals.isNumeric(this.fgMantenimientoFinServicio.controls['odometro'].value) && this.fgMantenimientoFinServicio.controls['odometro'].value < this.objUnidad.odometro) {
        this.boolOdometro = true;
      } else {
        this.boolOdometro = false;
      }
    } else {
      this.boolOdometro = false;
    }
  }

  public compUnidadImagenFileItem_deleteEvent(target : PictureGridUploadComponent ) : void {
    this.compZoneUploader.files.splice(target.index, 1);
  }

  public verImagen_clickEvent(itemUnidadMantenimientoImagen : any) {
    let objDialog = this.objMatDialog.open(FileViewerComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      maxHeight: '100%',
      data: {
        extension : itemUnidadMantenimientoImagen.extension,
        fileName : itemUnidadMantenimientoImagen.nombre + '.'+ itemUnidadMantenimientoImagen.extension,
        pdfSrc : this.objApplicationConfig.ENDPOINT + 'api/mantenimientos/imagenes/foto/' + itemUnidadMantenimientoImagen.idUnidadMantenimientoImagen
      },
      backdropClass: 'backdropBackground',
      panelClass : 'visualizarPanelClass',
      disableClose: true
    });

    objDialog.componentInstance.onDownload.subscribe( () => {
      this.objUnidadesMantenimientosImagenesService.download(itemUnidadMantenimientoImagen.idUnidadMantenimientoImagen)
        .subscribe(objResponse => {
          saveAs(objResponse, itemUnidadMantenimientoImagen.nombre + itemUnidadMantenimientoImagen.extension);
        }, error => {

        });
    });
  }

  public opcGallery_clickEvent() : void {
    this.openGallery = !this.openGallery;
  }

  public opcMenu_clickEvent(index : number) : void {
    this.lstUnidadesMantenimientosImagenesTalleres[index].open = !this.lstUnidadesMantenimientosImagenesTalleres[index].open;
    if(!this.lstUnidadesMantenimientosImagenesTalleres[index].open) {
      this.lstUnidadesMantenimientosImagenesTalleres[index].unidadesMantenimientosImagenEstatus.forEach( (itemUnidadMantenimientoImagenEstatus : any) => itemUnidadMantenimientoImagenEstatus.open = false);
    }
  }

  public opcSubMenu_clickEvent(indexTaller : number, indexEstatus : number) : void {
    this.lstUnidadesMantenimientosImagenesTalleres[indexTaller].unidadesMantenimientosImagenEstatus[indexEstatus].open = !this.lstUnidadesMantenimientosImagenesTalleres[indexTaller].unidadesMantenimientosImagenEstatus[indexEstatus].open;
  }

  public close() : void {
    this.objDialogRef.close();
    this.onClose.emit(this);
  }

}
